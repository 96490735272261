import React, { ButtonHTMLAttributes, FC } from 'react';
import './HitBox.scss';

type HitBoxProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  disableHighlight?: boolean;
};

const HitBox: FC<HitBoxProps> = ({ className, children, disableHighlight, ...props }) => {
  return (
    <div className={className}>
      <button type="button" className="hit-box" {...props} tabIndex={disableHighlight ? -1 : undefined}>
        {children}
      </button>
    </div>
  );
};

export default HitBox;
