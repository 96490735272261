import React, { FC, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AssignCandidateSkillDto } from '../../../../api';
import { SAVE_AND_FINISH_LATER } from '../../../../components/Navigation/Navigation.const';
import { SkillsIcon } from '../../../../icons';
import { Navigation, NavigationProps, PaperHeader, Spinner, Steps } from '../../../../components';
import { BaseSkills } from '../../components';
import { assignCandidateSkillListThunk, formSkillsSelector, skillsStatusSelector } from '../../../../store/skills';
import './Skills.scss';
import Paper from '../../../../components/Paper/Paper';
import { SkillFormWithAction } from './Skills.types';
import { navigateTo } from '../../../../utils/navigateTo';

interface SkillsProps {
  navigation: NavigationProps;
  editMode?: boolean;
}

const validate = (skills: AssignCandidateSkillDto[]) => {
  const filledSkills = skills.filter((x: AssignCandidateSkillDto) => x.level != 0 && x.years != 0);
  const notFilledEnough = filledSkills.length < 7;
  const filledTooMany = filledSkills.length > 90;
  const overMaxYearsOfExperience = filledSkills.findIndex((skill: AssignCandidateSkillDto) => skill.years > 50) >= 0;
  let duplicates = false;
  for (let i = 0; i < skills.length; ++i) {
    const skill = skills[i];
    if (skills.findIndex((s, index) => skill.skillName === s.skillName && index > i) !== -1) {
      duplicates = true;
      break;
    }
  }

  return { notFilledEnough, duplicates, filledTooMany, overMaxYearsOfExperience };
};

const Skills: FC<SkillsProps> = (props) => {
  const defaultValues = useSelector(formSkillsSelector);
  const { isPending } = useSelector(skillsStatusSelector);
  const form = useForm({ defaultValues, mode: 'onChange' });

  return (
    <FormProvider {...form}>
      <form>
        <Paper variant="default">
          <PaperHeader
            stepName="Skills"
            icon={<SkillsIcon className="header_text" />}
            currentStep={Steps.SKILLS}
            text={'SKILLS.DESCRIPTION'}
            subText={'SKILLS.SUB_DESCRIPTION'}
          />
        </Paper>
        <BaseSkills items={defaultValues} />
        <NavigationSection {...props} />
        {isPending && <Spinner />}
      </form>
    </FormProvider>
  );
};

const NavigationSection = ({ navigation, editMode }: { navigation: NavigationProps; editMode?: boolean }) => {
  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();
  const { t } = useTranslation();
  const [notEnoughSkillsFilled, setNotEnoughSkillsFilled] = useState(false);
  const [tooManySkillsFilled, setTooManySkillsFilled] = useState(false);
  const [duplicateSkills, setDuplicateSkills] = useState(false);
  const { errors } = useFormState();
  const hasErrors = !!Object.keys(errors).length;
  const formErrors = Object.values(errors) as any[];
  let overMaxYearsOfExperience = false;
  formErrors.flat().forEach((row: any[]) => {
    overMaxYearsOfExperience = Object.values(row).findIndex((err) => err.type === 'max') >= 0;
  });

  const onSubmit = async ({ action, ...rest }: SkillFormWithAction) => {
    const skills = Object.values(rest).reduce(
      (acc: AssignCandidateSkillDto[], skills: AssignCandidateSkillDto[]) => [...acc, ...skills],
      [],
    );

    const { filledTooMany, notFilledEnough, duplicates } = validate(skills);
    setNotEnoughSkillsFilled(notFilledEnough);
    setDuplicateSkills(duplicates);
    setTooManySkillsFilled(filledTooMany);

    if (notFilledEnough || filledTooMany || duplicates) {
      return;
    }

    const resultAction = await dispatch(assignCandidateSkillListThunk(skills));

    if (action.toString() === SAVE_AND_FINISH_LATER) {
      navigation.go(Steps.CONFIRMATION_PAGE_FINISH_LATER);
    } else if (assignCandidateSkillListThunk.fulfilled.match(resultAction)) {
      navigateTo(action, navigation);
    }
  };

  return (
    <Paper>
      {notEnoughSkillsFilled && <p className="error-container">{t('SKILLS.ERRORS.TOO_FEW_SKILLS')}</p>}
      {tooManySkillsFilled && <p className="error-container">{t('SKILLS.ERRORS.TOO_MANY_SKILLS')}</p>}
      {duplicateSkills && <p className="error-container">{t('SKILLS.ERRORS.DUPLICATE_SKILLS')}</p>}
      {overMaxYearsOfExperience && <p className="error-container">{t('SKILLS.ERRORS.MAX_YEARS_ALLOWED')}</p>}
      {!overMaxYearsOfExperience && hasErrors && (
        <p className="error-container">{t('SKILLS.ERRORS.LEVELS_OR_YEARS_EMPTY')}</p>
      )}
      <Navigation onSubmit={onSubmit} editMode={editMode} saveAndFinishLaterMode={true} />
    </Paper>
  );
};

export default Skills;
