import { AxiosPromise } from 'axios';
import axiosInstance from '../axiosInstance';
import { KeyValueEntryListDto } from '../models/KeyValueEntryListDto';

export const getDegrees = (): AxiosPromise<KeyValueEntryListDto> => {
  return axiosInstance({
    url: `/dictionaries/degree`,
    method: 'GET',
  });
};
