import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CloseButton, DottedButton } from '../../../../components';
import { AssignCandidateSkillDto } from '../../../../api';
import { SkillsFormGroup } from '../../../../types';
import SkillsItem from '../SkillsItem/SkillsItem';
import './OtherSkills.scss';

const NEW_SKILL: AssignCandidateSkillDto = {
  skillId: null,
  skillGroupNameId: null,
  skillGroupNameIndex: null,
  groupName: 'other',
  skillName: '',
  years: 0,
  level: 0,
};

const OtherSkills = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: SkillsFormGroup.other,
  });

  const handleAddSkill = () => append(NEW_SKILL);

  return (
    <section>
      <div className="skills_list">
        {fields.map(({ id }, index) => (
          <div key={id} className="skill-wrapper">
            <SkillsItem
              className="secondary"
              isEditable
              skillFormGroupName={SkillsFormGroup.other}
              key={id}
              index={index}
            />
            <CloseButton onClick={() => remove(index)} />
          </div>
        ))}
      </div>
      <DottedButton onClick={handleAddSkill}>{t('SKILLS.ADD_SKILL')}</DottedButton>
    </section>
  );
};

export default OtherSkills;
