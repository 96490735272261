import React, { FC, Fragment, useEffect, useRef } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Level } from '../../../../components';
import LevelControl from '../LevelControl/LevelControl';
import ExperienceControl from '../ExperienceControl/ExperienceControl';
import Errors from '../Errors/Errors';
import Input from '../Input/Input';
import { useTranslation } from 'react-i18next';
import './SkillsItem.scss';

interface SkillsItemProps {
  className?: string;
  isEditable: boolean;
  skillFormGroupName: string;
  index: number;
}

const useEffectAfterDependenciesChange = (func: () => void, dependencies: React.DependencyList) => {
  const firstRun = useRef(true);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
    } else {
      func();
    }
  }, dependencies);
};

const setSkillId = (id: string) => {
  const int = parseInt(id);
  // For some reason setting null doesn't work, so we're using empty string instead for an empty value
  return isNaN(int) ? '' : int;
};

const SkillsItem: FC<SkillsItemProps> = ({ className, skillFormGroupName, isEditable, index }) => {
  const { t } = useTranslation();
  const { register, setValue, trigger } = useFormContext();

  const baseName = `${skillFormGroupName}[${index}]`;
  const years = `${baseName}.years`;
  const level = `${baseName}.level`;
  const skillId = `${baseName}.skillId`;
  const skillName = `${baseName}.skillName`;
  const skillGroupNameId = `${baseName}.skillGroupNameId`;
  const skillGroupNameIndex = `${baseName}.skillGroupNameIndex`;
  const groupName = `${baseName}.groupName`;

  const yearsValue = useWatch({ name: years });
  const levelValue = useWatch({ name: level });
  const skillIdValue = useWatch({ name: skillId });
  const skillNameValue = useWatch({ name: skillName });
  const skillGroupNameIdValue = useWatch({ name: skillGroupNameId });
  const skillGroupNameIndexValue = useWatch({ name: skillGroupNameIndex });
  const groupNameValue = useWatch({ name: groupName });

  const { errors } = useFormState({ name: [level, years, skillName] });

  const errorTypes = ['level', 'years', 'skillName'];
  const formErrors = (errors?.[skillFormGroupName] as any)?.[index];
  const handleErrorClass = () =>
    errorTypes.reduce((acc: string, name: string) => {
      acc += formErrors && formErrors[name] ? `error-${name} ` : '';
      return acc;
    }, '');

  const handleExperience = (value: number) => {
    setValue(years, value, { shouldValidate: true });
    if (value === 0) {
      setTimeout(() => trigger());
    }
  };

  const handleLevels = (value: number) => {
    setValue(level, value, { shouldValidate: true });
    if (value === 0) {
      setTimeout(() => trigger());
    }
  };

  // If we edit name of a skill from the Other group, it means we're creating a new skill and we have to set the ID to null
  useEffectAfterDependenciesChange(() => {
    setValue(skillId, null);
  }, [skillNameValue]);

  return (
    <Fragment>
      <ul className={`skill-item ${className} ${handleErrorClass()}`}>
        <input {...register(skillId, { setValueAs: setSkillId })} type="hidden" defaultValue={skillIdValue} />
        <input
          {...register(skillGroupNameId, { valueAsNumber: true })}
          type="hidden"
          defaultValue={isNaN(skillGroupNameIdValue) ? null : skillGroupNameIdValue}
        />
        <input {...register(groupName)} type="hidden" defaultValue={groupNameValue} />
        <input
          {...register(skillGroupNameIndex, { valueAsNumber: true })}
          type="hidden"
          defaultValue={isNaN(skillGroupNameIndexValue) ? null : skillGroupNameIndexValue}
        />
        <Input defaultValue={skillNameValue} name={skillName} isEditable={isEditable} required={true} />
        <LevelControl
          defaultValue={levelValue}
          name={level}
          registerOptions={{
            valueAsNumber: true,
            required: groupNameValue === 'other' || yearsValue != 0,
            min: groupNameValue === 'other' || yearsValue != 0 ? 1 : 0,
            max: 4,
          }}
          level={
            <Level isEditable defaultValue={levelValue} upgradeLevel={handleLevels} downgradeLevel={handleLevels} />
          }
        />
        <div className="experience-wrapper">
          <div className="exp-label">
            <p>{t('SKILLS.YEARS_EXPERIENCE')}</p>
          </div>
          <ExperienceControl
            name={years}
            value={Number(yearsValue)}
            defaultValue={yearsValue}
            deleteExperience={handleExperience}
            addExperience={handleExperience}
            registerOptions={{
              valueAsNumber: true,
              required: groupNameValue === 'other' || levelValue != 0,
              min: groupNameValue === 'other' || levelValue != 0 ? 1 : 0,
              max: 50,
            }}
          />
        </div>
      </ul>
      {<Errors formErrors={formErrors} errorTypes={errorTypes} />}
    </Fragment>
  );
};

export default SkillsItem;
