export const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const range = (start: number, end: number) => {
  return Array.from(Array(start - end + 1).keys()).map((i) => start - i);
};

export const years: number[] = range(new Date().getFullYear(), 1950);
