import React, { FC } from 'react';
import { CandidateReportDto } from '../../../../api';
import { candidateReportSelector } from '../../../../store/candidateReport';
import { useSelector } from 'react-redux';

const LandingPageTitle: FC = () => {
  const report = useSelector(candidateReportSelector) as CandidateReportDto;

  return (
    <h1 className="landing-page_header">
      Hi <span className="landing-page_header--blue">{report.firstName}</span> {report.lastName},
    </h1>
  );
};

export default LandingPageTitle;
