import { AxiosPromise } from 'axios';
import axiosInstance from '../axiosInstance';
import { IndustryDto } from '../models/IndustryDto';

export const getIndustries = (): AxiosPromise<IndustryDto[]> => {
  return axiosInstance({
    url: `/dictionaries/industries`,
    method: 'GET',
  });
};
