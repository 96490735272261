import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { EducationIcon } from '../../../../icons';
import { ExtendedNavigation, Paper, PaperHeader, SAVE_AND_FINISH_LATER, Spinner, Steps } from '../../../../components';
import {
  educationFormSelector,
  educationStatusSelector,
  isRejectedAction,
  putNewEducationThunk,
} from '../../../../store/education';
import { EducationItem } from '../../components';
import { EducationDto } from '../../../../api';
import { navigateTo } from '../../../../utils/navigateTo';
import { DEFAULT_EDUCATION, EducationFormWithAction, EducationProps } from './Education.types';

const Education: FC<EducationProps> = ({ navigation, editMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();
  const defaultValues = useSelector(educationFormSelector);
  const { isPending } = useSelector(educationStatusSelector);
  const methods = useForm<EducationFormWithAction>({ defaultValues, mode: 'all' });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'education',
    keyName: 'itemKey',
  });

  const onSubmit = async (value: EducationFormWithAction) => {
    const { education, action } = value;
    let educationList: EducationDto[] = [];

    if (education) {
      educationList = education.map((item: EducationDto) => {
        return {
          id: item.id,
          school: item.school,
          fieldOfStudy: item.fieldOfStudy,
          degreeKey: item.degreeKey,
          startYear: Number(item.startYear),
          endYear: item.currentlyStudying ? undefined : Number(item.endYear),
          currentlyStudying: item.currentlyStudying,
        };
      });
    }
    const resultAction = await dispatch(putNewEducationThunk(educationList));

    if (isRejectedAction(resultAction)) {
      return;
    }
    if (action.toString() === SAVE_AND_FINISH_LATER) {
      navigation.go(Steps.CONFIRMATION_PAGE_FINISH_LATER);
    } else if (putNewEducationThunk.fulfilled.match(resultAction)) {
      navigateTo(action, navigation);
    }
  };

  const handleAddEducation = () => append(DEFAULT_EDUCATION);
  const handleRemoveEducation = (index: number) => remove(index);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Paper variant="default">
          <PaperHeader
            stepName="Education"
            icon={<EducationIcon className="experience_text" />}
            currentStep={Steps.EDUCATION}
            text="EDUCATION.DESCRIPTION"
          />
        </Paper>
        {fields.map((education, index: number) => (
          <EducationItem key={education.itemKey} index={index} removeEducation={handleRemoveEducation} />
        ))}
        <Paper>
          <ExtendedNavigation
            onSubmit={onSubmit}
            onAddNew={handleAddEducation}
            addNewText={t('EDUCATION.ADD_NEW')}
            addNewIsDisabled={!methods.formState.isValid}
            editMode={editMode}
            saveAndFinishLaterMode={true}
          />
        </Paper>
        {isPending && <Spinner />}
      </form>
    </FormProvider>
  );
};

export default Education;
