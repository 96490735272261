import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ExperienceDto } from '../../api';
import { BusinessProjectDto } from '../../api/models/BusinessProjectDto';
import { BusinessProjectFields } from '../../modules/Experience/containers/Experience/Experience.types';
import { RootState } from '../rootReducer';
import { RequestStatus } from '../Store.types';
import { adapter, ExperiencesState } from './reducer';

const selectExperience = ({ experience }: RootState) => experience;
const selectExperienceItems = ({ experience }: ExperiencesState) => experience;

export const experienceStatusSelector = createSelector(selectExperience, (experience) => mapStatus(experience));

const mapStatus = (experience: ExperiencesState): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = experience;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

const experienceEntitiesSelector = createSelector(selectExperience, selectExperienceItems);

export const experienceSelector = adapter.getSelectors(experienceEntitiesSelector);

export const experienceFormSelector = createSelector(experienceSelector.selectAll, (experience) =>
  mapToExperienceForm(experience),
);

const mapBusinessProjects = (businessProjects: BusinessProjectDto[]): BusinessProjectFields[] => {
  return businessProjects.map((businessProject) => ({
    id: businessProject.id,
    duration_start_year: businessProject.startDate ? dayjs(businessProject.startDate).year().toString() : '',
    duration_start_month: businessProject.startDate ? dayjs(businessProject.startDate).format('MMMM') : 'January',
    duration_end_year: businessProject.endDate ? dayjs(businessProject.endDate).year().toString() : '',
    duration_end_month: businessProject.endDate ? dayjs(businessProject.endDate).format('MMMM') : 'January',
    responsibilities: businessProject.responsibilities,
    currentlyWorking: businessProject.currentlyWorking,
    industry: businessProject.industry,
    business_project_description: businessProject.businessProjectDescription,
    role: businessProject.role ?? null,
  }));
};

const mapToExperienceForm = (experience: ExperienceDto[]): any => {
  const formExperience = experience.map((exp: ExperienceDto) => ({
    id: exp.id,
    jobtitle: exp.jobTitle,
    company: exp.company,
    currently_working: exp.currentlyWork,
    month_start: dayjs(exp.startDate).format('MMMM'),
    month_end: dayjs(exp.endDate).format('MMMM'),
    year_start: exp.startDate ? dayjs(exp.startDate).year().toString() : '',
    year_end: exp.endDate ? dayjs(exp.endDate).year().toString() : '',
    business_projects:
      exp.businessProjects.length > 0
        ? mapBusinessProjects(exp.businessProjects)
        : mapBusinessProjects([
            {
              id: null,
              responsibilities: '',
              industry: '',
              businessProjectDescription: '',
              role: null,
              currentlyWorking: false,
            },
          ]),
  }));

  return { experience: formExperience };
};
