import React, { FC } from 'react';

import './CharacterCount.scss';

interface CharacterCountProps {
  limit: number;
  count: number;
  className?: string;
}

const CharacterCount: FC<CharacterCountProps> = ({ limit, count, className }) => {
  return (
    <span className={className ?? 'character-count'}>
      <span className={count > limit ? 'count-exceeded' : ''}>{count}</span>
      {' / '}
      <span>{limit}</span>
    </span>
  );
};

export default CharacterCount;
