import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Root } from './modules/Root';
import reportWebVitals from './reportWebVitals';
import createStore from './store';
import './i18n';

const store = createStore();

ReactDOM.render(
  <Suspense fallback="loading">
    <Root store={store} />
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
