import React, { FC, MouseEventHandler } from 'react';
import { Plus } from '../../icons';

import './DottedButton.scss';

interface DottedButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const DottedButton: FC<DottedButtonProps> = ({ children, onClick, disabled = false }) => {
  const isDisabled = disabled ? 'dotted-button_disabled' : '';
  return (
    <button type="button" className={`dotted-button ${isDisabled}`} onClick={onClick} disabled={disabled}>
      <Plus stroke="#0098DA" />
      <span className="dotted-button_content">{children}</span>
    </button>
  );
};

export default DottedButton;
