import { createEntityAdapter, createReducer, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  Actions,
  isExperiencesFromCandidateReportAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  updateExperienceAction,
} from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { ExperienceDto, ExperienceListDto } from '../../api';

export interface ExperienceState extends RequestStatus {
  experience: EntityState<ExperienceDto>;
}

export const adapter = createEntityAdapter<ExperienceDto>();

const initialState: ExperienceState = {
  ...statusNotAsked,
  experience: adapter.getInitialState(),
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateExperienceAction, (state: ExperienceState, action: PayloadAction<any>) => ({
      ...state,
      experience: adapter.upsertOne(
        { ...state.experience },
        { ...state.experience.entities[action.payload.id], ...action.payload },
      ),
    }))
    .addMatcher(isPendingAction, (state: ExperienceState) => ({
      ...state,
      ...statusIsLoading,
    }))
    .addMatcher(
      isExperiencesFromCandidateReportAction,
      (state: ExperienceState, action: PayloadAction<{ experience: any }>) => ({
        ...state,
        ...statusIsSuccess,
        experience: adapter.setAll({ ...state.experience }, action.payload.experience),
      }),
    )
    .addMatcher(isFulfilledAction, (state: ExperienceState, action: PayloadAction<any>) => ({
      ...state,
      ...statusIsSuccess,
      experience: adapter.setAll({ ...state.experience }, action.payload),
    }))
    .addMatcher(isRejectedAction, (state: ExperienceState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
});

export default reducer;

export type ExperiencesState = ReturnType<typeof reducer>;
