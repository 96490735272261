import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';

import './Navigation.scss';
import { ACTION_BACK, ACTION_NEXT, ACTION_GO_TO_SUMMARY, SAVE_AND_FINISH_LATER } from './Navigation.const';
import { useFormContext } from 'react-hook-form';
import { Steps } from '../PageRoute/PageRoute.types';

export interface NavigationProps {
  onSubmit: any;
  editMode?: boolean;
  saveAndFinishLaterMode?: boolean;
}

const Navigation: FC<NavigationProps> = ({ onSubmit, editMode, saveAndFinishLaterMode, children }) => {
  const { t } = useTranslation();
  const { getValues, handleSubmit, clearErrors } = useFormContext();

  const handleOnBack = () => {
    clearErrors(Steps.EXPERIENCE);

    handleSubmit(() => {
      const data = {
        action: ACTION_BACK,
        ...getValues(),
      };

      onSubmit(data);
    })();
  };

  const handleOnNext = handleSubmit((data) => {
    data.action = ACTION_NEXT;

    onSubmit(data);
  });

  const handleOnGo = handleSubmit((data) => {
    window.scrollTo(0, 0);
    data.action = ACTION_GO_TO_SUMMARY;

    onSubmit(data);
  });

  const handleSaveAndFinish = () => {
    const data = {
      action: SAVE_AND_FINISH_LATER,
      ...getValues(),
    };

    handleSubmit(() => {
      onSubmit(data);
    })();
  };

  const navigationButtons = (
    <p className="navigation-container_btn">
      {saveAndFinishLaterMode && (
        <Button
          variant="outline"
          className="ski mb-3"
          onClick={(e) => {
            e.preventDefault();
            handleSaveAndFinish();
          }}
          size="sm"
        >
          {t('BUTTON.SAVE_AND_FINISH')}
        </Button>
      )}
      <Button
        className="navigation-container_btn--margin mb-3"
        type="button"
        variant="transparent"
        size="sm"
        onClick={handleOnBack}
      >
        {t('COMMON.BACK')}
      </Button>
      <Button className="navigation-container_btn--margin mb-3" variant="blue" onClick={handleOnNext} size="sm">
        {t('COMMON.NEXT')}
      </Button>
    </p>
  );

  const editModeButtons = (
    <p className="navigation-container_btn">
      <Button className="navigation-container_btn--margin" variant="blue" onClick={handleOnGo} size="sm">
        {t('COMMON.GO_TO_SUMMARY')}
      </Button>
    </p>
  );

  return (
    <section className="navigation-container">
      {children}

      {editMode ? editModeButtons : navigationButtons}
    </section>
  );
};

export default Navigation;
