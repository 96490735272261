import { createEntityAdapter, createReducer, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  Actions,
  isEducationFromCandidateReportAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { EducationDto, EducationListDto } from '../../api';

export interface EducationState extends RequestStatus {
  education: EntityState<EducationDto>;
}

export const adapter = createEntityAdapter<EducationDto>();

const initialState: EducationState = {
  ...statusNotAsked,
  education: adapter.getInitialState(),
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isPendingAction, (state: EducationState) => ({
      ...state,
      ...statusIsLoading,
    }))
    .addMatcher(
      isEducationFromCandidateReportAction,
      (state: EducationState, action: PayloadAction<{ education: EducationListDto }>) => ({
        ...state,
        ...statusIsSuccess,
        education: adapter.setAll({ ...state.education }, action.payload.education),
      }),
    )
    .addMatcher(isFulfilledAction, (state: EducationState, action: PayloadAction<any>) => ({
      ...state,
      ...statusIsSuccess,
      education: adapter.setAll({ ...state.education }, action.payload),
    }))
    .addMatcher(isRejectedAction, (state: EducationState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
});

export default reducer;

export type EducationsState = ReturnType<typeof reducer>;
