import { AxiosPromise } from 'axios';
import axiosInstance from '../axiosInstance';
import { DictionaryListDto } from '../models/DictionaryListDto';

export const getJobTitles = (): AxiosPromise<DictionaryListDto> => {
  return axiosInstance({
    url: `/dictionaries/job-title`,
    method: 'GET',
  });
};
