import dayjs from 'dayjs';
import { EducationDto } from '../../../../api';
import { NavigationAction, NavigationProps } from '../../../../components';

export const DEFAULT_EDUCATION: EducationDto = {
  id: null,
  school: '',
  fieldOfStudy: '',
  degreeKey: 'bachelor',
  startYear: dayjs(new Date()).year(),
  endYear: dayjs(new Date()).year(),
  currentlyStudying: false,
};

export interface EducationProps {
  navigation: NavigationProps;
  editMode?: boolean;
}

export interface EducationForm {
  education: EducationDto[];
}

export interface EducationFormWithAction extends EducationForm {
  action: NavigationAction;
}
