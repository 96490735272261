import React, { ButtonHTMLAttributes, FC } from 'react';
import Button from '../Button/Button';
import './RemoveButton.scss';

type RemoveButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const RemoveButton: FC<RemoveButtonProps> = ({ onClick, value, children, className }) => {
  return (
    <Button className={`remove-button ${className}`} type="button" onClick={onClick}>
      {children}
      {value && <span className="pl-2">{value}</span>}
    </Button>
  );
};

export default RemoveButton;
