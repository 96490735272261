import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Button } from '../../../../components';
import './SaveModal.scss';

interface SaveModalProps {
  show: boolean;
  handleClose: () => void;
  handleSave: () => void;
}

const SaveModal: FC<SaveModalProps> = ({ show, handleClose, handleSave }) => {
  const { t } = useTranslation();

  return (
    <Modal contentClassName="modal_container" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('SUMMARY.MODAL_TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('SUMMARY.MODAL_TEXT')}</Modal.Body>
      <Modal.Footer>
        <div className="modal_container_btn">
          <Button variant="transparent" size="sm" onClick={handleClose}>
            {t('SUMMARY.MODAL_CLOSE')}
          </Button>
          <Button variant="blue" size="sm" className="modal_container_btn--margin" onClick={handleSave}>
            {t('SUMMARY.MODAL_SAVE')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveModal;
