import React, { ButtonHTMLAttributes, FC } from 'react';
import dayjs from 'dayjs';
import HeaderWrapper from '../../../../components/HeaderWrapper/HeaderWrapper';
import { HitBox } from '../../../../components';
import { CertificateDto } from '../../../../api';
import Pencil from '../../../../icons/Pencil';
import './Certificates.scss';

interface CertificatesProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  model: CertificateDto[];
}

const Certificates: FC<CertificatesProps> = ({ onClick, model }) => {
  return (
    <section className="certificates-container">
      <HeaderWrapper stepName="Certificates">
        {!!onClick && (
          <HitBox onClick={onClick}>
            <Pencil className="blue" />
          </HitBox>
        )}
      </HeaderWrapper>
      {model?.map(({ type, issuer, issuedAt }, id: number) => (
        <section className="list" key={id}>
          <div className="list_item">
            <i className="list_tick-icon" />
            <p className="list_item--primary">{type}</p>
          </div>
          {issuer && (
            <div className="list_item list_item--intended">
              <i className="list_certificate-icon" />
              <p>{issuer}</p>
            </div>
          )}
          {issuedAt && (
            <div className="list_item list_item--intended">
              <i className="list_calendar-icon" />
              <p>{dayjs(issuedAt).format('YYYY')}</p>
            </div>
          )}
        </section>
      ))}
    </section>
  );
};

export default Certificates;
