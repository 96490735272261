import { combineReducers, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { LanguageDto, LanguageListDto } from '../../api';
import {
  Actions,
  isFulfilledLanguageListAction,
  isFulfilledLanguageNamesAction,
  isFulfilledLevelsAction,
  isLanguageFromCandidateReportAction,
  isPendingLanguageListAction,
  isPendingLanguageNamesAction,
  isPendingLevelsAction,
  isRejectedLanguageListsAction,
  isRejectedLanguageNamesAction,
  isRejectedLevelsAction,
} from './thunks';
import { KeyValueEntryDto } from '../../api/models/KeyValueEntryDto';

export interface LanguageNameState extends RequestStatus {
  items: EntityState<KeyValueEntryDto>;
}

export const languageNameAdapter = createEntityAdapter<KeyValueEntryDto>({ selectId: (c) => c.key });

const initialLanguageNameState: LanguageNameState = {
  ...statusNotAsked,
  items: languageNameAdapter.getInitialState(),
};

const languageName = createSlice({
  name: 'name',
  initialState: initialLanguageNameState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isPendingLanguageNamesAction, (state: LanguageNameState) => ({
      ...state,
      ...statusIsLoading,
    }));
    builder.addMatcher(
      isFulfilledLanguageNamesAction,
      (state: LanguageNameState, action: PayloadAction<KeyValueEntryDto[]>) => ({
        ...state,
        ...statusIsSuccess,
        items: languageNameAdapter.setAll({ ...state.items }, action.payload),
      }),
    );
    builder.addMatcher(isRejectedLanguageNamesAction, (state: LanguageNameState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
  },
});

export const levelAdapter = createEntityAdapter<KeyValueEntryDto>({ selectId: (l) => l.key });
export interface LevelState extends RequestStatus {
  items: EntityState<KeyValueEntryDto>;
}

const initialLevelState: LevelState = {
  ...statusNotAsked,
  items: levelAdapter.getInitialState(),
};

const level = createSlice({
  name: 'level',
  initialState: initialLevelState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isPendingLevelsAction, (state: LevelState) => ({
      ...state,
      ...statusIsLoading,
    }));
    builder.addMatcher(isFulfilledLevelsAction, (state: LevelState, action: PayloadAction<KeyValueEntryDto[]>) => ({
      ...state,
      ...statusIsSuccess,
      items: levelAdapter.setAll({ ...state.items }, action.payload),
    }));
    builder.addMatcher(isRejectedLevelsAction, (state: LevelState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
  },
});

export const languageListAdapter = createEntityAdapter<LanguageDto>({
  selectId: ({ levelKey, languageKey }) => languageKey,
});
export interface LanguageListState extends RequestStatus {
  languages: EntityState<LanguageDto>;
}

const initialLanguagesState: LanguageListState = {
  ...statusNotAsked,
  languages: languageListAdapter.getInitialState(),
};

const languageList = createSlice({
  name: 'list',
  initialState: initialLanguagesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isPendingLanguageListAction, (state: LanguageListState) => ({
      ...state,
      ...statusIsLoading,
    }));
    builder.addMatcher(
      isLanguageFromCandidateReportAction,
      (state: LanguageListState, action: PayloadAction<{ languages: LanguageListDto }>) => ({
        ...state,
        ...statusIsSuccess,
        languages: languageListAdapter.setAll({ ...state.languages }, action.payload.languages),
      }),
    );
    builder.addMatcher(
      isFulfilledLanguageListAction,
      (state: LanguageListState, action: PayloadAction<LanguageListDto>) => ({
        ...state,
        ...statusIsSuccess,
        languages: languageListAdapter.setAll({ ...state.languages }, action.payload),
      }),
    );
    builder.addMatcher(isRejectedLanguageListsAction, (state: LanguageListState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
  },
});

export const reducer = combineReducers({
  name: languageName.reducer,
  level: level.reducer,
  list: languageList.reducer,
});

export type LanguageStateType = ReturnType<typeof reducer>;
