import React, { ChangeEvent, useState } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

interface SelectProps {
  name: string;
  emptyOption?: string;
  className?: string;
  registerOptions?: RegisterOptions;
  disabled?: boolean;
}

const SelectKeyboard = ({ name, emptyOption, registerOptions, ...rest }: SelectProps) => {
  const { register, watch, setValue } = useFormContext();
  const [pasted, setPasted] = useState(false);
  const currentYear = new Date().getFullYear();
  const re = /^[0-9\b]+$/;

  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (re.test(target.value)) {
      setValue(name, target.value, { shouldValidate: true });

      if (parseInt(target.value) > currentYear || target.value < '1950') {
        setValue(name, target.value);
      }
    } else if (!re.test(target.value)) {
      pasted ? setValue(name, '') : setValue(name, target.value.slice(0, -1));
      setPasted(false);
    }
  };

  const handlePaste = () => {
    setPasted(true);
  };

  const value = watch(name, '');

  return (
    <input
      {...register(name, registerOptions)}
      type="text"
      onChange={handleOnChange}
      onPaste={handlePaste}
      value={value}
      {...rest}
      maxLength={4}
    />
  );
};

export default SelectKeyboard;
