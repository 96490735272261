import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Pensil } from '../../../../icons';
import './Input.scss';

interface InputProps {
  name: string;
  isEditable?: boolean;
  defaultValue: string;
  required?: boolean;
}

const Input: FC<InputProps> = ({ name, defaultValue, isEditable = true, required }) => {
  const { register } = useFormContext();

  return (
    <label className="skill-name" title={defaultValue}>
      <input
        readOnly={!isEditable}
        className={`input-container_input ${!isEditable && 'input-container_input--editable'}`}
        defaultValue={defaultValue}
        {...register(name, { required: required ?? false })}
      />
      {isEditable && <Pensil className="input-container_icon" />}
    </label>
  );
};

export default Input;
