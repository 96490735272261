import React, { FC } from 'react';
import { Button, Col, Modal, ModalProps, Row } from 'react-bootstrap';

interface ConfirmationModalProps extends ModalProps {
  title: string;
  confirmButtonText: string;
  declineButtonText: string;
  confirmButtonAction: () => void;
  declineButtonAction: () => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  confirmButtonText,
  declineButtonText,
  confirmButtonAction,
  declineButtonAction,
  ...props
}): React.ReactElement => {
  return (
    <Modal {...props} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Button variant="outline-primary" className="button__no-border" onClick={declineButtonAction}>
              {declineButtonText}
            </Button>
            <Button variant="primary" className="ml-2 float-right" onClick={confirmButtonAction}>
              {confirmButtonText}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
