import { createSelector } from '@reduxjs/toolkit';
import { adapter, CertificateState } from './reducer';
import { RootState } from '../rootReducer';
import { CertificateDto } from '../../api';
import dayjs from 'dayjs';
import {
  CertificateFields,
  CertificateFormFields,
} from '../../modules/Certificate/containers/Certificate/Certificate.type';
import { RequestStatus } from '../Store.types';

const selectCertificate = ({ certiricate }: RootState) => certiricate;
const selectCertificateItems = ({ certificates }: CertificateState) => certificates;

export const certificateStatusSelector = createSelector(selectCertificate, (certificate) => mapStatus(certificate));

const mapStatus = (certificate: CertificateState): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = certificate;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

const certificateEntitiesSelector = createSelector(selectCertificate, selectCertificateItems);

export const certificateSelector = adapter.getSelectors(certificateEntitiesSelector);

export const certificateFormSelector = createSelector(
  certificateSelector.selectAll,
  (certificates): CertificateFormFields => ({ certificates: mapToCertificateForm(certificates) }),
);

const mapToCertificateForm = (certificates: CertificateDto[]): CertificateFields[] =>
  certificates.map(({ id, type, issuedAt, issuer }: CertificateDto) => ({
    id,
    type,
    issuer,
    month: issuedAt !== undefined ? dayjs(issuedAt).format('MMMM') : '',
    year: issuedAt !== undefined ? dayjs(issuedAt).year().toString() : '',
  }));
