import React, { FC } from 'react';

interface PlusProps {
  stroke?: '#0098DA' | '#4E5A62';
  width?: number;
  height?: number;
}

const Plus: FC<PlusProps> = ({ stroke = '#0098DA', width = 14, height = 14 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" stroke={stroke} xmlns="http://www.w3.org/2000/svg">
      <path d="M7 1V13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 7L1 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Plus;
