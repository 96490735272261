import { RootState } from '../rootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { degreesNameAdapter, DegreesStateType } from './reducer';

const selectDegrees = ({ degrees }: RootState) => degrees;
const selectDegreesNamesItems = ({ items }: DegreesStateType) => items;

const degreeNamesEntitiesSelector = createSelector(selectDegrees, selectDegreesNamesItems);

export const degreesSelector = degreesNameAdapter.getSelectors(degreeNamesEntitiesSelector);
