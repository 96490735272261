import React, { FC, Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import {
  CharacterCount,
  ConfirmationModal,
  HoverHint,
  RemoveButton,
  RequiredFieldIndicator,
  Select,
} from '../../../../components';
import ResponsibilitiesControll from '../ResponsibilitiesController/ResponsibilitiesController';
import { months, years } from '../../../../constants/dates';
import { useTranslation } from 'react-i18next';
import './BusinessProjectForm.scss';
import SelectGroup from '../../../../components/SelectGroup/SelectGroup';
import TextareaAutosize from 'react-textarea-autosize';
import { DateValidationConfig, validateDate } from '../../utils/dateValidation';
import { Close } from '../../../../icons';
import { ErrorMessage } from '@hookform/error-message';
import useSimpleModal from '../../../../hooks/useSimpleModal';

interface BusinessProjectFormProps {
  index: number;
  name: string;
  experienceIndex: number;
  baseName: string;
  multipleProjects: boolean;
  removeButtonAction: (index: number) => void;
  currentlyWorking: boolean | undefined;
  parentStartMonth: string;
  parentStartYear: string;
  parentEndMonth: string;
  parentEndYear: string;
}

export const BusinessProjectForm: FC<BusinessProjectFormProps> = ({
  name,
  baseName,
  index,
  multipleProjects,
  removeButtonAction,
  currentlyWorking,
  parentStartMonth,
  parentStartYear,
  parentEndMonth,
  parentEndYear,
}) => {
  const clearEndDate = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    setValue(endYear, currentYear);
    setValue(endMonth, months[currentMonth]);
  };

  const {
    register,
    setError,
    clearErrors,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const toggleShowModal = () => setShowModal(!showModal);
  const [IndustryModal, openIndustryModal] = useSimpleModal(t('EXPERIENCE.HINTS.INDUSTRY.TITLE'));
  const [BusinessProjectModal, openBusinessProjectModal] = useSimpleModal(t('EXPERIENCE.HINTS.BUSINESS_PROJECT.TITLE'));
  const [DurationModal, openDurationModal] = useSimpleModal(t('EXPERIENCE.HINTS.DURATION.HEADER'));

  const role = `${baseName}.business_projects[${index}].role`;
  const roleValue = watch(role);

  const industry = `${baseName}.business_projects[${index}].industry`;
  const industryValue = watch(industry);

  const responsibilities = `${baseName}.business_projects[${index}].responsibilities`;

  const businessProjectDescription = `${baseName}.business_projects[${index}].business_project_description`;
  const businessProjectDescriptionValue = watch(businessProjectDescription);

  const businessProjectCurrentlyWorking = `${baseName}.business_projects[${index}].currentlyWorking`;
  const businessProjectCurrentlyWorkingValue = watch(businessProjectCurrentlyWorking);

  const startMonth = `${baseName}.business_projects[${index}].duration_start_month`;
  const startYear = `${baseName}.business_projects[${index}].duration_start_year`;
  const endYear = `${baseName}.business_projects[${index}].duration_end_year`;
  const endMonth = `${baseName}.business_projects[${index}].duration_end_month`;

  const [businessProjectDescriptionCount, setBusinessProjectDescriptionCount] = useState(
    businessProjectDescriptionValue ? businessProjectDescriptionValue.length : 0,
  );
  const businessProjectDescriptionLimit = 500;
  const businessProjectDescriptionMinLength = 50;

  const dateValidationConfigDefaults: Pick<DateValidationConfig, 'error' | 'setError' | 'clearErrors'> = {
    error: {
      startDate: {
        message: t('EXPERIENCE.START_DATE_VALIDATION'),
        fieldNames: [startYear, startMonth],
      },
      endDate: {
        message: t('EXPERIENCE.END_DATE_VALIDATION'),
        fieldNames: [endYear, endMonth],
      },
      futureDate: t('EXPERIENCE.DATE_FUTURE_VALIDATION'),
      parentStartDate: {
        message: t('EXPERIENCE.START_PROJECT_DATE_VALIDATION'),
        fieldNames: [parentStartMonth, parentStartYear],
      },
      parentEndDate: {
        message: t('EXPERIENCE.END_PROJECT_DATE_VALIDATION'),
        fieldNames: [parentEndMonth, parentEndYear],
      },
    },
    setError,
    clearErrors,
  };

  const getDateValidationConfig = (): DateValidationConfig => {
    return {
      ...dateValidationConfigDefaults,
      startDate: {
        year: getValues(startYear),
        month: getValues(startMonth),
      },
      endDate: {
        year: getValues(endYear),
        month: getValues(endMonth),
        isOngoing: getValues(businessProjectCurrentlyWorking),
      },
      parentStartDate: {
        year: parentStartYear.toString(),
        month: parentStartMonth.toString(),
      },
      parentEndDate: {
        year: parentEndYear.toString(),
        month: parentEndMonth.toString(),
      },
    };
  };

  const validateResponsibilities = (inputVal: string) => {
    const error = t('EXPERIENCE.RESPONSIBILITY_COUNT');
    const paragraphCount = inputVal
      .split('\n')
      .map((x) => x.trim())
      .filter((x) => x.length >= 3).length;
    return paragraphCount < 3 ? error : undefined;
  };

  useEffect(() => {
    if (currentlyWorking) {
      clearEndDate();
    } else {
      setValue(businessProjectCurrentlyWorking, false);
    }
  }, [currentlyWorking]);

  useEffect(() => {
    if (!multipleProjects) {
      clearErrors([startYear, startMonth, endYear, endMonth]);
    }
  }, [multipleProjects]);

  return (
    <div
      className={`experience-container experience-container_business-project ${showModal ? 'remove-experience' : ''}`}
    >
      <div className="experience-header">
        <h4>
          {t('EXPERIENCE.PROJECT_HEADER')} {index + 1}
        </h4>
        {multipleProjects && (
          <RemoveButton onClick={toggleShowModal}>
            <Close />
          </RemoveButton>
        )}
      </div>
      {multipleProjects && (
        <div className="experience-container experience-container_company mt-4">
          <label htmlFor={role}>{t('EXPERIENCE.ENTRY.ROLE')}</label>
          <input {...register(role)} type="text" defaultValue={roleValue ?? null} maxLength={100} />
        </div>
      )}

      <IndustryModal>{t('EXPERIENCE.HINTS.INDUSTRY.BODY')}</IndustryModal>
      <div className="experience-container experience-container_company mb-4">
        <label>
          {isMobile ? (
            <div onClick={() => openIndustryModal()}>
              <span>{t('EXPERIENCE.ENTRY.INDUSTRY')}</span>
              <span className="hoverHintButton">i</span>
            </div>
          ) : (
            <HoverHint
              header={t('EXPERIENCE.HINTS.INDUSTRY.TITLE')}
              titleText={
                <span>
                  {t('EXPERIENCE.ENTRY.INDUSTRY')}
                  <RequiredFieldIndicator />
                </span>
              }
            >
              {t('EXPERIENCE.HINTS.INDUSTRY.BODY')}
            </HoverHint>
          )}
        </label>
        <SelectGroup
          name={`${baseName}.business_projects[${index}].industry`}
          registerOptions={{
            required: {
              value: true,
              message: 'Industry is required',
            },
          }}
          industryValue={industryValue ?? 'Select Industry'}
        />
        <ErrorMessage
          errors={errors}
          name={industry}
          render={({ message }) => <span className="experience-container experience-container_error">{message}</span>}
        />
      </div>

      <BusinessProjectModal>
        {
          <Fragment>
            <ul className="hover-list">
              <li>{t('EXPERIENCE.HINTS.BUSINESS_PROJECT.BODY.LIST_ITEMS.FIRST')}</li>
              <li>{t('EXPERIENCE.HINTS.BUSINESS_PROJECT.BODY.LIST_ITEMS.SECOND')}</li>
            </ul>
            <p>{t('EXPERIENCE.HINTS.BUSINESS_PROJECT.BODY.EXAMPLE')}</p>
          </Fragment>
        }
      </BusinessProjectModal>
      <div className="experience-container experience-container_company mb-1">
        <label>
          {isMobile ? (
            <div onClick={() => openBusinessProjectModal()}>
              <span>{t('EXPERIENCE.ENTRY.PROJECT_DESC')}</span>
              <span className="hoverHintButton">i</span>
            </div>
          ) : (
            <HoverHint
              header={t('EXPERIENCE.HINTS.EXPERIENCE.TITLE')}
              titleText={
                <span>
                  {t('EXPERIENCE.ENTRY.PROJECT_DESC')}
                  <RequiredFieldIndicator />
                </span>
              }
            >
              <ul className="hover-list">
                <li>{t('EXPERIENCE.HINTS.BUSINESS_PROJECT.BODY.LIST_ITEMS.FIRST')}</li>
                <li>{t('EXPERIENCE.HINTS.BUSINESS_PROJECT.BODY.LIST_ITEMS.SECOND')}</li>
              </ul>
              <p>{t('EXPERIENCE.HINTS.BUSINESS_PROJECT.BODY.EXAMPLE')}</p>
            </HoverHint>
          )}
        </label>
        <TextareaAutosize
          {...register(`${baseName}.business_projects[${index}].business_project_description`, {
            required: { value: true, message: 'Business project description is required' },
            maxLength: {
              value: businessProjectDescriptionLimit,
              message: `Number of characters must not exceed ${businessProjectDescriptionLimit}`,
            },
            minLength: {
              value: businessProjectDescriptionMinLength,
              message: t('EXPERIENCE.BUSINESS_PROJECT_MIN_LENGTH_50'),
            },
            onChange: (e) => setBusinessProjectDescriptionCount(e.target.value.length),
          })}
          id="businessProjectDescription"
          minRows={3}
        />
        <div className="text-area-errors">
          <ErrorMessage
            errors={errors}
            name={businessProjectDescription}
            render={({ message }) => <span className="experience-container experience-container_error">{message}</span>}
          />
          <CharacterCount limit={businessProjectDescriptionLimit} count={businessProjectDescriptionCount} />
        </div>
      </div>

      <DurationModal>
        <p>{t('EXPERIENCE.HINTS.DURATION.BODY')}</p>
      </DurationModal>

      {multipleProjects && (
        <div className="experience-container experience-container_business-project-duration mb-4">
          <p className="input-label">
            {isMobile ? (
              <div onClick={() => openDurationModal()}>
                <span>{t('EXPERIENCE.ENTRY.DURATION')}</span>
                <span className="hoverHintButton">i</span>
              </div>
            ) : (
              <HoverHint
                header={t('EXPERIENCE.HINTS.DURATION.HEADER')}
                titleText={
                  <span>
                    {t('EXPERIENCE.ENTRY.DURATION')}
                    <RequiredFieldIndicator />
                  </span>
                }
              >
                <p>{t('EXPERIENCE.HINTS.DURATION.BODY')}</p>
              </HoverHint>
            )}
          </p>
          <div className="experience-container experience-container_employment-status-duration">
            <div className="experience-container_employment-status-duration-start">
              <span className="label">{t('EXPERIENCE.ENTRY.START_DATE')}</span>
              <div className="experience-container_employment-status-duration-picker">
                <Select
                  options={months}
                  name={`${baseName}.business_projects[${index}].duration_start_month`}
                  className="month_start--white"
                  registerOptions={{ validate: () => validateDate('startDate', getDateValidationConfig()) }}
                />
                <Select
                  options={years}
                  name={`${baseName}.business_projects[${index}].duration_start_year`}
                  className="year_start--white"
                  registerOptions={{ validate: () => validateDate('startDate', getDateValidationConfig()) }}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name={startYear}
                render={({ message }) => (
                  <span className="experience-container experience-container_error">{message}</span>
                )}
              />
            </div>
            <div className="experience-container_employment-status-duration-end">
              <span className="label">{t('EXPERIENCE.ENTRY.END_DATE')}</span>
              <>
                <div className="experience-container_employment-status-duration-picker">
                  <Select
                    disabled={businessProjectCurrentlyWorkingValue}
                    options={months}
                    name={`${baseName}.business_projects[${index}].duration_end_month`}
                    className="month_end--white"
                    registerOptions={{ validate: () => validateDate('endDate', getDateValidationConfig()) }}
                  />
                  <Select
                    disabled={businessProjectCurrentlyWorkingValue}
                    options={years}
                    name={`${baseName}.business_projects[${index}].duration_end_year`}
                    className="year_end--white"
                    registerOptions={{ validate: () => validateDate('endDate', getDateValidationConfig()) }}
                  />
                </div>
              </>
              <ErrorMessage
                errors={errors}
                name={endYear}
                render={({ message }) => (
                  <span className="experience-container experience-container_error">{message}</span>
                )}
              />
            </div>
          </div>
        </div>
      )}
      <ResponsibilitiesControll
        {...register(responsibilities, {
          required: { value: true, message: 'Responsibilities are required' },
          maxLength: { value: 2000, message: 'Number of characters must not exceed 2000' },
          validate: (x) => validateResponsibilities(x),
        })}
        setValue={setValue}
        errors={errors}
      />
      <div className="experience-container_business-project_currently_working">
        {currentlyWorking && (
          <>
            <input
              {...register(`${baseName}.business_projects[${index}].currentlyWorking`, {
                onChange: () => {
                  clearEndDate();
                  trigger([startYear, endYear]);
                },
              })}
              type="checkbox"
              defaultChecked={businessProjectCurrentlyWorkingValue}
            />

            {t('EXPERIENCE.ENTRY.CURRENTLY_WORKING_PROJECT')}
          </>
        )}
      </div>
      <div>
        <ConfirmationModal
          title="Are you sure you want to remove project details?"
          confirmButtonText="Yes, remove"
          declineButtonText="Cancel"
          confirmButtonAction={() => removeButtonAction(index)}
          declineButtonAction={toggleShowModal}
          show={showModal}
          onHide={toggleShowModal}
        />
      </div>
    </div>
  );
};
