import React from 'react';
import { CandidateSkillDto } from '../../../../api';
import SkillsItem from '../SkillsItem/SkillsItem';

const SkillsList = ({ skills, groupName }: { skills: CandidateSkillDto[]; groupName: string }) => (
  <>
    {skills.map(({ skillId }: CandidateSkillDto, skillIndex: number) => (
      <SkillsItem
        isEditable={false}
        className="primary"
        skillFormGroupName={groupName}
        key={skillId}
        index={skillIndex}
      />
    ))}
  </>
);

export default React.memo(SkillsList);
