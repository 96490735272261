import React, { FC } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { HitBox } from '../../../../components';
import { Minus, Plus } from '../../../../icons';
import './ExperienceControl.scss';

interface ExperienceControlProps {
  name: string;
  value: number;
  defaultValue: number;
  addExperience(value: number): void;
  deleteExperience(value: number): void;
  registerOptions?: RegisterOptions;
}

const ExperienceControl: FC<ExperienceControlProps> = ({
  name,
  value,
  defaultValue,
  addExperience,
  deleteExperience,
  registerOptions,
}) => {
  const { register } = useFormContext();
  const experienceOpacity = value === 0 ? 'experience-skills_years--color' : '';

  const handleAddExperience = () => addExperience(value + 1);

  const handleDeleteExperience = () => {
    if (value > 0) {
      deleteExperience(value - 1);
    }
  };

  return (
    <div className="skill-experience">
      <HitBox className="experience-control_btn" onClick={handleDeleteExperience}>
        <Minus />
      </HitBox>
      <input
        {...register(name, registerOptions)}
        readOnly
        className={`experience-control_years ${experienceOpacity}`}
        defaultValue={defaultValue}
      />
      <HitBox className="experience-control_btn" onClick={handleAddExperience}>
        <Plus width={11} height={10} stroke="#4E5A62" />
      </HitBox>
    </div>
  );
};

export default ExperienceControl;
