import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from '../../../../components';
import { LandingPageIcon } from '../../../../icons';
import { NavigationProps } from '../../../../components/PageRoute/PageRoute.types';
import { candidateReportSelector, getCandidateReportWithSectionsThunk } from '../../../../store/candidateReport';
import './LandingPage.scss';
import LandingPageTitle from '../Title/LandingPageTitle';
import { updateReportStatusThunk } from '../../../../store/report';
import { StatusEnum } from '../../../../types/reportStatus';
interface LandingPageProps {
  navigation: NavigationProps;
}

const LandingPage: FC<LandingPageProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const candidateReport = useSelector(candidateReportSelector);
  const isReportReady = Object.keys(candidateReport).length > 0;
  const updateReportStatus = (id: number, status: StatusEnum) => dispatch(updateReportStatusThunk({ id, status }));
  useEffect(() => {
    if (!isReportReady) {
      dispatch(getCandidateReportWithSectionsThunk());
    }
  });

  return (
    <>
      {isReportReady ? (
        <section className="landing-page">
          <LandingPageTitle />
          <hr className="landing-page_line" />
          <p className="landing-page_text">{t('LANDING_PAGE.HELLO')}</p>
          <LandingPageIcon className="landing-page_icon" />
          <p className="landing-page_btn-container">
            <Button
              variant="blue"
              size="lg"
              onClick={() => {
                navigation.next();
                updateReportStatus(candidateReport.reportId, StatusEnum.inProgress);
              }}
            >
              Start
            </Button>
          </p>
        </section>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default LandingPage;
