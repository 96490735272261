import { RootState } from '../rootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { industriesAdapter, IndustriesState } from './reducer';

const selectIndustries = ({ industries }: RootState) => industries;
const selectIndustryItems = ({ industries }: IndustriesState) => industries;

const industriesEntitiesSelector = createSelector(selectIndustries, selectIndustryItems);

export const industriesSelector = industriesAdapter.getSelectors(industriesEntitiesSelector);
