import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';
import { isFulfilledIndustriesAction, isPendingIndustriesAction, isRejectedIndustriesAction } from './thunks';
import { IndustryDto } from '../../api';

export interface IndustriesState extends RequestStatus {
  industries: EntityState<IndustryDto>;
}

export const industriesAdapter = createEntityAdapter<IndustryDto>({ selectId: (c) => c.option });

const initialIndustriesState: IndustriesState = {
  ...statusNotAsked,
  industries: industriesAdapter.getInitialState(),
};

export const reducer = createReducer(initialIndustriesState, (builder) => {
  builder.addMatcher(isPendingIndustriesAction, (state) => ({
    ...state,
    ...statusIsLoading,
  }));

  builder.addMatcher(isFulfilledIndustriesAction, (state, action) => ({
    ...state,
    ...statusIsSuccess,
    industries: industriesAdapter.setAll({ ...state.industries }, action.payload),
  }));

  builder.addMatcher(isRejectedIndustriesAction, (state) => ({
    ...state,
    ...statusIsFailure,
  }));
});

export type IndustriesStateType = ReturnType<typeof reducer>;
