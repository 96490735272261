import React, { FC, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/cjs/Accordion';
import './BaseSkills.scss';
import { skillGroupOrderSelector } from '../../../../store/skills';
import { SkillsForm } from '../../../../types/skills';
import { CandidateSkillDto } from '../../../../api/models/CandidateSkillDto';
import SkillsList from './SkillsList';
import { useTranslation } from 'react-i18next';
import OtherSkills from '../OtherSkills/OtherSkills';
import { SkillsFormGroup } from '../../../../types';
import SkillAccordion from '../SkillAccordion/SkillAccordion';
import { HoverHint } from '../../../../components';
import { insertStars } from '../SkillsTooltip/SkillsTooltip';
import { isMobile } from 'react-device-detect';
import './BaseSkills.scss';
import { Container, Row, Col } from 'react-bootstrap';
import TooltipModal from '../../../../components/TooltipModal/TooltipModal';

interface BaseSkillsProps {
  items: SkillsForm;
}

type skillArrayElement = [string, CandidateSkillDto[]];

const BaseSkills: FC<BaseSkillsProps> = ({ items }) => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const { t } = useTranslation();
  const [active, setActive] = useState<string | null>(null);

  const handleSelect = (selected: string | null) => {
    setActive(selected);
  };
  const groupOrder = useSelector(skillGroupOrderSelector);
  const sortedSkillGroups = useMemo(() => {
    const compareFunction = (firstItem: skillArrayElement, secondItem: skillArrayElement): number => {
      const skillsGroupLength = Object.keys(items).length;
      const firstIndex = groupOrder[firstItem[0]] !== undefined ? groupOrder[firstItem[0]] : skillsGroupLength;
      const secondIndex = groupOrder[secondItem[0]] !== undefined ? groupOrder[secondItem[0]] : skillsGroupLength;
      return firstIndex - secondIndex;
    };

    return Object.entries(items).sort(compareFunction);
  }, [items, groupOrder]);

  const starsData = [
    {
      starRate: 4,
      starPersonPositon: t('SKILLS.SKILL_LEVELS_DECSCRIPTION.EXPERT.NAME'),
      starDescription: t('SKILLS.SKILL_LEVELS_DECSCRIPTION.EXPERT.DESCRIPTION'),
    },
    {
      starRate: 3,
      starPersonPositon: t('SKILLS.SKILL_LEVELS_DECSCRIPTION.ADVANCED.NAME'),
      starDescription: t('SKILLS.SKILL_LEVELS_DECSCRIPTION.ADVANCED.DESCRIPTION'),
    },
    {
      starRate: 2,
      starPersonPositon: t('SKILLS.SKILL_LEVELS_DECSCRIPTION.INTERMEDIATE.NAME'),
      starDescription: t('SKILLS.SKILL_LEVELS_DECSCRIPTION.INTERMEDIATE.DESCRIPTION'),
    },
    {
      starRate: 1,
      starPersonPositon: t('SKILLS.SKILL_LEVELS_DECSCRIPTION.BASIC.NAME'),
      starDescription: t('SKILLS.SKILL_LEVELS_DECSCRIPTION.BASIC.DESCRIPTION'),
    },
  ];
  return (
    <section>
      <div className="skills_list">
        <TooltipModal
          headerTitle={t('SKILLS.SKILL_LEVELS_DECSCRIPTION.TITLE')}
          closeButtonCallback={() => setModalOpened(!modalOpened)}
          modalState={modalOpened}
        >
          {starsData.map((star, index) => {
            return (
              <div key={index}>
                <Row>
                  <Col>
                    <h6 className="starsDescriptionText">{star.starPersonPositon}</h6>
                  </Col>
                  <Col xs={5}>
                    {insertStars(star.starRate, 'starIconFilled')}
                    {insertStars(starsData.length - star.starRate, 'starIconNotFilled')}
                  </Col>
                </Row>
                <Row>
                  <Col className="descriptionText">{star.starDescription}</Col>
                </Row>
                <hr />
              </div>
            );
          })}
        </TooltipModal>
        <Accordion onSelect={handleSelect}>
          {sortedSkillGroups.map(([key, skills], index) => (
            <SkillAccordion key={index} groupName={key} index={String(index)} active={active === String(index)}>
              <ul className="skills_list_header">
                <span>{t('SKILLS.SKILL_NAME')}</span>
                <div>
                  {isMobile ? (
                    <div onClick={() => setModalOpened(!modalOpened)}>
                      <span>{t('SKILLS.SKILL_LEVEL')}</span>
                      <span className="hoverHintButton">i</span>
                    </div>
                  ) : (
                    <HoverHint
                      header={t('SKILLS.SKILL_LEVELS_DECSCRIPTION.TITLE')}
                      titleText={<span>{t('SKILLS.SKILL_LEVEL')}</span>}
                    >
                      <Container>
                        {starsData &&
                          starsData.map((star, index) => {
                            return (
                              <div key={index}>
                                <Row>
                                  <Col>
                                    <h6 className="starsDescriptionText">{star.starPersonPositon}</h6>
                                  </Col>
                                  <Col xs={4}>
                                    <div className="starsIconsContainer">
                                      {insertStars(star.starRate, 'starIconFilled')}
                                      {insertStars(starsData.length - star.starRate, 'starIconNotFilled')}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="descriptionText">{star.starDescription}</Col>
                                </Row>
                                {starsData.length - 1 !== index && <hr />}
                              </div>
                            );
                          })}
                      </Container>
                    </HoverHint>
                  )}
                </div>
                <span className="skills_year_exp">{t('SKILLS.YEARS_EXPERIENCE')}</span>
              </ul>
              {key !== SkillsFormGroup.other ? <SkillsList skills={skills} groupName={key} /> : <OtherSkills />}
            </SkillAccordion>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default BaseSkills;
