import React, { ButtonHTMLAttributes, FC } from 'react';

import './Button.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'lg' | 'sm';
  variant?: 'transparent' | 'blue' | 'secondary' | 'outline' | 'link';
}

const Button: FC<ButtonProps> = ({ className, children, size = 'sm', variant = 'transparent', ...props }) => {
  let btnVariant;
  switch (variant) {
    case 'transparent':
      btnVariant = 'button--transparent';
      break;
    case 'secondary':
      btnVariant = 'button--secondary';
      break;
    case 'outline':
      btnVariant = 'button--outline';
      break;
    case 'link':
      btnVariant = 'button--transparent button--link';
      break;
    default:
      btnVariant = 'button--blue';
      break;
  }

  const btnSize = size === 'sm' ? 'button--sm' : 'button--lg';

  return (
    <button {...props} className={`button ${btnVariant} ${btnSize} ${className}`}>
      {children}
    </button>
  );
};

export default Button;
