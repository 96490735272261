import React, { FC, useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import {
  Paper,
  Select,
  RemoveButton,
  ConfirmationModal,
  RequiredFieldIndicator,
  DottedButton,
} from '../../../../components';
import {
  BusinessProjectFields,
  DEFAULT_EXPERIENCE,
  ExperienceFields,
} from '../../containers/Experience/Experience.types';
import { months, years } from '../../../../constants/dates';
import { useTranslation } from 'react-i18next';
import './ExperienceForm.scss';
import { useDispatch } from 'react-redux';
import { updateExperienceAction } from '../../../../store/experience';
import { BusinessProjectForm } from '../BusinessProjectForm/BusinessProjectForm';
import { DateValidationConfig, validateDate } from '../../utils/dateValidation';
import { Close } from '../../../../icons';
import { ErrorMessage } from '@hookform/error-message';

interface FormProps {
  index: number;
  data: Partial<ExperienceFields>;
  removeExperience: (index: number) => void;
}

export const ExperienceForm: FC<FormProps> = ({
  data: { jobtitle, company, currently_working, year_end, id },
  index,
  removeExperience,
}) => {
  const {
    register,
    setError,
    trigger,
    clearErrors,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `experience[${index}].business_projects`,
    keyName: 'itemKey',
  });

  const [showModal, setShowModal] = useState(false);

  const baseName = `experience[${index}]`;
  const jobTitle = `${baseName}.jobtitle`;
  const currentlyWorking = `${baseName}.currently_working`;
  const currentlyWorkingValue = watch(currentlyWorking);

  const startYear = `${baseName}.year_start`;
  const startYearValue = watch(startYear);

  const startMonth = `${baseName}.month_start`;
  const startMonthValue = watch(startMonth);

  const endYear = `${baseName}.year_end`;

  const endMonth = `${baseName}.month_end`;

  const fullStartDate = new Date().setFullYear(startYearValue, months.indexOf(startMonthValue));
  const isStartDateInTheFuture = new Date(fullStartDate) > new Date();

  const clearEndDate = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    if (typeof id === 'number') {
      dispatch(updateExperienceAction({ id, year_end: currentYear }));
    }
    setValue(`${baseName}.year_end`, currentYear);
    setValue(`${baseName}.month_end`, months[currentMonth]);
  };

  const toggleShowModal = () => setShowModal(!showModal);

  const dateValidationConfigDefaults: Pick<DateValidationConfig, 'error' | 'setError' | 'clearErrors'> = {
    error: {
      startDate: {
        message: t('EXPERIENCE.START_DATE_VALIDATION'),
        fieldNames: [startYear, startMonth],
      },
      endDate: {
        message: t('EXPERIENCE.END_DATE_VALIDATION'),
        fieldNames: [endYear, endMonth],
      },
      futureDate: t('EXPERIENCE.DATE_FUTURE_VALIDATION'),
    },
    setError,
    clearErrors,
  };

  const getDateValidationConfig = (): DateValidationConfig => {
    return {
      ...dateValidationConfigDefaults,
      startDate: {
        year: getValues(startYear),
        month: getValues(startMonth),
      },
      endDate: {
        year: getValues(endYear),
        month: getValues(endMonth),
        isOngoing: getValues(currentlyWorking),
      },
    };
  };

  const handleAddBusinessProject = () => append({ ...DEFAULT_EXPERIENCE.business_projects[0] }, { shouldFocus: false });
  const handleRemoveBusinessProject = (index: number) => remove(index);

  useEffect(() => {
    if ((!year_end && isStartDateInTheFuture) || currentlyWorkingValue) {
      // workaround to bypass strange default value of "End date year" while going back from summary
      clearEndDate();
    }
    if (fields.length === 0) {
      append(DEFAULT_EXPERIENCE.business_projects[0], { shouldFocus: false });
    }
  }, []);

  return (
    <Paper>
      <section className="experience-container">
        <section className={`experience ${showModal ? 'remove-experience' : ''}`}>
          <div className="experience-details">
            <div className="experience-header">
              <h4>
                {t('EXPERIENCE.HEADER')} {index + 1}
              </h4>
              <RemoveButton onClick={toggleShowModal}>
                <Close />
              </RemoveButton>
            </div>
            <div className="experience-container experience-container_company mt-4">
              <label htmlFor={jobTitle}>
                {t('EXPERIENCE.ENTRY.JOB_TITLE')}
                <RequiredFieldIndicator />
              </label>
              <input
                {...register(jobTitle, { required: { value: true, message: 'Job title is required' } })}
                type="text"
                defaultValue={jobtitle}
                maxLength={100}
              />
              <ErrorMessage
                errors={errors}
                name={jobTitle}
                render={({ message }) => (
                  <span className="experience-container experience-container_error">{message}</span>
                )}
              />
            </div>

            <div className="experience-container experience-container_company">
              <label htmlFor="company">
                {t('EXPERIENCE.ENTRY.COMPANY')}
                <RequiredFieldIndicator />
              </label>
              <input
                {...register(`${baseName}.company`, { required: { value: true, message: 'Company is required' } })}
                type="text"
                defaultValue={company}
                maxLength={100}
              />
              <ErrorMessage
                errors={errors}
                name={`${baseName}.company`}
                render={({ message }) => (
                  <span className="experience-container experience-container_error">{message}</span>
                )}
              />
            </div>
            <div className="experience-container experience-container_employment-status">
              <div className="experience-container experience-container_employment-status-duration">
                <div className="experience-container_employment-status-duration-start">
                  <p className="input-label">
                    {t('EXPERIENCE.ENTRY.START_DATE')}
                    <RequiredFieldIndicator />
                  </p>
                  <div className="experience-container_employment-status-duration-picker">
                    <Select
                      options={months}
                      name={`${baseName}.month_start`}
                      className="month_start"
                      registerOptions={{ validate: () => validateDate('startDate', getDateValidationConfig()) }}
                    />
                    <Select
                      options={years}
                      name={`${baseName}.year_start`}
                      className="year_start"
                      registerOptions={{ validate: () => validateDate('startDate', getDateValidationConfig()) }}
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name={startYear}
                    render={({ message }) => (
                      <span className="experience-container experience-container_error">{message}</span>
                    )}
                  />
                </div>
                <div className="experience-container_employment-status-duration-end">
                  <p className="input-label">
                    {t('EXPERIENCE.ENTRY.END_DATE')}
                    {!currentlyWorkingValue && <RequiredFieldIndicator />}
                  </p>
                  <>
                    <div className="experience-container_employment-status-duration-picker">
                      <Select
                        options={months}
                        name={`${baseName}.month_end`}
                        className="month_end"
                        registerOptions={{ validate: () => validateDate('endDate', getDateValidationConfig()) }}
                        disabled={isStartDateInTheFuture || currentlyWorkingValue}
                      />
                      <Select
                        options={years}
                        name={`${baseName}.year_end`}
                        className="year_end"
                        registerOptions={{ validate: () => validateDate('endDate', getDateValidationConfig()) }}
                        disabled={isStartDateInTheFuture || currentlyWorkingValue}
                      />
                    </div>
                    {!currentlyWorkingValue && (
                      <ErrorMessage
                        errors={errors}
                        name={endYear}
                        render={({ message }) => (
                          <span className="experience-container experience-container_error">{message}</span>
                        )}
                      />
                    )}
                  </>
                </div>
                <div className="experience-container_current-employment">
                  <input
                    {...register(`${baseName}.currently_working`, {
                      onChange: () => {
                        clearEndDate();
                        trigger([startYear, endYear]);
                      },
                    })}
                    type="checkbox"
                    defaultChecked={currently_working}
                    disabled={isStartDateInTheFuture}
                  />
                  {t('EXPERIENCE.ENTRY.CURRENTLY_WORKING')}
                </div>
              </div>
            </div>
          </div>

          {fields.map((field: Partial<BusinessProjectFields & { itemKey: string }>, bpIndex: number) => (
            <BusinessProjectForm
              key={field.itemKey}
              baseName={baseName}
              index={bpIndex}
              name={`${baseName}.${field.itemKey}`}
              multipleProjects={fields.length > 1}
              experienceIndex={index}
              currentlyWorking={currentlyWorkingValue}
              removeButtonAction={() => handleRemoveBusinessProject(bpIndex)}
              parentStartMonth={getValues(startMonth)}
              parentStartYear={getValues(startYear)}
              parentEndMonth={getValues(endMonth)}
              parentEndYear={getValues(endYear)}
            />
          ))}

          <div className="add-business-project-container">
            <DottedButton onClick={handleAddBusinessProject}>{t('EXPERIENCE.ADD_BUSINESS_PROJECT')}</DottedButton>
          </div>

          <div>
            <ConfirmationModal
              title="Are you sure you want to remove experience details?"
              confirmButtonText="Yes, remove"
              declineButtonText="Cancel"
              confirmButtonAction={() => removeExperience(index)}
              declineButtonAction={toggleShowModal}
              show={showModal}
              onHide={toggleShowModal}
            />
          </div>
        </section>
      </section>
    </Paper>
  );
};
