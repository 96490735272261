import React, { FC } from 'react';
import './Arrow.scss';

interface ArrowProps {
  className?: string;
}

const Arrow: FC<ArrowProps> = ({ className }) => {
  return (
    <svg
      className={'arrow ' + className}
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.5 8.5L9 1L1.5 8.5" stroke="#0098DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Arrow;
