export interface NavigationProps {
  next: () => void;
  previous: () => void;
  go: (step: number | string, edit?: boolean) => void;
  params: () => void;
  path: () => string;
}
export interface Step {
  id: string;
  editMode?: boolean;
}

export interface UseStepProps {
  step: Step;
  index: number;
  navigation: NavigationProps;
}

export enum Steps {
  LANDING_PAGE = 'landing-page',
  SKILLS = 'skills',
  EDUCATION = 'education',
  LANGUAGES = 'languages',
  CERTIFICATES = 'certificates',
  ABOUT_ME = 'about_me',
  EXPERIENCE = 'experience',
  SUMMARY = 'summary',
  CONFIRMATION_PAGE_SUBMITTED = 'confirmation-page-submitted',
  CONFIRMATION_PAGE_FINISH_LATER = 'confirmation-page-finish-later',
}
