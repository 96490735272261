import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GrStar } from 'react-icons/gr';
import styles from './SkillsTooltip.module.scss';

interface SkillsTooltipProps {
  totalStars: number;
  items: {
    level: string;
    noOfFilledStars: number;
  }[];
}

export const insertStars = (noOfStars: number, styleClass: string) =>
  [...Array.from({ length: noOfStars })].map((_, index) => (
    <GrStar key={`${styleClass}-${index}`} className={styleClass} />
  ));

const SkillsTooltip: FC<SkillsTooltipProps> = ({ items, totalStars }) => {
  const { t } = useTranslation();

  return (
    <ul>
      {items.map((el) => (
        <div key={`${el.level}`} className={styles.tooltipSkillLevel}>
          <span>
            {insertStars(el.noOfFilledStars, styles.starIconFilled)}
            {insertStars(totalStars - el.noOfFilledStars, styles.starIconNotFilled)}
          </span>
          <span title={t(`SKILLS.SKILL_LEVELS_DECSCRIPTION.${el.level}.DESCRIPTION`)}>
            {t(`SKILLS.SKILL_LEVELS_DECSCRIPTION.${el.level}.NAME`)}
          </span>
        </div>
      ))}
    </ul>
  );
};

export default SkillsTooltip;
