import { createSelector } from '@reduxjs/toolkit';
import { LanguageFields } from '../../modules/Language/containers/Language/Language.types';
import { LanguageDto } from '../../api';
import { RootState } from '../rootReducer';
import { languageListAdapter, languageNameAdapter, LanguageStateType, levelAdapter } from './reducer';
import { RequestStatus } from '../Store.types';

const selectLanguage = ({ languages }: RootState) => languages;
const selectLanguageNamesItems = ({ name }: LanguageStateType) => name.items;
const selectLevelsItems = ({ level }: LanguageStateType) => level.items;
const selectLanguageList = ({ list }: LanguageStateType) => list.languages;

export const languageStatusSelector = createSelector(selectLanguage, (languages) => mapStatus(languages));

const mapStatus = (languages: LanguageStateType): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = languages.list;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

const languageNamesEntitiesSelector = createSelector(selectLanguage, selectLanguageNamesItems);

export const languageNamesSelector = languageNameAdapter.getSelectors(languageNamesEntitiesSelector);

const levelsEntitiesSelector = createSelector(selectLanguage, selectLevelsItems);

export const levelsSelector = levelAdapter.getSelectors(levelsEntitiesSelector);

const languageEntitiesSelector = createSelector(selectLanguage, selectLanguageList);

export const languageListSelector = languageListAdapter.getSelectors(languageEntitiesSelector);

export const formLanguageListSelector = createSelector(languageListSelector.selectAll, (languages) => ({
  languages: mapToFormLanguage(languages),
}));

const mapToFormLanguage = (languages: LanguageDto[]): LanguageFields[] =>
  languages.map(({ languageKey, levelKey }) => ({ name: languageKey, level: levelKey }));
