import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit';
import { KeyValueEntryDto } from '../../api/models/KeyValueEntryDto';
import { isFulfilledDegreeNameAction, isPendingDegreeNameAction, isRejectedDegreeNameAction } from './thunks';

export interface DegreesNameState extends RequestStatus {
  items: EntityState<KeyValueEntryDto>;
}

export const degreesNameAdapter = createEntityAdapter<KeyValueEntryDto>({ selectId: (c) => c.key });

const initialDegreesNameState: DegreesNameState = {
  ...statusNotAsked,
  items: degreesNameAdapter.getInitialState(),
};

export const reducer = createReducer(initialDegreesNameState, (builder) => {
  builder.addMatcher(isPendingDegreeNameAction, (state, action) => ({
    ...state,
    ...statusIsLoading,
  }));

  builder.addMatcher(isFulfilledDegreeNameAction, (state, action) => ({
    ...state,
    ...statusIsSuccess,
    items: degreesNameAdapter.setAll({ ...state.items }, action.payload),
  }));

  builder.addMatcher(isRejectedDegreeNameAction, (state, action) => ({
    ...state,
    ...statusIsFailure,
  }));
});

export type DegreesStateType = ReturnType<typeof reducer>;
