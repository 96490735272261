import React, { FC } from 'react';
import './HeaderWrapper.scss';
import { HoverHint, RequiredFieldIndicator } from '../index';

interface SectionWrapperProps {
  stepName: string;
  hoverHint?: {
    headerText: string;
    body: string;
  };
  isRequired?: boolean;
}

function createHoverHintTemplate(hoverHint: { headerText: string; body: string }) {
  return (
    <HoverHint header={hoverHint.headerText} id={'headerWrapper--hoverHintButton'}>
      <p>{hoverHint.body}</p>
    </HoverHint>
  );
}

const HeaderWrapper: FC<SectionWrapperProps> = ({ stepName, children, hoverHint, isRequired }) => {
  return (
    <header>
      <div className="header-content">
        <h2 className="header-content_title">
          {stepName}
          {isRequired && <RequiredFieldIndicator />}
          {hoverHint && createHoverHintTemplate(hoverHint)}
        </h2>
        {children}
      </div>
      <hr className="header-content_line" />
    </header>
  );
};

export default HeaderWrapper;
