import React, { FC, Fragment, useState } from 'react';
import { Levels } from './Level.types';
import HitBox from '../HitBox/HitBox';
import { Star } from '../../icons';
import './Level.scss';

interface LevelProps {
  isEditable?: boolean;
  defaultValue?: Levels;
  disableHighlight?: boolean;

  downgradeLevel?(level: Levels): void;

  upgradeLevel?(level: Levels): void;
}

const Level: FC<LevelProps> = ({
  defaultValue = 0,
  isEditable = false,
  downgradeLevel: downgradeLevel,
  upgradeLevel,
  disableHighlight,
}) => {
  const [level, setLevel] = useState(defaultValue);

  const handleUpgradeLevel = (nextLevel: number) => {
    if (isEditable && level < 4 && upgradeLevel) {
      if (level === nextLevel) {
        handleCancelLevel();
      } else {
        setLevel(nextLevel);
        upgradeLevel(nextLevel);
      }
    }
  };

  const handleDowngrandeLevel = (nextLevel: number) => {
    if (isEditable && level > 0 && downgradeLevel) {
      if (level === nextLevel) {
        handleCancelLevel();
      } else {
        setLevel(nextLevel);
        downgradeLevel(nextLevel);
      }
    }
  };

  const handleCancelLevel = () => {
    if (isEditable && level > 0 && downgradeLevel) {
      setLevel(0);
      downgradeLevel(0);
    }
  };

  return (
    <div className="level">
      {Object.keys(Levels)
        .filter((value) => !isNaN(Number(value)) && Number(value) !== 0)
        .map((value, idx) => (
          <Fragment key={idx}>
            {Number(value) <= level ? (
              <HitBox onClick={() => handleDowngrandeLevel(Number(value))} disableHighlight={disableHighlight}>
                <Star isFilled />
              </HitBox>
            ) : (
              <HitBox onClick={() => handleUpgradeLevel(Number(value))} disableHighlight={disableHighlight}>
                <Star />
              </HitBox>
            )}
          </Fragment>
        ))}
    </div>
  );
};

export default Level;
