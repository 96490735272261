import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { candidateReportErrorSelector } from '../../../../store/candidateReport';
import { PageNotFound } from '../../../../components';

interface RouterProps {
  children: ReactElement;
}

const Router: FC<RouterProps> = ({ children }) => {
  const { message } = useSelector(candidateReportErrorSelector);

  if (message) {
    return <PageNotFound />;
  }

  return children;
};

export default Router;
