import { createAsyncThunk, isFulfilled, isPending, isRejected, createAction } from '@reduxjs/toolkit';
import { ExperienceListDto, putNewExperience } from '../../api';
import { getCandidateReportWithSectionsThunk } from '../candidateReport';

export enum Actions {
  putNewExperience = '[EXPERIENCE] PUT NEW EXPERIENCE',
  updateExperience = '[EXPERIENCE] UPDATE EXPERIENCE',
}

export const putNewExperienceThunk = createAsyncThunk(
  Actions.putNewExperience,
  async (experience: ExperienceListDto) => {
    const { data } = await putNewExperience(experience);

    return data;
  },
);

export const updateExperienceAction = createAction<{ id: number; year_end: number | null }>(Actions.updateExperience);

export const isPendingAction = isPending(putNewExperienceThunk);
export const isFulfilledAction = isFulfilled(putNewExperienceThunk);
export const isExperiencesFromCandidateReportAction = isFulfilled(getCandidateReportWithSectionsThunk);
export const isRejectedAction = isRejected(putNewExperienceThunk);
