import React from 'react';

const TrashIcon: React.FC<{ color?: string }> = ({ color }) => {
  const fillColor = color ? color : '#4E5A62';

  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0771484 4.97023C0.0771484 5.23939 0.182505 5.49753 0.370042 5.68786C0.557578 5.87819 0.811932 5.98511 1.07715 5.98511V11.0595C1.07715 11.5978 1.28786 12.1141 1.66293 12.4948C2.03801 12.8754 2.54672 13.0893 3.07715 13.0893H8.07715C8.60758 13.0893 9.11629 12.8754 9.49136 12.4948C9.86643 12.1141 10.0771 11.5978 10.0771 11.0595V5.98511C10.3424 5.98511 10.5967 5.87819 10.7843 5.68786C10.9718 5.49753 11.0771 5.23939 11.0771 4.97023V3.95535C11.0771 3.68619 10.9718 3.42805 10.7843 3.23772C10.5967 3.04739 10.3424 2.94047 10.0771 2.94047H8.07715V1.92559C8.07715 1.65642 7.97179 1.39828 7.78426 1.20796C7.59672 1.01763 7.34236 0.910706 7.07715 0.910706H4.07715C3.81193 0.910706 3.55758 1.01763 3.37004 1.20796C3.18251 1.39828 3.07715 1.65642 3.07715 1.92559V2.94047H1.07715C0.811932 2.94047 0.557578 3.04739 0.370042 3.23772C0.182505 3.42805 0.0771484 3.68619 0.0771484 3.95535V4.97023ZM9.07715 11.0595C9.07715 11.3287 8.97179 11.5868 8.78426 11.7771C8.59672 11.9675 8.34236 12.0744 8.07715 12.0744H3.07715C2.81193 12.0744 2.55758 11.9675 2.37004 11.7771C2.18251 11.5868 2.07715 11.3287 2.07715 11.0595V5.98511H9.07715V11.0595ZM4.07715 1.92559H7.07715V2.94047H4.07715V1.92559ZM1.07715 3.95535H10.0771V4.97023H1.07715V3.95535Z"
        fill={fillColor}
      />
      <path
        d="M3.41643 11.125C3.57272 11.125 3.72261 11.0732 3.83312 10.9812C3.94363 10.8891 4.00572 10.7642 4.00572 10.6339V7.68748C4.00572 7.55724 3.94363 7.43234 3.83312 7.34024C3.72261 7.24815 3.57272 7.19641 3.41643 7.19641C3.26015 7.19641 3.11026 7.24815 2.99975 7.34024C2.88923 7.43234 2.82715 7.55724 2.82715 7.68748V10.6339C2.82715 10.7642 2.88923 10.8891 2.99975 10.9812C3.11026 11.0732 3.26015 11.125 3.41643 11.125Z"
        fill={fillColor}
      />
      <path
        d="M5.57728 11.125C5.68148 11.125 5.7814 11.0732 5.85508 10.9812C5.92875 10.8891 5.97014 10.7642 5.97014 10.6339V7.68748C5.97014 7.55724 5.92875 7.43234 5.85508 7.34024C5.7814 7.24815 5.68148 7.19641 5.57728 7.19641C5.47309 7.19641 5.37317 7.24815 5.29949 7.34024C5.22582 7.43234 5.18443 7.55724 5.18443 7.68748V10.6339C5.18443 10.7642 5.22582 10.8891 5.29949 10.9812C5.37317 11.0732 5.47309 11.125 5.57728 11.125Z"
        fill={fillColor}
      />
      <path
        d="M7.54152 11.125C7.64572 11.125 7.74564 11.0732 7.81932 10.9812C7.89299 10.8891 7.93438 10.7642 7.93438 10.6339V7.68748C7.93438 7.55724 7.89299 7.43234 7.81932 7.34024C7.74564 7.24815 7.64572 7.19641 7.54152 7.19641C7.43733 7.19641 7.33741 7.24815 7.26373 7.34024C7.19006 7.43234 7.14867 7.55724 7.14867 7.68748V10.6339C7.14867 10.7642 7.19006 10.8891 7.26373 10.9812C7.33741 11.0732 7.43733 11.125 7.54152 11.125Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default TrashIcon;
