import React, { useEffect, useState } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustriesThunk, industriesSelector } from '../../store/industry';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { IndustryDto } from '../../api';
import { useTranslation } from 'react-i18next';
import './SelectGroup.scss';
import CustomMenu from './CustomMenu';

interface SelectGroupProps {
  name: string;
  className?: string;
  registerOptions?: RegisterOptions;
  disabled?: boolean;
  industryValue?: string;
}

const SelectGroup = ({ name, registerOptions, industryValue, ...rest }: SelectGroupProps) => {
  const { register, watch, setValue } = useFormContext();
  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();
  const industriesData = useSelector(industriesSelector.selectAll);
  const [show, setShow] = useState(false);
  const [industry, setIndustry] = useState<string | undefined>(industryValue);
  watch(name, name);
  const { t } = useTranslation();

  const handleOnSelect = (eventKey: any) => {
    setShow(false);
    setIndustry(eventKey);
    setValue(name, eventKey, { shouldValidate: true });
  };

  const handleCloseMenu = () => setShow(false);
  const handleFocus = () => setShow(true);

  useEffect(() => {
    if (industriesData.length <= 0) {
      dispatch(getIndustriesThunk());
    }
  }, [dispatch, industriesData.length]);

  const newOptgroups = industriesData.map((o: IndustryDto) => {
    const { option, label } = o;
    return (
      <Dropdown.Item key={label} eventKey={option}>
        {option}
      </Dropdown.Item>
    );
  });

  return (
    <>
      {!show ? (
        <input
          {...register(name, registerOptions)}
          id="industry"
          onClick={handleFocus}
          placeholder={t('EXPERIENCE.ENTRY.INDUSTRY_PLACEHOLDER')}
          value={industry}
          readOnly
          {...rest}
        />
      ) : (
        <Dropdown onSelect={handleOnSelect} show={show} onBlur={() => setTimeout(handleCloseMenu, 300)}>
          <Dropdown.Toggle
            className="btn-block dropdown-btn"
            variant="light"
            id="dropdown-industry"
            onClick={handleCloseMenu}
          >
            {industry}
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu} className="selectgroup-menu">
            {newOptgroups}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default SelectGroup;
