import React, { ButtonHTMLAttributes, FC, Fragment } from 'react';
import HeaderWrapper from '../../../../components/HeaderWrapper/HeaderWrapper';
import { HitBox } from '../../../../components';
import Pencil from '../../../../icons/Pencil';
import SkillsLevel from '../SkillsLevel/SkillsLevel';
import { CandidateSkillDto } from '../../../../api';
import { SkillModel } from '../Summary.types';
import './Skills.scss';

interface SkillsProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  model: CandidateSkillDto[];
  hideEmpty?: boolean;
}

const Skills: FC<SkillsProps> = ({ onClick, model, hideEmpty }) => {
  const skills = model
    .filter((skill) => skill.level > 0)
    .reduce(
      (acc: SkillModel[][], skill: CandidateSkillDto) => {
        acc[skill.level - 1].push({ experience: skill.years, skill: skill.skillName });
        return acc;
      },
      [[], [], [], []],
    );

  return (
    <section className="skills-section">
      <HeaderWrapper stepName="Skills">
        {!!onClick && (
          <HitBox onClick={onClick}>
            <Pencil className="blue" />
          </HitBox>
        )}
      </HeaderWrapper>
      <Fragment>
        {skills
          .map((l, i) => [<SkillsLevel key={i} level={i + 1} skills={l} />, l.length])
          .filter(([_, len]) => (!!hideEmpty ? len > 0 : true))
          .map(([el, _]) => el)
          .reverse()}
      </Fragment>
    </section>
  );
};

export default Skills;
