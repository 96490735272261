import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { isFulfilledAction, isFulfilledPatchAction, isPendingAction, isRejectedAction } from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { CandidateReportDto } from '../../api';

export interface CandidateReportPayload {
  report: CandidateReportDto;
}
export interface CandidateReportState extends RequestStatus {
  report: CandidateReportDto | Record<string, any>;
}

const initialState: CandidateReportState = {
  ...statusNotAsked,
  report: {},
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isPendingAction, (state: CandidateReportState) => ({
      ...state,
      ...statusIsLoading,
    }))
    .addMatcher(isFulfilledAction, (state: CandidateReportState, action: PayloadAction<CandidateReportPayload>) => {
      return {
        ...state,
        ...statusIsSuccess,
        report: action.payload.report,
      };
    })
    .addMatcher(isFulfilledPatchAction, (state: CandidateReportState, action: PayloadAction<CandidateReportDto>) => {
      return {
        ...state,
        ...statusIsSuccess,
        report: action.payload,
      };
    })
    .addMatcher(isRejectedAction, (state: CandidateReportState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
});

export default reducer;

export type ReportState = ReturnType<typeof reducer>;
