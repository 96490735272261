import { AxiosPromise } from 'axios';

import { CandidateReportDto } from '../models/CandidateReportDto';
import { DescriptionDto } from '../models/DescriptionDto';
import axiosInstance from '../axiosInstance';
import { StatusEnum } from '../../types/reportStatus';

const url = '/section';

export const getCandidateReport = (): AxiosPromise<CandidateReportDto> => {
  return axiosInstance({
    url: `${url}/report`,
    method: 'GET',
  });
};

export const patchCandidateDescription = (data: DescriptionDto): AxiosPromise<CandidateReportDto> => {
  return axiosInstance({
    url: `${url}/report`,
    method: 'PATCH',
    data,
  });
};

export const finishReport = (): AxiosPromise<CandidateReportDto> => {
  return axiosInstance({
    url: `${url}/report/confirm`,
    method: 'POST',
  });
};

export const updateReportStatus = (id: number, status: StatusEnum): AxiosPromise<CandidateReportDto> => {
  return axiosInstance({
    url: `/report/update-status/${id}?needsAuth=false`,
    method: 'PUT',
    data: {
      status,
    },
  });
};
