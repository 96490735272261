import React, { ButtonHTMLAttributes, FC } from 'react';
import HeaderWrapper from '../../../../components/HeaderWrapper/HeaderWrapper';
import { HitBox } from '../../../../components';
import Pencil from '../../../../icons/Pencil';
import Description from '../Description/Description';
import { LanguagesModel } from './Languages.types';
import './Languages.scss';
import { useSelector } from 'react-redux';
import { languageNamesSelector, levelsSelector } from '../../../../store/language';
import { KeyValueEntryDto } from '../../../../api/models/KeyValueEntryDto';

interface LanguagesProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  model: LanguagesModel[];
}

const Languages: FC<LanguagesProps> = ({ onClick, model }) => {
  const dictLanguages = useSelector(languageNamesSelector.selectAll);
  const dictLevels = useSelector(levelsSelector.selectAll);

  const findDictValue = (dict: KeyValueEntryDto[], key: string): string => {
    const found = dict.find((d) => d.key === key);
    if (found) return found.value;

    return `-${key}-`;
  };

  return (
    <section className="languages-container">
      <HeaderWrapper stepName="Languages">
        {!!onClick && (
          <HitBox onClick={onClick}>
            <Pencil className="blue" />
          </HitBox>
        )}
      </HeaderWrapper>
      <Description
        list={model.map((language) => [
          findDictValue(dictLanguages, language.languageKey),
          `- ${findDictValue(dictLevels, language.levelKey)}`,
        ])}
        dot_style={false}
      />
    </section>
  );
};

export default Languages;
