import React, { ButtonHTMLAttributes, FC, Fragment } from 'react';
import HeaderWrapper from '../../../../components/HeaderWrapper/HeaderWrapper';
import { HitBox } from '../../../../components';
import Pencil from '../../../../icons/Pencil';
import SingleDescription from '../Description/SingleDescription';
import './AboutMe.scss';

interface ExperienceProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  aboutMe?: string;
}

const getAboutMe = (aboutMe: string | undefined) => {
  if (aboutMe) {
    return (
      <div className="aboutMe-section">
        <SingleDescription description={aboutMe} />
      </div>
    );
  }
};

const AboutMe: FC<ExperienceProps> = ({ onClick, aboutMe }) => {
  const isEmpty = !aboutMe;
  return (
    <section className={`${isEmpty ? 'empty-section' : ''}`}>
      <HeaderWrapper stepName="About me">
        {!!onClick && (
          <HitBox onClick={onClick}>
            <Pencil className="blue" />
          </HitBox>
        )}
      </HeaderWrapper>
      <Fragment>{getAboutMe(aboutMe)}</Fragment>
    </section>
  );
};

export default AboutMe;
