import React, { CSSProperties, FC } from 'react';

interface LanguagesIconProps {
  className?: string;
}

const blendModeSoft = {
  mixBlendMode: 'soft-light',
} as CSSProperties;

const blendModeMultiply = {
  mixBlendMode: 'multiply',
} as CSSProperties;

const LanguagesIcon: FC<LanguagesIconProps> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="187"
      height="181"
      viewBox="0 0 187 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M164.025 49.3318C168.543 8.19712 128.663 -6.09335 100.391 4.43862C71.8901 15.0561 65.2827 -2.54424 25.2065 2.4852C-15.3727 7.57752 5.22973 71.2766 5.22973 71.2766C5.22973 71.2766 11.6864 126.668 19.3358 152.199C26.9444 177.594 99.7715 168.406 117.488 167.648C135.102 166.895 150.408 168.349 165.733 140.467C181.031 112.635 159.524 90.3304 164.025 49.3318Z"
        fill="#F4F7FA"
      />
      <path
        d="M78.1172 90.9425C82.292 87.0247 87.8006 80.6627 90.2931 71.6666C93.562 59.8679 88.7719 53.9027 89.3121 33.9314C89.6533 21.3014 90.019 12.4908 96.3865 7.77821C101.376 4.08503 109.454 3.35067 114.357 6.77404C118.433 9.61992 120.791 15.6737 121.511 19.9944C122.097 23.516 121.627 26.0732 123.656 27.9302C125.732 29.8262 127.971 28.753 129.91 30.1571C133.845 33.0048 131.566 42.4373 131.198 43.9622C127.104 60.9191 111.477 72.3564 104.601 77.2631C98.9156 81.3189 90.1203 86.7268 78.1172 90.9425Z"
        fill="url(#paint0_linear_3159_3496)"
      />
      <g style={blendModeSoft} opacity="0.5">
        <g style={blendModeSoft} opacity="0.5">
          <path
            d="M73.3613 94.1108L73.2118 93.7164C80.4578 90.9627 86.6922 87.6798 91.7412 83.9591C99.1831 78.4749 103.815 72.1849 105.51 65.2631C106.837 59.8411 106.364 54.2202 105.715 46.4407C105.194 40.23 104.265 35.8745 103.445 32.0342C102.373 27.0078 101.526 23.0375 102.116 17.2755C102.714 11.4287 104.642 5.61608 107.845 0L108.211 0.209381C105.037 5.77357 103.128 11.5294 102.534 17.3177C101.95 23.0143 102.791 26.9553 103.856 31.9457C104.678 35.8006 105.61 40.1696 106.133 46.4071C106.764 53.932 107.262 59.8759 105.918 65.3656C104.199 72.3857 99.5127 78.7563 91.9896 84.3003C86.9114 88.0424 80.6433 91.3436 73.3613 94.1108Z"
            fill="white"
          />
        </g>
        <g style={blendModeSoft} opacity="0.5">
          <path
            d="M105.563 42.1042L105.345 42.0089C105.303 41.9906 101.161 40.1556 97.0239 35.8978C93.2056 31.9678 88.5998 25.1088 88.3556 14.7484L88.7774 14.7386C89.0216 24.9538 93.5523 31.7163 97.3132 35.5907C100.857 39.2424 104.399 41.0847 105.296 41.5206C108.695 30.2927 114.726 23.2494 119.201 19.3102C124.09 15.0084 128.241 13.3724 128.283 13.356L128.435 13.7497C128.395 13.7656 124.3 15.382 119.465 19.6405C115.005 23.5693 108.984 30.6162 105.63 41.8759L105.563 42.1042Z"
            fill="white"
          />
        </g>
        <g style={blendModeSoft} opacity="0.5">
          <path
            d="M106.457 58.9231L106.37 58.7961C106.33 58.7351 102.244 52.7844 96.9799 47.6195C91.749 42.4875 79.4932 40.7032 79.3699 40.6861L79.4309 40.2679C79.553 40.2856 91.9487 42.0901 97.2772 47.3185C102.144 52.0928 106.007 57.5355 106.632 58.4286C119.481 54.4607 133.198 24.3866 133.335 24.082L133.719 24.256C133.685 24.3329 130.193 32.0251 125.14 40.0885C118.339 50.9421 112.103 57.2639 106.606 58.8773L106.457 58.9231Z"
            fill="white"
          />
        </g>
        <g style={blendModeSoft} opacity="0.5">
          <path
            d="M93.911 82.7878L94.121 82.4215C97.4045 76.7169 96.9247 70.726 92.6956 64.6149C89.5292 60.0366 85.3385 57.1199 85.2964 57.0912L85.5363 56.7438C85.579 56.7731 89.8326 59.735 93.0447 64.3744C97.2226 70.411 97.7903 76.5869 94.6972 82.2604C96.2081 82.0412 101.671 81.142 108.5 78.7595C115.896 76.1797 126.453 71.2834 134.749 62.6065L150.865 45.7449L151.17 46.0361L135.054 62.8977C126.7 71.638 116.074 76.5668 108.631 79.1617C100.569 81.9698 94.3957 82.7292 94.334 82.7365L93.911 82.7878Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M69.3146 97.108C77.4335 101.084 88.3403 104.991 100.447 105.281C103.537 105.355 122.628 105.812 124.214 97.7807C124.773 94.9519 122.688 93.4588 123.369 89.6618C124.176 85.1647 127.619 84.3821 131.726 80.2702C134.175 77.8162 141.346 70.6349 138.475 63.3658C136.76 59.0219 131.537 54.8428 126.564 53.7336C119.269 52.1074 113.941 57.4048 105.082 66.5248C97.2277 74.6089 96.9151 77.3712 91.1581 83.0941C86.888 87.3421 80.0901 92.7488 69.3146 97.108Z"
        fill="url(#paint1_linear_3159_3496)"
      />
      <g style={blendModeSoft} opacity="0.5">
        <g style={blendModeSoft} opacity="0.5">
          <path
            d="M69.5076 97.0244C65.9396 97.0247 62.3817 96.6456 58.8939 95.8933C49.6817 93.9106 41.0439 89.5032 33.2198 82.7932L33.4945 82.4727C41.2643 89.1363 49.8398 93.5132 58.9799 95.4812C71.1589 98.1019 83.7706 96.1228 96.466 89.5978C109.69 82.8005 119.691 72.7746 125.752 65.5616C129.946 60.5706 132.699 54.1329 133.936 46.4285L134.353 46.495C133.104 54.2763 130.319 60.7794 126.075 65.8326C119.989 73.0761 109.945 83.1436 96.6589 89.9726C87.5285 94.6663 78.4329 97.0244 69.5076 97.0244Z"
            fill="white"
          />
        </g>
        <g style={blendModeSoft} opacity="0.5">
          <path
            d="M112.337 79.34L112.293 79.1568C112.284 79.1172 111.369 75.1658 111.616 69.9257C111.845 65.0831 113.139 58.113 118.009 52.406L118.33 52.6795C109.698 62.7927 112.295 77.0819 112.665 78.873C135.312 75.9502 141.315 63.0418 142.617 59.1459C142.637 59.0794 142.66 59.0139 142.687 58.95L143.066 59.1374L142.877 59.0434L143.067 59.1337C143.058 59.1557 143.042 59.2051 143.017 59.2796C141.691 63.2475 135.574 76.4111 112.525 79.3156L112.337 79.34Z"
            fill="white"
          />
        </g>
        <g style={blendModeSoft} opacity="0.5">
          <path
            d="M117.984 97.2351C112.863 97.2351 105.457 95.7902 96.6669 89.8451L96.5735 89.784V89.6711C96.5735 89.549 96.7316 77.3108 104.297 64.2113L111.921 51.0093L112.288 51.2205L104.663 64.4225C97.5417 76.7535 97.0325 88.3067 96.9984 89.5618C102.425 93.2159 110.76 97.3981 119.825 96.751C125.261 96.3621 128.409 94.3929 128.44 94.3703L128.667 94.7262C128.536 94.8098 125.396 96.773 119.855 97.1679C119.271 97.2113 118.646 97.2351 117.984 97.2351Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M149.265 6.54025L150.914 3.47217L151.888 6.81556L154.139 4.1577L154.397 7.63112L157.151 5.49884L156.682 8.94967L159.819 7.43699L158.642 10.7151L162.025 9.88731L160.192 12.8492L163.674 12.743L161.266 15.2592L164.693 15.8788L161.814 17.8389L165.037 19.1581L161.814 20.4773L164.693 22.4374L161.266 23.0576L163.674 25.5733L160.192 25.467L162.025 28.4289L158.642 27.6011L159.819 30.8798L156.682 29.3665L157.151 32.818L154.397 30.6851L154.139 34.1591L151.888 31.5007L150.914 34.8447L149.265 31.7766L147.616 34.8447L146.641 31.5007L144.391 34.1591L144.133 30.6851L141.378 32.818L141.848 29.3665L138.711 30.8798L139.888 27.6011L136.504 28.4289L138.337 25.467L134.856 25.5733L137.264 23.0576L133.837 22.4374L136.716 20.4773L133.492 19.1581L136.716 17.8389L133.837 15.8788L137.264 15.2592L134.856 12.743L138.337 12.8492L136.504 9.88731L139.888 10.7151L138.711 7.43699L141.848 8.94967L141.378 5.49884L144.133 7.63112L144.391 4.1577L146.641 6.81556L147.616 3.47217L149.265 6.54025Z"
        fill="url(#paint2_linear_3159_3496)"
      />
      <path
        d="M160.688 21.5577L163.779 20.6488L160.937 19.13L163.772 17.5984L160.676 16.7041L163.13 14.6164L159.917 14.385L161.883 11.8328L158.692 12.2747L160.084 9.369L157.055 10.4654L157.813 7.33379L155.077 9.0357L155.167 5.81501L152.845 8.04862L152.264 4.8792L150.457 7.54683L149.23 4.56787L148.017 7.55233L146.197 4.89385L145.632 8.06571L143.298 5.84309L143.405 9.06317L140.661 7.37469L141.433 10.5026L138.398 9.42089L139.805 12.3199L136.612 11.8932L138.59 14.4363L135.378 14.6829L137.842 16.7584L134.75 17.668L137.593 19.1861L134.758 20.7178L137.853 21.6121L135.399 23.6998L138.613 23.9311L136.646 26.4834L139.838 26.0414L138.445 28.9471L141.475 27.8514L140.717 30.9824L143.453 29.2804L143.363 32.5017L145.685 30.2681L146.266 33.4369L148.073 30.7693L149.3 33.7489L150.513 30.7638L152.332 33.4223L152.898 30.2504L155.231 32.4731L155.125 29.253L157.869 30.9414L157.096 27.8135L160.131 28.8952L158.724 25.9963L161.918 26.423L159.94 23.8805L163.152 23.6338L160.688 21.5577Z"
        fill="url(#paint3_linear_3159_3496)"
      />
      <path
        d="M161.842 19.1587C161.842 25.5928 157.008 30.9 150.775 31.6454C150.273 31.7057 149.769 31.7359 149.264 31.7357C142.319 31.7357 136.689 26.1044 136.689 19.1606C136.689 15.8254 138.014 12.6269 140.372 10.2686C142.731 7.91033 145.929 6.58545 149.264 6.58545C156.211 6.58118 161.842 12.2125 161.842 19.1587Z"
        fill="url(#paint4_linear_3159_3496)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.2"
        d="M155.036 26.575C154.399 28.3453 152.904 30.0917 150.775 31.6453C150.273 31.7057 149.769 31.7359 149.264 31.7357C142.319 31.7357 136.689 26.1043 136.689 19.1605C136.685 16.1212 137.785 13.1839 139.785 10.8958C139.886 11.8114 140.355 12.8315 141.492 13.8796C142.654 14.9497 144.516 16.0503 147.398 17.0948C154.272 19.5872 156.269 23.1364 155.036 26.575Z"
        fill="url(#paint5_linear_3159_3496)"
      />
      <g style={blendModeSoft} opacity="0.3">
        <path
          d="M149.265 28.5553C154.455 28.5553 158.662 24.3481 158.662 19.1581C158.662 13.9682 154.455 9.76099 149.265 9.76099C144.075 9.76099 139.868 13.9682 139.868 19.1581C139.868 24.3481 144.075 28.5553 149.265 28.5553Z"
          fill="white"
        />
      </g>
      <path
        d="M165.182 124.945C162.718 119.957 152.919 118.933 146.036 121.039C144.35 121.553 140.334 122.835 137.226 126.513C131.514 133.271 130.877 145.735 138.373 153.617C145.868 161.498 159.758 162.242 163.6 157.223C165.393 154.879 165.248 150.948 165.207 149.83C165.065 145.984 163.589 144.58 162.792 141.606C160.664 133.666 167.503 129.642 165.182 124.945Z"
        fill="#D8DEE8"
      />
      <path
        opacity="0.5"
        d="M137.226 126.511C140.335 122.833 144.35 121.551 146.036 121.036C151.518 119.362 158.848 119.67 162.85 122.384C163.363 124.011 163.797 126.31 162.639 127.922C160.766 130.53 156.79 128.476 152.863 131.27C149.756 133.479 150.982 135.665 147.607 138.272C143.925 141.117 141.485 139.272 139.371 141.961C137.032 144.936 137.806 149.958 138.083 151.365C138.258 152.257 138.497 153.123 138.73 153.978C138.608 153.856 138.49 153.734 138.373 153.612C130.88 145.733 131.514 133.268 137.226 126.511Z"
        fill="#97A6B7"
      />
      <path
        d="M59.9507 46.8834L59.9128 46.8791C59.2926 46.8083 58.7255 47.2161 58.6462 47.7905L44.3221 151.313C44.2397 151.91 44.6963 152.378 45.3403 152.359H45.3806C46.0234 152.34 46.6113 151.843 46.6931 151.248L60.9268 48.0499C61.0061 47.4749 60.5697 46.9511 59.9507 46.8834Z"
        fill="#99A0AA"
      />
      <path
        d="M105.696 142.485L93.167 47.5998C93.095 47.0504 92.5785 46.6689 92.0133 46.7415L91.9779 46.7458C91.4114 46.8185 91.0103 47.3215 91.083 47.8696L103.687 143.028C103.757 143.556 104.256 143.864 104.803 143.716L104.836 143.707C105.381 143.559 105.766 143.012 105.696 142.485Z"
        fill="#99A0AA"
      />
      <path
        d="M65.6939 134.478L69.978 38.865C70.0024 38.3156 70.484 37.8883 71.0536 37.9102H71.0884C71.6591 37.9334 72.1023 38.3986 72.0773 38.9523L67.7431 134.844C67.7187 135.376 67.2475 135.726 66.6907 135.626L66.6565 135.62C66.1029 135.52 65.6725 135.009 65.6939 134.478Z"
        fill="#99A0AA"
      />
      <path
        d="M113.001 16.206V105.884L83.4944 108.574L33.3728 113.146V12.9878L42.1888 13.3425L113.001 16.206Z"
        fill="#B7C0CE"
      />
      <path
        d="M113.694 16.206V105.884L84.1871 108.574L34.0654 113.146V12.9878L42.8814 13.3425L113.694 16.206Z"
        fill="white"
      />
      <path
        d="M114.779 15.1543V16.9124C114.777 17.0243 114.753 17.1347 114.707 17.2369C114.661 17.3391 114.595 17.431 114.513 17.5071C114.431 17.5833 114.335 17.642 114.229 17.6798C114.124 17.7177 114.012 17.7338 113.9 17.7273L113.587 17.7163L43.5823 15.0664L33.0272 14.6678H32.9624C32.3691 14.6458 31.8636 14.2038 31.8636 13.6795V11.7077C31.8636 11.184 32.352 10.7811 32.9454 10.8055L38.4393 11.0405L113.894 14.2759C114.371 14.2923 114.779 14.6873 114.779 15.1543Z"
        fill="#B7C0CE"
      />
      <path
        d="M114.779 104.687V106.446C114.779 106.915 114.381 107.329 113.912 107.373L87.687 109.831L34.073 114.86C33.4882 114.915 32.9785 114.534 32.9785 114.011V112.038C32.9785 111.514 33.4602 111.048 34.0456 110.997L34.1115 110.993L80.482 106.877L113.574 103.941L113.909 103.917C114.018 103.904 114.129 103.915 114.233 103.947C114.338 103.98 114.434 104.035 114.516 104.108C114.598 104.18 114.664 104.269 114.71 104.369C114.755 104.469 114.779 104.578 114.779 104.687V104.687Z"
        fill="#B7C0CE"
      />
      <g style={blendModeMultiply} opacity="0.2">
        <path
          d="M87.6631 109.831L34.0662 114.86C33.4808 114.915 32.9839 114.534 32.9839 114.011V112.038C32.9839 111.515 33.4362 111.048 34.0216 110.998L34.0686 110.993V14.6653H34.0369C33.4515 14.6434 32.9839 14.2014 32.9839 13.677V11.7053C32.9839 11.1815 33.4655 10.7786 34.0509 10.8031L39.4735 11.0381C40.6868 11.6694 41.8207 12.443 42.8511 13.3425C43.4552 13.8724 44.0169 14.4488 44.531 15.0664C46.6175 17.5839 48.1131 21.0615 48.2761 25.8639C48.7846 40.857 46.4783 43.9233 50.2533 47.8826C50.846 48.4891 51.4799 49.0539 52.1506 49.5729C52.3709 49.7469 52.5999 49.9251 52.8422 50.1076C56.1569 52.6105 60.944 54.7318 64.7562 58.5153C68.0374 61.7726 70.5976 66.2581 70.8765 73.2776C70.9846 75.9581 70.7599 79.0109 70.1153 82.5039C69.9371 83.4659 69.8009 84.392 69.7014 85.2856C69.5365 86.7402 69.4764 88.2048 69.5213 89.668C69.554 90.6641 69.6488 91.6572 69.8052 92.6415C70.0225 94.03 70.3876 95.3913 70.8942 96.7022C71.3143 97.7832 71.8408 98.8197 72.4661 99.7965C74.5062 102.984 77.3985 105.222 80.4928 106.878C81.6833 107.511 82.9084 108.078 84.1622 108.575C85.3452 109.048 86.5252 109.46 87.6631 109.831Z"
          fill="#B7C0CE"
        />
      </g>
      <path
        d="M65.4627 78.4095C65.4627 78.4095 66.3374 77.3632 67.9795 77.3137C69.6216 77.2643 71.8595 76.2894 72.8203 76.204C73.0517 76.1832 73.1671 76.1167 73.1866 76.0318C73.2269 75.8578 72.1604 75.6045 71.2143 75.5721C69.5368 75.5166 68.5576 76.1606 68.3317 75.8291C68.0784 75.4592 69.1619 74.4495 68.9636 74.2237C68.8097 74.0485 68.0735 74.6113 67.2854 74.9562C66.7257 75.2004 64.5385 77.8161 64.5385 77.8161L65.4627 78.4095Z"
        fill="url(#paint6_linear_3159_3496)"
      />
      <path
        d="M40.4445 76.6623C40.3609 75.4738 40.1051 71.849 42.0176 70.7649C43.9875 69.6477 47.1295 71.8679 48.4083 72.7714C53.8297 76.6025 53.9634 81.251 56.9545 81.7589C58.2975 81.9866 60.4341 81.4165 63.6511 77.2038L65.4653 78.4094C65.3652 83.1336 62.9222 87.3738 59.0508 89.2454C52.4904 92.4197 45.9507 86.7957 45.1651 86.0986C44.7316 85.714 40.8315 82.149 40.4445 76.6623Z"
        fill="url(#paint7_linear_3159_3496)"
      />
      <path
        d="M38.5152 160.265C38.21 160.513 38.1544 161.131 38.1178 161.535C38.1013 161.715 38.0934 161.806 38.1135 161.87C38.2606 162.335 39.1629 162.384 40.5773 162.529C42.8133 162.757 43.4787 162.933 43.6533 162.6C43.8175 162.288 43.4207 161.778 43.3762 161.722C43.1357 161.416 42.8408 161.309 42.2707 161.121C41.2158 160.774 40.5004 160.584 40.2873 160.526C38.9401 160.167 38.7124 160.103 38.5152 160.265Z"
        fill="url(#paint8_linear_3159_3496)"
      />
      <path
        d="M39.8448 158.223L40.0688 160.77C40.0077 160.892 39.2483 161.263 38.6465 160.946L38.0714 158.443L39.8448 158.223Z"
        fill="url(#paint9_linear_3159_3496)"
      />
      <path
        d="M58.0576 159.139C57.6913 159.542 57.6095 160.284 57.9269 160.65C58.2444 161.015 58.7083 160.74 60.0391 160.78C61.4645 160.822 61.8368 161.159 62.8056 160.891C63.1969 160.783 63.8879 160.521 63.8751 160.241C63.8659 160.045 63.5137 159.943 62.3386 159.55C60.6703 158.991 60.6776 158.961 60.3242 158.901C59.4335 158.754 58.5398 158.606 58.0576 159.139Z"
        fill="url(#paint10_linear_3159_3496)"
      />
      <path
        d="M58.9716 156.814L59.6864 159.194C59.6321 159.322 58.9056 159.748 58.2824 159.478L57.2202 157.17L58.9716 156.814Z"
        fill="url(#paint11_linear_3159_3496)"
      />
      <path
        d="M52.619 104.725C52.8472 122.537 55.2106 140.257 59.6586 157.506L56.922 158.242C54.6078 156.084 51.688 152.687 50.2797 147.887C49.405 144.907 49.6968 143.376 49.1419 139.711C48.2592 133.876 46.47 130.819 44.6246 126.113C42.963 121.885 40.9998 115.68 39.9468 107.385L52.619 104.725Z"
        fill="url(#paint12_linear_3159_3496)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M52.619 104.725C50.1271 106.624 47.0304 109.619 47.1286 113.228C47.2013 115.906 48.9642 116.399 50.2736 120.306C51.8797 125.095 49.9233 126.426 51.0605 132.494C51.9066 137.012 52.9462 136.035 54.2055 141.537C55.3275 146.436 54.8294 148.631 56.5649 152.546C57.3616 154.335 58.4028 156.004 59.6586 157.506L56.922 158.242C54.6078 156.084 51.688 152.687 50.2797 147.887C49.405 144.907 49.6968 143.376 49.1419 139.711C48.2592 133.876 46.47 130.819 44.6246 126.113C42.963 121.885 40.9992 115.68 39.9468 107.385L52.619 104.725Z"
        fill="url(#paint13_linear_3159_3496)"
      />
      <path
        d="M42.5683 104.76C42.0108 114.926 41.5562 125.279 41.2046 135.819C40.9441 143.726 40.7512 151.533 40.6259 159.239L37.8301 159.584C36.5499 157.207 35.5703 154.679 34.914 152.06C33.919 148.071 34.1491 145.701 33.7126 141.567C33.2243 136.927 32.5119 135.918 31.2513 130.068C28.6942 118.195 27.4153 112.259 30.2673 108.565C31.1927 107.368 34.0954 104.36 42.5683 104.76Z"
        fill="url(#paint14_linear_3159_3496)"
      />
      <path
        d="M46.4553 76.8769C47.5016 76.1987 46.5481 74.2263 46.4919 69.9892C46.4229 64.7968 47.8019 63.8292 46.6946 61.7922C45.34 59.301 41.9331 58.2699 39.6745 58.2217C39.1764 58.2107 39.0598 57.9385 34.8862 59.0196C27.3668 60.9669 25.4854 58.8798 22.1163 60.965C18.8633 62.9795 20.285 65.1863 17.1284 67.5493C12.0074 71.3792 6.62207 67.2386 2.96246 70.4422C-0.499358 73.4724 -0.578105 81.1933 3.06197 85.4066C7.18978 90.1827 14.6335 88.7481 19.6227 87.8007C20.672 87.6017 25.9713 86.4883 31.494 82.4136C33.956 80.5943 36.1404 78.4267 37.9787 75.9789C38.2918 75.6267 38.7802 75.2 39.4254 75.131C40.1995 75.0486 40.8215 75.5223 41.1213 75.7298C42.6919 76.8195 45.4273 77.5428 46.4553 76.8769Z"
        fill="url(#paint15_linear_3159_3496)"
      />
      <path
        d="M42.6571 71.2656C42.6571 71.2656 33.9216 68.7182 30.0746 73.0731C29.4758 73.7513 28.869 74.7494 28.4039 76.9537C26.7715 84.6862 31.1167 87.4399 30.5233 94.4563C30.4549 95.2603 29.6968 102.975 24.0935 107.717C21.4295 109.972 18.3932 110.941 18.4683 112.941C18.522 114.38 20.1629 115.692 21.6829 116.156C24.5904 117.043 26.2868 114.592 30.121 113.745C34.1286 112.858 35.0571 114.92 41.3727 115.753C43.4751 116.031 53.0694 117.299 55.4367 113.343C56.4446 111.659 55.2023 110.601 53.4277 103.698C53.2483 103 50.1765 90.8694 51.1081 85.5829C51.1886 85.1251 51.4597 83.2669 51.3797 80.8032C51.3535 80.0952 51.2625 79.391 51.1081 78.6996C51.1081 78.6996 50.3658 75.2561 47.6896 72.2991C47.3007 71.8754 46.8423 71.4329 42.6571 71.2656Z"
        fill="url(#paint16_linear_3159_3496)"
      />
      <g style={blendModeMultiply} opacity="0.3">
        <path
          style={blendModeMultiply}
          opacity="0.3"
          d="M30.0746 73.073C29.1944 73.0633 28.5076 76.4622 28.4039 76.9536C26.7715 84.6861 31.1167 87.4398 30.5233 94.4562C30.4549 95.2602 29.6968 102.975 24.0935 107.717C21.4295 109.972 18.3932 110.941 18.4683 112.94C18.522 114.38 20.1629 115.692 21.6829 116.156C24.5904 117.043 26.2868 114.592 30.1216 113.744C34.1286 112.858 35.0571 114.92 41.3727 115.753C43.4751 116.031 53.0694 117.298 55.4367 113.343C55.6809 112.934 55.7737 112.755 55.7499 112.581C55.4855 110.659 46.6024 112.407 44.3761 108.903C42.2011 105.48 47.8367 99.3367 45.9486 97.5011C44.4744 96.0672 40.7147 99.5015 37.6924 97.8942C35.7963 96.8864 35.4838 94.5832 34.5467 89.6374C32.9016 80.9526 31.4115 73.0877 30.0746 73.073Z"
          fill="url(#paint17_linear_3159_3496)"
        />
      </g>
      <path
        d="M39.8515 65.6001C39.97 66.1495 40.5725 69.1181 39.3589 69.8811C39.0958 70.0466 38.5904 70.1412 37.5795 70.331C36.9177 70.4531 36.3629 70.5313 35.9709 70.5752C36.0277 71.0727 36.195 71.8822 36.7688 72.6184C38.3779 74.6823 41.6035 74.1487 42.0638 74.0645C43.6863 73.769 45.2826 73.2709 45.6654 72.9614C46.2526 72.495 46.7141 72.1447 46.987 71.7894C46.3595 71.4842 45.0946 70.6857 44.24 70.4238C43.6573 70.2443 43.0645 70.099 42.4648 69.9886C42.3757 69.4152 42.2864 68.8422 42.1969 68.2696L39.8515 65.6001Z"
        fill="url(#paint18_linear_3159_3496)"
      />
      <path
        d="M39.795 67.078C39.795 67.078 40.0148 69.2511 42.1269 69.6345C44.0193 69.977 45.4428 65.406 44.4747 64.2235C44.1518 63.8292 43.3545 63.4538 42.6635 63.4739C42.1751 63.488 40.5355 64.1131 40.3066 65.7558C39.9794 65.4115 39.574 65.3126 39.3714 65.4566C39.1162 65.6398 39.2529 66.1715 39.2841 66.2923C39.3681 66.6012 39.5468 66.8759 39.795 67.078V67.078Z"
        fill="url(#paint19_linear_3159_3496)"
      />
      <path
        d="M43.1584 102.355L49.0486 87.7652C49.1426 87.5317 49.1876 87.2813 49.1805 87.0297C49.1735 86.778 49.1147 86.5306 49.0077 86.3027C48.9008 86.0748 48.748 85.8714 48.5589 85.7053C48.3699 85.5391 48.1486 85.4136 47.9089 85.3368L36.2494 81.6131C35.9228 81.5389 35.5804 81.5844 35.2845 81.7412C34.9885 81.898 34.7586 82.1558 34.6366 82.4677L28.6152 97.8478C28.5444 98.0288 28.5121 98.2226 28.5203 98.4168C28.5285 98.611 28.5771 98.8013 28.663 98.9757C28.7488 99.15 28.8701 99.3046 29.019 99.4295C29.1679 99.5544 29.3412 99.6469 29.5278 99.7011L41.4742 103.168C41.8058 103.264 42.1612 103.234 42.4721 103.084C42.783 102.934 43.0274 102.674 43.1584 102.355V102.355Z"
        fill="#26264F"
      />
      <path
        d="M42.4102 93.9626C42.4102 93.9626 43.5895 93.1763 43.5895 92.3901C43.5895 91.6038 44.3758 90.4238 44.7689 90.4238C45.162 90.4238 45.162 90.817 44.7689 91.6038C44.3758 92.3907 46.342 91.9969 47.1283 91.2101C47.9145 90.4232 48.7008 90.4238 48.3077 90.8169C47.6862 91.439 47.5214 91.9969 47.5214 91.9969C47.5214 91.9969 46.7352 95.1419 43.2947 94.9454L42.4102 93.9626Z"
        fill="url(#paint20_linear_3159_3496)"
      />
      <path
        d="M34.1535 71.5519C31.6384 70.4299 27.43 75.0558 26.6829 75.8769C22.7192 80.2342 17.0989 90.1106 21.5716 95.9293C25.3057 100.783 35.3304 101.851 43.5897 95.5356L42.4103 93.1768C41.5557 93.4875 35.9134 95.4397 31.7941 92.3906C30.9205 91.7435 30.5323 90.6319 30.3901 89.1278C30.1264 86.3258 33.4289 85.5799 35.3347 81.3812C37.0183 77.6649 36.3224 72.5188 34.1535 71.5519Z"
        fill="url(#paint21_linear_3159_3496)"
      />
      <path
        d="M95.9795 162.355C95.9795 162.355 97.7199 162.854 99.0476 163.408C100.375 163.961 100.325 163.11 100.123 162.094C99.9218 161.078 98.0898 161.67 98.0898 161.67C98.0898 161.67 95.1432 161.415 95.9795 162.355Z"
        fill="url(#paint22_linear_3159_3496)"
      />
      <path
        d="M102.94 133.855C102.879 136.185 102.606 138.504 102.126 140.784C101.695 142.823 101.444 143.175 101.309 144.585C101.017 147.652 102.005 148.091 102.187 151.558C102.387 155.387 101.369 158.43 100.966 159.591C100.683 160.411 100.352 161.214 99.974 161.995C99.739 162.2 99.4197 162.214 99.0644 162.167C98.911 162.145 98.7699 162.071 98.665 161.957C98.5601 161.843 98.4978 161.696 98.4888 161.542C98.2861 158.264 98.0419 155.151 97.8436 151.854C97.1717 146.63 96.5002 141.406 95.8291 136.183L102.94 133.855Z"
        fill="url(#paint23_linear_3159_3496)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M98.4912 161.512C98.2886 158.245 98.0456 155.141 97.8478 151.854C97.1759 146.63 96.5045 141.406 95.8334 136.183L102.943 133.855C102.938 134.038 102.929 134.208 102.922 134.384C99.5974 137.272 98.9466 139.559 98.979 141.119C99.0181 143.012 100.053 143.949 99.8507 146.693C99.706 148.655 99.1182 149 99.1182 150.77C99.1182 153.05 100.095 154.331 100.573 155.648C101.034 156.921 101.197 158.724 100.308 161.289C100.191 161.554 100.081 161.791 99.9819 161.995C99.7536 162.194 99.446 162.213 99.1023 162.171C98.9396 162.149 98.7896 162.072 98.6781 161.951C98.5665 161.831 98.5005 161.676 98.4912 161.512Z"
        fill="url(#paint24_linear_3159_3496)"
      />
      <path
        d="M91.0711 163.37C91.0711 163.37 92.8115 163.869 94.1398 164.423C95.4681 164.977 95.4169 164.124 95.2154 163.109C95.014 162.094 93.1814 162.685 93.1814 162.685C93.1814 162.685 90.2342 162.429 91.0711 163.37Z"
        fill="url(#paint25_linear_3159_3496)"
      />
      <path
        d="M98.0313 134.87C97.971 137.2 97.6988 139.519 97.2182 141.799C96.7866 143.838 96.5358 144.19 96.4008 145.599C96.1084 148.667 97.0974 149.106 97.2787 152.574C97.4789 156.402 96.4613 159.445 96.0578 160.606C95.7751 161.426 95.4441 162.228 95.0664 163.009C94.8308 163.214 94.5115 163.229 94.1562 163.182C94.0029 163.16 93.8619 163.086 93.757 162.972C93.6521 162.858 93.5897 162.711 93.5806 162.557C93.3779 159.279 93.1338 156.166 92.936 152.869C92.2633 147.645 91.5918 142.421 90.9215 137.197L98.0313 134.87Z"
        fill="url(#paint26_linear_3159_3496)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M93.5819 162.526C93.3799 159.259 93.1363 156.156 92.9391 152.869C92.2664 147.645 91.5949 142.421 90.9247 137.197L98.0339 134.87C98.029 135.05 98.0199 135.223 98.0125 135.399C94.6881 138.286 94.0373 140.573 94.0697 142.134C94.1094 144.026 95.1441 144.964 94.9414 147.708C94.7973 149.669 94.2089 150.015 94.2089 151.785C94.2089 154.064 95.1856 155.346 95.6642 156.663C96.1244 157.936 96.2886 159.739 95.3986 162.304C95.282 162.568 95.1715 162.806 95.0733 163.009C94.8443 163.209 94.5367 163.228 94.1936 163.186C94.0308 163.164 93.8806 163.087 93.7689 162.966C93.6572 162.846 93.5911 162.69 93.5819 162.526V162.526Z"
        fill="url(#paint27_linear_3159_3496)"
      />
      <path
        d="M93.9672 112.623C91.1799 113.09 89.0604 113.593 88.1405 115.177C87.1693 116.849 88.7906 118.159 89.2967 121.885C90.072 127.591 87.1241 129.654 88.0947 135.402C88.2384 136.248 88.4492 137.081 88.7253 137.894C89.1386 139.126 89.933 140.195 90.9937 140.946C91.8745 141.566 92.8234 142.083 93.8219 142.487C96.68 143.633 98.0669 143.031 101.888 143.35C108.138 143.874 109.111 145.875 111.908 144.716C114.734 143.545 116.731 140.26 116.101 138.824C115.723 137.964 114.591 138.472 112.932 137.392C110.91 136.074 111.061 134.562 109.371 132.552C106.913 129.629 104.848 130.995 102.74 128.412C101.261 126.601 101.07 124.449 100.736 120.696C100.381 116.702 100.969 113.771 99.3745 112.896C98.9911 112.686 98.5315 112.574 97.9827 112.591C95.8205 112.659 94.1961 112.585 93.9672 112.623Z"
        fill="url(#paint28_linear_3159_3496)"
      />
      <path
        d="M94.1369 110.738L92.9282 113.488C92.9282 113.488 94.7138 114.384 96.3467 113.488V110.738H94.1369Z"
        fill="url(#paint29_linear_3159_3496)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M111.538 142.552C110.662 141.904 111.095 140.126 109.797 139.15C108.592 138.244 107.687 139.376 105.925 138.751C103.666 137.95 103.736 135.585 101.569 134.783C100.011 134.208 99.5651 135.279 97.3339 135.278C95.1027 135.278 92.1653 134.21 90.265 131.584C89.5386 130.566 89.02 129.416 88.7388 128.197C88.184 130.292 87.579 132.316 88.0973 135.4C88.2811 136.481 88.5742 137.54 88.972 138.562C89.268 139.335 89.769 140.013 90.4212 140.523C91.4602 141.33 92.6054 141.991 93.8244 142.487C96.6825 143.633 98.0695 143.031 101.891 143.35C108.141 143.873 109.114 145.875 111.911 144.716C113.717 143.967 115.181 142.356 115.841 140.915C113.409 142.83 112.175 143.024 111.538 142.552Z"
        fill="url(#paint30_linear_3159_3496)"
      />
      <path
        d="M93.8917 105.287C93.8917 105.287 92.4511 107.991 92.3601 109.888C92.2692 111.786 94.4106 111.648 94.4106 111.648L97.0703 111.554C97.0703 111.554 98.0269 105.639 93.8917 105.287Z"
        fill="url(#paint31_linear_3159_3496)"
      />
      <path
        d="M94.6002 110.042C94.1509 111.565 94.8761 113.04 95.4969 114.303C96.705 116.76 99.8561 119.809 103.009 119.46C106.528 119.069 106.936 114.788 110.184 114.637C112.909 114.511 113.472 117.485 117.021 118.225C121.52 119.162 125.616 115.429 126.102 114.973C129.208 112.063 131.221 107.038 129.578 102.642C129.41 102.194 127.759 97.9359 124.645 97.4854C122.015 97.1057 121.038 99.8307 116.909 100.176C114.211 100.402 113.735 99.3137 111.864 99.9522C108.886 100.967 109.217 104.019 106.819 104.661C104.333 105.327 103.232 102.247 98.6346 101.858C97.9998 101.804 94.2932 101.492 93.253 103.427C92.8342 104.207 92.7829 105.471 93.3049 106.389C93.8445 107.339 94.718 107.427 94.9347 108.36C95.0867 109.008 94.7779 109.44 94.6002 110.042Z"
        fill="url(#paint32_linear_3159_3496)"
      />
      <path
        d="M79.7957 108.148C81.4116 113.163 82.7747 113.163 84.2971 113.485C85.5229 113.745 86.8469 113.33 90.8643 113.541C91.5864 113.579 92.1834 113.626 92.5906 113.663C92.402 116.441 93.6662 117.992 93.4782 120.77C93.4782 120.77 76.5823 122.313 78.2354 108.97L79.7957 108.148Z"
        fill="url(#paint33_linear_3159_3496)"
      />
      <path
        d="M79.5436 108.39C79.5436 108.39 80.107 107.691 80.0319 106.904C79.9569 106.117 78.2317 105.883 77.8185 106.213C77.4052 106.543 78.5815 108.894 78.5815 108.894L79.5436 108.39Z"
        fill="url(#paint34_linear_3159_3496)"
      />
      <path
        d="M120.37 40.6598C120.37 49.8201 110.617 57.2449 98.5881 57.2449C94.0794 57.2449 89.8899 56.2017 86.4159 54.4155C84.1182 54.8062 80.5513 55.4661 78.67 55.8153C78.5439 55.8387 78.4139 55.8283 78.2932 55.785C78.1726 55.7417 78.0656 55.6671 77.9832 55.5688C77.9009 55.4705 77.8461 55.3522 77.8245 55.2258C77.803 55.0994 77.8154 54.9696 77.8605 54.8496L79.961 49.2628C79.5343 48.7311 79.1435 48.1715 78.7914 47.5877C77.5156 45.4787 76.8063 43.1315 76.8063 40.6598C76.8063 31.5032 86.5569 24.0765 98.5881 24.0765C101.36 24.0687 104.117 24.4767 106.768 25.287C114.744 27.7477 120.37 33.7044 120.37 40.6598Z"
        fill="url(#paint35_linear_3159_3496)"
      />
      <path
        d="M87.2089 38.1606V40.6921H84.8892V38.1606H83.6226V44.2785H84.888V41.747H87.2077V44.2785H88.6844V38.1606H87.2089Z"
        fill="white"
      />
      <path
        d="M94.3845 39.4285V38.1606H91.0088V44.2785H94.3845V43.2273H92.2748V41.7507H94.3845V40.694H92.2748V39.4285H94.3845Z"
        fill="white"
      />
      <path d="M97.9684 43.2273V38.1606H96.7048V44.2785H100.291V43.2273H97.9684Z" fill="white" />
      <path d="M103.664 43.2273V38.1606H102.188V44.2785H106.196V43.2273H103.664Z" fill="white" />
      <path
        d="M112.769 38.9915C112.274 38.453 111.56 38.1838 110.625 38.1838C109.69 38.1838 108.972 38.4508 108.471 38.9847C107.971 39.5183 107.721 40.2915 107.72 41.3044C107.719 42.3174 107.968 43.094 108.468 43.6345C108.968 44.1745 109.684 44.4449 110.617 44.4458C111.549 44.4466 112.265 44.1762 112.762 43.6345C113.262 43.0916 113.512 42.3184 113.513 41.3148C113.513 40.3019 113.265 39.5274 112.769 38.9915ZM110.617 43.3634C110.103 43.3634 109.719 43.1919 109.46 42.847C109.202 42.5021 109.072 41.9924 109.072 41.313C109.072 40.6335 109.202 40.1195 109.465 39.7746C109.727 39.4297 110.111 39.2558 110.624 39.2558C111.646 39.2558 112.158 39.9415 112.159 41.313C112.159 42.6844 111.645 43.3679 110.617 43.3634V43.3634Z"
        fill="white"
      />
      <g style={blendModeMultiply} opacity="0.3">
        <path
          style={blendModeMultiply}
          opacity="0.3"
          d="M120.369 40.6599C120.369 49.8202 110.616 57.245 98.5873 57.245C94.0786 57.245 89.8891 56.2018 86.4151 54.4156C84.1174 54.8063 80.5506 55.4662 78.6692 55.8154C78.5432 55.8388 78.4131 55.8284 78.2925 55.785C78.1718 55.7417 78.0648 55.6671 77.9825 55.5689C77.9001 55.4706 77.8453 55.3522 77.8238 55.2259C77.8022 55.0995 77.8146 54.9697 77.8597 54.8496L79.9603 49.2629C79.5335 48.7312 79.1428 48.1716 78.7907 47.5878C79.9621 46.0257 81.2324 45.1058 82.4881 44.5783C82.8689 44.4189 83.2613 44.2891 83.662 44.1901C84.0863 44.0861 84.5176 44.0134 84.9525 43.9728C85.7545 43.9054 86.5611 43.915 87.3613 44.0015C87.7886 44.0448 88.2202 44.1052 88.6475 44.1724C91.7547 44.6693 94.708 45.6417 97.0106 44.3616C97.5042 44.0862 97.9451 43.7253 98.3126 43.2958C101.376 39.7357 97.7223 33.2809 101.237 28.6268C102.531 26.9146 104.473 25.8689 106.764 25.2871C114.743 27.7478 120.369 33.7045 120.369 40.6599Z"
          fill="url(#paint36_linear_3159_3496)"
        />
      </g>
      <path
        d="M65.023 51.4865C65.0701 51.6118 65.083 51.7472 65.0606 51.8792C65.0381 52.0111 64.981 52.1346 64.8952 52.2373C64.8093 52.3399 64.6978 52.4179 64.5719 52.4632C64.446 52.5086 64.3104 52.5198 64.1788 52.4956C62.2156 52.1293 58.4943 51.4432 56.0965 51.0354C52.4711 52.8991 48.0997 53.9863 43.395 53.9863C30.8437 53.9863 20.6676 46.2379 20.6676 36.6826C20.6676 31.0934 24.1471 26.125 29.5459 22.9611C33.3801 20.7135 38.1836 19.3772 43.395 19.3772C55.9494 19.3772 66.1249 27.1249 66.1249 36.6826C66.1249 39.9699 64.9211 43.0422 62.8315 45.6562L65.023 51.4865Z"
        fill="url(#paint37_linear_3159_3496)"
      />
      <path
        d="M32.5714 33.3105V35.8408H30.0399V33.3105H28.5632V40.2696H30.0399V37.1051H32.5714V40.2696H34.0481V33.3105H32.5714Z"
        fill="white"
      />
      <path
        d="M42.5348 33.9816C41.9598 33.3565 41.1288 33.0439 40.0418 33.0439C39.0504 33.0439 38.2648 33.3046 37.694 33.8253C37.6402 33.8725 37.5888 33.9224 37.5402 33.9749C36.959 34.5959 36.6683 35.4943 36.6678 36.67C36.6674 37.8457 36.9576 38.7481 37.5383 39.3773C37.987 39.8656 38.5883 40.1605 39.3367 40.2716C39.5674 40.3044 39.8002 40.3203 40.0332 40.3192C41.1161 40.3192 41.948 40.0052 42.5287 39.3773C43.1094 38.7494 43.3994 37.8504 43.3986 36.6804C43.3986 35.5067 43.1107 34.6071 42.5348 33.9816ZM40.032 39.0629C39.4354 39.0629 38.9877 38.8633 38.689 38.4641C38.3903 38.0648 38.2399 37.4703 38.2379 36.6804C38.2379 35.8904 38.3897 35.2946 38.6933 34.893C38.9949 34.4913 39.446 34.2917 40.0405 34.2917C41.2321 34.2917 41.8275 35.0879 41.8267 36.6804C41.8259 38.2728 41.2281 39.067 40.0332 39.0629H40.032Z"
        fill="white"
      />
      <path d="M47.3381 39.006V33.3105H45.8615V40.2696H50.2914V39.0035L47.3381 39.006Z" fill="white" />
      <path
        d="M55.8747 33.0994H54.0433L51.5631 40.189L51.5479 40.2721H53.1643L53.2492 39.8881L53.6765 38.3736H56.2519L56.7665 40.2721H58.3805L55.8747 33.0994ZM54.0525 37.3187C54.5537 35.6309 54.8595 34.6542 54.9682 34.2415C54.9944 34.3569 55.0353 34.5241 55.0964 34.7238C55.1574 34.9234 55.4217 35.8421 55.8948 37.3187H54.0525Z"
        fill="white"
      />
      <g style={blendModeMultiply} opacity="0.3">
        <path
          style={blendModeMultiply}
          opacity="0.3"
          d="M64.9612 52.1465C64.8824 52.2776 64.7652 52.3814 64.6254 52.4439C64.4857 52.5063 64.3302 52.5244 64.1798 52.4956C62.2167 52.1294 58.4954 51.4432 56.0976 51.0355C52.4722 52.8991 48.1008 53.9863 43.3961 53.9863C30.8448 53.9863 20.6687 46.238 20.6687 36.6827C20.6687 31.0935 24.1482 26.1251 29.547 22.9612C31.6365 23.3323 33.485 24.1741 34.8865 25.6868C36.9871 27.954 37.3796 30.961 37.6946 33.8252C37.9619 36.2554 38.1768 38.583 39.3373 40.2715C39.6635 40.7499 40.0684 41.1695 40.535 41.5125C43.727 43.8579 47.4586 41.0504 51.5614 40.1781C52.1162 40.0557 52.6801 39.9788 53.2474 39.948C54.1432 39.8944 55.0416 39.9957 55.9029 40.2477C58.825 41.1109 62.1361 44.0312 64.9612 52.1465Z"
          fill="url(#paint38_linear_3159_3496)"
        />
      </g>
      <path
        d="M117.953 89.9818C114.384 94.7433 107.66 98.155 99.905 98.5457C88.3603 99.125 78.8722 92.774 78.5615 84.3664C78.4534 81.4765 79.4405 78.7039 81.2474 76.278L79.0755 71.2724C78.8698 70.8011 79.2794 70.2743 79.805 70.3329C81.5948 70.5325 84.9937 70.9073 87.1913 71.1204C87.2646 71.077 87.3384 71.0355 87.4148 70.9946C90.6257 69.1871 94.5227 67.9912 98.769 67.6982C110.475 66.8827 120.349 73.2093 120.67 81.8428C120.776 84.7613 119.776 87.5498 117.953 89.9818Z"
        fill="url(#paint39_linear_3159_3496)"
      />
      <path
        d="M93.4647 82.4856C93.2633 83.4336 92.944 84.4317 92.5491 85.2412C92.3862 85.5891 92.1877 85.9193 91.9569 86.2264C92.3451 86.4329 92.7178 86.6671 93.0722 86.9272C93.1479 86.8228 93.2218 86.7123 93.2932 86.5969C93.9036 85.6361 94.417 84.284 94.7338 83.043C94.8901 83.043 95.0073 82.9819 95.0311 82.8891L93.4647 82.4856ZM98.3812 82.3794L99.2603 82.5332C99.4971 81.5961 99.6716 80.6444 99.7828 79.6843L99.0106 79.7215H94.6038C94.7821 79.0885 94.9383 78.6514 95.0684 78.1399C95.1346 78.1487 95.2018 78.1355 95.2598 78.1024C95.3178 78.0694 95.3633 78.0182 95.3895 77.9567L93.7254 77.5038C93.3457 79.2588 92.6492 81.0499 91.8049 82.109C91.7811 82.1389 91.7549 82.1657 91.7311 82.1938C91.7616 82.2134 91.7921 82.2335 91.8269 82.2591C92.1774 82.5225 92.5066 82.8129 92.8116 83.1278C93.2871 82.5351 93.7144 81.6218 94.0935 80.9913H95.2246V87.0505C95.2246 87.2043 95.2472 87.2526 95.0928 87.2526C94.9261 87.2526 94.4512 87.2526 93.9519 87.2416C93.9824 87.2977 94.03 87.3588 94.0605 87.4247C94.2407 87.8259 94.376 88.2458 94.464 88.6767C95.2497 88.6767 95.8424 88.6029 96.1855 88.4533C96.7495 88.2055 96.9125 87.7525 96.9125 87.0731V80.99H98.2378C98.1658 81.4118 98.1072 81.9887 98.0486 82.3574L98.333 82.3861L97.0987 82.8183C97.7384 84.0764 98.3196 85.771 98.4868 86.8759L99.8543 86.3747C99.64 85.2668 99.0582 83.6387 98.3812 82.3794V82.3794ZM91.1847 77.4495C91.1847 77.4562 91.1798 77.4629 91.178 77.469C90.5596 79.5653 89.5182 81.7543 88.4634 82.9844C88.7775 83.3872 89.0688 83.8075 89.3357 84.2431C89.6092 83.911 89.951 83.5313 90.1622 83.1278V88.5833H91.6377V81.1359C91.6655 81.1224 91.6948 81.1121 91.725 81.1054C91.7682 81.0928 91.8066 81.0674 91.8352 81.0325C91.8637 80.9976 91.8811 80.955 91.8849 80.9101L91.6597 80.8606L91.4155 80.8039C91.4765 80.6696 91.5376 80.5328 91.5986 80.3961C91.9307 79.6519 92.2329 78.8816 92.4935 78.1197C92.6712 78.1307 92.7896 78.0721 92.8244 77.9659L91.1847 77.4495Z"
        fill="white"
      />
      <path
        d="M110.204 82.6748V81.5351C111.048 80.7519 111.899 79.6477 112.459 78.8162C112.576 78.7924 112.7 78.732 112.748 78.6496L111.646 77.9781L111.409 78.0391H106.617V79.5158H110.379C110.036 79.9376 109.601 80.5175 109.197 80.932L108.73 80.8399V82.6803H106.198V83.9458H108.73V87.0755C108.73 87.2904 108.664 87.2904 108.26 87.2904C107.974 87.2904 107.528 87.2776 107.086 87.2666C107.277 87.6573 107.513 88.2757 107.583 88.6706C109.805 88.6926 110.207 88.2647 110.207 87.0908V83.9482H112.95V82.6828L110.204 82.6748ZM106.21 85.9486C105.972 85.7142 105.696 85.4713 105.401 85.2302C105.948 83.9 106.256 82.239 106.397 80.2184L106.469 79.9797L105.709 79.8485L105.473 79.9321H104.225C104.369 79.0885 104.499 78.4835 104.616 77.8652C104.82 77.8523 104.913 77.7596 104.926 77.6509L103.309 77.5215C103.192 78.254 103.049 79.0885 102.895 79.9321H101.552V81.197H102.643C102.357 82.4624 102.062 83.9073 101.789 84.892L102.952 85.489L103.048 85.102C103.26 85.2692 103.488 85.4206 103.701 85.5983C103.19 86.4773 102.537 87.1421 101.741 87.5694C101.757 87.5822 101.774 87.5968 101.789 87.6121C102.078 87.8831 102.416 88.3599 102.596 88.6962C103.486 88.1485 104.232 87.3948 104.77 86.4987C105.162 86.8481 105.523 87.2297 105.851 87.6396L106.705 86.4877C106.554 86.296 106.388 86.1158 106.21 85.9486V85.9486ZM103.371 83.8023C103.559 82.9709 103.761 82.0418 103.952 81.1982H104.985C104.844 82.253 104.605 83.4916 104.273 84.4073C103.963 84.1924 103.654 83.98 103.371 83.8023Z"
        fill="white"
      />
      <g style={blendModeMultiply} opacity="0.2">
        <path
          style={blendModeMultiply}
          opacity="0.2"
          d="M117.953 89.9818C114.384 94.7432 107.66 98.155 99.905 98.5457C88.3603 99.125 78.8722 92.774 78.5615 84.3663C78.4534 81.4765 79.4405 78.7039 81.2474 76.278L79.0755 71.2723C78.8698 70.8011 79.2794 70.2743 79.805 70.3329C81.5948 70.5325 84.9937 70.9073 87.1913 71.1203C87.2646 71.077 87.3384 71.0355 87.4148 70.9946C87.9344 71.3541 88.4112 71.7719 88.8359 72.2399C90.1941 73.7452 90.8186 75.5766 91.1812 77.4665C91.368 78.4389 91.4864 79.4236 91.5975 80.3893C91.6152 80.5456 91.6323 80.6994 91.6518 80.8538C91.6604 80.9362 91.6714 81.0187 91.6799 81.098C91.719 81.4368 91.7623 81.7695 91.8081 82.0961C91.8148 82.148 91.8234 82.2005 91.8301 82.2524C91.9857 83.3286 92.1921 84.3437 92.5522 85.238C92.7412 85.72 92.9913 86.1756 93.2964 86.5938C93.5232 86.901 93.7864 87.1795 94.0802 87.4234C94.3476 87.6475 94.6387 87.8418 94.9482 88.0027C95.3205 88.1995 95.7153 88.3503 96.1239 88.452C97.9077 88.8946 99.8037 88.3629 101.791 87.6145C103.221 87.0785 104.696 86.4296 106.211 85.9504C107.05 85.6759 107.912 85.4761 108.786 85.3534C109.262 85.2888 109.742 85.2598 110.223 85.2667C110.999 85.2736 111.77 85.3882 112.514 85.6074C114.781 86.2782 116.558 87.8666 117.953 89.9818Z"
          fill="url(#paint40_linear_3159_3496)"
        />
      </g>
      <path
        d="M131.105 143.208C131.105 143.208 131.766 143.516 131.738 143.873C131.709 144.23 131.677 144.605 131.127 145.442C130.578 146.278 130.493 146.677 130.14 146.638C129.787 146.598 129.857 143.962 131.105 143.208Z"
        fill="url(#paint41_linear_3159_3496)"
      />
      <path
        d="M130.676 142.223C130.697 142.289 131.12 143 131.362 143.404C131.41 143.484 131.43 143.577 131.42 143.67C131.41 143.763 131.371 143.85 131.307 143.919C131.215 144.018 131.088 144.078 130.953 144.086C130.819 144.095 130.685 144.051 130.582 143.964C130.219 143.659 129.689 143.204 129.397 142.875L130.676 142.223Z"
        fill="url(#paint42_linear_3159_3496)"
      />
      <path
        d="M144.373 128.885C144.708 127.556 140.771 125.527 140.248 125.259C139.217 124.729 129.31 119.761 125.17 124.185C121.687 127.909 124.861 135.543 125.483 137.038C126.789 140.174 128.661 142.349 129.985 143.652L131.393 142.4C131.353 140.589 131.239 139.082 131.132 137.981C130.974 136.353 130.832 134.885 130.455 132.988C129.857 129.983 129.379 129.468 129.775 128.993C130.652 127.941 133.406 129.956 138.276 130.173C140.319 130.264 144.068 130.098 144.373 128.885Z"
        fill="url(#paint43_linear_3159_3496)"
      />
      <path
        d="M143.23 152.791C143.256 152.808 143.284 152.823 143.312 152.837C143.495 152.935 143.908 153.172 144 153.692C144.112 154.329 143.543 155.369 142.814 156.591C142.085 157.812 141.949 156.365 141.975 156.14C142 155.914 142.524 152.926 142.524 152.926L143.23 152.791Z"
        fill="url(#paint44_linear_3159_3496)"
      />
      <path
        d="M141.569 152.969L143.067 153.688C143.067 153.688 143.417 153.519 143.17 153.056L142.549 151.893L141.569 152.969Z"
        fill="url(#paint45_linear_3159_3496)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M140.249 125.259C139.296 124.777 138.319 124.343 137.323 123.96C137.045 124.607 136.687 124.963 136.362 125.161C134.869 126.077 133.299 124.397 130.445 124.974C129.712 125.121 127.713 125.523 126.983 126.984C126.11 128.726 128.017 130.046 127.739 133.572C127.594 135.404 127.045 135.498 127.258 136.789C127.595 138.828 129.083 139.314 129.395 141.095C129.477 141.567 129.476 142.169 129.262 142.899C129.514 143.173 129.76 143.43 129.986 143.652L131.393 142.4C131.354 140.589 131.24 139.082 131.133 137.982C130.975 136.353 130.833 134.885 130.456 132.988C129.858 129.983 129.379 129.468 129.776 128.993C130.652 127.941 133.407 129.956 138.277 130.173C140.318 130.264 144.067 130.098 144.372 128.885C144.709 127.556 140.772 125.529 140.249 125.259Z"
        fill="url(#paint46_linear_3159_3496)"
      />
      <path
        d="M145.902 124.926C143.86 125.192 136.745 126.372 132.761 132.396C132.307 133.082 131.432 134.428 131.052 136.142C129.814 141.697 134.34 148.43 141.785 153.472L143.062 151.92C142.923 150.378 142.523 148.144 141.271 145.823C139.585 142.698 137.661 141.84 137.644 139.653C137.623 137.135 140.149 135.218 140.629 134.87C144.191 132.285 147.615 134.414 150.745 132.009C152.366 130.762 153.096 128.923 153.457 127.447L145.902 124.926Z"
        fill="url(#paint47_linear_3159_3496)"
      />
      <path
        d="M143.915 100.566C143.802 99.9735 143.365 99.9558 143.272 99.4064C143.154 98.7178 143.695 97.8943 144.365 97.5415C145.02 97.196 145.316 97.5556 146.746 97.5415C148.28 97.5263 148.519 97.105 149.769 97.2839C150.332 97.3519 150.878 97.5266 151.377 97.7985C151.913 98.0976 152.978 98.691 153.113 99.7922C153.261 100.997 152.187 101.895 152.084 101.979C152.49 103.377 152.317 103.969 152.019 104.23C152.019 104.23 151.704 104.507 150.09 104.488C150.09 104.488 149.634 105.054 149.37 105.243C149.068 105.458 148.481 105.563 147.217 104.963C147.186 104.588 147.131 104.215 147.05 103.848C146.863 102.993 146.769 102.562 146.532 102.481C145.933 102.277 145.133 104.37 144.324 104.216C143.803 104.117 143.398 103.112 143.466 102.301C143.534 101.489 144.043 101.239 143.915 100.566Z"
        fill="url(#paint48_linear_3159_3496)"
      />
      <path
        d="M140.855 117.806C140.928 117.823 141.244 117.928 141.368 119.248C141.67 122.43 140.013 123.718 140.421 125.444C140.812 127.101 142.81 127.904 144.866 128.731C146.239 129.284 150.488 130.994 152.959 129.071C153.997 128.263 154.113 127.286 154.855 123.695C156.74 114.571 157.47 113.787 156.677 111.812C155.316 108.421 151.671 107.016 151.061 106.782C144.594 104.29 136.96 108.87 133.939 114.131C133.542 114.824 131.169 118.962 132.836 120.924C133.845 122.112 136.163 122.303 137.693 121.494C139.741 120.412 140.174 117.65 140.855 117.806Z"
        fill="url(#paint49_linear_3159_3496)"
      />
      <path
        d="M133.787 120.22C133.766 120.22 133.745 120.216 133.726 120.207C133.691 120.19 133.664 120.161 133.65 120.125C133.637 120.089 133.638 120.049 133.654 120.014C133.709 119.892 135.044 117.027 138.129 115.897C138.164 115.886 138.203 115.89 138.236 115.906C138.269 115.923 138.295 115.952 138.307 115.987C138.32 116.021 138.319 116.06 138.305 116.094C138.29 116.128 138.263 116.155 138.229 116.17C135.262 117.258 133.932 120.106 133.919 120.135C133.908 120.16 133.889 120.182 133.866 120.197C133.842 120.212 133.815 120.22 133.787 120.22V120.22Z"
        fill="url(#paint50_linear_3159_3496)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.45"
        d="M152.449 110.646C152.041 111.757 153.266 112.721 152.741 113.926C151.988 115.655 148.998 114.768 147.929 116.478C146.663 118.504 149.564 121.828 148.366 122.966C147.821 123.484 147.191 122.821 145.597 123.184C143.325 123.703 142.644 125.498 141.587 125.226C141.255 125.14 140.854 124.839 140.514 123.91C140.366 124.425 140.295 124.914 140.421 125.444C140.812 127.101 142.81 127.904 144.866 128.731C146.239 129.285 150.488 130.994 152.959 129.071C153.997 128.263 154.113 127.286 154.855 123.695C156.74 114.571 157.47 113.787 156.677 111.812C156.245 110.736 155.582 109.859 154.86 109.157C153.302 109.501 152.663 110.064 152.449 110.646Z"
        fill="url(#paint51_linear_3159_3496)"
      />
      <path
        d="M146.473 105.415C146.498 105.562 146.493 105.713 146.458 105.857C146.424 106.002 146.36 106.139 146.271 106.258C146.183 106.378 146.071 106.479 145.943 106.554C145.814 106.63 145.672 106.678 145.524 106.697C144.769 106.793 143.847 106.939 143.436 107.117C142.681 107.445 145.604 107.294 148.252 107.525C149.454 107.637 150.662 107.65 151.865 107.564C151.907 107.562 151.946 107.546 151.977 107.518C152.008 107.491 152.029 107.453 152.036 107.413C152.043 107.372 152.036 107.33 152.016 107.294C151.996 107.257 151.965 107.229 151.927 107.213C151.29 106.924 150.253 106.489 149.358 106.275C148.252 106.009 147.323 103.398 147.323 103.398L146.263 104.165L146.473 105.415Z"
        fill="url(#paint52_linear_3159_3496)"
      />
      <path
        d="M148.254 104.668C148.254 104.668 146.963 106.133 144.991 105.654C143.226 105.223 143.669 101.172 144.96 100.526C145.39 100.31 146.173 100.326 146.763 100.609C147.11 100.776 147.208 100.95 147.781 101.212C148.311 101.456 148.478 101.419 148.621 101.59C148.799 101.803 148.877 102.261 148.32 103.371C148.722 103.087 149.154 103.026 149.419 103.214C149.509 103.285 149.577 103.38 149.616 103.488C149.786 103.986 149.264 104.496 148.254 104.668Z"
        fill="url(#paint53_linear_3159_3496)"
      />
      <path
        d="M148.54 117.617C148.715 117.775 150.484 119.334 152.612 118.801C154.466 118.338 155.603 116.536 155.802 115.02C156.277 111.414 151.579 108.387 152.158 107.657C152.502 107.223 154.352 108.064 155.584 108.824C156.862 109.612 160.317 111.741 161.053 115.92C161.115 116.275 161.928 121.258 159.084 123.316C156.454 125.218 151.303 124.183 147.814 119.683C148.056 118.994 148.298 118.306 148.54 117.617Z"
        fill="url(#paint54_linear_3159_3496)"
      />
      <path
        d="M146.384 111.365H139.519C139.416 111.364 139.314 111.385 139.22 111.426C139.125 111.467 139.04 111.528 138.971 111.604C138.902 111.681 138.849 111.771 138.817 111.868C138.785 111.966 138.773 112.07 138.784 112.173L139.632 122.981C139.667 123.419 140.049 123.811 140.486 123.811H147.304C147.742 123.811 148.071 123.421 148.036 122.982L147.239 112.19C147.209 111.747 146.825 111.365 146.384 111.365Z"
        fill="url(#paint55_linear_3159_3496)"
      />
      <path
        d="M145.95 111.365H139.086C138.983 111.364 138.881 111.386 138.787 111.427C138.693 111.468 138.608 111.529 138.539 111.605C138.47 111.682 138.418 111.771 138.386 111.869C138.354 111.967 138.342 112.07 138.353 112.173L139.203 122.981C139.238 123.419 139.621 123.811 140.058 123.811H146.875C147.314 123.811 147.642 123.421 147.608 122.982L146.808 112.19C146.775 111.747 146.391 111.365 145.95 111.365Z"
        fill="url(#paint56_linear_3159_3496)"
      />
      <path
        d="M137.665 116.467L137.794 116.257C137.849 116.046 137.979 115.862 138.159 115.739C138.34 115.616 138.559 115.563 138.776 115.59L139.451 115.673C139.501 115.68 139.549 115.697 139.592 115.722C139.635 115.748 139.672 115.783 139.702 115.824C139.731 115.864 139.751 115.911 139.762 115.96C139.772 116.009 139.773 116.06 139.763 116.109C139.702 116.411 139.538 116.772 139.122 116.744C138.417 116.696 137.966 117.724 137.966 117.724L137.695 117.343C137.605 117.216 137.554 117.065 137.548 116.909C137.543 116.754 137.584 116.6 137.665 116.467V116.467Z"
        fill="url(#paint57_linear_3159_3496)"
      />
      <path
        d="M148.535 117.87L148.38 117.618C148.314 117.365 148.159 117.144 147.942 116.997C147.726 116.85 147.464 116.787 147.204 116.819L146.394 116.919C146.334 116.926 146.276 116.946 146.225 116.977C146.173 117.008 146.128 117.049 146.093 117.098C146.058 117.147 146.034 117.203 146.021 117.262C146.009 117.321 146.009 117.382 146.021 117.441C146.097 117.803 146.291 118.235 146.788 118.202C147.634 118.145 148.174 119.377 148.174 119.377L148.499 118.92C148.607 118.768 148.668 118.587 148.674 118.4C148.681 118.214 148.632 118.03 148.535 117.87V117.87Z"
        fill="url(#paint58_linear_3159_3496)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3159_3496"
          x1="78.1172"
          y1="47.7549"
          x2="132.188"
          y2="47.7549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3159_3496"
          x1="116.899"
          y1="48.9819"
          x2="99.0413"
          y2="96.9139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3159_3496"
          x1="76117.8"
          y1="29949.8"
          x2="77593.3"
          y2="3880.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3159_3496"
          x1="83248.8"
          y1="39683"
          x2="84754.7"
          y2="17288.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3159_3496"
          x1="157.666"
          y1="-1.46385"
          x2="139.519"
          y2="43.0783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCB148" />
          <stop offset="0.05" stopColor="#FDBA46" />
          <stop offset="0.14" stopColor="#FFC244" />
          <stop offset="0.32" stopColor="#FFC444" />
          <stop offset="0.48" stopColor="#FDB946" />
          <stop offset="0.78" stopColor="#F99C4D" />
          <stop offset="0.87" stopColor="#F8924F" />
          <stop offset="1" stopColor="#F8924F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3159_3496"
          x1="50429.2"
          y1="16391.9"
          x2="56151.6"
          y2="16391.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCB148" />
          <stop offset="0.05" stopColor="#FDBA46" />
          <stop offset="0.14" stopColor="#FFC244" />
          <stop offset="0.32" stopColor="#FFC444" />
          <stop offset="0.48" stopColor="#FDB946" />
          <stop offset="0.78" stopColor="#F99C4D" />
          <stop offset="0.87" stopColor="#F8924F" />
          <stop offset="1" stopColor="#F8924F" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3159_3496"
          x1="-11283.2"
          y1="-9096.82"
          x2="-12006.1"
          y2="-8671.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3159_3496"
          x1="62.1152"
          y1="68.6961"
          x2="47.9401"
          y2="82.9707"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3159_3496"
          x1="43.8238"
          y1="161.548"
          x2="38.2535"
          y2="161.715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3159_3496"
          x1="-1939.36"
          y1="9204.38"
          x2="-2097.7"
          y2="9025.29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3159_3496"
          x1="-7315.2"
          y1="9269.97"
          x2="-7887.18"
          y2="9430.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3159_3496"
          x1="-1644"
          y1="9122"
          x2="-1806.08"
          y2="8927.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_3159_3496"
          x1="28841.9"
          y1="154498"
          x2="10492.7"
          y2="139263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_3159_3496"
          x1="28841.9"
          y1="154498"
          x2="10492.7"
          y2="139263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_3159_3496"
          x1="31385.6"
          y1="158656"
          x2="13922.6"
          y2="151292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_3159_3496"
          x1="49.51"
          y1="56.7896"
          x2="-6.08736"
          y2="94.5541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_3159_3496"
          x1="48455.4"
          y1="83202.5"
          x2="30124.1"
          y2="98678.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_3159_3496"
          x1="35392.6"
          y1="107815"
          x2="50100.7"
          y2="74528.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_3159_3496"
          x1="29483.8"
          y1="18101.6"
          x2="29925.4"
          y2="16016.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_3159_3496"
          x1="14616"
          y1="10472"
          x2="14235.1"
          y2="12419.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_3159_3496"
          x1="45.4673"
          y1="91.9695"
          x2="43.7092"
          y2="102.517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.23" stopColor="#FCB048" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_3159_3496"
          x1="27729.6"
          y1="48374.1"
          x2="16277.5"
          y2="58212.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_3159_3496"
          x1="98.1019"
          y1="166.211"
          x2="97.7758"
          y2="145.681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_3159_3496"
          x1="20881.7"
          y1="78139.9"
          x2="23557.6"
          y2="90377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_3159_3496"
          x1="20880.2"
          y1="78139.4"
          x2="23556.2"
          y2="90376.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_3159_3496"
          x1="10122.9"
          y1="6842.37"
          x2="10122.9"
          y2="6141.59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_3159_3496"
          x1="20325.2"
          y1="78552.6"
          x2="23000.1"
          y2="90789.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_3159_3496"
          x1="20323.7"
          y1="78553.1"
          x2="22998.8"
          y2="90790.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_3159_3496"
          x1="57012.7"
          y1="62765"
          x2="62171.5"
          y2="91336.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_3159_3496"
          x1="7894.03"
          y1="7232.57"
          x2="7792.25"
          y2="7411.31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_3159_3496"
          x1="52943.5"
          y1="45269.6"
          x2="65732.8"
          y2="45269.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_3159_3496"
          x1="12132.1"
          y1="14237.2"
          x2="12546.7"
          y2="14704.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_3159_3496"
          x1="90710.3"
          y1="53420.7"
          x2="93783.2"
          y2="42325.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_3159_3496"
          x1="27744.9"
          y1="24970.3"
          x2="29217.4"
          y2="36292.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_3159_3496"
          x1="1718.12"
          y1="8379.96"
          x2="1229.75"
          y2="6781.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_3159_3496"
          x1="74571.9"
          y1="36607.6"
          x2="105661"
          y2="36607.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_3159_3496"
          x1="83007.8"
          y1="32633.5"
          x2="94417.1"
          y2="52641.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_3159_3496"
          x1="35951"
          y1="35935.6"
          x2="69801.2"
          y2="35935.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_3159_3496"
          x1="39605.3"
          y1="26021.7"
          x2="57393"
          y2="59465.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_3159_3496"
          x1="87.5112"
          y1="62.0431"
          x2="123.488"
          y2="124.628"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_3159_3496"
          x1="71032.8"
          y1="43865.2"
          x2="85508.7"
          y2="70450"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_3159_3496"
          x1="-528.549"
          y1="5847.1"
          x2="-494.105"
          y2="5866.22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_3159_3496"
          x1="130.886"
          y1="141.317"
          x2="130.165"
          y2="144.311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_3159_3496"
          x1="55613.9"
          y1="61668.4"
          x2="55947.2"
          y2="59748.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_3159_3496"
          x1="-2780.76"
          y1="4681.06"
          x2="-2712.99"
          y2="4746.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_3159_3496"
          x1="3718.41"
          y1="4916.39"
          x2="3654.28"
          y2="5073.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_3159_3496"
          x1="18716.3"
          y1="38142.4"
          x2="21106.4"
          y2="41346.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_3159_3496"
          x1="58977.5"
          y1="75805.4"
          x2="63180.4"
          y2="76583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_3159_3496"
          x1="87.6206"
          y1="-3974.4"
          x2="-180.002"
          y2="-5917.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_3159_3496"
          x1="66825.1"
          y1="56701.7"
          x2="76854"
          y2="56701.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_3159_3496"
          x1="12837.8"
          y1="10379.4"
          x2="13196.6"
          y2="10379.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_3159_3496"
          x1="47190.2"
          y1="49944.3"
          x2="51724.8"
          y2="49944.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_3159_3496"
          x1="21710"
          y1="8944.66"
          x2="21579.1"
          y2="9720.71"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_3159_3496"
          x1="373"
          y1="-2246.74"
          x2="-57.0895"
          y2="-1987.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_3159_3496"
          x1="39657.5"
          y1="39068.3"
          x2="42596"
          y2="39068.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_3159_3496"
          x1="26256.9"
          y1="29308.8"
          x2="25468.9"
          y2="29818.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_3159_3496"
          x1="25244.2"
          y1="28406.9"
          x2="28259.4"
          y2="30814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_3159_3496"
          x1="5989.19"
          y1="5629.7"
          x2="6468.62"
          y2="4929.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_3159_3496"
          x1="-8361.75"
          y1="6760.05"
          x2="-8936.4"
          y2="5921.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LanguagesIcon;
