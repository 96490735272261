//Based on: https://github.com/revelcw/react-hooks-helper
import { useState } from 'react';

const error = (msg: string) => {
  throw new Error(msg);
};

const getIndexById = (arr: string[], matchId: number | string) =>
  arr.findIndex(({ id }: any) => {
    return id === matchId;
  });

const useStep = ({
  initialStep = 0, // accept number or string (if string convert to index)
  steps: stepsProp,
  editMode = false,
}: {
  initialStep: number | string;
  steps: any;
  editMode?: boolean;
}) => {
  if (process.env.NODE_ENV !== 'production') {
    if (!Array.isArray(stepsProp) && !Number.isInteger(stepsProp)) {
      error('useStep: You must specify either an array or an integer for `steps`');
    }
  }

  // Convert steps to an array if it is a number.
  const steps = typeof stepsProp === 'number' ? new Array(stepsProp).fill({}) : stepsProp;

  // Compute initialStepIndex in case an id is passed vs an index.
  const initialStepIndex = typeof initialStep === 'number' ? initialStep : getIndexById(steps, initialStep);

  if (process.env.NODE_ENV !== 'production') {
    if (typeof initialStep === 'string' && initialStepIndex === -1) {
      error(`useStep: id of "${initialStep}" specified in initialStep not found in steps`);
    }
  }

  // Setup state.
  const [index, setStep] = useState(initialStepIndex);
  const [applyEdit, setEdit] = useState(editMode);
  const step = steps[index];
  step.editMode = applyEdit;

  const deltaSetStep = (delta = 1) => {
    setStep((index + steps.length + delta) % steps.length);
  };

  // URL methods
  const getQuery = () => {
    const url = new URL(window.location.href);
    const p = new URLSearchParams(url.search);
    const paramsObj: any = {};

    for (const [key, value] of p.entries() as any) {
      paramsObj[key] = value;
    }

    return paramsObj;
  };

  const getPath = (): string => {
    const url = new URL(window.location.href);
    const path = url.hash;

    return path.replace('#/', '');
  };

  // Build navigation callback functions.
  const navigation = {
    next: () => {
      deltaSetStep(1);
      getQuery();
      getPath();
    },
    previous: () => {
      deltaSetStep(-1);
      getQuery();
      getPath();
    },
    params: getQuery,
    path: getPath,
    go: (newStep: number | string, edit?: boolean) => {
      if (typeof newStep === 'number') {
        if (process.env.NODE_ENV !== 'production') {
          if (newStep < 0 || newStep > steps.length) {
            error(`useStep: Index out of range in go(${newStep})`);
          }
        }
        setEdit(!!edit);
        setStep(newStep);
        getQuery();
        getPath();
      } else {
        const newStepId = getIndexById(steps, newStep);
        if (process.env.NODE_ENV !== 'production') {
          if (newStepId === -1) {
            error(`useStep: go("${newStep}") not found in steps`);
          }
        }
        setEdit(!!edit);
        setStep(newStepId);
        getQuery();
        getPath();
      }
    },
  };

  return {
    index,
    step,
    navigation,
    edit: editMode,
  };
};

export default useStep;
