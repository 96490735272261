import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';

const CustomMenu = React.forwardRef<HTMLDivElement, typeof Dropdown.Menu>(({ children }, ref) => {
  const [value, setValue] = useState('');

  return (
    <div ref={ref}>
      <Form.Control
        autoFocus
        className="my-3 w-full search-industry"
        placeholder="Type to search..."
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <ul className="selectgroup-menu">
        {React.Children.toArray(children).filter((child: any) => {
          if (child.props) {
            return !value ? child.props.eventKey : child.props.eventKey.toLowerCase().includes(value.toLowerCase());
          }
        })}
      </ul>
    </div>
  );
});

CustomMenu.displayName = 'CustomMenu';

export default CustomMenu;
