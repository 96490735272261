import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { LanguagesIcon } from '../../../../icons';
import {
  ExtendedNavigation,
  HoverHint,
  NarrowHr,
  Paper,
  PaperHeader,
  SAVE_AND_FINISH_LATER,
  Spinner,
  Steps,
} from '../../../../components';
import {
  assignLanguageListThunk,
  formLanguageListSelector,
  getLanguageNamesThunk,
  getLevelsThunk,
  languageNamesSelector,
  languageStatusSelector,
  levelsSelector,
} from '../../../../store/language';
import { LanguageItem } from '../../components';
import { navigateTo } from '../../../../utils/navigateTo';
import { LanguageFields, LanguagesFormWithAction, LanguagesProps } from './Language.types';
import './Language.scss';
import { AssignLanguageDto } from '../../../../api/models/AssignLanguageDto';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Col, Row } from 'react-bootstrap';
import TooltipModal from '../../../../components/TooltipModal/TooltipModal';

const DEFAULT_LANGUAGE: LanguageFields = {
  name: '',
  level: '',
};

const Languages: FC<LanguagesProps> = ({ navigation, editMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();

  const languageNames = useSelector(languageNamesSelector.selectAll);
  const levels = useSelector(levelsSelector.selectAll);
  const defaultValues = useSelector(formLanguageListSelector);
  const { isPending } = useSelector(languageStatusSelector);
  const methods = useForm({ defaultValues, mode: 'onBlur' });
  const [errorText, setError] = useState('');
  const [errorRequiredLanguageText, setErrorRequiredLanguageText] = useState('');

  const [modalOpened, setModalOpened] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'languages',
  });

  useEffect(() => {
    if (languageNames.length <= 0 && levels.length <= 0) {
      dispatch(getLanguageNamesThunk());
      dispatch(getLevelsThunk());
    }
  }, [dispatch, languageNames.length, levels.length]);

  const handleAddNewLanguage = () => append(DEFAULT_LANGUAGE, { shouldFocus: false });
  const handleRemoveLanguage = (index: number) => remove(index);

  const clearErrors = () => {
    setError('');
    setErrorRequiredLanguageText('');
  };

  const onSubmit = async ({ languages, action }: LanguagesFormWithAction) => {
    let languagesToSend: AssignLanguageDto[] = [];

    if (!(languages instanceof Array)) {
      return;
    }

    if (languages) {
      if (languages.some((x) => !x.level || !x.name)) {
        clearErrors();
        setError('Language or level not chosen');
        return;
      }

      if (new Set(languages.map((x) => x.name)).size != languages.length) {
        clearErrors();
        setError('Language duplicate');
        return;
      }

      if (languages.length === 0) {
        clearErrors();
        setErrorRequiredLanguageText('Please add at least one language');
        return;
      }

      clearErrors();
      languagesToSend = languages.map((lang) => ({ languageKey: lang.name, levelKey: lang.level }));
    }

    const resultAction = await dispatch(assignLanguageListThunk(languagesToSend));

    if (action.toString() === SAVE_AND_FINISH_LATER) {
      navigation.go(Steps.CONFIRMATION_PAGE_FINISH_LATER);
    } else if (assignLanguageListThunk.fulfilled.match(resultAction)) {
      navigateTo(action, navigation);
    }
  };

  const languagePropsData = [
    {
      languageLevel: 'A1',
      languageLevelSubText: t('LANGUAGES.LEVEL.A1'),
      languageDescription: t('LANGUAGES.LEVEL_DESCRIPTION.A1'),
    },
    {
      languageLevel: 'A2',
      languageLevelSubText: t('LANGUAGES.LEVEL.A2'),
      languageDescription: t('LANGUAGES.LEVEL_DESCRIPTION.A2'),
    },
    {
      languageLevel: 'B1',
      languageLevelSubText: t('LANGUAGES.LEVEL.B1'),
      languageDescription: t('LANGUAGES.LEVEL_DESCRIPTION.B1'),
    },
    {
      languageLevel: 'B2',
      languageLevelSubText: t('LANGUAGES.LEVEL.B2'),
      languageDescription: t('LANGUAGES.LEVEL_DESCRIPTION.B2'),
    },
    {
      languageLevel: 'C1',
      languageLevelSubText: t('LANGUAGES.LEVEL.C1'),
      languageDescription: t('LANGUAGES.LEVEL_DESCRIPTION.C1'),
    },
    {
      languageLevel: 'C2',
      languageLevelSubText: t('LANGUAGES.LEVEL.C2'),
      languageDescription: t('LANGUAGES.LEVEL_DESCRIPTION.C2'),
    },
    {
      languageLevel: 'NATIVE',
    },
  ];

  return (
    <FormProvider {...methods}>
      <form>
        <Paper>
          <TooltipModal
            headerTitle={t('SKILLS.SKILL_LEVELS_DECSCRIPTION.TITLE')}
            closeButtonCallback={() => setModalOpened(!modalOpened)}
            modalState={modalOpened}
          >
            {languagePropsData.map((language, index) => {
              return (
                <div key={index}>
                  <Row>
                    <Col>
                      <h6 className="language-level">
                        {language.languageLevel}
                        <span className="language-level-subtext">
                          {language.languageLevelSubText && ` - ${language.languageLevelSubText}`}
                        </span>
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{language.languageDescription}</Col>
                  </Row>
                  <hr />
                </div>
              );
            })}
          </TooltipModal>
          <PaperHeader
            stepName="Languages"
            icon={<LanguagesIcon className="header_text" />}
            currentStep={Steps.LANGUAGES}
            text={'LANGUAGES.DESCRIPTION'}
          />
          {fields?.length > 0 && (
            <li className="language-container">
              <ul className="language mb-4 mb-sm-0">
                <p>Language</p>
                <p>
                  {isMobile ? (
                    <div onClick={() => setModalOpened(!modalOpened)}>
                      <span>Level</span>
                      <span className="hoverHintButton">i</span>
                    </div>
                  ) : (
                    <HoverHint header={t('LANGUAGES.HINTS.DESCRIPTION')} titleText={<span>Level</span>}>
                      {languagePropsData.map((language, index) => {
                        return (
                          <div key={index} className="hover-container">
                            <Row>
                              <Col>
                                <h6 className="language-level">
                                  {language.languageLevel}
                                  <span className="language-level-subtext">
                                    {language.languageLevelSubText && ` - ${language.languageLevelSubText}`}
                                  </span>
                                </h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="language-level-description">{language.languageDescription}</Col>
                            </Row>
                            {languagePropsData.length - 1 !== index && <NarrowHr />}
                          </div>
                        );
                      })}
                    </HoverHint>
                  )}
                </p>
              </ul>
              {languageNames.length > 0 &&
                levels.length > 0 &&
                fields.map((field, index: number) => (
                  <LanguageItem
                    key={field.id}
                    index={index}
                    languageNames={languageNames}
                    levels={levels}
                    removeLanguage={handleRemoveLanguage}
                  />
                ))}
            </li>
          )}
        </Paper>
        <Paper>
          {errorText !== '' && <p className="error-container language-error-message">{errorText}</p>}
          {errorRequiredLanguageText !== '' && (
            <p className="error-container extended-nav-error">{errorRequiredLanguageText}</p>
          )}
          <ExtendedNavigation
            onSubmit={onSubmit}
            onAddNew={handleAddNewLanguage}
            addNewText="Add new language"
            addNewIsDisabled={!methods.formState.isValid}
            editMode={editMode}
            saveAndFinishLaterMode={true}
          />
        </Paper>
        {isPending && <Spinner />}
      </form>
    </FormProvider>
  );
};

export default Languages;
