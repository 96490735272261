import React, { FC, ReactNode, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import styles from './HoverHint.module.scss';

type HoverHintProps = {
  titleText?: string | HTMLElement | ReactNode;
  header?: string;
  position?: 'top' | 'right' | 'left' | 'bottom' | 'auto';
  children: string | HTMLElement | ReactNode;
  'data-test-id'?: string;
  id?: string;
};

const HoverHint: FC<HoverHintProps> = ({ header, position = 'auto', titleText, children, ...props }) => {
  const [show, setShow] = useState(false);

  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  const popover = (
    // Overwritte styles works only inline
    <Popover
      id="hover-hint-popover"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      style={{
        border: 'none',
        boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25',
        borderRadius: 15,
        minWidth: 500,
        padding: '20px 10px',
      }}
    >
      <Popover.Title style={{ backgroundColor: 'transparent', paddingLeft: 25 }}>
        <h5 className="headerText">{header}</h5>
      </Popover.Title>
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );
  return (
    <OverlayTrigger placement={position} show={show} overlay={popover} data-test-id={props['data-test-id'] || 'button'}>
      {!titleText ? (
        <span
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={styles.hoverHintButton}
          id={props['id'] || ''}
        >
          i
        </span>
      ) : (
        <span onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
          <span className={styles.titleText}>{titleText}</span>
          <span className={`${styles.hoverHintButton} ${styles.withTitle}`} id={props['id'] || ''}>
            i
          </span>
        </span>
      )}
    </OverlayTrigger>
  );
};

export default HoverHint;
