import { AxiosPromise } from 'axios';
import axiosInstance from '../axiosInstance';
import { LanguageListDto } from '../models/LanguageListDto';
import { AssignLanguageListDto } from '../models/AssignLanguageListDto';
import { KeyValueEntryListDto } from '../models/KeyValueEntryListDto';

export const getLanguageNames = (): AxiosPromise<KeyValueEntryListDto> => {
  return axiosInstance({
    url: `/dictionaries/language`,
    method: 'GET',
  });
};

export const getLevels = (): AxiosPromise<KeyValueEntryListDto> => {
  return axiosInstance({
    url: `/dictionaries/language-level`,
    method: 'GET',
  });
};

export const putLanguageList = (data: AssignLanguageListDto): AxiosPromise<LanguageListDto> => {
  return axiosInstance({
    url: `/section/languages`,
    method: 'PUT',
    data,
  });
};
