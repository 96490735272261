import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { AssignCandidateSkillListDto, assignSkills } from '../../api';
import { getCandidateReportWithSectionsThunk } from '../candidateReport';

export enum Actions {
  assignCandidateSkills = '[SKILLS] ASSIGN CANDIDATE SKILLS',
}

export const assignCandidateSkillListThunk = createAsyncThunk(
  Actions.assignCandidateSkills,
  async (skills: AssignCandidateSkillListDto) => {
    const { data } = await assignSkills(skills);

    return data;
  },
);

export const isPendingAction = isPending(assignCandidateSkillListThunk);
export const isFulfilledAction = isFulfilled(assignCandidateSkillListThunk);
export const isSkillsFromCandidateReportAction = isFulfilled(getCandidateReportWithSectionsThunk);
export const isRejectedAction = isRejected(assignCandidateSkillListThunk);
