import React, { FC, memo, useEffect, useState } from 'react';
import { Message, useFormContext, ValidateResult } from 'react-hook-form';
import { Paper, Select, RemoveButton, ConfirmationModal, RequiredFieldIndicator } from '../../../../components';
import SelectKeyboard from '../../../../components/SelectKeyboard/SelectKeyboard';
import { useTranslation } from 'react-i18next';
import './EducationItem.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getDegreesThunk } from '../../../../store/degree/thunks';
import { degreesSelector } from '../../../../store/degree';
import { TrashIcon } from '../../../../icons';
import { ErrorMessage } from '@hookform/error-message';

interface EducationItemProps {
  index: number;
  removeEducation: (index: number) => void;
}

export const NonMemoizedEducationItem: FC<EducationItemProps> = ({ index, removeEducation }) => {
  const {
    register,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const baseName = `education[${index}]`;
  const school = `${baseName}.school`;
  const schoolValue = watch(school);
  const fieldOfStudy = `${baseName}.fieldOfStudy`;
  const fieldOfStudyValue = watch(fieldOfStudy);
  const degreeKey = `${baseName}.degreeKey`;
  const startYear = `${baseName}.startYear`;
  const startYearValue = watch(startYear);
  const endYear = `${baseName}.endYear`;
  const endYearValue = watch(endYear);
  const currentlyStudying = `${baseName}.currentlyStudying`;
  const currentlyStudyingValue = watch(currentlyStudying);

  const [showModal, setShowModal] = useState(false);

  const toggleShowModal = () => setShowModal(!showModal);

  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();
  const degrees = useSelector(degreesSelector.selectAll);

  const formErrors = (errors?.education as any)?.[index];

  const yearValidation = (start: number, end: number, set: boolean): ValidateResult => {
    if (!currentlyStudyingValue && !!end && !!start && end < start && end < 2050 && start > 1920) {
      setError(endYear, { message: t('EDUCATION.YEARS_VALIDATION') });
      return set ? (t('EDUCATION.YEARS_VALIDATION') as Message) : undefined;
    } else if (end > 2050 || start > 2050 || start < 1920 || end < 1920) {
      setError(endYear, { message: t('EDUCATION.YEARS_REALISTIC') });
      return set ? (t('EDUCATION.YEARS_REALISTIC') as Message) : undefined;
    }

    clearErrors(endYear);
    return undefined;
  };

  useEffect(() => {
    dispatch(getDegreesThunk());
  }, []);

  return (
    <Paper variant="default">
      <section className="education-container">
        <section className="education">
          <div className="education-container education-container_input mt-4">
            <label htmlFor="school">
              School name
              <RequiredFieldIndicator />
            </label>
            <input
              {...register(school, { required: { value: true, message: 'School name is required' } })}
              type="text"
              defaultValue={schoolValue}
              maxLength={100}
            />
            <ErrorMessage
              errors={errors}
              name={school}
              render={({ message }) => <span className="education-container education-container_error">{message}</span>}
            />
          </div>
          <div className="education-container education-container_input">
            <label htmlFor="fieldOfStudy">Field of study</label>
            <input {...register(fieldOfStudy)} type="text" defaultValue={fieldOfStudyValue} maxLength={100} />
            <ErrorMessage
              errors={errors}
              name={fieldOfStudy}
              render={({ message }) => <span className="education-container education-container_error">{message}</span>}
            />
          </div>
          <div className="education-container education-container_input">
            <label htmlFor="degree">Degree</label>
            <Select options={degrees} name={degreeKey} className="degrees" />
            <ErrorMessage
              errors={errors}
              name={degreeKey}
              render={({ message }) => <span className="education-container education-container_error">{message}</span>}
            />
          </div>
          <label>
            <input
              {...register(currentlyStudying)}
              type="checkbox"
              name={currentlyStudying}
              defaultChecked={currentlyStudyingValue}
              onChange={(value) => {
                setValue(currentlyStudying, value.target.checked);
                clearErrors(endYear);
              }}
            />
            I am currently studying
          </label>
          <div className="education-container education-container_school-status-duration">
            <div className="education-container_school-status-duration-start">
              <label htmlFor="start_year">Start Year</label>
              <RequiredFieldIndicator />
              <p>
                <SelectKeyboard
                  name={startYear}
                  className="year_start"
                  registerOptions={{
                    required: { value: true, message: 'Start date is required' },
                    validate: (start) => yearValidation(parseInt(start), parseInt(endYearValue), false),
                  }}
                />
              </p>
              <ErrorMessage
                errors={errors}
                name={startYear}
                render={({ message }) => (
                  <span className="education-container education-container_error">{message}</span>
                )}
              />
            </div>
            <div className="education-container_school-status-duration-end">
              <label htmlFor="end_year">End Year</label>
              <RequiredFieldIndicator />
              {currentlyStudyingValue ? (
                <p>Present</p>
              ) : (
                <p>
                  <SelectKeyboard
                    name={endYear}
                    className="year_start"
                    registerOptions={{
                      required: {
                        value: !currentlyStudyingValue,
                        message: 'End date is required',
                      },
                      validate: (end) => yearValidation(parseInt(startYearValue), parseInt(end), true),
                    }}
                  />
                </p>
              )}
              <ErrorMessage
                errors={errors}
                name={endYear}
                render={({ message }) => (
                  <span className="education-container education-container_error">{message}</span>
                )}
              />
            </div>
          </div>
          <RemoveButton onClick={toggleShowModal} value="Remove" className="button__no-border">
            <TrashIcon />
          </RemoveButton>

          <ConfirmationModal
            title="Are you sure you want to remove education details?"
            confirmButtonText="Confirm"
            declineButtonText="Cancel"
            confirmButtonAction={() => removeEducation(index)}
            declineButtonAction={toggleShowModal}
            show={showModal}
            onHide={toggleShowModal}
          />
        </section>
      </section>
    </Paper>
  );
};

export const EducationItem = memo(NonMemoizedEducationItem);
