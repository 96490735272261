import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './TooltipModal.module.scss';
import Container from 'react-bootstrap/Container';
import Button from '../Button/Button';

interface TooltipModalProps {
  headerTitle: string;
  closeButtonCallback: () => void;
  modalState: boolean;
}

const TooltipModal: FC<TooltipModalProps> = ({ headerTitle, closeButtonCallback, modalState, children }) => {
  return (
    <>
      <Modal
        show={modalState}
        size="sm"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // overwritting styles works inline
        style={{
          border: 'none',
          boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Modal.Header>
          <Modal.Title className={styles.headerText} id="contained-modal-title-vcenter">
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
        <Container
          style={{
            padding: '1rem',
          }}
        >
          <Modal.Body className={styles.defaultDescription}>{children}</Modal.Body>
          <Button variant="transparent" onClick={closeButtonCallback}>
            Close
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default TooltipModal;
