import React, { FC, useEffect, useState } from 'react';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from '../../../components';
import { NavigationProps, Steps } from '../../../components/PageRoute/PageRoute.types';
import SummaryIcon from '../../../icons/SummaryIcon';
import { Certificates, Education, Experience, Languages, Skills, SaveModal } from '../components';
import { finishCandidateReportThunk, reportStatusSelector, updateReportStatusThunk } from '../../../store/report';
import { skillsSelector } from '../../../store/skills/selector';
import { experienceSelector } from '../../../store/experience/selector';
import { languageListSelector } from '../../../store/language';
import { educationSelector } from '../../../store/education';
import { certificateSelector } from '../../../store/certificate';
import { SummaryModel } from '../Summary.types';
import HeaderWrapper from '../../../components/HeaderWrapper/HeaderWrapper';
import './Summary.scss';
import { candidateReportSelector, getCandidateReportWithSectionsThunk } from '../../../store/candidateReport';
import { CandidateReportDto } from '../../../api';
import { StatusEnum } from '../../../types/reportStatus';
import AboutMe from '../components/AboutMe/AboutMe';

interface SummaryProps {
  navigation: NavigationProps;
}

const Summary: FC<SummaryProps> = ({ navigation }) => {
  const [modalShow, setModalShow] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();
  useEffect(() => {
    dispatch(getCandidateReportWithSectionsThunk());
  }, []);
  const skills = useSelector(skillsSelector.selectAll);
  const experience = useSelector(experienceSelector.selectAll);
  const languages = useSelector(languageListSelector.selectAll);
  const education = useSelector(educationSelector.selectAll);
  const certificates = useSelector(certificateSelector.selectAll);
  const report = useSelector(candidateReportSelector) as CandidateReportDto;

  const { isPending } = useSelector(reportStatusSelector);
  const candidateReport = useSelector(candidateReportSelector);
  const updateReportStatus = (id: number, status: StatusEnum) => dispatch(updateReportStatusThunk({ id, status }));
  const model: SummaryModel = {
    certificates,
    experience,
    skills,
    languages,
    education,
  };

  const handleNavigation = (step: number | string) => {
    navigation.go(step, true);
  };

  const handleGoBack = () => navigation.previous();
  const handleSaveAndFinishLater = () => navigation.go(Steps.CONFIRMATION_PAGE_FINISH_LATER);

  const handleSave = async () => {
    handleModalHide();
    const resultAction = await dispatch(finishCandidateReportThunk());

    if (finishCandidateReportThunk.fulfilled.match(resultAction)) {
      navigation.go(Steps.CONFIRMATION_PAGE_SUBMITTED);
    }
  };

  const handleModalShow = () => setModalShow(true);
  const handleModalHide = () => setModalShow(false);

  return (
    <div className="summary-container">
      <section className="summary">
        <SummaryIcon className="summary_text" />
        <HeaderWrapper stepName="Summary"></HeaderWrapper>
        <p className="header_text">
          Hey, you made it! Now simply check if the information you provided us with is truthful. If you need to correct
          some of them just click <strong>the pencil</strong> on the right. It will direct you to an appropriate section
          so that you could <strong>make some changes</strong>. Go back to the Summary and click{' '}
          <strong>Save and send</strong> to provide the recruiter with your form.
        </p>
        <Skills className="skills_container" onClick={() => handleNavigation(Steps.SKILLS)} model={model?.skills} />
        <Experience
          className="experience_container"
          onClick={() => handleNavigation(Steps.EXPERIENCE)}
          model={model?.experience}
        />
        <AboutMe
          className="aboutme_container"
          onClick={() => handleNavigation(Steps.ABOUT_ME)}
          aboutMe={report?.description}
        />
        <Education
          className="education_container"
          onClick={() => handleNavigation(Steps.EDUCATION)}
          model={model?.education}
        />
        <Languages
          className="languages_container"
          onClick={() => handleNavigation(Steps.LANGUAGES)}
          model={model?.languages}
        />
        <Certificates
          className="certificates_container"
          onClick={() => handleNavigation(Steps.CERTIFICATES)}
          model={model?.certificates}
        />
      </section>
      <hr className="summary-container_line" />
      <span className="summary-container_btn">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            handleSaveAndFinishLater();
            updateReportStatus(candidateReport.reportId, StatusEnum.filled);
          }}
        >
          {t('BUTTON.SAVE_AND_FINISH')}
        </Button>
        <Button variant="outline" size="sm" onClick={handleGoBack} className="skills-container_btn--margin">
          {t('COMMON.BACK')}
        </Button>

        <Button variant="blue" size="sm" className="skills-container_btn--margin" onClick={handleModalShow}>
          {t('BUTTON.SAVE_AND_SEND')}
        </Button>
      </span>
      <SaveModal show={modalShow} handleClose={handleModalHide} handleSave={handleSave} />
      {isPending && <Spinner />}
    </div>
  );
};

export default Summary;
