import React, { FC } from 'react';
import './Errors.scss';
import { useTranslation } from 'react-i18next';

interface ErrorsProps {
  formErrors: { [key: string]: { message: string; type: string } };
  errorTypes: string[];
}

const Errors: FC<ErrorsProps> = ({ formErrors, errorTypes }) => {
  const { t } = useTranslation();

  if (!formErrors) {
    return null;
  }

  return (
    <div className="error-container">
      <div className="error-position">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className="error-list">
        {errorTypes?.map(
          (error) =>
            formErrors[error] && (
              <li key={error}>{t(`ERROR.${formErrors[error].type.toUpperCase()}.${error.toUpperCase()}`)}</li>
            ),
        )}
      </ul>
    </div>
  );
};

export default Errors;
