import React, { FC } from 'react';

interface LandingPageIconProps {
  className?: string;
}

const ConfirmPageIcon: FC<LandingPageIconProps> = ({ className = '' }) => {
  return (
    <svg className={className} viewBox="0 0 212 227" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M109.222 17.3953C55.4256 15.807 8.82641 54.8414 1.78574 106.059C-5.91163 162.059 36.4544 212.446 88.5851 220.516C153.426 230.554 216.319 172.483 210.671 109.485C206.417 62.0221 163.908 19.0099 109.222 17.3953Z"
        fill="#F4F7FA"
      />
      <path
        d="M113.718 164.755C102.971 165.349 82.4876 122.402 96.7986 99.551C102.771 90.0153 111.784 88.7975 115.595 76.6554C118.996 65.8206 113.323 62.0017 115.106 49.9341C117.612 32.9654 132.66 14.5703 146.52 15.9816C153.474 16.6895 161.254 22.4982 163.502 30.7498C165.302 37.359 159.836 44.0818 161.768 45.4201C163.354 46.5196 166.573 42.041 169.404 42.8761C173.58 44.1074 176.463 56.7617 172.688 67.3758C166.226 85.5472 144.363 86.1828 143.47 99.2783C142.901 107.626 151.588 110.226 150.158 118.826C148.898 126.393 141.599 127.831 132.257 137.198C117.623 151.871 119.48 164.437 113.718 164.755Z"
        fill="url(#paint0_linear)"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M116.44 174.195C116.224 174.259 115.982 174.18 115.847 173.985C115.728 173.814 115.723 173.597 115.816 173.426C115.819 173.101 115.603 172.048 115.355 170.842C113.963 164.051 110.265 146.012 113.94 117.307C118.561 81.2064 132.967 41.8302 156.758 0.270695C156.906 0.0131374 157.235 -0.0764803 157.492 0.0711261C157.749 0.218732 157.839 0.547082 157.691 0.80464C132.13 45.4556 104.219 111.163 116.409 170.627C116.96 173.317 117.06 173.802 116.595 174.122C116.546 174.155 116.494 174.18 116.44 174.195Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M123.772 79.0405C123.499 79.1204 123.209 78.9727 123.114 78.7016C123.016 78.4215 123.164 78.115 123.444 78.0171C146.516 69.9492 160.198 61.6862 167.614 56.1743C175.643 50.2068 178.254 46.0942 178.279 46.0528C178.435 45.8012 178.766 45.7237 179.019 45.8796C179.271 46.0362 179.349 46.3668 179.193 46.6191C179.087 46.7908 176.496 50.8967 168.344 56.9711C160.865 62.544 147.066 70.8951 123.799 79.0315C123.79 79.0345 123.781 79.0375 123.772 79.0405Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M132.142 53.0972C132.036 53.1281 131.919 53.1266 131.807 53.0859C125.528 50.7875 121.199 43.0035 118.941 29.9501C117.277 20.3286 117.415 11.2456 117.418 11.1552C117.423 10.8577 117.668 10.6212 117.964 10.6273C118.261 10.6325 118.498 10.8773 118.492 11.174C118.486 11.531 118.03 46.8978 132.177 52.0768C132.456 52.1785 132.599 52.488 132.497 52.7666C132.435 52.9331 132.3 53.0513 132.142 53.0972Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M113.907 124.311C113.758 124.354 113.589 124.332 113.45 124.237C104.761 118.218 100.561 107.7 98.5676 99.9375C96.4168 91.5608 96.3016 84.4923 96.3008 84.4222C96.2971 84.1255 96.5343 83.8815 96.831 83.8777C97.1277 83.874 97.3717 84.1112 97.3755 84.4087C97.3762 84.4779 97.4922 91.438 99.6152 99.6942C101.563 107.272 105.652 117.527 114.062 123.353C114.306 123.523 114.367 123.857 114.197 124.101C114.125 124.206 114.021 124.277 113.907 124.311Z"
          fill="white"
        />
      </g>
      <path
        d="M110.457 188.53C106.941 171.376 107.432 158.084 108.458 149.412C109.263 142.604 111.054 133.091 115.898 131.895C118.219 131.322 119.542 133.078 121.964 132.368C126.539 131.026 126.421 123.416 129.308 117.88C134.246 108.409 150.143 100.854 161.527 106.454C175.235 113.197 176.692 136.208 169.321 143.299C164.953 147.5 157.138 146.447 156.712 149.503C156.284 152.578 164.117 154.245 164.666 159.176C165.234 164.28 157.554 168.906 157.089 169.18C147.781 174.652 140.46 168.992 130.246 171.517C124.706 172.887 117.486 176.87 110.457 188.53Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M110.457 188.53C106.941 171.376 107.432 158.084 108.458 149.412C109.263 142.604 111.054 133.091 115.898 131.895C118.219 131.322 119.542 133.078 121.964 132.368C126.539 131.026 126.421 123.416 129.308 117.88C134.246 108.409 150.143 100.854 161.527 106.454C175.235 113.197 176.692 136.208 169.321 143.299C164.953 147.5 157.138 146.447 156.712 149.503C156.284 152.578 164.117 154.245 164.666 159.176C165.234 164.28 157.554 168.906 157.089 169.18C147.781 174.652 140.46 168.992 130.246 171.517C124.706 172.887 117.486 176.87 110.457 188.53Z"
        fill="url(#paint2_linear)"
      />
      <g opacity="0.8">
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M108.533 202.404L108.8 202.414C110.325 161.365 136.039 125.669 177.586 106.927L177.476 106.684C157.786 115.566 141.028 128.78 129.014 144.895C116.383 161.838 109.301 181.725 108.533 202.404Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M95.959 142.862C103.479 151.209 110.788 167.501 112.25 179.18L112.514 179.147C111.046 167.422 103.708 151.065 96.157 142.683L95.959 142.862Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M108.928 194.349L109.181 194.433C111.094 188.622 115.871 184 123.379 180.698C128.885 178.276 135.703 176.607 143.646 175.736L143.617 175.471C135.647 176.344 128.802 178.021 123.271 180.453C115.691 183.788 110.865 188.463 108.928 194.349Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M130.811 142.673L131.059 142.77C135.228 132.001 132.395 120.194 129.286 112.184L129.037 112.281C132.13 120.246 134.948 131.985 130.811 142.673Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M149.35 123.832L149.475 124.068C160.96 117.961 174.436 120.165 181.446 122.022L181.514 121.765C174.467 119.897 160.916 117.682 149.35 123.832Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M120.447 41.687L140.114 205.153C140.408 207.603 138.719 209.801 136.353 210.036L54.4382 219.029C49.4203 219.34 46.673 216.777 46.3627 214.171L25.7152 40.3796C25.4072 37.7882 27.869 35.4408 32.9087 35.4408L115.568 37.182C117.962 37.2219 120.153 39.2492 120.447 41.687Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M124.133 41.4055L143.907 205.689C144.204 208.152 142.509 210.361 140.134 210.597L55.2117 219.052C52.5495 219.317 50.1095 217.391 49.7977 214.771L29.0342 40.0815C28.7247 37.4766 30.6804 35.3822 33.372 35.4281L119.236 36.8771C121.637 36.917 123.838 38.9549 124.133 41.4055Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M122.524 50.4673L140.853 202.791C141.13 205.093 139.543 207.152 137.319 207.368L57.4531 215.131C54.9776 215.372 52.7101 213.574 52.4201 211.135L33.2313 49.7624C32.9429 47.3367 34.7586 45.3741 37.2581 45.4005L117.945 46.2598C120.19 46.2839 122.249 48.1771 122.524 50.4673Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M79.6941 41.2926C79.7988 42.1669 79.1715 42.8673 78.2911 42.856C77.41 42.8447 76.6109 42.1255 76.5055 41.2489C76.4008 40.3723 77.0304 39.6727 77.9115 39.6855C78.7927 39.699 79.5894 40.4182 79.6941 41.2926Z"
        fill="url(#paint6_linear)"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M108.711 152.716C92.3608 122.487 67.9357 113.438 60.5817 82.2834C56.6069 65.4427 59.315 49.3242 63.8765 35.9432L33.3694 35.4281C30.6779 35.3829 28.7221 37.4765 29.0324 40.0815L49.7959 214.771C50.1069 217.391 52.547 219.317 55.2099 219.052L121.967 212.406C122.46 185.319 116 166.192 108.711 152.716Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M113.162 131.882C113.074 131.012 112.371 130.279 111.594 130.299L65.8908 131.518C65.0722 131.54 64.4855 132.328 64.5804 133.222L65.1068 138.206C65.2009 139.099 65.9405 139.806 66.7583 139.78L112.409 138.357C113.185 138.333 113.743 137.604 113.655 136.735L113.162 131.882Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M109.766 142.565C109.677 141.693 108.975 141.001 108.195 141.027L71.9462 142.209C71.1336 142.235 70.5492 142.985 70.6426 143.875L73.1534 167.714C73.2468 168.601 73.9788 169.29 74.7884 169.251L110.896 167.538C111.673 167.501 112.233 166.766 112.144 165.899L109.766 142.565ZM99.1932 160.685C99.3084 161.806 98.5801 162.679 97.5687 162.724L87.341 163.179C86.3168 163.224 85.3927 162.418 85.2752 161.289L84.0891 149.865C83.9709 148.733 84.7066 147.724 85.7324 147.687L95.982 147.32C96.9956 147.284 97.9121 148.226 98.0281 149.349L99.1932 160.685Z"
            fill="white"
          />
          <g opacity="0.8">
            <path
              opacity="0.8"
              d="M90.4457 160.939C90.0797 160.955 89.6971 160.816 89.3861 160.52L86.3888 157.668C85.763 157.072 85.659 156.07 86.1553 155.428C86.6524 154.787 87.5606 154.751 88.1857 155.347L90.0503 157.126L94.6849 151.129C95.1774 150.493 96.0773 150.459 96.6964 151.053C97.3147 151.647 97.4171 152.643 96.9261 153.28L91.4089 160.433C91.1619 160.753 90.8117 160.923 90.4457 160.939Z"
              fill="white"
            />
          </g>
        </g>
        <path
          opacity="0.8"
          d="M111.873 82.6268H55.38C51.5302 82.6268 48.5841 85.7423 49.0073 89.7066L50.64 104.988C51.0617 108.939 54.6789 112.172 58.5114 112.111L114.764 111.21C118.352 111.153 120.982 107.994 120.599 104.192L119.115 89.4536C118.731 85.6399 115.475 82.6268 111.873 82.6268Z"
          fill="white"
        />
      </g>
      <path
        d="M177.001 109.762C177.001 109.762 175.438 121.398 187.699 129.601L174.653 132.713C174.653 132.713 170.478 124.906 173.501 111.973L177.001 109.762Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M174.704 111.974C174.704 111.974 174.001 110.238 174.114 109.457C174.228 108.676 176.087 107.633 177.355 106.556C178.623 105.479 177.308 107.639 177.013 108.13C176.718 108.621 175.683 109.358 175.832 111.028L174.704 111.974Z"
        fill="url(#paint8_linear)"
      />
      <path d="M153.165 42.9005L107.982 103.315L155.655 138.968L200.837 78.5533L153.165 42.9005Z" fill="white" />
      <path
        d="M154.44 66.5251L146.045 60.2465L152.323 51.851L160.719 58.1296L154.44 66.5251ZM148.666 59.8685L154.062 63.905L158.099 58.5084L152.702 54.4718L148.666 59.8685Z"
        fill="#D8DEE8"
      />
      <path d="M162.219 62.9942L159.416 66.7417L187.899 88.0437L190.702 84.2962L162.219 62.9942Z" fill="#D8DEE8" />
      <path
        d="M144.573 79.7178L136.178 73.4393L142.456 65.0438L150.852 71.3223L144.573 79.7178ZM138.798 73.0612L144.195 77.0978L148.232 71.7011L142.835 67.6645L138.798 73.0612Z"
        fill="#D8DEE8"
      />
      <path d="M152.348 76.1868L149.545 79.9343L178.028 101.236L180.831 97.4888L152.348 76.1868Z" fill="#D8DEE8" />
      <path
        d="M134.708 92.9097L126.312 86.6312L132.591 78.2357L140.987 84.5142L134.708 92.9097ZM128.933 86.2531L134.33 90.2897L138.367 84.893L132.97 80.8565L128.933 86.2531Z"
        fill="#D8DEE8"
      />
      <path d="M142.482 89.378L139.68 93.1256L168.163 114.428L170.966 110.68L142.482 89.378Z" fill="#D8DEE8" />
      <path
        d="M124.841 106.102L116.445 99.8239L122.724 91.4284L131.119 97.707L124.841 106.102ZM119.066 99.4459L124.463 103.482L128.499 98.0858L123.103 94.0492L119.066 99.4459Z"
        fill="#D8DEE8"
      />
      <path d="M132.621 102.571L129.818 106.318L158.302 127.62L161.104 123.873L132.621 102.571Z" fill="#D8DEE8" />
      <path
        d="M148.366 61.2828C148.362 61.2798 148.357 61.2768 148.354 61.2738C148.137 61.1119 147.994 60.8769 147.978 60.6065L147.789 57.4993C147.759 56.9834 148.149 56.5451 148.665 56.515C149.181 56.4849 149.623 56.881 149.653 57.3969L149.771 59.4415L159.767 57.4556C160.274 57.3547 160.767 57.6845 160.868 58.1914C160.969 58.6982 160.639 59.1915 160.132 59.2916L149.095 61.4688C148.834 61.5216 148.578 61.441 148.366 61.2828Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M128.297 88.1171C128.293 88.114 128.289 88.111 128.285 88.108C128.068 87.9461 127.925 87.7111 127.909 87.4408L127.72 84.3335C127.69 83.8176 128.08 83.3793 128.597 83.3492C129.113 83.3191 129.554 83.7152 129.584 84.2311L129.703 86.2757L139.699 84.2898C140.206 84.1889 140.698 84.5188 140.799 85.0256C140.9 85.5324 140.57 86.0257 140.064 86.1259L129.026 88.3031C128.766 88.3558 128.51 88.276 128.297 88.1171Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M118.541 101.159C118.538 101.156 118.533 101.153 118.529 101.15C118.312 100.988 118.169 100.753 118.154 100.483L117.965 97.3754C117.934 96.8595 118.325 96.4212 118.841 96.3911C119.357 96.361 119.798 96.7571 119.828 97.273L119.947 99.3176L129.943 97.3317C130.45 97.2308 130.943 97.5607 131.043 98.0675C131.144 98.5743 130.815 99.0676 130.308 99.1678L119.27 101.345C119.01 101.398 118.754 101.318 118.541 101.159Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M143.821 78.6891C143.629 78.5452 143.492 78.3291 143.455 78.073L141.906 67.3294C141.832 66.818 142.187 66.3428 142.699 66.269C143.211 66.1952 143.685 66.5499 143.76 67.062L145.309 77.8057C145.382 78.317 145.028 78.7922 144.516 78.866C144.26 78.9029 144.013 78.8329 143.821 78.6891Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M137.675 74.0922C137.483 73.9484 137.345 73.7322 137.309 73.4762C137.235 72.9648 137.589 72.4896 138.102 72.4158L148.845 70.8667C149.357 70.7929 149.831 71.1476 149.906 71.6597C149.979 72.1711 149.625 72.6463 149.113 72.7201L138.369 74.2692C138.113 74.3061 137.867 74.2353 137.675 74.0922Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M189.121 121.183C188.57 120.097 188.343 120.104 188.207 119.531C187.898 118.225 188.695 116.566 189.371 116.543C189.806 116.528 189.842 117.205 190.764 118.26C191.894 119.553 192.579 119.383 193.243 120.505C193.398 120.768 194.099 121.949 193.668 122.831C193.119 123.956 190.954 124.134 190.048 123.266C189.621 122.855 189.801 122.521 189.121 121.183Z"
        fill="url(#paint14_linear)"
      />
      <path
        d="M191.188 122.193C191.188 122.193 190.739 124.591 191.059 126.541C191.348 128.303 194.613 130.134 195.239 130.659C195.864 131.184 193.579 131.753 191.482 131.479C189.386 131.205 183.645 129.368 182.773 128.344C181.901 127.321 183.789 127.825 185.377 127.631C186.964 127.437 188.48 124.776 188.526 123.418C188.573 122.06 191.188 122.193 191.188 122.193Z"
        fill="url(#paint15_linear)"
      />
      <path
        d="M190.156 123.895C190.156 123.895 188.775 124.501 187.552 123.524C186.457 122.648 188.085 119.958 189.199 119.923C189.571 119.912 190.114 120.178 190.434 120.568C190.623 120.798 190.634 120.951 190.95 121.321C191.242 121.662 191.37 121.693 191.415 121.859C191.471 122.066 191.375 122.412 190.626 123.008C190.999 122.939 191.322 123.037 191.447 123.255C191.466 123.289 191.508 123.398 191.495 123.51C191.45 123.916 190.92 124.103 190.156 123.895Z"
        fill="url(#paint16_linear)"
      />
      <path
        d="M155.234 115.641C155.234 115.641 154.262 115.506 154.566 113.649C154.803 112.194 154.624 111.705 154.28 111.963C153.936 112.221 153.887 113.062 153.887 113.062C153.887 113.062 152.634 111.993 152.118 110.593C151.602 109.193 151.271 110.372 151.16 111.072C151.05 111.772 151.898 113.946 152.376 114.352C152.855 114.757 153.556 116.543 153.556 116.543L155.234 115.641Z"
        fill="url(#paint17_linear)"
      />
      <path
        d="M191.182 218.301C191.682 219.321 191.542 219.76 191.417 219.953C191.051 220.518 190.173 219.984 187.879 220.256C187.611 220.288 187.097 220.358 186.456 220.207C185.907 220.077 185.26 219.785 185.288 219.638C185.298 219.585 185.396 219.563 185.433 219.555C186.069 219.42 190.054 218.197 190.054 218.197L191.182 218.301Z"
        fill="url(#paint18_linear)"
      />
      <path
        d="M191.489 216.058L191.133 218.339C191.133 218.339 190.154 219.031 189.897 218.191C189.712 217.586 189.717 215.957 189.717 215.957L191.489 216.058Z"
        fill="url(#paint19_linear)"
      />
      <path
        d="M180.322 216.215L181.284 218.84L182.681 218.618L182.357 216.187L180.322 216.215Z"
        fill="url(#paint20_linear)"
      />
      <path
        d="M181.716 220.287C182.392 219.484 182.996 219.557 183.118 219.006C183.213 218.573 182.948 218.041 182.548 217.839C181.985 217.555 181.304 217.999 181.149 218.099C180.103 218.779 180.388 219.842 179.402 220.303C179.203 220.396 178.889 220.494 178.557 220.805C178.416 220.937 178.068 221.264 178.149 221.489C178.243 221.75 178.865 221.741 179.456 221.732C180.166 221.721 180.52 221.717 180.862 221.504C181.334 221.208 181.193 220.906 181.716 220.287Z"
        fill="url(#paint21_linear)"
      />
      <path
        d="M198.965 161.517C198.707 166.821 197.947 171.349 197.083 175.04C196.154 179.01 195.648 179.689 195.325 182.441C194.624 188.431 196.54 189.331 196.749 196.123C196.981 203.622 194.861 209.532 194.03 211.786C193.303 213.757 192.552 215.345 191.989 216.446C191.289 217.031 190.244 216.778 189.142 216.529C188.88 209.727 188.509 203.322 188.247 196.519C187.151 186.27 186.055 176.021 184.959 165.772C189.627 164.353 194.296 162.935 198.965 161.517Z"
        fill="url(#paint22_linear)"
      />
      <path
        opacity="0.3"
        d="M189.14 216.529C188.878 209.727 188.507 203.322 188.245 196.519C187.149 186.27 186.053 176.021 184.957 165.772C189.626 164.354 194.295 162.936 198.964 161.517C198.947 161.868 198.922 162.207 198.901 162.552C192.275 168.062 190.906 172.509 190.903 175.563C190.901 179.267 192.886 181.146 192.376 186.505C192.011 190.337 190.842 190.988 190.769 194.452C190.674 198.911 192.534 201.46 193.411 204.057C194.258 206.567 194.503 210.101 192.655 215.081C192.416 215.595 192.19 216.053 191.988 216.447C191.288 217.03 190.243 216.778 189.14 216.529Z"
        fill="url(#paint23_linear)"
      />
      <path
        d="M191.044 165.481C186.842 170.626 184.446 175.436 183.063 178.806C181.4 182.861 180.193 185.899 180.571 189.834C180.988 194.165 182.952 195.722 184.256 199.414C185.522 202.995 186.138 208.506 182.838 216.706C182.007 216.994 181.176 217.282 180.346 217.569C179.794 215.353 178.901 212.038 177.566 208.091C174.921 200.268 172.284 195.121 171.571 193.522C169.06 187.891 168.807 177.84 179.258 158.445C183.187 160.79 187.115 163.135 191.044 165.481Z"
        fill="url(#paint24_linear)"
      />
      <path
        d="M152.645 116.138C153.75 115.364 154.856 114.59 155.961 113.816C157.306 122.085 160.364 125.331 162.962 126.751C169.228 130.176 174.82 124.073 186.813 126.563C190.118 127.249 195.879 128.528 199.073 133.236C199.912 134.474 200.211 135.411 201.605 144.289C203.388 155.637 203.451 157.618 203.2 160.062C202.74 164.533 202.461 167.243 200.399 169.642C194.924 176.011 183.286 172.727 181.828 172.295C179.132 171.497 174.944 170.255 173.279 166.694C172.496 165.018 172.58 163.487 173.783 157.358C176.167 145.218 176.472 144.433 175.807 143.103C173.561 138.617 166.445 141.184 160.433 137.012C156.918 134.572 152.956 129.257 152.645 116.138Z"
        fill="url(#paint25_linear)"
      />
      <path
        opacity="0.3"
        d="M187.282 170.673C186.109 168.267 187.686 166.049 185.956 163.157C183.751 159.47 179.785 160.722 178.292 157.998C175.739 153.339 185.788 146.843 183.745 139.427C182.771 135.886 179.367 133.323 176.523 132.352C170.21 130.197 166.081 135.691 160.163 133.974C158.76 133.566 157.15 132.723 155.521 130.972C156.961 134.008 158.748 135.843 160.434 137.013C166.445 141.185 173.561 138.618 175.808 143.104C176.473 144.433 176.168 145.219 173.784 157.359C172.581 163.487 172.497 165.019 173.28 166.695C174.945 170.256 179.133 171.497 181.829 172.296C182.627 172.532 186.476 173.62 190.694 173.515C188.813 172.749 187.8 171.735 187.282 170.673Z"
        fill="url(#paint26_linear)"
      />
      <path
        d="M57.4491 44.9027C57.4491 57.5909 47.2251 68.5597 34.5746 69.4054C21.8805 70.2541 11.5586 60.595 11.5586 47.8285C11.5586 35.0621 21.8805 24.0872 34.5746 23.3168C47.2251 22.5494 57.4491 32.2154 57.4491 44.9027Z"
        fill="#9D3060"
      />
      <path
        d="M60.162 45.716C60.162 58.4042 49.938 69.373 37.2875 70.2187C24.5934 71.0674 14.2715 61.4082 14.2715 48.6418C14.2715 35.8753 24.5934 24.8998 37.2875 24.1301C49.9388 23.3627 60.162 33.0279 60.162 45.716Z"
        fill="url(#paint27_linear)"
      />
      <path
        d="M36.4803 59.6438C35.4854 59.7085 34.5599 59.2461 34.0448 58.4193L25.3955 44.5299C24.5852 43.2286 25.022 41.4453 26.3707 40.5483C27.7188 39.6514 29.4675 39.979 30.277 41.2803L36.2754 50.9222L44.1904 34.8022C44.9006 33.3555 46.6071 32.6921 48.0011 33.3194C49.3951 33.9467 49.9494 35.6269 49.2399 37.0728L39.0114 57.9215C38.543 58.8764 37.6122 59.5331 36.5797 59.637C36.5473 59.6393 36.5142 59.6415 36.4803 59.6438Z"
        fill="white"
      />
      <path
        d="M46.2112 223.057C46.2112 223.057 46.0433 224.393 46.1563 224.84C46.2692 225.288 50.1529 225.611 51.4708 225.455C52.7888 225.3 50.6854 223.953 49.8457 223.701C49.0052 223.448 46.2112 223.057 46.2112 223.057Z"
        fill="url(#paint28_linear)"
      />
      <path
        d="M48.1643 220.828L47.7546 223.509C47.7546 223.509 46.7108 223.978 46.2093 223.514L46.1543 220.709L48.1643 220.828Z"
        fill="url(#paint29_linear)"
      />
      <path
        d="M26.2702 220.709C26.2702 220.709 27.094 221.32 26.8741 222.052C26.6542 222.784 24.7316 225.719 23.361 226.552C21.9903 227.385 22.4203 225.65 22.8187 224.569C23.2179 223.488 24.2368 221.108 24.6344 220.709C25.0328 220.31 26.2702 220.709 26.2702 220.709Z"
        fill="url(#paint30_linear)"
      />
      <path
        d="M27.7525 217.764C27.3541 218.874 26.9708 219.788 26.671 220.461C26.0083 221.95 25.6453 222.509 25.2349 222.994C24.8772 223.416 24.0299 224.317 23.8017 224.167C23.5705 224.015 24.1903 222.92 25.3855 219.712C25.5587 219.247 26.1069 217.764 26.1069 217.764H27.7525Z"
        fill="url(#paint31_linear)"
      />
      <path
        d="M56.2991 171.367C56.583 174.158 56.8564 178.49 56.4482 183.747C55.9165 190.597 54.5474 194.931 52.8296 202.235C51.7798 206.698 50.3655 213.267 49.0852 221.483C48.7885 221.653 47.9141 222.1 46.7898 221.933C46.1602 221.84 45.6887 221.589 45.4109 221.409C42.0528 211.147 43.0446 204.788 44.6653 200.825C45.3559 199.136 46.1941 197.803 46.6414 195.208C47.2341 191.773 46.5013 189.852 45.4568 181.897C44.712 176.226 44.8408 175.349 45.3363 174.499C47.3154 171.099 53.3726 171.165 56.2991 171.367Z"
        fill="url(#paint32_linear)"
      />
      <path
        d="M44.7374 172.56C42.6799 183.184 40.6609 188.765 39.1329 191.921C38.7563 192.7 37.4986 195.21 36.3103 198.811C35.2582 201.998 34.8191 204.342 34.5947 205.374C33.8853 208.631 32.1321 213.386 27.4155 219.623C27.3567 219.658 26.5524 220.113 25.7835 219.707C25.3942 219.501 25.2006 219.175 25.123 219.022C25.7398 213.059 26.4477 208.048 27.0623 204.181C27.191 203.369 27.7589 199.821 28.649 195.132C28.9977 193.298 29.5083 190.609 30.1198 187.847C30.8812 184.409 32.0493 179.731 33.8491 174.125C37.4783 173.604 41.1075 173.082 44.7374 172.56Z"
        fill="url(#paint33_linear)"
      />
      <path
        d="M46.492 130.534C47.6585 129.456 49.8907 128.212 51.0106 125.322C51.3479 124.452 52.5416 121.37 50.7801 119.012C49.7348 117.612 47.7933 116.661 46.0816 116.998C44.6191 117.286 44.3901 118.304 41.9049 120.354C40.9379 121.152 39.3828 122.435 37.6522 123.147C34.6873 124.368 32.9062 123.219 29.93 123.35C26.2729 123.511 22.0255 125.552 19.9786 128.52C16.3464 133.787 21.6083 139.13 17.6297 145.3C16.3261 147.321 15.6837 146.869 12.5952 150.782C9.50304 154.7 7.95694 156.659 8.23257 159.06C8.61439 162.391 12.2036 165.575 15.5828 165.791C18.8843 166.003 20.9832 163.405 22.6634 164.542C23.8593 165.35 23.1755 166.923 24.4459 168.812C26.0839 171.249 29.3026 171.734 29.823 171.813C34.6488 172.541 41.1548 166.882 43.9187 160.178C48.9019 148.091 40.1487 136.398 46.492 130.534Z"
        fill="url(#paint34_linear)"
      />
      <path
        d="M66.2934 133.89C66.3265 130.516 58.6608 127.819 55.1536 126.951C49.6975 125.599 45.3288 126.448 44.2677 126.672C37.4635 128.113 33.2258 132.197 31.6308 133.912C32.9366 134.763 36.5409 137.358 38.3258 142.28C38.8815 143.812 40.5218 149.047 38.1751 153.952C36.8233 156.778 34.4315 158.9 32.615 160.512C29.8904 162.928 28.4798 163.3 27.5663 165.465C26.5715 167.823 27.5738 168.973 26.3388 170.587C25.0118 172.321 23.4582 171.515 21.2094 173.268C18.9057 175.064 19.7258 176.542 17.63 178.526C14.5845 181.409 10.5464 180.473 10.3581 181.771C10.1962 182.887 13.0662 184.401 15.504 185.015C20.3321 186.231 24.5434 184.19 25.7958 183.561C29.263 181.819 28.6643 180.691 31.3077 179.364C35.7344 177.141 38.7588 179.615 48.8412 179.869C54.3011 180.007 57.0612 180.038 58.5742 178.191C59.9554 176.504 59.2294 174.653 58.4002 166.677C58.0056 162.881 57.8633 159.063 57.5726 155.257C56.7502 144.493 56.7253 143.546 57.4551 142.057C60.0713 136.725 66.264 136.919 66.2934 133.89Z"
        fill="url(#paint35_linear)"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M17.6293 178.526C19.7251 176.542 18.9058 175.064 21.2087 173.268C23.4575 171.515 25.0118 172.321 26.338 170.587C27.5731 168.973 26.5707 167.823 27.5656 165.465C28.4791 163.3 29.8896 162.929 32.6143 160.512C34.4308 158.9 36.8226 156.779 38.1744 153.952C40.5211 149.048 38.8801 143.812 38.325 142.28C36.5402 137.357 32.9359 134.763 31.63 133.912C31.7558 133.777 31.9042 133.622 32.0623 133.46C40.6672 134.642 45.6843 139.614 46.3048 141.086C47.7387 144.49 45.4681 145.74 45.4101 155.555C45.3823 160.247 45.8884 162.098 44.3664 164.057C42.6252 166.297 40.619 165.603 38.1014 168.532C36.1667 170.782 36.4769 172.208 34.671 173.156C32.7288 174.175 31.4922 172.987 29.3015 173.454C25.6422 174.234 24.8597 178.454 22.44 180.763C20.6108 182.509 17.2279 183.729 10.557 182.419C10.4026 182.195 10.328 181.975 10.3581 181.77C10.5457 180.473 14.5837 181.409 17.6293 178.526Z"
          fill="url(#paint36_linear)"
        />
      </g>
      <path
        d="M43.6944 143.735C44.6094 145.677 40.4056 150.202 35.7342 152.35C34.7823 152.787 27.9472 155.931 24.9002 152.908C22.3826 150.411 24.0078 145.247 24.5876 143.404C27.4358 134.351 36.1823 129.746 39.8476 128.085C42.5828 134.925 40.972 137.973 40.3379 138.925C39.6751 139.919 37.8263 142.007 35.863 143.847C34.4818 145.141 33.6218 145.771 33.7377 145.972C33.8816 146.224 35.5007 145.75 41.7891 142.688C42.5188 142.806 43.3819 143.072 43.6944 143.735Z"
        fill="url(#paint37_linear)"
      />
      <path
        d="M70.0056 132.575L66.9202 147.94C66.8215 148.433 66.4397 148.819 65.9487 148.924L42.9432 153.862C42.1185 154.039 41.3564 153.372 41.4219 152.532L42.6359 136.904C42.6781 136.362 43.0621 135.909 43.5908 135.778C47.2403 134.878 61.287 131.505 68.6952 131.066C69.5221 131.017 70.169 131.762 70.0056 132.575Z"
        fill="url(#paint38_linear)"
      />
      <path
        d="M47.0592 121.353C47.0592 121.353 46.6254 124.822 45.3451 125.617C44.0648 126.412 41.9946 127.45 41.7664 127.732C41.5382 128.013 49.6987 128.891 53.7429 126.85C52.4099 126.572 52.0476 126.47 52.0476 126.47C51.6862 126.367 51.0114 126.177 50.3351 125.792C49.875 125.53 49.1324 125.018 48.4524 124.051L48.4674 122.129L47.0592 121.353Z"
        fill="url(#paint39_linear)"
      />
      <path
        d="M46.8656 122.582C46.8656 122.582 47.7008 123.728 49.0097 123.25C50.1822 122.822 49.4826 119.795 48.5525 119.449C48.2423 119.334 47.6729 119.385 47.2919 119.622C47.0238 119.79 46.3091 120.677 46.7188 121.673C46.4228 121.587 46.1622 121.666 46.0967 121.812C46.0139 121.998 46.2646 122.251 46.3211 122.309C46.5184 122.507 46.7557 122.563 46.8656 122.582Z"
        fill="url(#paint40_linear)"
      />
      <path
        opacity="0.3"
        d="M49.2858 145.86C51.7567 145.164 53.8058 146.893 55.7744 145.524C57.7912 144.122 56.4748 141.726 58.5714 139.371C60.8126 136.853 63.6563 138.087 65.7311 135.792C66.5015 134.94 67.1943 133.552 67.2049 131.185C67.7245 131.134 68.2223 131.094 68.693 131.066C69.5206 131.017 70.1676 131.762 70.0041 132.575L66.9187 147.94C66.8201 148.433 66.4382 148.819 65.9472 148.924L43.8379 153.669C45.3983 148.192 47.4814 146.368 49.2858 145.86Z"
        fill="url(#paint41_linear)"
      />
      <path
        d="M68.5416 140.749C68.5416 140.749 70.0094 139.393 70.169 137.817C70.3287 136.242 69.2209 134.98 68.1485 134.66C67.0753 134.338 66.503 134.812 67.2229 136.364C67.9429 137.915 69.0853 138.922 68.5416 140.749Z"
        fill="url(#paint42_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="151.901"
          y1="36.6075"
          x2="102.871"
          y2="163.373"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="145.471"
          y1="122.958"
          x2="120.13"
          y2="221.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="161.034"
          y1="103.647"
          x2="114.787"
          y2="194.583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="81.9005"
          y1="123.02"
          x2="49.6185"
          y2="126.542"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="110.343"
          y1="41.6467"
          x2="30.5194"
          y2="313.401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="27.9103"
          y1="118.196"
          x2="108.165"
          y2="135.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00359712" stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="80.433"
          y1="34.9533"
          x2="-13.3818"
          y2="289.035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="184.305"
          y1="152.32"
          x2="180.767"
          y2="131.095"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="176.027"
          y1="108.432"
          x2="176.568"
          y2="116.293"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003597" stopColor="#F36F56" />
          <stop offset="1" stopColor="#FFC444" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="149.06"
          y1="55.4313"
          x2="158.205"
          y2="62.2706"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="0.9964" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="128.991"
          y1="82.2657"
          x2="138.136"
          y2="89.105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="0.9964" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="119.236"
          y1="95.3079"
          x2="128.381"
          y2="102.147"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="0.9964" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="139.784"
          y1="69.7089"
          x2="147.43"
          y2="75.4269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="147.43"
          y1="75.427"
          x2="139.784"
          y2="69.7088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="191.027"
          y1="122.386"
          x2="190.792"
          y2="114.401"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003597" stopColor="#26264F" />
          <stop offset="1" stopColor="#444B8C" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="189.507"
          y1="108.46"
          x2="188.779"
          y2="135.728"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003597" stopColor="#F36F56" />
          <stop offset="1" stopColor="#FFC444" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="195.414"
          y1="118.64"
          x2="187.666"
          y2="123.394"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003597" stopColor="#F36F56" />
          <stop offset="1" stopColor="#FFC444" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="154.112"
          y1="115.763"
          x2="155.635"
          y2="120.627"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003597" stopColor="#F36F56" />
          <stop offset="1" stopColor="#FFC444" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="186.528"
          y1="226.174"
          x2="191.192"
          y2="209.997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="190.698"
          y1="222.979"
          x2="190.341"
          y2="197.098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1="181.567"
          y1="222.139"
          x2="181.21"
          y2="196.256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1="179.777"
          y1="223.142"
          x2="184.441"
          y2="206.964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint22_linear"
          x1="192.163"
          y1="167.101"
          x2="195.278"
          y2="220.548"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint23_linear"
          x1="192.162"
          y1="167.101"
          x2="195.277"
          y2="220.548"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint24_linear"
          x1="149.422"
          y1="189.808"
          x2="201.777"
          y2="186.704"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint25_linear"
          x1="176.758"
          y1="129.873"
          x2="186.191"
          y2="179.004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint26_linear"
          x1="169.096"
          y1="131.344"
          x2="178.529"
          y2="180.475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint27_linear"
          x1="14.2719"
          y1="47.1786"
          x2="60.1623"
          y2="47.1786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint28_linear"
          x1="49.5738"
          y1="214.464"
          x2="48.2313"
          y2="238.629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint29_linear"
          x1="48.164"
          y1="222.214"
          x2="46.1538"
          y2="222.214"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint30_linear"
          x1="25.2743"
          y1="213.115"
          x2="23.9319"
          y2="237.278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint31_linear"
          x1="27.7522"
          y1="220.974"
          x2="23.7543"
          y2="220.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint32_linear"
          x1="49.2442"
          y1="219.792"
          x2="49.9155"
          y2="193.839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint33_linear"
          x1="34.3193"
          y1="219.406"
          x2="34.9906"
          y2="193.453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint34_linear"
          x1="25.1603"
          y1="154.44"
          x2="34.5573"
          y2="141.127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="0.9964" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint35_linear"
          x1="53.8877"
          y1="150.465"
          x2="3.28525"
          y2="179.847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint36_linear"
          x1="35.3009"
          y1="126.24"
          x2="27.5447"
          y2="173.673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint37_linear"
          x1="36.9052"
          y1="126.503"
          x2="29.149"
          y2="173.935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.9964" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint38_linear"
          x1="41.4174"
          y1="142.478"
          x2="70.031"
          y2="142.478"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint39_linear"
          x1="43.3801"
          y1="123.531"
          x2="46.8399"
          y2="126.346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint40_linear"
          x1="41.1731"
          y1="118.698"
          x2="49.0565"
          y2="121.692"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint41_linear"
          x1="43.8369"
          y1="142.367"
          x2="70.0288"
          y2="142.367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint42_linear"
          x1="70.3754"
          y1="145.648"
          x2="67.4668"
          y2="130.806"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ConfirmPageIcon;
