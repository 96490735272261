import { ACTION_BACK, ACTION_NEXT, ACTION_GO_TO_SUMMARY, NavigationAction, NavigationProps } from '../components';

export const navigateTo = (action: NavigationAction, navigation: NavigationProps) => {
  if (action === ACTION_NEXT) {
    navigation.next();
  }

  if (action === ACTION_GO_TO_SUMMARY) {
    navigation.go('summary');
  }

  if (action === ACTION_BACK) {
    navigation.previous();
  }
};
