import React, { FC, MouseEventHandler } from 'react';
import DottedButton from '../DottedButton/DottedButton';
import Navigation from '../Navigation/Navigation';

import './ExtendedNavigation.scss';

interface ExtendedNavigationProps {
  onSubmit: any;
  onAddNew: MouseEventHandler<HTMLButtonElement>;
  addNewIsDisabled: boolean;
  addNewText: string;
  editMode?: boolean;
  saveAndFinishLaterMode?: boolean;
}

const ExtendedNavigation: FC<ExtendedNavigationProps> = ({
  onSubmit,
  onAddNew,
  addNewIsDisabled,
  addNewText,
  editMode,
  saveAndFinishLaterMode,
  children,
}) => {
  return (
    <Navigation onSubmit={onSubmit} editMode={editMode} saveAndFinishLaterMode={saveAndFinishLaterMode}>
      {children}
      <section className="extended-navigation extended-navigation_actions">
        <DottedButton onClick={onAddNew} disabled={addNewIsDisabled}>
          {addNewText}
        </DottedButton>
      </section>
    </Navigation>
  );
};

export default ExtendedNavigation;
