import { AxiosPromise } from 'axios';
import axiosInstance from '../axiosInstance';
import { CertificateListDto } from '../models/CertificateListDto';

const url = '/section';

export const putNewCertificates = (data: CertificateListDto): AxiosPromise<CertificateListDto> => {
  return axiosInstance({
    url: `${url}/certificates`,
    method: 'PUT',
    data,
  });
};
