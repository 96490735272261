import { combineReducers } from 'redux';
import { reducer as experienceReducer } from './experience';
import { reducer as candidateSkillsReducer } from './skills';
import { reducer as reportReducer } from './report';
import { reducer as candidateReportReducer } from './candidateReport';
import { reducer as educationReducer } from './education';
import { reducer as languageReducer } from './language';
import { reducer as certificateReducer } from './certificate';
import { reducer as degreeReducer } from './degree';
import { reducer as industryReducer } from './industry';

const rootReducer = combineReducers({
  candidateSkills: candidateSkillsReducer,
  report: reportReducer,
  experience: experienceReducer,
  candidateReport: candidateReportReducer,
  education: educationReducer,
  certiricate: certificateReducer,
  languages: languageReducer,
  degrees: degreeReducer,
  industries: industryReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
