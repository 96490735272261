import { AxiosPromise } from 'axios';
import { EducationListDto } from '../models/EducationListDto';
import { EducationDto } from '../models/EducationDto';
import axiosInstance from '../axiosInstance';

const url = '/section';

export const putNewEducation = (data: EducationListDto): AxiosPromise<EducationDto> => {
  return axiosInstance({
    url: `${url}/education`,
    method: 'PUT',
    data,
  });
};
