import { CandidateSkillDto } from '../api';

export enum SkillsFormGroup {
  skills = 'skills',
  other = 'other',
}

export interface SkillsForm {
  [key: string]: CandidateSkillDto[];
}

export interface SkillGroupOrder {
  [key: string]: number;
}
