import { createEntityAdapter, createReducer, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  Actions,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  isSkillsFromCandidateReportAction,
} from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { CandidateSkillDto, CandidateSkillListDto } from '../../api';

export interface CandidateSkillListState extends RequestStatus {
  skills: EntityState<CandidateSkillDto>;
}

export const adapter = createEntityAdapter<CandidateSkillDto>({
  selectId: ({ candidateId, skillId, skillGroupNameId }) => Number(`${skillId}${candidateId}${skillGroupNameId}`),
});

const initialState: CandidateSkillListState = {
  ...statusNotAsked,
  skills: adapter.getInitialState(),
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isPendingAction, (state: CandidateSkillListState) => ({
      ...state,
      ...statusIsLoading,
    }))
    .addMatcher(
      isSkillsFromCandidateReportAction,
      (state: CandidateSkillListState, action: PayloadAction<{ skills: CandidateSkillListDto }>) => ({
        ...state,
        ...statusIsSuccess,
        skills: adapter.setAll({ ...state.skills }, action.payload.skills),
      }),
    )
    .addMatcher(isFulfilledAction, (state: CandidateSkillListState, action: PayloadAction<CandidateSkillListDto>) => ({
      ...state,
      ...statusIsSuccess,
      skills: adapter.setAll({ ...state.skills }, action.payload),
    }))
    .addMatcher(isRejectedAction, (state: CandidateSkillListState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
});

export default reducer;

export type CandidateSkillsState = ReturnType<typeof reducer>;
