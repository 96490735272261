import React, { ButtonHTMLAttributes, FC } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderWrapper from '../../../../components/HeaderWrapper/HeaderWrapper';
import { HitBox } from '../../../../components';
import Pencil from '../../../../icons/Pencil';
import { EducationDto } from '../../../../api';
import './Education.scss';
import { KeyValueEntryDto } from '../../../../api/models/KeyValueEntryDto';
import { useSelector } from 'react-redux';
import { degreesSelector } from '../../../../store/degree';

interface EducationProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  model: EducationDto[];
}

const Education: FC<EducationProps> = ({ onClick, model }) => {
  const { t } = useTranslation();
  const dictDegrees = useSelector(degreesSelector.selectAll);
  const isEmpty = !model.length;

  const findDictValue = (dict: KeyValueEntryDto[], key: string): string => {
    const found = dict.find((d) => d.key === key);
    if (found) return found.value;

    return `-${key}-`;
  };

  return (
    <section className={`${isEmpty ? 'empty-section' : ''}`}>
      <HeaderWrapper stepName="Education">
        {!!onClick && (
          <HitBox onClick={onClick}>
            <Pencil className="blue" />
          </HitBox>
        )}
      </HeaderWrapper>
      {model.map((el: EducationDto, id: number) => (
        <section className="list" key={id}>
          <div className="list_item">
            <i className="list_tick-icon" />
            <p className="list_item--primary">
              {el.startYear} - {el.endYear ? el.endYear : t('COMMON.CURRENTLY')}
            </p>
          </div>
          <div className="list_item list_item--intended">
            <i className="list_pin-icon" />
            <p>{el.school}</p>
          </div>
          <div className="list_item list_item--intended">
            <i className="list_hat-icon" />
            <p>
              {!!el.fieldOfStudy ? el.fieldOfStudy + ', ' : ''}
              {findDictValue(dictDegrees, el.degreeKey)}
            </p>
          </div>
        </section>
      ))}
    </section>
  );
};

export default Education;
