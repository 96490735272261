import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { Actions, isFulfilledAction, isPendingAction, isRejectedAction } from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { CandidateReportDto } from '../../api';

export interface CandidateReportState extends RequestStatus {
  candidateReport: CandidateReportDto | Record<string, unknown>;
}

const initialState: CandidateReportState = {
  ...statusNotAsked,
  candidateReport: {},
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isPendingAction, (state: CandidateReportState) => ({
      ...state,
      ...statusIsLoading,
    }))
    .addMatcher(isFulfilledAction, (state: CandidateReportState, action: PayloadAction<CandidateReportDto>) => ({
      ...state,
      ...statusIsSuccess,
      candidateReport: action.payload,
    }))
    .addMatcher(isRejectedAction, (state: CandidateReportState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
});

export default reducer;

export type ReportState = ReturnType<typeof reducer>;
