import React, { CSSProperties, FC } from 'react';

interface CertificatesIconProps {
  className?: string;
}

const blendModeSoft = {
  mixBlendMode: 'soft-light',
} as CSSProperties;

const blendModeMultiply = {
  mixBlendMode: 'multiply',
} as CSSProperties;

const CertificatesIcon: FC<CertificatesIconProps> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="187"
      height="181"
      viewBox="0 0 187 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.0322 10.8467C40.4897 13.8865 36.6805 20.9136 27.4357 28.6929C16.3781 37.9994 12.3958 35.8828 7.24525 41.5918C-5.42527 55.6345 -0.651706 89.6626 15.1532 110.031C20.5462 116.979 25.0528 119.685 27.0485 127.546C31.335 144.429 16.2726 154.465 19.1367 162.814C25.5932 181.614 124.85 197.384 164.677 140.891C183.278 114.508 186.061 76.4098 169.441 47.4738C139.077 -5.3924 59.1284 -8.39486 43.0322 10.8467Z"
        fill="#F4F7FA"
      />
      <path
        d="M26.5806 91.1871L94.7698 95.3425L170.479 90.98L95.8081 114.654L26.5806 91.1871Z"
        fill="url(#paint0_linear_3159_4127)"
      />
      <path
        d="M81.7795 162.68L26.2929 141.041C20.8387 139.573 16.6011 134.169 16.6011 128.928V128.871C16.6011 123.63 20.8387 120.114 26.2929 121.076L85.709 137.644L81.7795 162.68Z"
        fill="#BFC8D6"
      />
      <path
        d="M170.571 144.671L84.3883 161.506C78.9077 162.121 74.3992 158.296 74.3992 152.96V149.341C74.3992 144.005 78.9025 139.321 84.3863 138.884L170.569 124.86C166.699 132.066 165.805 138.81 170.571 144.671Z"
        fill="url(#paint1_linear_3159_4127)"
      />
      <path
        d="M171.332 145.641L85.1211 162.629C77.9723 163.44 72.0479 158.433 72.0479 151.452V151.377C72.0479 144.397 77.9723 138.253 85.1211 137.698L171.332 123.808C171.859 123.767 172.285 124.206 172.285 124.788C172.285 125.37 171.859 125.877 171.332 125.919L85.1211 140.107C79.3697 140.575 74.6212 145.523 74.6212 151.13V151.205C74.6212 156.812 79.3697 160.851 85.1211 160.219L171.332 143.529C171.859 143.471 172.285 143.896 172.285 144.477C172.285 145.059 171.859 145.582 171.332 145.641Z"
        fill="#97A6B7"
      />
      <path
        d="M84.0549 137.702L26.2929 121.076C20.8387 120.114 16.6011 112.965 16.6011 105.096V105.009C16.6011 97.14 20.8387 90.8952 26.2929 91.0978L87.4865 100.384L84.0549 137.702Z"
        fill="url(#paint2_linear_3159_4127)"
      />
      <path
        d="M170.572 122.279L89.2938 135.572C81.1466 136.186 74.4019 130.2 74.4019 122.19V116.755C74.4019 108.743 81.1466 102.065 89.2938 101.843L170.572 92.5286C166.687 103.33 165.818 113.444 170.572 122.279Z"
        fill="url(#paint3_linear_3159_4127)"
      />
      <path
        d="M171.332 123.806L85.1211 137.696C77.9723 138.254 72.0479 130.188 72.0479 119.707V119.592C72.0479 109.111 77.9723 100.437 85.1211 100.258L171.332 91.0207C171.859 91.0078 172.285 91.7058 172.285 92.5795C172.285 93.4531 171.859 94.1743 171.332 94.1878L85.1211 103.878C79.3697 104.05 74.6212 111.042 74.6212 119.46V119.575C74.6212 127.993 79.3697 134.496 85.1211 134.077L171.332 120.637C171.859 120.599 172.285 121.276 172.285 122.15C172.285 123.024 171.859 123.765 171.332 123.806Z"
        fill="url(#paint4_linear_3159_4127)"
      />
      <g style={blendModeSoft} opacity="0.5">
        <path
          d="M66.5543 129.816L53.1639 126.192C50.3467 118.503 50.5584 111.44 53.1639 102.999L66.5543 105.373C64.0814 114.257 64.4558 122.302 66.5543 129.816Z"
          fill="white"
        />
      </g>
      <path
        d="M148.168 125.608C150.67 127.804 154.445 127.626 156.773 125.244C159.674 122.275 165.115 120.26 174.867 126.432C192.286 137.455 173.033 168.635 162.032 169.548C151.031 170.46 147.364 167.72 141.864 169.548C136.363 171.375 128.571 159.144 125.362 148.07C122.153 136.997 130.405 123.852 138.655 122.401C142.716 121.69 145.778 123.51 148.168 125.608Z"
        fill="url(#paint5_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M133.79 159.006L137.98 161.597C142.168 164.187 143.925 154.117 151.627 147.792C159.329 141.467 174.466 146.02 173.653 135.146C173.249 129.726 170.528 125.731 167.9 123.088C169.934 123.705 172.244 124.771 174.867 126.434C192.286 137.457 173.034 168.637 162.033 169.55C151.032 170.462 147.365 167.722 141.864 169.55C137.141 171.119 130.73 162.324 126.98 152.798L133.79 159.006Z"
        fill="url(#paint6_linear_3159_4127)"
      />
      <path
        d="M150.999 127.469C150.999 127.469 143.125 114.643 156.417 110.976C169.708 107.309 175.362 102.39 175.362 102.39C175.362 102.39 172 113.079 169.556 121.118C167.111 129.157 155.359 130.975 150.999 127.469Z"
        fill="url(#paint7_linear_3159_4127)"
      />
      <path
        d="M147.371 112.52C149.663 112.784 148.746 109.199 150.944 111.591C152.856 113.674 153.195 125.142 153.249 128.081C153.252 128.264 153.183 128.442 153.057 128.576C152.931 128.71 152.759 128.79 152.575 128.799C152.48 128.803 152.384 128.787 152.295 128.754C152.206 128.72 152.124 128.668 152.055 128.602C151.987 128.536 151.932 128.457 151.894 128.369C151.857 128.281 151.838 128.186 151.838 128.091C151.867 121.773 145.051 114.641 144.315 113.192C143.552 111.688 145.08 112.256 147.371 112.52Z"
        fill="url(#paint8_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M156.692 120.011C157.732 114.25 162.191 115.314 171.205 107.52C172.504 106.406 173.73 105.21 174.876 103.939C173.857 107.19 171.436 114.941 169.556 121.117C167.376 128.285 157.796 130.505 152.655 128.417C154.354 126.917 155.911 124.349 156.692 120.011Z"
        fill="url(#paint9_linear_3159_4127)"
      />
      <path
        d="M91.437 73.8152L143.439 60.8076L146.04 73.4415L98.4333 95.414L60.7383 86.1023L37.9995 63.776L91.437 73.8152Z"
        fill="url(#paint10_linear_3159_4127)"
      />
      <path
        d="M91.437 73.8152L143.439 60.8076L146.04 73.4415L98.4333 95.414L60.7383 86.1023L37.9995 63.776L91.437 73.8152Z"
        fill="url(#paint11_linear_3159_4127)"
      />
      <path
        d="M89.8027 91.3026C100.138 85.7615 107.771 80.1181 112.953 75.8522C123.147 67.4586 127.824 61.1268 138.144 58.6249C139.989 58.1746 150.496 55.6302 153.889 60.2976C157.644 65.4642 150.344 76.2691 149.457 77.5815C136.269 97.0984 102.694 96.1874 92.1612 95.555L89.8027 91.3026Z"
        fill="url(#paint12_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M153.889 60.2968C152.898 58.9336 151.292 58.1976 149.48 57.8335C150.083 59.9218 150.138 61.882 149.384 63.5946C147.326 68.2749 141.222 66.8331 136.937 72.3259C132.728 77.7203 135.642 82.9198 131.919 85.1444C127.655 87.6946 122.368 81.7348 117.8 84.5873C114.694 86.525 115.661 90.2017 112.041 92.2044C109.54 93.5882 107.088 92.934 104.239 92.5756C100.973 92.1652 96.4982 92.0777 90.8818 93.2511L92.1601 95.5543C102.692 96.1867 136.27 97.0983 149.456 77.5807C150.344 76.2683 157.647 65.4635 153.889 60.2968Z"
        fill="url(#paint13_linear_3159_4127)"
      />
      <path
        d="M97.617 94.2193C97.617 94.2193 99.5103 92.8612 102.259 93.7863C105.007 94.7114 109.371 95.459 109.364 96.8023C109.357 98.1456 104.989 98.9896 101.974 98.4351C99.0883 97.9062 96.8179 95.3979 97.617 94.2193Z"
        fill="url(#paint14_linear_3159_4127)"
      />
      <path
        d="M94.8407 91.9253L99.898 93.5523C99.898 93.5523 100.72 94.9213 99.6111 95.7892C98.5026 96.657 94.6497 94.8242 94.6497 94.8242L94.8407 91.9253Z"
        fill="url(#paint15_linear_3159_4127)"
      />
      <path
        d="M24.5409 68.6107C23.0477 77.3054 41.8126 86.7109 46.1088 88.8642C54.8633 93.2524 71.3366 99.4477 95.786 97.0809C96.1115 95.1303 96.4368 93.1788 96.7619 91.2265C92.1237 89.6366 87.5911 87.7538 83.1914 85.5896C73.7137 80.9319 70.1149 77.5994 56.9651 69.6471C42.4102 60.8475 38.1707 59.7191 33.8346 60.8076C30.1232 61.7392 25.2364 64.5705 24.5409 68.6107Z"
        fill="url(#paint16_linear_3159_4127)"
      />
      <path
        d="M44.0976 52.0762C40.926 40.6042 46.1614 25.1249 57.1947 17.6609C66.2014 11.5691 76.7521 11.9647 80.0454 12.0876C83.648 12.2227 83.2775 12.6911 94.6234 14.7896C110.375 17.704 111.829 16.9969 116.411 19.1933C125.027 23.3222 129.208 30.8248 129.99 32.2845C133.994 39.755 137.252 53.6433 129.648 60.4357C125.325 64.2957 119.264 64.2809 115.854 63.9187L115.157 59.3208C115.852 58.7836 119.946 55.5013 120.452 49.7068C121.031 43.0482 116.379 38.7501 115.854 38.2817C110.636 48.3343 109.38 59.9789 112.334 70.9131C108.168 73.1995 101.28 76.3023 92.3067 77.295C85.5465 78.0425 77.1761 78.9677 71.8248 74.786C64.2739 68.8853 63.6358 53.5172 71.0933 37.522C68.3636 39.3503 64.409 42.6584 62.7685 47.8951C62.5202 48.6871 62.1001 50.2407 61.9322 56.2546C61.6273 67.0621 47.3818 63.9644 44.0976 52.0762Z"
        fill="url(#paint17_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M95.2294 60.0183C91.4298 60.4841 90.3953 64.4291 86.866 64.2C79.1942 63.6982 73.0342 50.4159 76.2773 43.1629C78.39 38.4331 83.8983 37.6077 83.5226 34.5242C83.2215 32.057 79.3988 30.1585 76.2773 30.0658C71.4169 29.9217 68.2073 34.1543 67.0776 35.6391C64.4457 39.1099 63.8783 42.921 63.8191 45.3721C65.7491 41.5835 68.8262 39.0391 71.0836 37.5273C63.6261 53.5226 64.2643 68.8906 71.8151 74.7913C77.1664 78.973 85.5369 78.0472 92.297 77.3003C99.5796 76.4942 105.489 74.2991 109.685 72.2778C105.614 65.6379 100.422 59.3801 95.2294 60.0183Z"
        fill="url(#paint18_linear_3159_4127)"
      />
      <path
        d="M46.2456 44.432C49.6765 41.1844 58.0933 43.2302 61.2353 47.8964C63.5469 51.3311 61.6342 54.158 63.3255 58.4857C65.3977 63.7874 73.2143 68.5803 78.0181 70.1758C77.266 71.7951 77.4873 72.2139 76.7353 73.8331C73.6794 73.451 51.5911 70.0677 46.1877 56.9526C45.904 56.2694 42.5663 47.9144 46.2456 44.432Z"
        fill="url(#paint19_linear_3159_4127)"
      />
      <path
        d="M96.3835 10.1936C96.3835 10.1936 92.7879 14.0819 89.8035 14.4866C86.819 14.8912 82.5055 14.1006 81.7618 14.4866C81.0181 14.8726 85.5105 16.4726 89.0726 17.4498C92.6348 18.427 99.1035 20.3789 100.892 19.9511C102.681 19.5233 98.9376 17.2311 97.4553 16.6231C95.9731 16.0152 98.0948 12.8918 98.0948 12.8918L96.3835 10.1936Z"
        fill="url(#paint20_linear_3159_4127)"
      />
      <path
        d="M101.717 12.4204C103.018 12.0621 104.206 10.6023 104.191 9.08985C104.178 7.78838 103.264 6.9025 102.717 6.36853C101.133 4.82452 98.3785 3.85952 96.3816 4.87599C95.1985 5.47622 94.6581 8.2014 94.5384 8.67168C94.4033 9.20629 94.6118 9.5865 94.6671 10.1134C94.7469 10.8442 94.8633 11.999 95.0332 13.9091C95.4419 13.5798 95.8784 13.2867 96.3378 13.0329C96.5431 12.9203 97.355 12.4854 98.2325 12.2821C99.9933 11.8787 100.485 12.7601 101.717 12.4204Z"
        fill="url(#paint21_linear_3159_4127)"
      />
      <path
        d="M94.2904 11.8375C94.2904 11.8375 93.9887 14.5942 96.38 16.2006C98.5203 17.6379 102.34 13.2966 101.784 11.3235C101.599 10.6647 100.833 9.86055 99.9724 9.51379C99.4641 9.30857 99.1894 9.37354 98.364 9.02293C97.6049 8.70126 97.4801 8.49153 97.1648 8.50826C96.7705 8.52498 96.2159 8.88332 95.5854 10.5277C95.4999 9.83418 95.1493 9.32337 94.6983 9.22493C94.5377 9.19886 94.3731 9.21802 94.2229 9.28026C93.539 9.58199 93.5023 10.6152 94.2904 11.8375Z"
        fill="url(#paint22_linear_3159_4127)"
      />
      <path
        d="M97.2867 8.16267C97.3286 8.31192 96.7457 8.44702 96.3217 8.94882C95.5021 9.92348 95.5903 11.9185 96.552 12.823C97.1201 13.3576 97.5749 13.117 98.6751 13.7925C99.2095 14.1238 99.6873 14.5387 100.09 15.0213C99.559 16.3723 98.4094 17.2427 97.2764 17.1848C96.0541 17.1205 95.2988 16.0062 94.981 15.5392C94.7694 15.2271 94.0874 14.228 94.2553 13.0263C94.4297 11.7744 95.3201 11.6251 95.2049 10.5983C95.1464 10.0837 94.8684 9.66164 94.9476 8.80407C94.9638 8.61992 94.9944 8.43731 95.0389 8.25788C95.9943 8.10605 97.2263 7.94715 97.2867 8.16267Z"
        fill="url(#paint23_linear_3159_4127)"
      />
      <path
        d="M99.0386 14.8612C99.0386 14.8612 98.421 14.7519 97.6934 14.1227C97.658 14.0924 97.6091 14.0718 97.5892 14.0873C97.5165 14.1458 97.7551 14.8458 98.302 15.0658C98.688 15.2215 99.1724 15.1108 99.1885 15.0015C99.193 14.9545 99.1177 14.903 99.0386 14.8612Z"
        fill="url(#paint24_linear_3159_4127)"
      />
      <path
        d="M73.7141 72.6784L80.015 48L103.409 55.1764L94.6496 82.5639L73.7141 72.6784Z"
        fill="url(#paint25_linear_3159_4127)"
      />
      <path
        d="M124.275 47.2949L103.409 55.1764L94.6497 82.5639L114.711 70.5001L124.275 47.2949Z"
        fill="url(#paint26_linear_3159_4127)"
      />
      <path
        d="M117.514 62.8811C117.514 62.8811 120.988 60.6668 121.364 58.0953C121.739 55.5239 119.122 53.4633 116.584 52.9409C114.046 52.4185 112.691 53.1899 114.396 55.7221C116.101 58.2542 118.801 59.8986 117.514 62.8811Z"
        fill="url(#paint27_linear_3159_4127)"
      />
      <path
        d="M74.6463 72.9068C74.6463 72.9068 70.8866 70.9124 70.177 68.3751C69.4674 65.8378 71.8239 63.626 74.2944 62.9486C76.7648 62.2711 78.2187 62.9589 76.8433 65.5862C75.4678 68.2136 72.9742 70.015 74.6463 72.9068Z"
        fill="url(#paint28_linear_3159_4127)"
      />
      <path
        d="M48.2458 127.653C48.2458 127.653 56.3519 136.203 54.7654 141.634C53.1789 147.066 40.4634 137.867 40.4634 137.867L48.2458 127.653Z"
        fill="url(#paint29_linear_3159_4127)"
      />
      <path
        d="M43.197 118.953C43.776 118.622 45.3316 117.067 45.2242 115.297C45.1515 114.089 44.3287 113.734 44.3518 112.466C44.3788 111.024 45.4526 110.804 45.9036 109.243C46.4292 107.422 45.7382 105.056 44.3177 103.885C43.1726 102.942 42.4855 103.542 40.578 102.406C38.8925 101.401 39.0051 100.679 37.9275 100.251C35.8289 99.4142 34.4341 101.766 31.4298 102.202C27.2539 102.808 25.8179 98.8712 22.701 99.797C19.8349 100.648 17.5819 105.008 18.1976 108.113C18.4446 109.357 19.0146 109.85 18.8506 111.088C18.5991 112.99 16.9135 113.166 16.2875 115.206C15.8147 116.75 16.083 118.914 17.2924 119.8C18.7078 120.839 20.3232 119.318 21.7379 120.328C23.2613 121.417 22.1413 123.719 23.6544 124.903C25.0832 126.021 27.6128 125.239 29.0115 124.616C30.697 123.866 32.1722 122.722 33.7638 121.789C36.7193 120.056 40.2885 120.626 43.197 118.953Z"
        fill="url(#paint30_linear_3159_4127)"
      />
      <path
        d="M31.5289 157.014L51.5438 152.008L52.5448 156.87L34.2213 165.327L19.7134 161.743L10.9614 153.15L31.5289 157.014Z"
        fill="url(#paint31_linear_3159_4127)"
      />
      <path
        d="M50.6596 131.554C51.33 129.922 46.7282 125.453 40.9588 123.801C36.7706 122.602 33.2201 123.271 31.373 123.597C25.3816 124.654 21.6503 127.65 20.2433 128.909C21.393 129.532 24.5665 131.436 26.1389 135.047C27.6578 138.538 26.5718 141.747 26.0063 143.61C25.5135 145.233 24.7647 147.7 22.5497 149.485C21.3794 150.428 20.087 150.906 17.5027 151.866C14.4546 152.994 12.5644 153.305 12.578 153.917C12.596 154.694 15.6917 155.332 15.5836 155.815C15.5759 155.851 15.5527 155.854 15.5495 155.879C15.5084 156.218 18.9856 157.051 23.3815 157.712C28.5211 158.484 31.0906 158.87 33.5462 158.451C37.376 157.796 36.732 156.617 40.5702 155.956C45.0356 155.184 47.064 156.584 51.1061 155.031C51.7868 154.77 54.5113 153.72 54.4335 152.629C54.337 151.278 49.8426 151.741 45.9305 148.285C44.7458 147.246 43.7787 145.983 43.085 144.568C42.7125 143.796 41.1473 139.79 42.717 135.433C42.7848 135.242 42.8737 135.059 42.9821 134.888C44.8851 131.64 50.026 133.094 50.6596 131.554Z"
        fill="url(#paint32_linear_3159_4127)"
      />
      <path
        d="M31.0207 138.532C31.9858 140.393 28.6037 145.787 23.8913 146.248C21.3372 146.497 18.8166 145.257 17.3067 143.382C14.9759 140.487 15.6984 136.884 15.9493 135.626C17.4386 128.185 26.1867 125.088 26.9883 124.818C29.1171 128.116 29.083 130.197 28.6333 131.499C28.2628 132.572 27.2933 133.72 25.3523 136.014C23.6249 138.058 22.7687 138.785 23.0254 139.408C23.3297 140.147 24.983 140.202 26.031 139.987C27.972 139.592 29.0348 138.03 29.312 137.593C29.8473 137.68 30.6888 137.893 31.0207 138.532Z"
        fill="url(#paint33_linear_3159_4127)"
      />
      <path
        d="M33.8329 118.982C33.8329 118.982 33.4508 122.037 32.323 122.736C31.1953 123.436 29.3727 124.351 29.1707 124.602C28.9687 124.854 36.1554 125.622 39.7163 123.826C38.5428 123.581 38.2237 123.49 38.2237 123.49C37.6985 123.354 37.192 123.154 36.7157 122.894C36.0559 122.512 35.4896 121.988 35.0579 121.36L35.0707 119.669L33.8329 118.982Z"
        fill="url(#paint34_linear_3159_4127)"
      />
      <path
        d="M32.6524 121.045C32.6524 121.045 34.7426 123.522 37.6505 122.219C40.2541 121.053 38.1652 114.263 35.9868 113.628C35.2611 113.415 33.9706 113.628 33.1414 114.228C32.5578 114.653 31.0724 116.795 32.1699 118.998C31.4803 118.851 30.8987 119.071 30.7739 119.416C30.615 119.854 31.2287 120.391 31.3677 120.51C31.7291 120.819 32.1786 121.006 32.6524 121.045Z"
        fill="url(#paint35_linear_3159_4127)"
      />
      <path
        d="M34.936 163.745C38.0947 162.061 41.0793 160.069 43.8462 157.798C47.7706 154.568 49.572 152.131 53.542 151.168C54.2497 150.995 58.2969 150.015 59.6022 151.811C61.0472 153.799 58.2377 157.958 57.8961 158.463C52.8214 165.975 39.8974 165.625 35.8438 165.381L34.936 163.745Z"
        fill="url(#paint36_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M59.6021 151.811C59.2206 151.286 58.6023 151.003 57.905 150.863C58.1366 151.667 58.1578 152.421 57.8677 153.08C57.0751 154.881 54.7262 154.327 53.0774 156.441C51.4574 158.517 52.5788 160.518 51.1473 161.375C49.5062 162.356 47.4713 160.063 45.7131 161.16C44.5178 161.906 44.8896 163.321 43.4961 164.091C42.5311 164.624 41.5899 164.373 40.493 164.234C39.236 164.077 37.5144 164.041 35.3528 164.492C35.5162 164.788 35.68 165.083 35.8443 165.378C39.8973 165.622 52.822 165.973 57.8966 158.46C58.2363 157.958 61.0457 153.799 59.6021 151.811Z"
        fill="url(#paint37_linear_3159_4127)"
      />
      <path
        d="M37.9424 164.867C37.9424 164.867 38.6713 164.344 39.729 164.701C40.7866 165.057 42.4664 165.344 42.4638 165.859C42.4612 166.373 40.7808 166.7 39.619 166.487C38.5086 166.287 37.6349 165.321 37.9424 164.867Z"
        fill="url(#paint38_linear_3159_4127)"
      />
      <path
        d="M36.8741 163.987L38.8209 164.613C38.8209 164.613 39.1374 165.139 38.7102 165.474C38.283 165.809 36.8008 165.101 36.8008 165.101L36.8741 163.987Z"
        fill="url(#paint39_linear_3159_4127)"
      />
      <path
        d="M9.81768 155.011C9.24253 158.356 16.4653 161.977 18.1167 162.806C21.4865 164.495 27.8266 166.88 37.2367 165.969C37.3624 165.218 37.4876 164.467 37.6124 163.717C35.8273 163.105 34.0829 162.38 32.3898 161.546C28.7414 159.752 27.3563 158.471 22.2939 155.41C16.6924 152.02 15.0615 151.588 13.3927 152.008C11.9651 152.366 10.0847 153.456 9.81768 155.011Z"
        fill="url(#paint40_linear_3159_4127)"
      />
      <path
        d="M39.2432 132.399L31.4312 128.619L38.6288 126.684C38.7947 126.639 38.9681 126.628 39.1383 126.652L46.3315 127.661L39.2432 132.399Z"
        fill="url(#paint41_linear_3159_4127)"
      />
      <path
        d="M39.8191 140.002L46.3316 137.444C46.5574 137.356 46.7395 137.058 46.7395 136.78V128.076C46.7395 127.797 46.5574 127.611 46.3316 127.661L39.8191 129.1C39.536 129.165 39.3044 129.47 39.3044 129.785V139.633C39.3051 139.948 39.536 140.113 39.8191 140.002Z"
        fill="url(#paint42_linear_3159_4127)"
      />
      <path
        d="M31.5664 138.343L39.4582 140.016C39.7419 140.076 39.9729 139.869 39.9729 139.553V129.675C39.9729 129.359 39.7419 129.087 39.4582 129.068L31.5664 128.545C31.3406 128.53 31.1592 128.745 31.1592 129.024V137.751C31.1592 138.031 31.3406 138.296 31.5664 138.343Z"
        fill="url(#paint43_linear_3159_4127)"
      />
      <path
        d="M46.7393 135.123C46.7393 135.123 48.0312 133.929 48.172 132.541C48.3129 131.153 47.3357 130.043 46.3926 129.76C45.4494 129.478 44.9438 129.894 45.5781 131.261C46.2124 132.627 47.218 133.514 46.7393 135.123Z"
        fill="url(#paint44_linear_3159_4127)"
      />
      <path
        d="M33.6764 139.106C33.6764 139.106 31.9169 139.07 30.8908 138.126C29.8646 137.181 29.8576 135.703 30.3761 134.865C30.8946 134.026 31.5495 134.003 31.9812 135.444C32.4129 136.884 32.2488 138.22 33.6764 139.106Z"
        fill="url(#paint45_linear_3159_4127)"
      />
      <path
        d="M45.4392 94.3376C45.4392 94.3376 43.4404 94.9114 41.9144 95.5477C40.3884 96.184 40.4469 95.2048 40.6785 94.0384C40.9101 92.8721 43.0151 93.5514 43.0151 93.5514C43.0151 93.5514 46.4004 93.2574 45.4392 94.3376Z"
        fill="url(#paint46_linear_3159_4127)"
      />
      <path
        d="M37.4434 61.595C37.5128 64.2713 37.8255 66.9359 38.3775 69.5556C38.8729 71.8974 39.1617 72.302 39.3161 73.9219C39.6526 77.4461 38.5164 77.9505 38.308 81.9341C38.0777 86.3326 39.2473 89.8285 39.7073 91.1621C40.032 92.1043 40.4124 93.0263 40.8466 93.9233C41.1168 94.1594 41.4835 94.1761 41.8914 94.1221C42.0677 94.097 42.2299 94.0118 42.3505 93.8808C42.4712 93.7499 42.5428 93.5812 42.5534 93.4035C42.7863 89.6374 43.0681 86.0617 43.2939 82.2737C44.0659 76.2723 44.8379 70.2706 45.6099 64.2687L37.4434 61.595Z"
        fill="url(#paint47_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M42.5545 93.3687C42.7868 89.6155 43.0692 86.0501 43.2931 82.2737C44.0651 76.2723 44.8371 70.2706 45.6091 64.2687L37.4387 61.595C37.4445 61.8015 37.4548 62.0003 37.4632 62.2029C41.2827 65.52 42.0309 68.148 41.9929 69.9403C41.9479 72.1142 40.759 73.1918 40.9912 76.3441C41.1572 78.5958 41.8346 78.9947 41.8346 81.0282C41.8346 83.6473 40.7101 85.1192 40.162 86.6324C39.6331 88.0953 39.4446 90.1668 40.4669 93.1133C40.6014 93.4176 40.7281 93.6923 40.8413 93.9233C41.1038 94.153 41.4576 94.1755 41.852 94.1266C42.039 94.1017 42.2115 94.0125 42.3397 93.8741C42.4679 93.7358 42.5439 93.5571 42.5545 93.3687Z"
        fill="url(#paint48_linear_3159_4127)"
      />
      <path
        d="M51.08 95.5034C51.08 95.5034 49.0805 96.0772 47.5551 96.7135C46.0298 97.3497 46.0877 96.3706 46.3193 95.2042C46.5509 94.0378 48.6552 94.7172 48.6552 94.7172C48.6552 94.7172 52.0405 94.4232 51.08 95.5034Z"
        fill="url(#paint49_linear_3159_4127)"
      />
      <path
        d="M43.084 62.7607C43.1534 65.4371 43.4661 68.1016 44.0181 70.7214C44.5135 73.0631 44.8023 73.4684 44.9567 75.0877C45.2926 78.6119 44.1571 79.1163 43.9486 83.0998C43.7183 87.499 44.8879 90.9949 45.3472 92.3279C45.672 93.27 46.0524 94.1921 46.4866 95.0891C46.7574 95.3252 47.1235 95.3419 47.532 95.2879C47.7081 95.2627 47.8701 95.1775 47.9906 95.0467C48.1111 94.9159 48.1827 94.7474 48.1934 94.5699C48.4263 90.8032 48.708 87.2275 48.9345 83.4402C49.7065 77.4387 50.4785 71.437 51.2505 65.4351L43.084 62.7607Z"
        fill="url(#paint50_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M48.1961 94.5345C48.4284 90.7813 48.7108 87.2159 48.9347 83.4395C49.7067 77.438 50.4787 71.4364 51.2507 65.4344L43.0803 62.7607C43.0855 62.9673 43.0964 63.166 43.1041 63.3687C46.9243 66.6857 47.6718 69.3138 47.6345 71.1061C47.5888 73.2799 46.4006 74.3575 46.6328 77.5099C46.7988 79.7616 47.4762 80.1604 47.4762 82.194C47.4762 84.813 46.3517 86.285 45.8036 87.7988C45.2747 89.2611 45.0862 91.3326 46.1085 94.2791C46.2423 94.5834 46.3658 94.8581 46.4823 95.0891C46.7454 95.3187 47.0986 95.3413 47.493 95.2924C47.6801 95.2676 47.8527 95.1784 47.981 95.0401C48.1094 94.9017 48.1855 94.7229 48.1961 94.5345Z"
        fill="url(#paint51_linear_3159_4127)"
      />
      <path
        d="M47.7524 37.2016C50.9543 37.7381 53.3893 38.3152 54.4431 40.1352C55.5593 42.0575 53.6962 43.5616 53.1146 47.843C52.2242 54.398 55.6108 56.768 54.4958 63.3725C54.3312 64.3442 54.089 65.3011 53.7715 66.2341C53.2969 67.6508 52.3839 68.8799 51.1646 69.7435C50.153 70.4557 49.0629 71.0496 47.9158 71.5133C44.6348 72.8302 43.0387 72.1393 38.6485 72.506C31.4682 73.1068 30.3495 75.4068 27.1373 74.0751C23.8897 72.7292 21.5937 68.9547 22.3193 67.3059C22.7542 66.3183 24.0564 66.9018 25.96 65.6602C28.2837 64.1458 28.1107 62.4094 30.0516 60.1005C32.8752 56.7416 35.2485 58.3107 37.67 55.3437C39.3691 53.2625 39.5891 50.7908 39.9719 46.4791C40.3797 41.8896 39.7042 38.523 41.5365 37.5174C41.9772 37.2755 42.5015 37.1469 43.1352 37.1668C45.6229 37.2434 47.4886 37.1578 47.7524 37.2016Z"
        fill="url(#paint52_linear_3159_4127)"
      />
      <path
        d="M47.5571 35.0361L48.9454 38.1956C48.9454 38.1956 46.8938 39.2249 45.0159 38.1956V35.0361H47.5571Z"
        fill="url(#paint53_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M27.5638 71.5875C28.5706 70.8425 28.0727 68.7999 29.5646 67.6786C30.949 66.6376 31.988 67.9359 34.0126 67.2205C36.6098 66.2999 36.5274 63.5831 39.0171 62.6625C40.8069 62.0005 41.3196 63.2318 43.8833 63.2312C46.447 63.2305 49.8213 62.003 52.0042 58.9851C52.8397 57.8162 53.4368 56.494 53.7611 55.0942C54.3987 57.5016 55.0967 59.8266 54.4984 63.3714C54.2873 64.6129 53.9506 65.8297 53.4935 67.003C53.1532 67.8908 52.5776 68.6692 51.8286 69.2547C50.635 70.1828 49.3192 70.942 47.9183 71.5109C44.6373 72.8278 43.0412 72.1369 38.6511 72.5036C31.4708 73.1045 30.352 75.4044 27.1398 74.0727C25.0638 73.2125 23.3821 71.3617 22.6243 69.7064C25.4151 71.9059 26.833 72.1285 27.5638 71.5875Z"
        fill="url(#paint54_linear_3159_4127)"
      />
      <path
        d="M47.8393 28.7732C47.8393 28.7732 49.4946 31.8799 49.5988 34.0595C49.703 36.2391 47.2429 36.0809 47.2429 36.0809L44.1871 35.9734C44.1871 35.9734 43.0882 29.1779 47.8393 28.7732Z"
        fill="url(#paint55_linear_3159_4127)"
      />
      <path
        d="M47.0238 34.2366C47.5384 35.9864 46.7066 37.6803 45.9944 39.1311C44.6067 41.954 40.9867 45.4583 37.3647 45.0562C33.3213 44.6059 32.853 39.6895 29.121 39.5177C25.9905 39.3736 25.3433 42.7904 21.2639 43.6396C16.0953 44.7159 11.3893 40.4274 10.8309 39.9044C7.26231 36.559 4.95016 30.7883 6.83771 25.7355C7.03071 25.2208 8.92727 20.3276 12.5055 19.8104C15.5292 19.3742 16.6486 22.5047 21.3926 22.9023C24.4928 23.1596 25.0384 21.9109 27.189 22.6449C30.6103 23.8119 30.2301 27.3175 32.9855 28.0547C35.84 28.8216 37.1041 25.2832 42.3866 24.8329C43.1161 24.7686 47.3744 24.4109 48.5697 26.6343C49.0503 27.5291 49.1101 28.9818 48.5099 30.0369C47.8897 31.1273 46.8861 31.2264 46.6371 32.3014C46.4653 33.049 46.8198 33.5456 47.0238 34.2366Z"
        fill="url(#paint56_linear_3159_4127)"
      />
      <path
        d="M68.41 42.1901C63.5168 45.7536 62.2513 44.8278 60.6192 44.0996C59.3055 43.5116 58.3566 42.2339 54.4837 39.7088C53.7876 39.2585 53.2015 38.8956 52.7994 38.6531C51.0946 41.3602 48.8751 41.945 47.167 44.6522C47.167 44.6522 61.8113 57.5189 69.3036 44.0089L68.41 42.1901Z"
        fill="url(#paint57_linear_3159_4127)"
      />
      <path
        d="M68.4805 42.5819C68.4805 42.5819 68.4303 41.5525 69.0312 40.8712C69.6321 40.1899 71.3936 41.1408 71.5537 41.7269C71.7139 42.3129 69.0312 43.7006 69.0312 43.7006L68.4805 42.5819Z"
        fill="url(#paint58_linear_3159_4127)"
      />
      <path
        d="M130.173 144.85C130.173 144.85 130.992 145.23 130.956 145.671C130.919 146.113 130.88 146.578 130.2 147.61C129.521 148.642 129.417 149.138 128.978 149.09C128.539 149.042 128.633 145.78 130.173 144.85Z"
        fill="url(#paint59_linear_3159_4127)"
      />
      <path
        d="M129.642 143.633C129.669 143.715 130.191 144.593 130.49 145.092C130.549 145.191 130.575 145.307 130.563 145.421C130.55 145.536 130.501 145.644 130.423 145.728C130.309 145.851 130.153 145.925 129.986 145.936C129.819 145.946 129.655 145.893 129.526 145.785C129.076 145.411 128.423 144.845 128.062 144.439L129.642 143.633Z"
        fill="url(#paint60_linear_3159_4127)"
      />
      <path
        d="M146.572 127.147C146.986 125.505 142.121 123 141.474 122.666C140.2 122.01 127.954 115.87 122.84 121.339C118.535 125.943 122.459 135.376 123.226 137.224C124.839 141.1 127.154 143.79 128.79 145.399L130.53 143.852C130.481 141.613 130.337 139.751 130.208 138.39C130.015 136.378 129.838 134.563 129.372 132.218C128.633 128.505 128.041 127.868 128.532 127.28C129.614 125.981 133.019 128.471 139.039 128.739C141.563 128.851 146.195 128.646 146.572 127.147Z"
        fill="url(#paint61_linear_3159_4127)"
      />
      <path
        d="M145.159 156.694C145.191 156.716 145.225 156.735 145.26 156.752C145.486 156.873 145.997 157.165 146.11 157.808C146.249 158.596 145.546 159.882 144.645 161.391C143.744 162.9 143.576 161.112 143.607 160.833C143.639 160.555 144.283 156.861 144.283 156.861L145.159 156.694Z"
        fill="url(#paint62_linear_3159_4127)"
      />
      <path
        d="M143.108 156.915L144.959 157.804C144.959 157.804 145.392 157.595 145.088 157.022L144.321 155.585L143.108 156.915Z"
        fill="url(#paint63_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.3"
        d="M141.474 122.666C140.296 122.069 139.089 121.532 137.857 121.058C137.513 121.859 137.071 122.298 136.67 122.544C134.824 123.672 132.883 121.599 129.356 122.312C128.455 122.494 125.98 122.994 125.077 124.796C123.999 126.949 126.356 128.581 126.012 132.939C125.833 135.201 125.154 135.32 125.418 136.916C125.834 139.435 127.673 140.036 128.061 142.238C128.162 142.817 128.161 143.564 127.897 144.467C128.209 144.806 128.512 145.123 128.791 145.397L130.531 143.85C130.482 141.612 130.338 139.75 130.209 138.389C130.016 136.377 129.84 134.562 129.373 132.217C128.635 128.503 128.043 127.867 128.533 127.279C129.616 125.98 133.02 128.47 139.04 128.738C141.563 128.85 146.196 128.645 146.573 127.145C146.986 125.505 142.121 123 141.474 122.666Z"
        fill="url(#paint64_linear_3159_4127)"
      />
      <path
        d="M148.461 122.254C145.937 122.583 137.145 124.041 132.22 131.487C131.659 132.335 130.578 133.996 130.106 136.119C128.576 142.981 134.171 151.307 143.372 157.538L144.95 155.621C144.778 153.714 144.284 150.954 142.737 148.085C140.652 144.225 138.274 143.161 138.252 140.458C138.228 137.347 141.351 134.977 141.943 134.547C146.345 131.352 150.578 133.984 154.446 131.008C156.45 129.468 157.351 127.195 157.798 125.371L148.461 122.254Z"
        fill="url(#paint65_linear_3159_4127)"
      />
      <path
        d="M146.003 92.146C145.863 91.4138 145.324 91.3932 145.208 90.7152C145.063 89.864 145.732 88.8495 146.559 88.4101C147.369 87.9829 147.735 88.4275 149.501 88.4101C151.398 88.3914 151.693 87.871 153.237 88.0884C153.934 88.1721 154.608 88.3881 155.224 88.7247C155.888 89.094 157.203 89.8274 157.371 91.1887C157.553 92.6773 156.226 93.7877 156.099 93.8907C156.601 95.618 156.386 96.3502 156.019 96.6725C156.019 96.6725 155.629 97.0147 153.634 96.9941C153.634 96.9941 153.071 97.6934 152.744 97.927C152.371 98.1927 151.646 98.3239 150.084 97.5809C150.045 97.1177 149.976 96.6576 149.877 96.2035C149.646 95.1439 149.53 94.6138 149.234 94.5141C148.494 94.2612 147.505 96.8487 146.505 96.6583C145.861 96.5361 145.361 95.2932 145.445 94.2908C145.528 93.2885 146.164 92.9778 146.003 92.146Z"
        fill="url(#paint66_linear_3159_4127)"
      />
      <path
        d="M142.224 113.454C142.314 113.475 142.705 113.605 142.859 115.236C143.231 119.169 141.183 120.761 141.688 122.892C142.172 124.939 144.64 125.933 147.181 126.956C148.879 127.639 154.129 129.752 157.184 127.376C158.471 126.375 158.611 125.168 159.527 120.73C161.858 109.454 162.76 108.485 161.779 106.043C160.096 101.851 155.591 100.115 154.841 99.8266C146.848 96.7463 137.407 102.407 133.679 108.91C133.188 109.766 130.255 114.88 132.316 117.306C133.562 118.774 136.428 119.01 138.319 118.01C140.846 116.675 141.383 113.261 142.224 113.454Z"
        fill="url(#paint67_linear_3159_4127)"
      />
      <path
        d="M133.488 116.438C133.463 116.438 133.437 116.432 133.414 116.421C133.392 116.411 133.373 116.397 133.357 116.38C133.34 116.363 133.328 116.342 133.32 116.32C133.311 116.298 133.307 116.274 133.308 116.251C133.309 116.227 133.314 116.204 133.324 116.182C133.393 116.032 135.043 112.491 138.857 111.094C138.902 111.078 138.951 111.081 138.994 111.101C139.036 111.122 139.069 111.158 139.086 111.202C139.102 111.247 139.1 111.296 139.08 111.339C139.061 111.382 139.025 111.416 138.981 111.432C135.314 112.776 133.67 116.297 133.654 116.332C133.639 116.364 133.616 116.391 133.587 116.409C133.557 116.428 133.523 116.438 133.488 116.438Z"
        fill="url(#paint68_linear_3159_4127)"
      />
      <path
        style={blendModeMultiply}
        opacity="0.45"
        d="M156.554 104.607C156.049 105.98 157.562 107.17 156.914 108.66C155.984 110.796 152.288 109.7 150.967 111.812C149.401 114.317 152.987 118.425 151.508 119.831C150.834 120.474 150.055 119.651 148.084 120.101C145.277 120.744 144.434 122.96 143.13 122.624C142.72 122.518 142.222 122.145 141.803 120.998C141.62 121.635 141.534 122.239 141.689 122.894C142.172 124.941 144.641 125.935 147.182 126.957C148.88 127.641 154.13 129.754 157.185 127.378C158.472 126.377 158.612 125.17 159.528 120.732C161.859 109.455 162.761 108.487 161.78 106.045C161.245 104.714 160.429 103.631 159.534 102.764C157.609 103.191 156.818 103.885 156.554 104.607Z"
        fill="url(#paint69_linear_3159_4127)"
      />
      <path
        d="M149.168 98.1405C149.199 98.322 149.192 98.5079 149.15 98.687C149.107 98.866 149.028 99.0346 148.919 99.1826C148.809 99.3305 148.671 99.4549 148.512 99.5481C148.354 99.6413 148.178 99.7015 147.995 99.7251C147.063 99.8435 145.922 100.024 145.414 100.244C144.481 100.648 148.095 100.462 151.367 100.748C153.411 100.927 154.959 100.867 155.833 100.797C155.884 100.794 155.933 100.773 155.971 100.739C156.009 100.705 156.035 100.659 156.044 100.609C156.052 100.559 156.044 100.507 156.02 100.462C155.996 100.417 155.957 100.381 155.91 100.361C155.123 100.005 153.841 99.4665 152.736 99.2027C151.367 98.8746 150.219 95.647 150.219 95.647L148.91 96.5953L149.168 98.1405Z"
        fill="url(#paint70_linear_3159_4127)"
      />
      <path
        d="M151.369 97.2159C151.369 97.2159 149.773 99.0288 147.336 98.4344C145.153 97.9017 145.702 92.8946 147.298 92.0923C147.83 91.8254 148.797 91.8453 149.525 92.1953C149.955 92.4018 150.076 92.6167 150.785 92.9403C151.439 93.2388 151.646 93.1976 151.822 93.408C152.043 93.6711 152.138 94.2385 151.451 95.6095C151.947 95.2569 152.48 95.1829 152.81 95.4165C152.921 95.5046 153.005 95.6217 153.054 95.7549C153.262 96.3718 152.619 97.0036 151.369 97.2159Z"
        fill="url(#paint71_linear_3159_4127)"
      />
      <path
        d="M151.722 113.22C151.937 113.413 154.124 115.343 156.754 114.685C159.045 114.112 160.452 111.885 160.698 110.01C161.284 105.553 155.477 101.812 156.195 100.91C156.621 100.373 158.908 101.413 160.43 102.352C162.01 103.326 166.28 105.954 167.19 111.123C167.267 111.562 168.271 117.72 164.756 120.264C161.507 122.616 155.138 121.333 150.827 115.774L151.722 113.22Z"
        fill="url(#paint72_linear_3159_4127)"
      />
      <path
        d="M136.452 106.82L136.611 106.559C136.679 106.298 136.839 106.07 137.063 105.919C137.286 105.767 137.557 105.702 137.824 105.736L138.661 105.839C138.722 105.846 138.782 105.866 138.836 105.898C138.889 105.93 138.935 105.972 138.972 106.023C139.008 106.074 139.033 106.131 139.046 106.192C139.059 106.253 139.059 106.316 139.047 106.377C138.969 106.75 138.769 107.196 138.255 107.161C137.384 107.103 136.826 108.373 136.826 108.373L136.491 107.902C136.379 107.745 136.316 107.559 136.309 107.367C136.302 107.174 136.352 106.984 136.452 106.82Z"
        fill="url(#paint73_linear_3159_4127)"
      />
      <path
        d="M155.271 116.614L155.469 116.382C155.696 116.236 155.862 116.012 155.936 115.753C156.011 115.494 155.989 115.216 155.874 114.972L155.516 114.21C155.489 114.154 155.451 114.104 155.404 114.063C155.357 114.023 155.303 113.992 155.243 113.973C155.184 113.955 155.121 113.949 155.06 113.956C154.998 113.962 154.938 113.982 154.884 114.013C154.554 114.206 154.193 114.532 154.385 115.009C154.712 115.819 153.677 116.746 153.677 116.746L154.23 116.917C154.414 116.974 154.611 116.976 154.796 116.922C154.981 116.868 155.147 116.761 155.271 116.614Z"
        fill="url(#paint74_linear_3159_4127)"
      />
      <path
        d="M146.215 110.911L137.681 106.782L145.544 104.671C145.725 104.622 145.915 104.61 146.101 104.636L153.958 105.738L146.215 110.911Z"
        fill="url(#paint75_linear_3159_4127)"
      />
      <path
        d="M146.844 119.217L153.958 116.423C154.205 116.326 154.403 116.002 154.403 115.697V106.189C154.403 105.884 154.205 105.681 153.958 105.738L146.844 107.309C146.534 107.377 146.282 107.712 146.282 108.057V118.815C146.282 119.158 146.534 119.339 146.844 119.217Z"
        fill="url(#paint76_linear_3159_4127)"
      />
      <path
        d="M137.828 117.405L146.448 119.236C146.758 119.3 147.011 119.075 147.011 118.729V107.936C147.011 107.591 146.758 107.293 146.448 107.273L137.828 106.701C137.582 106.685 137.383 106.92 137.383 107.225V116.758C137.382 117.063 137.582 117.353 137.828 117.405Z"
        fill="url(#paint77_linear_3159_4127)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3159_4127"
          x1="26.5806"
          y1="102.817"
          x2="170.479"
          y2="102.817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3159_4127"
          x1="74.4011"
          y1="143.213"
          x2="170.571"
          y2="143.213"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.49" stopColor="#EAEDF3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3159_4127"
          x1="96546.3"
          y1="86473.7"
          x2="87779.9"
          y2="131382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3159_4127"
          x1="153757"
          y1="96094.4"
          x2="297517"
          y2="96094.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.49" stopColor="#EAEDF3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3159_4127"
          x1="156588"
          y1="104369"
          x2="312766"
          y2="104369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3159_4127"
          x1="144.407"
          y1="131.367"
          x2="175.118"
          y2="173.079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3159_4127"
          x1="86567.8"
          y1="116614"
          x2="65590.9"
          y2="150132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3159_4127"
          x1="164.444"
          y1="98.9986"
          x2="156.193"
          y2="138.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A1F094" />
          <stop offset="1" stopColor="#4FC3BA" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3159_4127"
          x1="143.111"
          y1="108.707"
          x2="158.931"
          y2="130.193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3159_4127"
          x1="167.251"
          y1="99.5886"
          x2="159.002"
          y2="138.853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#38E8C7" />
          <stop offset="1" stopColor="#425F63" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3159_4127"
          x1="155567"
          y1="30677.8"
          x2="155375"
          y2="42547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3159_4127"
          x1="92.6118"
          y1="56.9174"
          x2="91.4968"
          y2="78.9317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_3159_4127"
          x1="119803"
          y1="63084.7"
          x2="185784"
          y2="63084.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_3159_4127"
          x1="117824"
          y1="62671.4"
          x2="182713"
          y2="62671.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_3159_4127"
          x1="25393.8"
          y1="10591.4"
          x2="24757.6"
          y2="8758.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_3159_4127"
          x1="11202.4"
          y1="8217.28"
          x2="10215.6"
          y2="6538.29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_3159_4127"
          x1="103158"
          y1="60796.5"
          x2="98218.7"
          y2="80798.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_3159_4127"
          x1="175472"
          y1="100050"
          x2="162203"
          y2="41328.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_3159_4127"
          x1="80591.6"
          y1="38975.4"
          x2="82431.5"
          y2="67484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_3159_4127"
          x1="49397.3"
          y1="51452.5"
          x2="41662.2"
          y2="24671.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_3159_4127"
          x1="37586.8"
          y1="6120.84"
          x2="37073.6"
          y2="8585.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_3159_4127"
          x1="7586.13"
          y1="1763.53"
          x2="9816.84"
          y2="405.598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_3159_4127"
          x1="17974.1"
          y1="7901.75"
          x2="16277.5"
          y2="10362.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_3159_4127"
          x1="5344.67"
          y1="319.038"
          x2="7500.08"
          y2="-500.035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_3159_4127"
          x1="98.8717"
          y1="14.1631"
          x2="96.7635"
          y2="16.3408"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_3159_4127"
          x1="37161.8"
          y1="56903.2"
          x2="46061.1"
          y2="17889.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_3159_4127"
          x1="58115.3"
          y1="22278.1"
          x2="44086.1"
          y2="37783.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_3159_4127"
          x1="17388"
          y1="17003.9"
          x2="16948.8"
          y2="19807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_3159_4127"
          x1="12060.8"
          y1="16894.4"
          x2="14703.2"
          y2="23487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_3159_4127"
          x1="48.1307"
          y1="141.998"
          x2="49.7937"
          y2="154.999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.1" stopColor="#FEBC46" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_3159_4127"
          x1="42.1093"
          y1="95.7595"
          x2="24.8339"
          y2="120.175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_3159_4127"
          x1="31.9805"
          y1="150.51"
          x2="31.5521"
          y2="158.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#893976" />
          <stop offset="1" stopColor="#311944" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_3159_4127"
          x1="-91534.1"
          y1="96228.6"
          x2="-92596.5"
          y2="106017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_3159_4127"
          x1="-36763.8"
          y1="57824.4"
          x2="-37786.8"
          y2="63614.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.1" stopColor="#FEBC46" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_3159_4127"
          x1="34.2402"
          y1="114.591"
          x2="34.4673"
          y2="125.366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_3159_4127"
          x1="-13477.1"
          y1="15226.4"
          x2="-10941.6"
          y2="15836.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_3159_4127"
          x1="24724.4"
          y1="43056.8"
          x2="34498.7"
          y2="43056.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#893976" />
          <stop offset="1" stopColor="#311944" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_3159_4127"
          x1="24313.8"
          y1="42722.6"
          x2="33925.4"
          y2="42722.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#893976" />
          <stop offset="1" stopColor="#311944" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_3159_4127"
          x1="5056.79"
          y1="6221.72"
          x2="4962.81"
          y2="5950.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_3159_4127"
          x1="2274"
          y1="4962.35"
          x2="2127.77"
          y2="4713.59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_3159_4127"
          x1="23004.8"
          y1="41725.2"
          x2="22273.1"
          y2="44688.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#893976" />
          <stop offset="1" stopColor="#311944" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_3159_4127"
          x1="13890.5"
          y1="14341.7"
          x2="17341.6"
          y2="14341.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_3159_4127"
          x1="7864.76"
          y1="31526.4"
          x2="8723.95"
          y2="31526.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_3159_4127"
          x1="8191.69"
          y1="29319.5"
          x2="9399.17"
          y2="29319.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA80F9" />
          <stop offset="1" stopColor="#6165D7" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_3159_4127"
          x1="-5608.71"
          y1="14410.9"
          x2="-5982.13"
          y2="13400"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_3159_4127"
          x1="29591.3"
          y1="804.922"
          x2="29144.2"
          y2="-71.6757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_3159_4127"
          x1="42.4551"
          y1="98.1799"
          x2="42.9269"
          y2="74.5907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_3159_4127"
          x1="-21779.9"
          y1="50205.5"
          x2="-25057.6"
          y2="65534.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_3159_4127"
          x1="-21789.5"
          y1="50205.6"
          x2="-25066.1"
          y2="65534.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_3159_4127"
          x1="11211"
          y1="4970.22"
          x2="11211"
          y2="4092.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_3159_4127"
          x1="-21071.3"
          y1="50720.9"
          x2="-24347.9"
          y2="66050.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_3159_4127"
          x1="-21080.6"
          y1="50721"
          x2="-24356"
          y2="66051"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_3159_4127"
          x1="-76456.9"
          y1="61589.8"
          x2="-79302.6"
          y2="78110.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_3159_4127"
          x1="-8535.28"
          y1="3694.52"
          x2="-8407.85"
          y2="3918.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_3159_4127"
          x1="-172707"
          y1="14786.7"
          x2="-178629"
          y2="25030.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_3159_4127"
          x1="-13206.4"
          y1="7128.84"
          x2="-13722.5"
          y2="7716.26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_3159_4127"
          x1="-100872"
          y1="29984.7"
          x2="-104721"
          y2="16088.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_3159_4127"
          x1="-80745.6"
          y1="-10396.2"
          x2="-82596.5"
          y2="-7099.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_3159_4127"
          x1="-5523.03"
          y1="3385.39"
          x2="-5556.59"
          y2="3607.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_3159_4127"
          x1="128.885"
          y1="146.588"
          x2="130.343"
          y2="147.397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_3159_4127"
          x1="2705.29"
          y1="5495.29"
          x2="2672.52"
          y2="5627.64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_3159_4127"
          x1="65025.3"
          y1="64988.1"
          x2="71278.1"
          y2="79433.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_3159_4127"
          x1="-4104"
          y1="5786.62"
          x2="-4005.79"
          y2="5881.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_3159_4127"
          x1="4210.95"
          y1="5888.47"
          x2="4118"
          y2="6116.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#A03976" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_3159_4127"
          x1="18245.8"
          y1="43835.6"
          x2="21710.5"
          y2="48480.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_3159_4127"
          x1="76351"
          y1="92311.4"
          x2="83363.6"
          y2="100163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444B8C" />
          <stop offset="1" stopColor="#26264F" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_3159_4127"
          x1="-1910.8"
          y1="-6717.95"
          x2="-2298.92"
          y2="-9534.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#6B3976" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_3159_4127"
          x1="76185.7"
          y1="65694.9"
          x2="90721.3"
          y2="65694.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_3159_4127"
          x1="14683.6"
          y1="12013.7"
          x2="15204.5"
          y2="12013.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_3159_4127"
          x1="54483.7"
          y1="58013.7"
          x2="61056.6"
          y2="58013.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_3159_4127"
          x1="29252.6"
          y1="10441"
          x2="31058.9"
          y2="10441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.1" stopColor="#FEBC46" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_3159_4127"
          x1="19649.4"
          y1="12949.7"
          x2="20454.8"
          y2="12949.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.1" stopColor="#FEBC46" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint72_linear_3159_4127"
          x1="46267.7"
          y1="45086.4"
          x2="50527.2"
          y2="45086.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint73_linear_3159_4127"
          x1="7171.16"
          y1="5712.99"
          x2="7288.46"
          y2="5712.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.1" stopColor="#FEBC46" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint74_linear_3159_4127"
          x1="6670.52"
          y1="6882.04"
          x2="6752.89"
          y2="6882.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="0.1" stopColor="#FEBC46" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint75_linear_3159_4127"
          x1="42159.2"
          y1="13500.1"
          x2="46277.5"
          y2="13500.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint76_linear_3159_4127"
          x1="22197.1"
          y1="29911.9"
          x2="23222.2"
          y2="29911.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint77_linear_3159_4127"
          x1="24947.7"
          y1="27846.4"
          x2="26388.6"
          y2="27846.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CertificatesIcon;
