import React, { ButtonHTMLAttributes, FC, Fragment, useEffect, useState } from 'react';
import HeaderWrapper from '../../../../components/HeaderWrapper/HeaderWrapper';
import { HitBox } from '../../../../components';
import Pencil from '../../../../icons/Pencil';
import Description from '../Description/Description';
import SingleDescription from '../Description/SingleDescription';
import './Experience.scss';
import { ExperienceDto } from '../../../../api';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getJobTitles } from '../../../../api/services/jobTitle';
import { DictionaryListDto } from '../../../../api/models/DictionaryListDto';
import { DictionaryItemDto } from '../../../../api/models/DictionaryItemDto';

interface ExperienceProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  model: ExperienceDto[];
  aboutMe?: string;
}

const formatExperienceLenght = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, t: (key: string) => string) => {
  const experienceYears = endDate.diff(startDate, 'year');
  const experienceMonths = endDate.diff(startDate, 'month') % 12;
  const yr = t('EXPERIENCE.YEAR_ABBREVIATION');
  const mth = t('EXPERIENCE.MONTH_ABBREVIATION');

  return (
    (experienceYears > 0 ? `${experienceYears} ${yr} ` : '') +
    (experienceMonths > 0 ? `${experienceMonths} ${mth}` : '')
  );
};

const Experience: FC<ExperienceProps> = ({ onClick, model }) => {
  const isEmpty = !model.length;
  const date_options = { year: 'numeric', month: 'numeric' } as const;
  const { t } = useTranslation();
  const [jobTitles, setJobTitles] = useState<DictionaryListDto>();

  useEffect(() => {
    getJobTitles().then((jobTitles) => {
      setJobTitles(jobTitles.data);
    });
  }, []);

  const getJobTitle = (jobKey: string) =>
    jobTitles?.items?.find((o: DictionaryItemDto) => o.key === jobKey)?.value || '';

  return (
    <section className={`${isEmpty ? 'empty-section' : ''}`}>
      <HeaderWrapper stepName="Experience">
        {!!onClick && (
          <HitBox onClick={onClick}>
            <Pencil className="blue" />
          </HitBox>
        )}
      </HeaderWrapper>
      <Fragment>
        {model?.map((el, index) => {
          return (
            <div key={index} className="itm-experience">
              <div className="itm-experience_col place">
                <h4 className="place_title">{getJobTitle(el?.jobTitle)}</h4>
                <div className="place_item">
                  <i className="place_pin-icon" />
                  <p>{el?.company}</p>
                </div>
                <div className="place_item">
                  <i className="place_calendar-icon" />
                  <p>
                    <span>{dayjs(el?.startDate).toDate().toLocaleDateString(undefined, date_options)}</span>
                    {dayjs(el?.startDate).toDate() > new Date() && <span> - n/a </span>}
                    {el?.endDate && !el?.currentlyWork && (
                      <span> - {dayjs(el?.endDate).toDate().toLocaleDateString(undefined, date_options)}</span>
                    )}
                    {!el?.endDate && el?.currentlyWork && <span> - {t('COMMON.PRESENT')}</span>}
                  </p>
                </div>
                <div className="place_item experience_length">
                  <i>
                    {formatExperienceLenght(
                      dayjs(el?.startDate),
                      el.endDate ? dayjs(el.endDate).add(1, 'month') : dayjs(),
                      t,
                    )}
                  </i>
                </div>
              </div>
              <div>
                {el?.businessProjects.map((project, index) => (
                  <div key={index} className="itm-experience_col mb-5">
                    {el?.businessProjects.length > 1 && (
                      <SingleDescription label="Role" description={project.role || el.jobTitle} />
                    )}
                    <SingleDescription label="Industry" description={project.industry} />
                    <SingleDescription
                      label="Business project description"
                      description={project.businessProjectDescription}
                    />
                    {project.startDate && (
                      <div className="description-container">
                        <h5 className="description-container_secondary-title">Duration</h5>
                        <div className="place_item_multiline">
                          {project.currentlyWorking && (
                            <div className="place_item">
                              <p>{t('EXPERIENCE.ENTRY.CURRENTLY_WORKING_PROJECT')}</p>
                            </div>
                          )}
                          <div>
                            <div className="place_item">
                              <i className="place_calendar-icon" />
                              <p>
                                <span>
                                  {dayjs(project?.startDate).toDate().toLocaleDateString(undefined, date_options)}
                                </span>
                                {dayjs(project?.startDate).toDate() > new Date() && <span> - n/a </span>}
                                {project?.endDate && (
                                  <span>
                                    {' '}
                                    -
                                    {project.currentlyWorking
                                      ? t('COMMON.PRESENT')
                                      : dayjs(project?.endDate).toDate().toLocaleDateString(undefined, date_options)}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="place_item experience_length">
                              <i>
                                {formatExperienceLenght(
                                  dayjs(project.startDate),
                                  dayjs(project.endDate).add(1, 'month'),
                                  t,
                                )}
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <Description label="Responsibilities" list={[project.responsibilities]} dot_style={true} />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </Fragment>
    </section>
  );
};

export default Experience;
