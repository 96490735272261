import React from 'react';
import ReactDOM from 'react-dom';
import { Spinner as Loader } from 'react-bootstrap';
import './Spinner.scss';

const Spinner = () => {
  return ReactDOM.createPortal(
    <div className="spinner">
      <Loader animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Loader>
    </div>,
    document.body,
  );
};

export default Spinner;
