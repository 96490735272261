export interface RequestStatus {
  isPending: boolean;
  isNotAsked: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  error?: any;
}

export const statusNotAsked: RequestStatus = {
  isPending: false,
  isNotAsked: true,
  isSuccess: false,
  isFailure: false,
  error: {},
};

export const statusIsLoading: RequestStatus = {
  isPending: true,
  isNotAsked: false,
  isSuccess: false,
  isFailure: false,
};

export const statusIsFailure: RequestStatus = {
  isPending: false,
  isNotAsked: false,
  isSuccess: false,
  isFailure: true,
};

export const statusIsSuccess: RequestStatus = {
  isPending: false,
  isNotAsked: false,
  isSuccess: true,
  isFailure: false,
};
