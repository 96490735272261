import React, { FC } from 'react';
import './Paper.scss';

export type PaperProps = {
  className?: string;
  variant?: 'landing-page' | 'default';
};

const Paper: FC<PaperProps> = ({ className = '', children, variant = 'default' }) => {
  const paperVariant = variant ? `paper_${variant}` : '';

  return (
    <div className={`paper ${paperVariant} ${className}`}>
      <article className="paper_content">{children}</article>
    </div>
  );
};

export default Paper;
