import { ErrorOption, ValidateResult } from 'react-hook-form';
import { months } from '../../../constants/dates';
import dayjs from 'dayjs';

type DateObject = {
  year?: string;
  month: string;
  isOngoing?: boolean;
};

type ErrorConfig = {
  message: string;
  fieldNames: string[];
};

export type DateValidationConfig = {
  parentStartDate?: DateObject;
  parentEndDate?: DateObject;
  startDate: DateObject;
  endDate: DateObject;
  error: {
    startDate: ErrorConfig;
    endDate: ErrorConfig;
    futureDate: string;
    parentStartDate?: ErrorConfig;
    parentEndDate?: ErrorConfig;
  };
  setError: (name: string, error: ErrorOption) => void;
  clearErrors: (name?: string | string[] | undefined) => void;
};

export const validateDate = (changed: 'startDate' | 'endDate', config: DateValidationConfig): ValidateResult => {
  const validateSecond = changed === 'startDate' ? 'endDate' : 'startDate';

  const validationResult = dateValidation(config, changed);
  dateValidation(config, validateSecond);

  return validationResult;
};

const dateValidation = (config: DateValidationConfig, focus: 'startDate' | 'endDate'): ValidateResult => {
  const { startDate, endDate, error, setError, clearErrors } = config;

  if (config[focus].year && isFutureDate(config[focus])) {
    return addError(error[focus].fieldNames[0], error.futureDate, setError);
  } else if (!config['endDate'].isOngoing && endDateBeforeOrEqualStartDate(startDate, endDate)) {
    return addError(error[focus].fieldNames[0], error[focus].message, setError);
  } else if (config.parentStartDate && focus === 'startDate') {
    const parentStartDate = dayjs()
      .set('year', Number(config.parentStartDate.year))
      .set('month', months.indexOf(config.parentStartDate.month));
    const projectStartDate = dayjs()
      .set('year', Number(config.startDate.year))
      .set('month', months.indexOf(config.startDate.month));
    if (projectStartDate.isBefore(parentStartDate)) {
      return addError(error[focus].fieldNames[0], error?.parentStartDate?.message ?? 'Error', setError);
    }
  } else if (config.parentEndDate && focus === 'endDate') {
    const parentEndDate = dayjs()
      .set('year', Number(config.parentEndDate.year))
      .set('month', months.indexOf(config.parentEndDate.month));
    const projectEndDate = dayjs()
      .set('year', Number(config.endDate.year))
      .set('month', months.indexOf(config.endDate.month));
    if (projectEndDate.isAfter(parentEndDate, 'month')) {
      return addError(error[focus].fieldNames[0], error?.parentEndDate?.message ?? 'Error', setError);
    }
  }

  error[focus].fieldNames.map((fieldName) => clearErrors(fieldName));

  return undefined;
};

const isFutureDate = (date: DateObject) => {
  if (!date.year) {
    return false;
  }

  const fullDate = new Date().setFullYear(+date.year, months.indexOf(date.month));

  return new Date(fullDate) > new Date();
};

const endDateBeforeOrEqualStartDate = (startDate: DateObject, endDate: DateObject) => {
  const { year: startYearValue, month: startMonthValue } = startDate;
  const { year: endYearValue, month: endMonthValue } = endDate;
  return (
    !!endYearValue &&
    !!startYearValue &&
    (+endYearValue < +startYearValue ||
      (+endYearValue == +startYearValue &&
        (months.indexOf(startMonthValue) > months.indexOf(endMonthValue) ||
          months.indexOf(startMonthValue) == months.indexOf(endMonthValue))))
  );
};

const addError = (field: string, message: string, setError: (name: string, error: ErrorOption) => void) => {
  setError(field, { message });
  return message;
};
