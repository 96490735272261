import React, { ChangeEvent, useEffect } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { KeyValueEntryDto } from '../../api/models/KeyValueEntryDto';
interface SelectProps {
  options: Array<string | number> | KeyValueEntryDto[];
  name: string;
  emptyOption?: string;
  className?: string;
  registerOptions?: RegisterOptions;
  disabled?: boolean;
}

const Select = ({ name, options, emptyOption, registerOptions, ...rest }: SelectProps) => {
  const { register, watch, setValue } = useFormContext();

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(name, e.target.value, { shouldValidate: true });
  };

  const newOptions: any[] = [];

  if (emptyOption) {
    newOptions.push(
      <option key="" value="">
        {emptyOption}
      </option>,
    );
  }

  options.forEach((option: string | number | KeyValueEntryDto) => {
    if (option === undefined) return;
    if (typeof option === 'string' || typeof option === 'number') {
      newOptions.push(
        <option key={option} value={option}>
          {option}
        </option>,
      );
    } else {
      newOptions.push(
        <option key={option.key} value={option.key}>
          {option.value}
        </option>,
      );
    }
  });

  if (newOptions.length === 0) return <div></div>;

  const value = watch(name, name);

  useEffect(() => value || setValue(name, newOptions[0].key), []);

  return (
    <select {...register(name, registerOptions)} value={value} onChange={handleOnChange} {...rest}>
      {newOptions}
    </select>
  );
};

export default Select;
