import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { ReportState } from './reducer';

export const selectCandidateReport = ({ candidateReport }: RootState) => candidateReport;
export const selectReport = ({ report }: ReportState) => report;
export const selectError = ({ error }: ReportState) => error;

export const candidateReportSelector = createSelector(selectCandidateReport, selectReport);

export const candidateReportErrorSelector = createSelector(selectCandidateReport, selectError);
