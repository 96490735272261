import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';
import { Steps } from '../PageRoute/PageRoute.types';
import './PaperHeader.scss';

interface PaperHeaderProps {
  stepName: string;
  currentStep: Steps;
  icon: ReactNode;
  text: string;
  subText?: string;
  subTextTitle?: string;
  hoverHint?: {
    headerText: string;
    body: string;
  };
  isRequired?: boolean;
}

const PaperHeader: FC<PaperHeaderProps> = ({
  currentStep,
  icon,
  stepName,
  text,
  subText,
  subTextTitle,
  hoverHint,
  isRequired,
}) => {
  const { t } = useTranslation();
  return (
    <section className="header-container">
      <section className="header">
        {icon}
        <HeaderWrapper stepName={stepName} hoverHint={hoverHint} isRequired={isRequired}>
          <span>
            <span className="header-content--step">Step {Object.keys(Steps).indexOf(currentStep.toUpperCase())}</span> /{' '}
            {Object.keys(Steps).length - 3}
          </span>
        </HeaderWrapper>
        <span className="header_text">
          <p>{t(text)}</p>
          {!!subTextTitle && <p className="header_subtextTitle">{t(subTextTitle)}</p>}

          {!!subText && <p className="header_subtext">{t(subText)}</p>}
        </span>
      </section>
    </section>
  );
};

export default PaperHeader;
