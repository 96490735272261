import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getIndustries } from '../../api';

export enum Actions {
  getIndustries = '[INDUSTRY] GET INDUSTRIES',
}

export const getIndustriesThunk = createAsyncThunk(Actions.getIndustries, async () => {
  const { data } = await getIndustries();

  return data;
});

export const isPendingIndustriesAction = isPending(getIndustriesThunk);
export const isFulfilledIndustriesAction = isFulfilled(getIndustriesThunk);
export const isRejectedIndustriesAction = isRejected(getIndustriesThunk);
