import React, { FC, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useModal from './useModal';

const useSimpleModal = (modalTitle: string) => {
  const [Modal, openModal] = useModal(modalTitle);

  const SimpleModal = useMemo(() => {
    const ModalComponent: FC = ({ children }) => {
      return (
        <Modal>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Modal>
      );
    };

    return ModalComponent;
  }, [Modal]);

  return [SimpleModal, openModal] as const;
};

export default useSimpleModal;
