import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CandidateReportDto } from '../../../../api';
import { candidateReportSelector } from '../../../../store/candidateReport';
import TextareaAutosize from 'react-textarea-autosize';
import './AboutMeForm.scss';
import { CharacterCount } from '../../../../components';
import { ErrorMessage } from '@hookform/error-message';

export const AboutMeForm: FC = () => {
  const defaultReportValues = useSelector(candidateReportSelector) as CandidateReportDto;
  const description: string = defaultReportValues?.description;
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const aboutMe = `aboutMe`;
  const value = watch(aboutMe);
  const { t } = useTranslation();
  const MAX_ALLOWED_CHARACTERS = 1000;
  const MIN_REQUIRED_CHARACTERS = 5;

  const [characterCount, setCharacterCount] = useState(
    errors?.aboutme || value?.length ? value?.length : description?.length ?? 0,
  );

  return (
    <section className={'aboutme-container'}>
      <section className="aboutme">
        <div className="aboutme-container aboutme-container_input mt-4">
          <TextareaAutosize
            {...register(aboutMe, {
              onChange: (e) => {
                setCharacterCount(e.target.value.length);
              },
              maxLength: {
                value: MAX_ALLOWED_CHARACTERS,
                message: t('ABOUT.ABOUT_ME_OVER_1000'),
              },
              minLength: {
                value: MIN_REQUIRED_CHARACTERS,
                message: t('ABOUT.ABOUT_ME_LESS_5'),
              },
              required: { value: true, message: t('ABOUT.ABOUT_ME_LESS_5') },
            })}
            id={aboutMe}
            defaultValue={description}
            className="aboutMeTextArea"
          />
          <div className={'aboutMe-section mt-4'}>
            <span className={'text-left w-75'}>
              <ErrorMessage
                errors={errors}
                name={aboutMe}
                render={({ message }) => <span className={'aboutme-container_error'}>{message}</span>}
              />
            </span>
            <CharacterCount limit={MAX_ALLOWED_CHARACTERS} count={characterCount}></CharacterCount>
          </div>
        </div>
      </section>
    </section>
  );
};
