import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { EducationListDto, putNewEducation } from '../../api';
import { getCandidateReportWithSectionsThunk } from '../candidateReport';

export enum Actions {
  putNewEducation = '[EDUCATION] PUT NEW EDUCATION',
}

export const putNewEducationThunk = createAsyncThunk(Actions.putNewEducation, async (education: EducationListDto) => {
  const { data } = await putNewEducation(education);

  return data;
});

export const isPendingAction = isPending(putNewEducationThunk);
export const isFulfilledAction = isFulfilled(putNewEducationThunk);
export const isEducationFromCandidateReportAction = isFulfilled(getCandidateReportWithSectionsThunk);
export const isRejectedAction = isRejected(putNewEducationThunk);
