import { createSelector } from '@reduxjs/toolkit';
import { EducationDto } from '../../api';
import { RootState } from '../rootReducer';
import { adapter, EducationsState } from './reducer';
import { RequestStatus } from '../Store.types';

const selectEducation = ({ education }: RootState) => education;
const selectEducationItems = ({ education }: EducationsState) => education;

export const educationStatusSelector = createSelector(selectEducation, (education) => mapStatus(education));

const mapStatus = (education: EducationsState): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = education;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

const educationEntitiesSelector = createSelector(selectEducation, selectEducationItems);

export const educationSelector = adapter.getSelectors(educationEntitiesSelector);

export const educationFormSelector = createSelector(educationSelector.selectAll, (education) =>
  mapToEducationForm(education),
);

const mapToEducationForm = (education: EducationDto[]) => {
  const formEducation = education.map((educationFields: EducationDto) => ({ ...educationFields }));

  return { education: formEducation };
};
