import React, { FC } from 'react';
import './Description.scss';

interface DescriptionProps {
  label?: string;
  list: Array<string | string[]>;
  dot_style?: boolean;
}

const Distinguisher = ({ style }: { style: boolean }) => {
  return !style ? <i className="description-list_tick-icon" /> : <i className="description-list_dot-icon" />;
};

const DescriptionArrayItem = ({ style, arr }: { style: boolean; arr: string[] }) => {
  return (
    <div className="description-list_item">
      <Distinguisher style={style} />
      <p>
        <strong>{arr[0]}</strong> {arr[1]}
      </p>
    </div>
  );
};

const DescriptionStringItem = ({ style, str }: { style: boolean; str: string }) => {
  return (
    <>
      {str.split(/\r?\n/).map((elem: string, index) =>
        elem.trim().length > 0 ? (
          <div key={index} className="description-list_item">
            <Distinguisher style={style} />
            <p>{elem}</p>
          </div>
        ) : null,
      )}
    </>
  );
};

/* todo: Please describe the list */
const Description: FC<DescriptionProps> = ({ list, dot_style = false, label }) => {
  return (
    <section className="description-container">
      {label && <h5 className="description-container_secondary-title">{label}</h5>}
      <div className="description-container_wrapper">
        {list?.map((entry: string | string[], idx: number) => (
          <div className="description-list" key={idx}>
            {Array.isArray(entry) ? (
              <DescriptionArrayItem style={dot_style} arr={entry || []} />
            ) : (
              <DescriptionStringItem style={dot_style} str={entry || ''} />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Description;
