import React, { FC } from 'react';
import './Description.scss';

interface SingleDescriptionProps {
  label?: string;
  description: string;
  before?: JSX.Element;
  after?: JSX.Element;
}

const SingleDescription: FC<SingleDescriptionProps> = ({ description, before, after, label }) => {
  return (
    <section className="description-container">
      {label && <h5 className="description-container_secondary-title">{label}</h5>}
      <div className="description-container_wrapper">
        <div className="description-list_item">
          {before}
          <p>{description}</p>
        </div>
        {after}
      </div>
    </section>
  );
};

export default SingleDescription;
