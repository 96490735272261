import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { DeepMap, FieldError, FormProvider, useForm } from 'react-hook-form';
import { ExperienceIcon } from '../../../../icons';
import { Navigation, Paper, PaperHeader, SAVE_AND_FINISH_LATER, Spinner, Steps } from '../../../../components';
import { experienceStatusSelector } from '../../../../store/experience';

import { AboutMeForm } from '../../components';
import { navigateTo } from '../../../../utils/navigateTo';
import { AboutFormWithAction, AboutProps } from './AboutMe.types';
import './AboutMe.scss';
import { DescriptionDto } from '../../../../api/models/DescriptionDto';
import { patchCandidateReportDescriptionThunk } from '../../../../store/candidateReport';
import { candidateReportSelector } from '../../../../store/report';
import { useTranslation } from 'react-i18next';

const AboutMe: FC<AboutProps> = ({ navigation, editMode }) => {
  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();
  const { t } = useTranslation();
  const defaultValues = useSelector(candidateReportSelector);
  const { isPending } = useSelector(experienceStatusSelector);
  const methods = useForm<AboutFormWithAction>({ ...defaultValues, mode: 'onChange' });

  const onSubmit = async (values: AboutFormWithAction) => {
    const { aboutMe, action } = values;

    const descriptionData: DescriptionDto = {
      description: aboutMe,
    };
    const patchResultAction = await dispatch(patchCandidateReportDescriptionThunk(descriptionData));

    if (action.toString() === SAVE_AND_FINISH_LATER) {
      navigation.go(Steps.CONFIRMATION_PAGE_FINISH_LATER);
    } else if (patchCandidateReportDescriptionThunk.fulfilled.match(patchResultAction)) {
      navigateTo(action, navigation);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="aboutMe">
        <Paper>
          <PaperHeader
            stepName="About me"
            icon={<ExperienceIcon className="experience_text" />}
            currentStep={Steps.ABOUT_ME}
            text="ABOUT.DESCRIPTION"
            isRequired={true}
            subText={t('ABOUT.HINT_DESCRIPTION')}
            subTextTitle={t('ABOUT.HINT_TITLE')}
          />
        </Paper>
        <Paper>
          <AboutMeForm />
        </Paper>
        <Paper>
          <Navigation onSubmit={onSubmit} editMode={editMode} saveAndFinishLaterMode={true} />
        </Paper>
        {isPending && <Spinner />}
      </form>
    </FormProvider>
  );
};

export default AboutMe;
