import React from 'react';

const PageNotFoundIcon = () => {
  return (
    <svg width="449" height="235" viewBox="0 0 449 235" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M31.5202 161.44C29.8602 160.58 28.1402 159.74 26.3902 158.89C20.2102 155.88 13.8102 152.77 9.29018 148.32C4.25018 143.37 2.42018 137.66 3.52018 130.35C5.18018 119.35 12.6102 111.67 25.6102 107.52C35.5502 104.35 45.1502 104.54 45.2402 104.54C46.1302 104.56 46.8702 103.86 46.8902 102.97C46.9102 102.08 46.2102 101.34 45.3202 101.32C44.9102 101.31 35.1502 101.11 24.7102 104.42C18.5002 106.39 13.3602 109.21 9.41018 112.78C4.41018 117.31 1.36018 123.06 0.330179 129.86C-0.919821 138.17 1.27018 144.95 7.02018 150.6C11.9202 155.41 18.5502 158.64 24.9702 161.76C26.7402 162.62 28.4902 163.48 30.1602 164.34C30.5702 163.36 31.0102 162.39 31.5202 161.44Z"
          fill="#9ECFEC"
        />
        <path
          d="M37.261 234.37C37.261 234.37 7.87099 220.92 24.601 196.22C41.331 171.52 9.03099 172.37 9.26099 156.49C9.49099 140.61 30.411 123.41 33.891 169.31C33.891 169.31 43.171 158.2 46.211 165.74C49.251 173.28 41.331 178.18 41.331 178.18C41.331 178.18 66.741 170.1 69.291 185.35C71.841 200.6 65.581 208.94 65.581 208.94C65.581 208.94 69.001 198.61 80.241 202.05C91.481 205.49 82.231 234.39 82.231 234.39H37.261V234.37Z"
          fill="#9ECFEC"
        />
        <path
          d="M56.8303 226.44C56.8703 226.44 56.9003 226.42 56.9203 226.39C56.9603 226.34 56.9503 226.27 56.9003 226.23C47.4103 219.19 19.4803 151.12 19.2003 150.43C19.1803 150.37 19.1103 150.34 19.0503 150.37C18.9903 150.39 18.9603 150.46 18.9903 150.52C19.2703 151.21 47.2203 219.33 56.7703 226.41C56.7803 226.44 56.8003 226.44 56.8303 226.44Z"
          fill="#E4F5FF"
        />
        <path
          d="M39.501 212.76C43.641 212.76 47.031 211.96 47.091 211.94C47.151 211.92 47.191 211.86 47.181 211.8C47.161 211.74 47.101 211.7 47.041 211.71C46.911 211.74 33.621 214.9 27.561 208.59C27.521 208.54 27.441 208.54 27.401 208.59C27.361 208.64 27.351 208.71 27.401 208.75C30.471 211.96 35.361 212.76 39.501 212.76Z"
          fill="#E4F5FF"
        />
        <path
          d="M33.601 184.21C33.631 184.21 33.661 184.2 33.691 184.17C33.771 184.09 41.571 175.68 42.501 170.27C42.511 170.21 42.471 170.15 42.401 170.14C42.341 170.13 42.281 170.17 42.271 170.24C41.351 175.58 33.601 183.94 33.521 184.02C33.481 184.07 33.481 184.14 33.531 184.18C33.551 184.2 33.581 184.21 33.601 184.21Z"
          fill="#E4F5FF"
        />
        <path
          d="M54.0498 223.43C54.0898 223.43 54.1198 223.41 54.1398 223.38C54.2098 223.28 61.6398 213.12 71.7598 208.78C71.8198 208.75 71.8498 208.69 71.8198 208.63C71.7898 208.57 71.7298 208.54 71.6698 208.57C61.4898 212.93 54.0298 223.14 53.9498 223.25C53.9098 223.3 53.9198 223.37 53.9798 223.41C53.9998 223.42 54.0198 223.43 54.0498 223.43Z"
          fill="#E4F5FF"
        />
        <path
          d="M154.231 234.37C154.231 234.37 157.621 222.38 151.891 210.58C149.841 206.37 134.981 210.58 134.981 210.58C134.981 210.58 152.661 190.6 136.961 181.87C121.261 173.14 121.731 187.57 126.841 199.29C126.841 199.29 117.431 194.73 112.841 199.97C108.251 205.21 117.841 213.29 117.841 213.29C117.841 213.29 99.4411 197.81 87.3511 211.97C75.2611 226.13 79.3611 234.38 79.3611 234.38H154.231V234.37Z"
          fill="#E4F5FF"
        />
        <path
          d="M108.901 230.43C108.911 230.43 108.931 230.43 108.941 230.42C115.421 227.96 120.741 224.14 124.741 219.08C127.941 215.03 130.311 210.18 131.781 204.66C134.281 195.27 133.301 187.09 133.291 187.01C133.281 186.95 133.221 186.9 133.161 186.91C133.101 186.92 133.051 186.98 133.061 187.04C133.071 187.12 134.051 195.26 131.551 204.61C129.251 213.23 123.451 224.66 108.861 230.2C108.801 230.22 108.771 230.29 108.791 230.35C108.801 230.41 108.851 230.43 108.901 230.43Z"
          fill="#9ECFEC"
        />
        <path
          d="M116.641 227.5C121.671 227.5 137.511 226.85 146.161 219.11C146.211 219.07 146.211 218.99 146.171 218.95C146.131 218.9 146.051 218.9 146.011 218.94C135.741 228.12 115.281 227.26 115.071 227.24C115.011 227.23 114.951 227.29 114.951 227.35C114.951 227.41 115.001 227.47 115.061 227.47C115.091 227.48 115.661 227.5 116.641 227.5Z"
          fill="#9ECFEC"
        />
        <path
          d="M129.031 212.02C129.071 212.02 129.121 211.99 129.141 211.95C129.171 211.89 129.141 211.82 129.081 211.8C128.991 211.76 120.541 207.91 118.251 202.47C118.231 202.41 118.161 202.38 118.101 202.41C118.041 202.44 118.011 202.5 118.041 202.56C120.361 208.08 128.901 211.97 128.991 212.01C128.991 212.02 129.011 212.02 129.031 212.02Z"
          fill="#9ECFEC"
        />
        <path
          d="M350.811 234.37C350.811 234.37 354.681 220.68 348.131 207.21C345.791 202.4 328.821 207.21 328.821 207.21C328.821 207.21 349.011 184.4 331.091 174.43C313.171 164.46 313.701 180.94 319.541 194.32C319.541 194.32 308.801 189.12 303.561 195.09C298.321 201.07 309.261 210.29 309.261 210.29C309.261 210.29 288.251 192.62 274.451 208.78C260.651 224.94 265.321 234.36 265.321 234.36H350.811V234.37Z"
          fill="#9ECFEC"
        />
        <path
          d="M299.062 229.88C299.082 229.88 299.092 229.88 299.112 229.87C306.512 227.06 312.582 222.7 317.152 216.92C320.802 212.3 323.502 206.76 325.182 200.46C328.042 189.74 326.922 180.4 326.912 180.31C326.902 180.24 326.832 180.19 326.762 180.19C326.692 180.2 326.642 180.27 326.642 180.34C326.652 180.43 327.762 189.73 324.922 200.4C322.302 210.24 315.672 223.29 299.012 229.62C298.942 229.65 298.912 229.72 298.932 229.79C298.952 229.84 299.002 229.88 299.062 229.88Z"
          fill="#E4F5FF"
        />
        <path
          d="M307.891 226.53C313.641 226.53 331.711 225.79 341.601 216.95C341.651 216.9 341.661 216.82 341.611 216.76C341.561 216.71 341.481 216.7 341.421 216.75C329.701 227.23 306.341 226.24 306.101 226.23C306.031 226.22 305.961 226.28 305.961 226.36C305.961 226.43 306.011 226.5 306.091 226.5C306.131 226.5 306.781 226.53 307.891 226.53Z"
          fill="#E4F5FF"
        />
        <path
          d="M322.042 208.85C322.092 208.85 322.142 208.82 322.162 208.77C322.192 208.7 322.162 208.62 322.092 208.59C321.992 208.55 312.342 204.15 309.732 197.94C309.702 197.87 309.622 197.84 309.562 197.87C309.492 197.9 309.462 197.98 309.492 198.04C312.142 204.34 321.892 208.78 321.992 208.83C322.002 208.85 322.022 208.85 322.042 208.85Z"
          fill="#E4F5FF"
        />
        <path
          d="M263.3 234.37C263.3 234.37 294.2 226.42 285.65 208.23C282.06 200.59 273.62 202.09 273.62 202.09C273.62 202.09 307.58 172.91 275.22 159.69C263.3 154.82 265.79 182.47 265.79 182.47C265.79 182.47 259.51 172.41 254.75 176.85C249.99 181.28 253.48 196.05 253.48 196.05C253.48 196.05 241.45 184.18 229.57 194.7C217.69 205.22 205.57 234.31 205.57 234.31L263.3 234.37Z"
          fill="#E4F5FF"
        />
        <path
          d="M240.02 230.64C240.04 230.64 240.06 230.63 240.08 230.62C255.16 220.82 264.48 204.77 269.64 193.04C275.23 180.33 277.28 169.8 277.3 169.7C277.31 169.64 277.27 169.58 277.21 169.56C277.15 169.55 277.09 169.59 277.07 169.65C277.05 169.75 275 180.26 269.42 192.95C264.27 204.65 254.98 220.65 239.95 230.42C239.9 230.46 239.88 230.53 239.92 230.58C239.94 230.62 239.98 230.64 240.02 230.64Z"
          fill="#9ECFEC"
        />
        <path
          d="M247.95 225.42C251.64 225.42 264.55 224.92 274.14 218.28C274.19 218.24 274.21 218.17 274.17 218.12C274.13 218.07 274.06 218.05 274.01 218.09C262.84 225.83 247.1 225.18 246.94 225.18C246.87 225.18 246.82 225.23 246.82 225.29C246.82 225.35 246.87 225.41 246.93 225.41C246.96 225.41 247.32 225.42 247.95 225.42Z"
          fill="#9ECFEC"
        />
        <path
          d="M272.11 186.78C272.13 186.78 272.141 186.78 272.161 186.77C272.221 186.74 272.25 186.68 272.22 186.62C272.19 186.54 268.78 178.34 270.15 171.11C270.16 171.05 270.12 170.99 270.06 170.97C270 170.96 269.941 171 269.921 171.06C268.531 178.36 271.97 186.62 272 186.7C272.03 186.75 272.07 186.78 272.11 186.78Z"
          fill="#9ECFEC"
        />
        <path
          d="M252.469 219.14C252.479 219.14 252.499 219.14 252.509 219.13C252.569 219.11 252.599 219.04 252.569 218.98C252.549 218.93 250.519 213.97 251.699 208.02C251.709 207.96 251.669 207.9 251.609 207.88C251.549 207.87 251.489 207.91 251.469 207.97C250.279 213.99 252.329 219.02 252.349 219.07C252.379 219.12 252.429 219.14 252.469 219.14Z"
          fill="#9ECFEC"
        />
        <path
          d="M412.512 234.37C412.512 234.37 455.202 223.39 443.392 198.25C438.432 187.7 426.782 189.76 426.782 189.76C426.782 189.76 473.702 149.44 428.992 131.18C412.522 124.45 415.962 162.65 415.962 162.65C415.962 162.65 407.282 148.75 400.702 154.88C394.132 161.01 398.942 181.41 398.942 181.41C398.942 181.41 382.322 165.01 365.912 179.54C349.502 194.07 332.762 234.27 332.762 234.27L412.512 234.37Z"
          fill="#E4F5FF"
        />
        <path
          d="M380.343 229.22C380.373 229.22 380.403 229.21 380.433 229.19C401.273 215.65 414.143 193.48 421.273 177.27C428.993 159.71 431.823 145.16 431.853 145.02C431.873 144.93 431.813 144.85 431.723 144.83C431.633 144.81 431.553 144.87 431.533 144.96C431.503 145.1 428.673 159.62 420.973 177.15C413.863 193.32 401.033 215.42 380.253 228.92C380.183 228.97 380.153 229.07 380.203 229.14C380.243 229.19 380.293 229.22 380.343 229.22Z"
          fill="#9ECFEC"
        />
        <path
          d="M391.312 222.01C396.402 222.01 414.252 221.31 427.492 212.14C427.562 212.09 427.582 211.99 427.532 211.92C427.482 211.85 427.382 211.83 427.312 211.88C411.872 222.57 390.132 221.68 389.912 221.67C389.822 221.66 389.752 221.73 389.742 221.82C389.742 221.91 389.802 221.98 389.892 221.99C389.932 221.99 390.432 222.01 391.312 222.01Z"
          fill="#9ECFEC"
        />
        <path
          d="M424.692 168.62C424.712 168.62 424.732 168.62 424.752 168.61C424.832 168.58 424.872 168.48 424.842 168.4C424.792 168.29 420.082 156.97 421.982 146.97C422.002 146.88 421.942 146.8 421.852 146.78C421.762 146.76 421.682 146.82 421.662 146.91C419.742 157 424.492 168.41 424.542 168.52C424.562 168.59 424.622 168.62 424.692 168.62Z"
          fill="#9ECFEC"
        />
        <path
          d="M397.553 213.34C397.573 213.34 397.593 213.34 397.613 213.33C397.693 213.3 397.733 213.2 397.703 213.12C397.673 213.05 394.873 206.2 396.503 197.98C396.523 197.89 396.463 197.81 396.373 197.79C396.283 197.77 396.203 197.83 396.183 197.92C394.533 206.24 397.373 213.18 397.403 213.25C397.433 213.3 397.493 213.34 397.553 213.34Z"
          fill="#9ECFEC"
        />
        <path
          d="M206.812 234.3C206.812 234.3 231.172 204.89 221.882 195.98C212.592 187.08 205.502 201.15 205.502 201.15C205.502 201.15 212.122 157.17 186.102 157.72C160.072 158.27 188.572 189.64 188.572 189.64C188.572 189.64 179.092 180.54 175.142 185.38C171.192 190.22 176.782 200.15 183.882 200.59C183.882 200.59 150.662 193.92 148.232 207.37C145.802 220.82 153.702 234.26 153.702 234.26L206.812 234.3Z"
          fill="#9ECFEC"
        />
        <path
          d="M184.772 231.95C184.802 231.95 184.832 231.94 184.852 231.91C189.832 226.77 193.132 220.25 194.662 212.51C195.892 206.33 195.992 199.36 194.972 191.81C193.242 178.96 188.792 168.71 188.742 168.61C188.712 168.55 188.652 168.52 188.592 168.55C188.532 168.58 188.502 168.64 188.532 168.7C188.572 168.8 193.012 179.02 194.742 191.84C196.332 203.66 195.882 220.18 184.682 231.74C184.642 231.79 184.642 231.86 184.682 231.9C184.712 231.94 184.742 231.95 184.772 231.95Z"
          fill="#E4F5FF"
        />
        <path
          d="M194.163 222.35C195.613 222.35 197.523 222.23 199.633 221.77C203.803 220.87 209.583 218.43 213.513 211.97C213.543 211.92 213.533 211.84 213.473 211.81C213.413 211.78 213.343 211.79 213.313 211.85C209.433 218.24 203.703 220.65 199.583 221.55C195.113 222.52 191.523 221.97 191.493 221.97C191.433 221.96 191.373 222 191.363 222.07C191.353 222.13 191.393 222.19 191.463 222.2C191.473 222.19 192.513 222.35 194.163 222.35Z"
          fill="#E4F5FF"
        />
        <path
          d="M195.553 202.63C195.573 202.63 195.593 202.62 195.613 202.61C195.663 202.58 195.683 202.5 195.653 202.45C195.613 202.38 191.423 195.74 181.713 191.43C181.653 191.4 181.583 191.43 181.563 191.49C181.533 191.55 181.563 191.62 181.623 191.64C191.263 195.92 195.423 202.51 195.463 202.57C195.473 202.61 195.513 202.63 195.553 202.63Z"
          fill="#E4F5FF"
        />
        <path
          d="M62.7595 139.43C61.9495 139.59 61.1195 139.61 60.2695 139.47C60.3095 140.88 60.4295 142.83 60.7495 144.03C61.2895 146.08 65.4595 145.84 64.0095 143.43C63.2695 142.18 62.9195 140.64 62.7595 139.43Z"
          fill="#FF96A9"
        />
        <path
          d="M62.7599 139.43C62.6099 138.29 62.6199 137.45 62.6199 137.45L60.2399 137.75C60.2399 137.75 60.2299 138.48 60.2599 139.48C61.1199 139.6 61.9599 139.59 62.7599 139.43Z"
          fill="#ED7E94"
        />
        <path
          d="M60.9997 135.76C61.4197 134.92 63.1297 134.99 64.3597 134.22C64.0297 133.54 63.7197 133.04 63.7197 133.04C56.4097 132.92 56.4697 136.73 58.0997 137.81C59.2297 138.56 61.8597 138.59 63.5597 138.14C62.0297 137.9 60.6497 137.14 60.9997 135.76Z"
          fill="#ED7E94"
        />
        <path
          d="M65.0399 137.27C65.3599 136.55 64.8399 135.2 64.3599 134.22C63.1299 134.99 61.4199 134.91 60.9999 135.76C60.6499 137.14 62.0399 137.91 63.5599 138.14C64.3099 137.93 64.8799 137.64 65.0399 137.27Z"
          fill="#FF96A9"
        />
        <path
          d="M59.9687 134.91C59.9687 134.91 68.4887 134.55 67.5187 128.44C66.5487 122.34 58.0287 126.99 57.4287 132.97C57.4287 132.97 51.6887 131.7 52.3487 137.44C52.3487 137.44 36.6987 149.77 48.7787 156.96C60.8687 164.15 61.0487 143.18 61.0487 143.18C61.0487 143.18 61.2287 139.37 59.7787 136.71C59.7787 136.71 58.5087 135.92 58.6887 135.2C58.8787 134.49 59.9687 134.91 59.9687 134.91Z"
          fill="#00557A"
        />
        <path
          d="M37.3092 124.88C38.4892 122.77 39.8792 120.87 41.3492 119.04C40.9392 118.69 40.5392 118.33 40.1492 117.96C40.0892 117.92 40.0392 117.87 39.9792 117.83C37.8092 120.32 36.0092 122.96 35.6992 125.13C35.6992 125.13 40.6192 141 55.9792 147.45C56.0692 146.84 56.1092 146.23 56.1292 145.62C47.4892 141.61 40.7592 133.73 37.3092 124.88Z"
          fill="#ED7E94"
        />
        <path
          d="M55.0188 139.83C50.6788 137.17 45.2788 132.91 40.8488 126.41C40.8488 126.41 42.0088 123.9 43.6188 120.87C42.8488 120.29 42.0888 119.68 41.3388 119.04C39.8688 120.87 38.4788 122.77 37.2988 124.88C40.7488 133.73 47.4788 141.61 56.1088 145.61C56.1488 143.63 55.7888 141.66 55.0188 139.83Z"
          fill="#FF96A9"
        />
        <path
          d="M56.1187 145.61C56.1087 146.22 56.0588 146.84 55.9688 147.44C56.5788 147.7 57.1987 147.94 57.8487 148.16C57.8487 148.16 58.4487 148.27 59.4387 148.35C59.3987 147.86 59.3487 147.36 59.2987 146.87C58.2187 146.51 57.1587 146.08 56.1187 145.61Z"
          fill="#ECC2F0"
        />
        <path
          d="M64.3387 148.24C65.9887 148.04 67.7887 147.63 69.5287 146.87C69.5287 146.87 70.1087 146.6 71.0687 146.01C70.9787 145.35 70.8387 144.69 70.6287 144.08C68.5587 145.19 66.3187 146 63.8887 146.39C64.1787 146.96 64.2887 147.61 64.3387 148.24Z"
          fill="#ECC2F0"
        />
        <path
          d="M68.7495 141.37C67.8695 141.82 66.9395 142.23 65.9395 142.6C65.6995 143.92 65.0595 145 63.6495 145.18C61.6495 145.58 60.3095 143.82 59.2195 142.11C58.0295 141.54 56.5895 140.79 55.0195 139.83C55.7895 141.66 56.1495 143.63 56.1195 145.61C57.1495 146.09 58.2195 146.52 59.2995 146.88C59.3495 147.37 59.3995 147.86 59.4395 148.36C60.6295 148.45 62.3795 148.5 64.3395 148.25C64.2895 147.61 64.1795 146.96 63.8895 146.4C66.3195 146 68.5595 145.2 70.6295 144.09C70.2695 143 69.6895 142.04 68.7495 141.37Z"
          fill="#FEF2FE"
        />
        <path
          d="M63.6488 145.18C65.0588 145 65.6988 143.93 65.9388 142.6C64.9388 142.97 63.8887 143.29 62.7587 143.56C62.7587 143.56 61.3788 143.13 59.2188 142.1C60.3088 143.83 61.6488 145.58 63.6488 145.18Z"
          fill="#FF96A9"
        />
        <path
          d="M68.7299 106.99C67.2199 105.85 67.1799 103.53 67.8199 101.53C66.8099 103.18 66.4199 107.05 67.5999 108.03C69.1299 109.32 70.0199 108.19 70.0199 108.19C70.0199 108.19 77.1099 118.52 79.6199 123.7C77.7499 117.38 73.2799 112.44 70.4199 106.49C70.4199 107.72 69.2099 107.23 68.7299 106.99Z"
          fill="#ED7E94"
        />
        <path
          d="M70.5983 104.84C70.5983 104.84 70.5783 101.03 68.8883 100.78C68.4883 100.72 68.1283 101.02 67.8183 101.53C67.1783 103.53 67.2083 105.85 68.7283 106.99C69.2083 107.23 70.4183 107.72 70.4183 106.51C73.2783 112.46 77.7483 117.4 79.6183 123.72C80.1483 124.81 80.4783 125.68 80.4883 126.17C80.5583 128.53 77.6883 136.8 68.7483 141.38C69.6883 142.05 70.2683 143 70.6283 144.08C76.9883 140.65 81.6683 134.28 84.8783 127.67C85.1883 126.69 85.4783 125.67 85.7283 124.61C85.7283 124.6 74.5483 107.34 70.5983 104.84Z"
          fill="#FF96A9"
        />
        <path
          d="M70.6289 144.08C70.8389 144.69 70.9789 145.35 71.0689 146.01C74.2089 144.05 81.3789 138.57 84.8789 127.66C81.6789 134.28 76.9889 140.65 70.6289 144.08Z"
          fill="#ED7E94"
        />
        <path
          d="M58.8188 203.43C61.9588 191.54 63.2288 178.6 70.1688 168.3C68.3188 168.47 65.6688 169.7 62.2888 173.78C55.1988 182.32 55.9088 202.78 55.9088 202.78C50.0988 206.73 45.8288 212.54 42.7988 218.06C43.2088 219.19 43.8988 220.2 44.7588 221.07C48.7888 214.64 53.1488 208.45 58.8188 203.43Z"
          fill="#D3A900"
        />
        <path
          d="M65.3 178.07C62.32 186.19 61.03 195.07 58.82 203.44C53.15 208.46 48.79 214.65 44.75 221.09C46.05 222.4 47.76 223.37 49.57 223.89C61.83 212.04 67.74 197.8 70.59 186.82C68.9 183.85 67.03 181 65.3 178.07Z"
          fill="#FFCC00"
        />
        <path
          d="M71.5188 182.84C70.4388 179.44 69.1588 176.12 67.4788 172.95C66.6588 174.61 65.9388 176.32 65.2988 178.06C67.0288 180.99 68.8988 183.84 70.5788 186.81C70.9388 185.44 71.2488 184.11 71.5188 182.84Z"
          fill="#D3A900"
        />
        <path
          d="M70.1685 168.3C69.1585 169.8 68.2785 171.35 67.4785 172.95C69.1585 176.12 70.4385 179.44 71.5185 182.84C73.2385 174.72 73.2385 169.19 73.2385 169.19C73.2385 169.19 72.1885 168.12 70.1685 168.3Z"
          fill="#FFCC00"
        />
        <path
          d="M49.5694 223.88C47.7594 223.36 46.0494 222.39 44.7494 221.08C42.1594 225.2 39.6994 229.43 37.1094 233.51C41.8594 230.6 45.9794 227.34 49.5694 223.88Z"
          fill="#FF96A9"
        />
        <path
          d="M42.79 218.07C38.28 226.28 36.5 233.89 36.5 233.89C36.7 233.77 36.91 233.64 37.11 233.52C39.7 229.43 42.16 225.21 44.75 221.09C43.88 220.21 43.2 219.2 42.79 218.07Z"
          fill="#ED7E94"
        />
        <path
          d="M87.0801 223.21C90.1801 226.57 93.5601 230.12 97.2501 233.89C97.2501 233.89 97.0601 233.48 96.7201 232.73C93.7901 229.44 90.9601 226.17 88.2301 222.89C87.8501 223.01 87.4701 223.12 87.0801 223.21Z"
          fill="#ED7E94"
        />
        <path
          d="M88.2285 222.89C90.9585 226.18 93.7885 229.44 96.7185 232.73C95.8385 230.81 93.9285 226.66 91.4285 221.43C90.4185 222.03 89.3485 222.53 88.2285 222.89Z"
          fill="#FF96A9"
        />
        <path
          d="M66.3099 176.36C66.3099 176.36 70.0299 176.36 70.9899 170.88C71.9499 165.4 62.2799 166.45 62.2799 166.45C62.2799 166.45 61.5999 166.45 60.6499 166.67C59.3399 167.61 58.3399 168.84 58.0899 170.56C57.3599 177.33 60.0199 183.13 63.6499 188.93C71.3499 201.28 79.3099 212.18 88.2299 222.89C89.3399 222.53 90.4199 222.03 91.4299 221.43C84.2199 206.27 72.1499 182.01 66.3099 176.36Z"
          fill="#FFCC00"
        />
        <path
          d="M58.0885 170.56C58.3385 168.83 59.3385 167.61 60.6485 166.67C55.5485 167.84 42.8085 175.31 87.0785 223.21C87.4685 223.12 87.8485 223.01 88.2285 222.89C79.3085 212.18 71.3485 201.28 63.6485 188.93C60.0285 183.13 57.3685 177.33 58.0885 170.56Z"
          fill="#D3A900"
        />
        <path
          d="M56.7891 145.91C56.9191 146.67 57.8491 148.17 57.8491 148.17C57.8491 148.17 58.6891 149.77 59.6591 152.11C59.5991 150.35 59.4891 148.6 59.2991 146.88C58.4491 146.59 57.6091 146.26 56.7891 145.91Z"
          fill="#ECC2F0"
        />
        <path
          d="M62.4793 165.5C62.4293 165.83 62.3693 166.15 62.2793 166.45C62.2793 166.45 66.3093 175.88 73.2393 169.19C73.2393 169.19 73.2593 168.75 73.2593 167.99C72.4593 168.67 71.5293 169.22 70.4193 169.59C67.1193 170.42 64.1793 168.25 62.4793 165.5Z"
          fill="#D3A900"
        />
        <path
          d="M71.1684 153.88C70.0584 153.56 68.9884 152.99 67.9984 152.43C70.3684 152.63 72.5884 150.88 73.3084 148.65C73.2984 148.57 73.2784 148.5 73.2584 148.42C72.5084 145.66 68.7284 148.42 68.7284 148.42L67.5984 144.69C67.5984 144.69 60.2684 147.35 57.8584 145.82C56.9384 145.24 56.7184 145.44 56.7984 145.9C57.6184 146.26 58.4584 146.58 59.3084 146.87C59.4984 148.59 59.6084 150.34 59.6684 152.1C61.2184 155.83 63.1084 161.45 62.4884 165.5C64.1884 168.25 67.1284 170.42 70.4284 169.59C71.5384 169.22 72.4684 168.67 73.2684 167.99C73.2684 165.33 73.0784 158.77 71.1684 153.88Z"
          fill="#FEF2FE"
        />
        <path
          d="M73.31 148.65C72.59 150.88 70.37 152.63 68 152.43C68.99 153 70.06 153.56 71.17 153.88C71.08 153.66 71 153.45 70.9 153.23C70.91 153.24 73.84 151.3 73.31 148.65Z"
          fill="#ECC2F0"
        />
        <path
          d="M61.5097 149.94C61.5097 149.94 60.0897 157.09 66.6797 152.76C66.6797 152.76 61.5697 155.17 61.5097 149.94Z"
          fill="#ECC2F0"
        />
        <path
          d="M61.1996 85.85H56.3596C46.5496 85.85 38.5996 93.8 38.5996 103.61C38.5996 113.42 46.5496 121.37 56.3596 121.37H61.1996L80.7196 128.3V78.93L61.1996 85.85Z"
          fill="#FFCC00"
        />
        <path
          d="M63.8196 118.34H58.9796C49.1696 118.34 41.2196 110.39 41.2196 100.58C41.2196 96.85 42.3696 93.4 44.3296 90.54C40.8096 93.79 38.5996 98.44 38.5996 103.6C38.5996 113.41 46.5496 121.36 56.3596 121.36H61.1996L80.7196 128.29V124.33L63.8196 118.34Z"
          fill="#D3A900"
        />
        <path
          d="M82.1205 128.53C87.5826 128.53 92.0105 117.373 92.0105 103.61C92.0105 89.8471 87.5826 78.69 82.1205 78.69C76.6584 78.69 72.2305 89.8471 72.2305 103.61C72.2305 117.373 76.6584 128.53 82.1205 128.53Z"
          fill="#D3A900"
        />
        <path
          d="M82.1195 123.59C86.4991 123.59 90.0495 114.645 90.0495 103.61C90.0495 92.5754 86.4991 83.63 82.1195 83.63C77.7398 83.63 74.1895 92.5754 74.1895 103.61C74.1895 114.645 77.7398 123.59 82.1195 123.59Z"
          fill="#FFCC00"
        />
        <path
          d="M63.819 118.34C63.819 118.34 58.739 112.35 59.659 100.38C59.659 100.38 54.709 112.22 59.659 119.62L63.819 118.34Z"
          fill="#D3A900"
        />
        <path
          d="M82.1197 98.56C83.4673 98.56 84.5597 97.1587 84.5597 95.43C84.5597 93.7014 83.4673 92.3 82.1197 92.3C80.7721 92.3 79.6797 93.7014 79.6797 95.43C79.6797 97.1587 80.7721 98.56 82.1197 98.56Z"
          fill="#32467A"
        />
        <path
          d="M79.6797 112.69C79.6797 114.42 80.7697 115.82 82.1197 115.82C83.4697 115.82 84.5597 114.42 84.5597 112.69C84.5597 110.96 83.4697 109.56 82.1197 109.56C80.7697 109.56 79.6797 110.96 79.6797 112.69Z"
          fill="#32467A"
        />
        <path
          d="M41.3485 119.04C44.0885 115.63 47.1185 112.46 49.6385 108.69C50.2185 107.73 50.3285 105.55 50.5985 103.6C49.5985 104.05 49.0485 105.23 48.9885 107.56C48.9885 107.56 49.2285 108.61 48.5085 109.49C48.0585 110.04 43.5285 113.73 39.9785 117.82C40.0385 117.86 40.0885 117.91 40.1485 117.95C40.5385 118.33 40.9385 118.69 41.3485 119.04Z"
          fill="#ED7E94"
        />
        <path
          d="M50.7596 110.7C50.7596 110.7 51.8896 111.43 52.9396 109.89C53.9896 108.36 53.5796 103.36 51.8096 103.36C51.3496 103.36 50.9496 103.43 50.5996 103.59C50.3296 105.54 50.2096 107.72 49.6396 108.68C47.1196 112.46 44.0896 115.62 41.3496 119.03C42.0996 119.67 42.8596 120.27 43.6296 120.86C45.7496 116.88 48.6596 111.98 50.7596 110.7Z"
          fill="#FF96A9"
        />
        <path
          d="M63.0391 136.55C63.0391 136.55 64.2291 136.78 64.7391 136.18C64.7391 136.18 64.6491 137.2 64.1691 137.26C63.6891 137.31 63.0391 136.55 63.0391 136.55Z"
          fill="white"
        />
        <path
          d="M22.0207 234.98H430.621C431.061 234.98 431.421 234.76 431.421 234.5C431.421 234.23 431.061 234.02 430.621 234.02H22.0207C21.5807 234.02 21.2207 234.24 21.2207 234.5C21.2207 234.76 21.5707 234.98 22.0207 234.98Z"
          fill="#93C3E0"
        />
        <path
          d="M430.08 187.64C429.8 187.64 429.52 187.57 429.26 187.41C428.5 186.96 428.25 185.97 428.7 185.2C434.86 174.86 438.42 161.07 438.46 147.39C438.51 133.65 434.99 121.15 428.8 113.09C423.53 106.23 416.1 102.68 406.72 102.53C399.59 102.42 393.94 104.42 393.88 104.44C393.04 104.74 392.12 104.31 391.82 103.47C391.52 102.63 391.95 101.71 392.78 101.41C393.02 101.32 398.68 99.31 406.12 99.31C406.3 99.31 406.47 99.31 406.65 99.31C411.21 99.37 415.44 100.19 419.24 101.76C424.01 103.73 428.09 106.88 431.34 111.12C434.76 115.57 437.39 121.09 439.16 127.54C440.84 133.66 441.69 140.34 441.66 147.39C441.64 154.38 440.76 161.32 439.06 168.03C437.3 174.98 434.73 181.31 431.44 186.84C431.16 187.36 430.63 187.64 430.08 187.64Z"
          fill="#E4F5FF"
        />
        <path
          d="M368.301 145.62C368.301 145.62 369.291 149.06 371.971 145.97C373.641 144.04 374.271 141.3 374.511 139.51C373.591 140 372.561 140.3 371.481 140.43C370.611 142.34 369.421 144.63 368.301 145.62Z"
          fill="#FF96A9"
        />
        <path
          d="M372.731 137.4C372.731 137.4 372.221 138.78 371.471 140.43C372.541 140.3 373.581 140 374.501 139.51C374.641 138.43 374.641 137.7 374.641 137.7C374.641 137.7 374.601 135.24 372.731 137.4Z"
          fill="#ED7E94"
        />
        <path
          d="M373.77 134.09C373.18 133.3 371.58 132.84 370.26 132.16L368.93 134.85L369.92 135.14L369.45 137.7C369.45 137.7 370.27 138.31 371.53 138.45C373.39 137.3 374.97 135.63 373.77 134.09Z"
          fill="#FF96A9"
        />
        <path
          d="M376.98 131.23C376.34 130.59 370.92 130.82 370.92 130.82L370.26 132.15C371.58 132.84 373.18 133.29 373.77 134.08C374.97 135.63 373.39 137.3 371.54 138.44C372.41 138.53 373.48 138.4 374.64 137.69C377.49 135.95 377.62 131.87 376.98 131.23Z"
          fill="#ED7E94"
        />
        <path
          d="M374.651 133.27C374.651 133.27 369.001 131.29 365.621 127.97C365.621 127.97 375.931 127.5 380.481 130.62C380.481 130.62 380.601 134.96 374.651 137.7L375.291 134.26C375.291 134.26 376.801 134.06 376.051 132.82C375.291 131.58 374.651 133.27 374.651 133.27Z"
          fill="#09205A"
        />
        <path
          d="M357.04 106.08C356.78 105.76 356.54 105.42 356.31 105.07C356.17 105.71 356.04 106.36 355.91 107.01C356.17 107.34 356.44 107.66 356.74 107.96C356.83 107.28 356.93 106.65 357.04 106.08Z"
          fill="#F6DEF8"
        />
        <path
          d="M355.281 102.93C355.071 103.56 354.851 104.23 354.641 104.92C354.991 105.65 355.411 106.35 355.911 106.99C356.041 106.34 356.181 105.7 356.311 105.05C355.871 104.39 355.511 103.68 355.281 102.93Z"
          fill="#FEF2FE"
        />
        <path
          d="M356.991 130.82C358.751 134.34 361.041 137.59 364.061 140.07C358.641 134.02 355.851 123.81 355.911 121.26C355.911 121.26 356.021 113.31 356.741 107.94C356.441 107.64 356.171 107.32 355.911 106.99C354.291 114.98 353.341 123.14 356.991 130.82Z"
          fill="#09205A"
        />
        <path
          d="M401.98 117.54C401.83 117.72 401.66 117.88 401.49 118.04C401.84 123.99 400.84 129.87 400.11 135.48C393.12 141.77 385.66 148.06 375.87 149.23C375.59 149.92 375.33 150.62 375.07 151.32C375.68 151.33 376.3 151.33 376.96 151.32C376.96 151.32 392.73 146.97 401.04 136.02C401.04 136.03 402.44 125.39 401.98 117.54Z"
          fill="#09205A"
        />
        <path
          d="M400.11 135.49C400.83 129.89 401.84 124 401.49 118.05C400.64 118.84 399.53 119.46 398.49 120.08C398.02 125.93 397.05 134.23 395.28 135.57C395.28 135.57 377.26 146.06 370.52 144.35C368.07 143.73 365.92 142.15 364.07 140.08C361.05 137.59 358.76 134.35 357 130.83C353.35 123.15 354.29 114.99 355.91 107.01C355.42 106.37 354.99 105.67 354.64 104.94C352.76 110.97 350.77 119.2 351.01 123.15C351.01 123.15 353.96 138.84 358.62 145.44C358.62 145.44 361.05 151.08 375.07 151.34C375.32 150.64 375.59 149.94 375.87 149.25C385.66 148.07 393.11 141.78 400.11 135.49Z"
          fill="#32467A"
        />
        <path
          d="M401.98 117.54C401.94 116.93 401.9 116.34 401.84 115.78C401.69 115.98 401.53 116.18 401.35 116.36C401.41 116.92 401.45 117.48 401.49 118.04C401.66 117.89 401.83 117.72 401.98 117.54Z"
          fill="#F6DEF8"
        />
        <path
          d="M398.61 118.48C398.57 118.98 398.53 119.52 398.49 120.07C399.53 119.45 400.64 118.83 401.49 118.04C401.46 117.48 401.41 116.92 401.35 116.36C400.58 117.19 399.58 117.86 398.61 118.48Z"
          fill="#FEF2FE"
        />
        <path
          d="M359.441 96.09C358.901 96.32 358.231 96.87 357.391 97.89C356.951 98.43 356.161 100.34 355.291 102.94C355.521 103.68 355.881 104.4 356.321 105.07C356.551 104.02 356.781 102.97 357.001 101.93C360.431 101.52 360.531 98.66 359.441 96.09Z"
          fill="#FF96A9"
        />
        <path
          d="M358.16 103.22C360.19 102.05 362.17 101.84 361.86 99.85C361.62 98.31 361.29 95.3 359.44 96.09C360.54 98.67 360.43 101.52 357 101.93C356.77 102.97 356.54 104.02 356.32 105.07C356.55 105.42 356.79 105.76 357.05 106.08C357.33 104.57 357.7 103.49 358.16 103.22Z"
          fill="#ED7E94"
        />
        <path
          d="M361.241 171.78C360.911 171.81 360.721 171.84 360.721 171.84C360.721 171.84 359.231 176.56 356.711 183.45C358.261 184.99 360.211 186.03 362.461 186.03C362.021 181.28 361.581 176.53 361.241 171.78Z"
          fill="#09205A"
        />
        <path
          d="M343.9 220.99C344.58 220.31 345.15 219.4 345.84 218.71C348.23 215.98 350.28 213.24 352.1 210.17C359.22 197.9 365.92 185.98 368.97 172.3C366.59 171.37 362.65 171.64 361.24 171.78C361.58 176.53 362.02 181.28 362.46 186.03C360.21 186.03 358.26 184.99 356.71 183.45C351.23 198.43 340.9 223.64 330.49 232.79C335.59 229.76 339.7 225.29 343.9 220.99Z"
          fill="#32467A"
        />
        <path
          d="M368.971 172.3C365.921 185.97 359.221 197.9 352.101 210.17C350.281 213.24 348.231 215.98 345.841 218.71C345.161 219.39 344.591 220.3 343.901 220.99C339.701 225.29 335.591 229.76 330.491 232.79C330.311 232.95 330.141 233.1 329.961 233.24C329.961 233.24 349.891 223.92 359.091 205.62C368.301 187.33 370.081 179.52 370.821 174.97C371.041 173.62 370.221 172.8 368.971 172.3Z"
          fill="#09205A"
        />
        <path
          d="M392.18 208.66C388.68 199.57 384.02 190.72 377.96 182.79C374.83 183.94 371.59 184.16 368.32 184.11C377.53 199.07 392.34 223.44 396.65 232.34C395.72 224.31 395.06 216.33 392.18 208.66Z"
          fill="#32467A"
        />
        <path
          d="M377.97 182.79C384.03 190.71 388.69 199.57 392.19 208.66C395.06 216.32 395.73 224.3 396.66 232.34C396.82 232.66 396.96 232.97 397.09 233.25C397.09 233.25 402.21 206.8 370.87 171.84C370.87 171.84 364.76 165.84 360.74 171.84C360.74 171.84 363.88 176.87 368.34 184.12C371.6 184.17 374.84 183.94 377.97 182.79Z"
          fill="#09205A"
        />
        <path
          d="M369.051 171.6L372.971 144.5C372.971 144.5 371.351 144.54 370.601 144.32C369.911 144.12 369.211 143.93 368.501 143.59C368.501 143.59 357.771 148.28 360.581 170.83C362.981 173 366.091 172.63 369.051 171.6Z"
          fill="#FEF2FE"
        />
        <path d="M368.3 144.96L368.24 145.87L369.36 145.73L369.26 144.75L368.3 144.96Z" fill="#FFD83A" />
        <path
          d="M369.261 144.75L367.391 145.16L367.761 143.6L367.591 144.86L369.311 144.65L369.741 146.23L372.741 145.09L369.471 146.66L369.261 144.75Z"
          fill="#F6DEF8"
        />
        <path
          d="M360.59 170.84C360.63 171.17 360.68 171.51 360.72 171.84C360.72 171.84 363.28 175.69 368.93 172.42L369.05 171.6C366.09 172.63 362.98 173 360.59 170.84Z"
          fill="#09205A"
        />
        <path
          d="M368.429 145.65C368.539 145.44 368.599 145.33 368.599 145.33C361.919 150.95 359.609 163.1 359.609 163.1L361.839 169.28C361.579 160.97 363.579 152.52 368.429 145.65Z"
          fill="#FFD83A"
        />
        <path
          d="M368.43 145.65C363.58 152.52 361.57 160.97 361.84 169.28L361.88 169.39L366 166.01C366 166.01 365.76 164.8 365.26 159.87C364.62 153.45 367.67 147.13 368.43 145.65Z"
          fill="#FFCC00"
        />
        <path
          d="M375.87 149.23C376.25 149.18 376.62 149.13 377 149.07L377.09 143.73C377.09 143.73 375.28 144.17 374.81 144.26C374.34 144.35 372.99 144.5 372.99 144.5C355.26 157.79 366.791 184.81 366.791 184.81C372.971 184.81 377.981 179.22 377.981 179.22C373.321 173.6 372.321 167.88 372.761 163.1C372.691 158.4 374.09 153.68 375.87 149.23Z"
          fill="#32467A"
        />
        <path
          d="M376.99 149.07C376.62 149.13 376.24 149.19 375.86 149.23C374.08 153.67 372.69 158.39 372.74 163.1C373.37 156.26 376.95 151.33 376.95 151.33L376.99 149.07Z"
          fill="#09205A"
        />
        <path
          d="M360.18 147.38C360.18 147.38 358.47 172.53 356.52 178.9C356.52 178.9 356.13 180.3 360.11 184.26L360.71 171.83C360.71 171.83 358.26 150.22 368.5 143.59C368.5 143.59 366.64 142.57 366.25 142.16C366.25 142.16 364.91 141.08 364.7 140.77C364.5 140.46 360.18 147.38 360.18 147.38Z"
          fill="#32467A"
        />
        <path d="M333.71 231.22L320.49 233.89H334.36L333.71 231.22Z" fill="#09205A" />
        <path d="M397.301 231.21L384.721 233.89H400.451L397.301 231.21Z" fill="#09205A" />
        <path
          d="M373.78 151.34L369.58 149.88L372.98 149.01L374.5 144.32C373.91 144.41 372.98 144.51 372.98 144.51C364.01 151.23 362.14 161.43 363.25 169.86C363.82 159.26 373.78 151.34 373.78 151.34Z"
          fill="#09205A"
        />
        <path
          d="M360.531 169.17C360.251 163.18 360.491 148.79 368.511 143.59C368.511 143.59 367.681 143.13 367.011 142.71L362.991 145.04L363.161 147.26L358.621 148.19C358.621 148.19 359.661 165.41 360.531 169.17Z"
          fill="#09205A"
        />
        <path
          d="M355.27 104.97C355.381 104.97 355.47 104.867 355.47 104.74C355.47 104.613 355.381 104.51 355.27 104.51C355.16 104.51 355.07 104.613 355.07 104.74C355.07 104.867 355.16 104.97 355.27 104.97Z"
          fill="#F6DEF8"
        />
        <path
          d="M355.069 106.39C355.069 106.56 354.949 106.7 354.799 106.7C354.649 106.7 354.529 106.56 354.529 106.39C354.529 106.22 354.649 106.08 354.799 106.08C354.949 106.08 355.069 106.22 355.069 106.39Z"
          fill="#7860DC"
        />
        <path
          d="M354.749 107.5C354.749 107.67 354.629 107.81 354.479 107.81C354.329 107.81 354.209 107.67 354.209 107.5C354.209 107.33 354.329 107.19 354.479 107.19C354.629 107.19 354.749 107.33 354.749 107.5Z"
          fill="#7860DC"
        />
        <path
          d="M354.47 108.61C354.47 108.78 354.35 108.92 354.2 108.92C354.05 108.92 353.93 108.78 353.93 108.61C353.93 108.44 354.05 108.3 354.2 108.3C354.35 108.3 354.47 108.44 354.47 108.61Z"
          fill="#7860DC"
        />
        <path
          d="M360.429 171.72C360.429 172.07 360.249 172.36 360.019 172.36C359.789 172.36 359.609 172.07 359.609 171.72C359.609 171.37 359.789 171.08 360.019 171.08C360.249 171.08 360.429 171.37 360.429 171.72Z"
          fill="#09205A"
        />
        <path
          d="M365.52 172.13H364.28C364.22 172.13 364.16 172.08 364.16 172.01C364.16 171.95 364.21 171.89 364.28 171.89H365.52C365.58 171.89 365.64 171.94 365.64 172.01C365.63 172.08 365.58 172.13 365.52 172.13Z"
          fill="#09205A"
        />
        <path
          d="M368.84 175.63C368.72 175.63 368.62 175.54 368.61 175.42C368.6 175.29 368.69 175.18 368.82 175.17C368.85 175.17 372.1 174.87 373.42 173.22C373.5 173.12 373.65 173.1 373.75 173.18C373.85 173.26 373.87 173.41 373.79 173.51C372.35 175.31 369.01 175.62 368.87 175.64C368.85 175.63 368.85 175.63 368.84 175.63Z"
          fill="#09205A"
        />
        <path
          d="M371.01 151.66C370.99 151.66 370.98 151.66 370.96 151.65L370.05 151.22C369.99 151.19 369.97 151.12 369.99 151.07C370.02 151.01 370.09 150.99 370.14 151.01L371.05 151.44C371.11 151.47 371.13 151.54 371.11 151.59C371.1 151.64 371.06 151.66 371.01 151.66Z"
          fill="#32467A"
        />
        <path
          d="M378.64 85.42H383.53C393.43 85.42 401.46 93.45 401.46 103.35C401.46 113.25 393.43 121.28 383.53 121.28H378.64L358.92 128.27V78.43L378.64 85.42Z"
          fill="#0098DA"
        />
        <path
          d="M375.99 118.23H380.88C390.78 118.23 398.81 110.2 398.81 100.3C398.81 96.54 397.65 93.05 395.67 90.16C399.23 93.44 401.46 98.13 401.46 103.35C401.46 113.25 393.43 121.28 383.53 121.28H378.64L358.92 128.27V124.27L375.99 118.23Z"
          fill="#006D9C"
        />
        <path
          d="M357.519 128.52C363.037 128.52 367.509 117.251 367.509 103.35C367.509 89.449 363.037 78.18 357.519 78.18C352.002 78.18 347.529 89.449 347.529 103.35C347.529 117.251 352.002 128.52 357.519 128.52Z"
          fill="#006D9C"
        />
        <path
          d="M357.52 123.53C361.938 123.53 365.52 114.495 365.52 103.35C365.52 92.2049 361.938 83.17 357.52 83.17C353.101 83.17 349.52 92.2049 349.52 103.35C349.52 114.495 353.101 123.53 357.52 123.53Z"
          fill="#0098DA"
        />
        <path
          d="M338.09 97.44H357.52C358.81 97.44 359.86 96.39 359.86 95.1C359.86 93.81 358.81 92.76 357.52 92.76H338.09C336.8 92.76 335.75 93.81 335.75 95.1C335.74 96.39 336.79 97.44 338.09 97.44Z"
          fill="#32467A"
        />
        <path
          d="M338.09 113.03H357.52C358.81 113.03 359.86 111.98 359.86 110.69C359.86 109.4 358.81 108.35 357.52 108.35H338.09C336.8 108.35 335.75 109.4 335.75 110.69C335.74 111.98 336.79 113.03 338.09 113.03Z"
          fill="#32467A"
        />
        <path
          d="M375.99 118.23C375.99 118.23 381.12 112.18 380.19 100.09C380.19 100.09 385.19 112.05 380.19 119.52L375.99 118.23Z"
          fill="#006D9C"
        />
        <path
          d="M399.13 105.31C396.7 106.01 395.12 111.64 396.38 113.04C397.78 114.59 398.87 114.21 398.87 114.21C398.87 114.21 398.8 115.97 398.61 118.49C399.58 117.87 400.58 117.2 401.35 116.37C401.23 115.21 401.05 114.05 400.81 112.88C400.22 110.53 400.94 107.37 399.13 105.31Z"
          fill="#FF96A9"
        />
        <path
          d="M401.041 111.79C401.041 111.79 402.831 105.81 399.951 105.26C399.671 105.21 399.391 105.23 399.131 105.3C400.941 107.36 400.221 110.53 400.811 112.87C401.061 114.03 401.231 115.2 401.351 116.36C401.521 116.17 401.691 115.98 401.841 115.78C401.671 114.24 401.421 112.87 401.041 111.79Z"
          fill="#ED7E94"
        />
        <path
          d="M369.981 136.15L371.791 136.51C371.791 136.51 371.121 137.41 370.511 137.33C369.891 137.25 369.981 136.15 369.981 136.15Z"
          fill="white"
        />
        <path
          d="M372.98 144.5C372.97 144.5 371.36 144.53 370.61 144.32C370.27 144.22 369.93 144.13 369.59 144.02L369.32 144.66L372.98 144.5Z"
          fill="#FF96A9"
        />
        <path
          d="M32.7305 22.88H142.93L103.09 16.67C103.09 16.67 102.87 4.68 89.7705 9.57C89.7705 9.57 88.7005 0.470002 78.5805 0.0300017C68.4605 -0.409998 69.1305 8.24 69.1305 8.24C69.1305 8.24 56.2505 -0.209998 48.7105 8.01C41.1705 16.23 53.1505 18.44 53.1505 18.44L32.7305 22.88Z"
          fill="#E7F8FF"
        />
        <path
          d="M407.89 39.83H343.74L366.93 36.21C366.93 36.21 367.06 29.23 374.68 32.08C374.68 32.08 375.3 26.78 381.19 26.52C387.08 26.26 386.69 31.3 386.69 31.3C386.69 31.3 394.18 26.39 398.58 31.17C402.97 35.95 396 37.24 396 37.24L407.89 39.83Z"
          fill="#E7F8FF"
        />
        <path
          d="M157.796 118.99V66.8502C157.796 64.6676 155.953 62.8488 153.742 62.8488C152.144 62.8488 151.161 63.5763 150.424 64.5464L110.86 120.324C110.246 121.173 110 121.9 110 122.749C110 124.81 111.72 126.508 113.809 126.508H149.687V142.271C149.687 144.454 151.53 146.272 153.742 146.272C155.953 146.272 157.796 144.454 157.796 142.271V126.508H164.8C167.012 126.508 168.732 124.81 168.732 122.628C168.732 120.566 167.012 118.99 164.8 118.99H157.796ZM120.935 118.99L149.687 78.2482V118.99H120.935Z"
          fill="#0098DA"
        />
        <path
          d="M210.144 139.725C194.908 139.725 187.904 129.539 187.904 104.439C187.904 79.3395 194.908 69.2753 210.144 69.2753C225.379 69.2753 232.383 79.3395 232.383 104.439C232.383 129.539 225.379 139.725 210.144 139.725ZM210.144 62C189.502 62 179.795 75.3381 179.795 104.439C179.795 133.541 189.502 147 210.144 147C230.786 147 240.492 133.541 240.492 104.439C240.492 75.3381 230.786 62 210.144 62Z"
          fill="#0098DA"
        />
        <path
          d="M298.065 118.99V66.8502C298.065 64.6676 296.222 62.8488 294.01 62.8488C292.413 62.8488 291.43 63.5763 290.692 64.5464L251.128 120.324C250.514 121.173 250.268 121.9 250.268 122.749C250.268 124.81 251.988 126.508 254.077 126.508H289.955V142.271C289.955 144.454 291.798 146.272 294.01 146.272C296.222 146.272 298.065 144.454 298.065 142.271V126.508H305.068C307.28 126.508 309 124.81 309 122.628C309 120.566 307.28 118.99 305.068 118.99H298.065ZM261.204 118.99L289.955 78.2482V118.99H261.204Z"
          fill="#0098DA"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="448.29" height="234.98" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PageNotFoundIcon;
