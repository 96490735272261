import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { ReportState } from './reducer';
import { RequestStatus } from '../Store.types';

export const selectReport = ({ report }: RootState) => report;
export const selectCandidateReport = ({ candidateReport }: ReportState) => candidateReport;

export const reportStatusSelector = createSelector(selectReport, (report) => mapStatus(report));

const mapStatus = (report: ReportState): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = report;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

export const candidateReportSelector = createSelector(selectReport, selectCandidateReport);
