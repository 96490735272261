import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getCandidateReportWithSectionsThunk } from '../candidateReport';
import { getLanguageNames, getLevels, putLanguageList } from '../../api';
import { AssignLanguageListDto } from '../../api/models/AssignLanguageListDto';

export enum Actions {
  getLanguageNames = '[LANGUAGE] GET LANGUAGE NAMES',
  getLevels = '[LANGUAGE] GET LEVELS',
  assignLanguageList = '[LANGUAGE] ASSIGN LANGUAGE LIST',
}

export const getLanguageNamesThunk = createAsyncThunk(Actions.getLanguageNames, async () => {
  const { data } = await getLanguageNames();

  return data.items;
});

export const getLevelsThunk = createAsyncThunk(Actions.getLevels, async () => {
  const { data } = await getLevels();

  return data.items;
});

export const assignLanguageListThunk = createAsyncThunk(
  Actions.assignLanguageList,
  async (assignLanguageListDto: AssignLanguageListDto) => {
    const { data } = await putLanguageList(assignLanguageListDto);

    return data;
  },
);

export const isPendingLanguageNamesAction = isPending(getLanguageNamesThunk);
export const isFulfilledLanguageNamesAction = isFulfilled(getLanguageNamesThunk);
export const isRejectedLanguageNamesAction = isRejected(getLanguageNamesThunk);

export const isPendingLevelsAction = isPending(getLevelsThunk);
export const isFulfilledLevelsAction = isFulfilled(getLevelsThunk);
export const isRejectedLevelsAction = isRejected(getLevelsThunk);

export const isPendingLanguageListAction = isPending(assignLanguageListThunk);
export const isFulfilledLanguageListAction = isFulfilled(assignLanguageListThunk);
export const isLanguageFromCandidateReportAction = isFulfilled(getCandidateReportWithSectionsThunk);
export const isRejectedLanguageListsAction = isRejected(assignLanguageListThunk);
