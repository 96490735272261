import React, { FC, useCallback, useMemo, useState } from 'react';
import TooltipModal from '../components/TooltipModal/TooltipModal';

const useModal = (modalTitle: string) => {
  const [modalOpened, toggleModal] = useState(false);
  const openModal = useCallback(() => toggleModal(true), []);
  const closeModal = useCallback(() => toggleModal(false), []);

  const Modal = useMemo(() => {
    const ModalComponent: FC = ({ children }) => {
      return (
        <TooltipModal headerTitle={modalTitle} closeButtonCallback={closeModal} modalState={modalOpened}>
          {children}
        </TooltipModal>
      );
    };

    return ModalComponent;
  }, [modalTitle, modalOpened]);

  return [Modal, openModal] as const;
};

export default useModal;
