import { NavigationAction, NavigationProps } from '../../../../components';
import dayjs from 'dayjs';
import { months } from '../../../../constants/dates';

export const DEFAULT_EXPERIENCE: ExperienceFields = {
  id: null,
  jobtitle: '',
  company: '',
  currently_working: false,
  month_start: months[0],
  month_end: months[0],
  year_start: dayjs().year().toString(),
  year_end: dayjs().year().toString(),
  business_projects: [
    {
      id: null,
      duration_end_month: months[0],
      duration_end_year: dayjs().year().toString(),
      duration_start_month: months[0],
      duration_start_year: dayjs().year().toString(),
      industry: '',
      responsibilities: '',
      business_project_description: '',
      role: '',
      currentlyWorking: false,
    },
  ],
};

export interface ExperienceProps {
  navigation: NavigationProps;
  editMode?: boolean;
}

export interface ExperienceFields {
  id: number | null;
  jobtitle: string;
  company: string;
  currently_working: boolean; // TODO: react-hook-form puts value like: ["on", "on"]. Length=1 is false, length=2 is true
  month_start: string;
  month_end?: string;
  year_start: string;
  year_end?: string;
  business_projects: BusinessProjectFields[];
}

export interface BusinessProjectFields {
  id: number | null;
  duration_start_month: string;
  duration_end_month: string;
  duration_start_year: string;
  duration_end_year: string;
  responsibilities: string;
  industry: string;
  business_project_description: string;
  role: string | null;
  currentlyWorking: boolean;
}

export interface ExperienceFormFields {
  experience: ExperienceFields[];
}

export interface ExperienceFormWithAction extends ExperienceFormFields {
  action: NavigationAction;
  aboutMe: string;
}
