import React, { FC, Fragment } from 'react';
import { Level, Levels } from '../../../../components';
import { SkillModel } from '../Summary.types';

interface SkillsLevelProps {
  skills: SkillModel[];
  level: Levels;
}

const SkillsLevel: FC<SkillsLevelProps> = ({ skills, level }) => {
  const half = Math.ceil(skills.length / 2);

  const leftSide = skills.slice(0, Math.ceil(skills.length / 2));
  const rightSite = skills.slice(half, skills.length);

  return (
    <Fragment>
      <div className="skills-level">
        <div className="skills-level_wrapper">
          <h5>{Levels[level]}</h5>
          <Level defaultValue={level} isEditable={false} disableHighlight={true} />
        </div>
        <div className="skills-level_list">
          {leftSide.map((i: SkillModel, id: number) => (
            <Fragment key={id}>
              <div className="badge-skill">
                <i className="badge-skill_item">{i.experience}</i>
                {i.skill}
              </div>
            </Fragment>
          ))}
          {rightSite.map((i: SkillModel, id: number) => (
            <Fragment key={id}>
              <div className="badge-skill">
                <i className="badge-skill_item">{i.experience}</i>
                {i.skill}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default SkillsLevel;
