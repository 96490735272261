import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Editor,
  EditorState,
  ContentState,
  ContentBlock,
  genKey,
  CharacterMetadata,
  Modifier,
  DraftHandleValue,
  BlockMapBuilder,
  DraftBlockType,
} from 'draft-js';
import { List, Repeat } from 'immutable';
import { isMobile } from 'react-device-detect';
import { ErrorMessage } from '@hookform/error-message';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TooltipModal from '../../../../components/TooltipModal/TooltipModal';
import { RequiredFieldIndicator, HoverHint, CharacterCount } from '../../../../components';
import { FieldErrorsImpl, FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';

const createContentBlockArray = (textArray: string[]) => {
  return textArray.map((word) => {
    return new ContentBlock({
      key: genKey(),
      type: 'unordered-list-item',
      characterList: new (List as any)(Repeat(CharacterMetadata.create(), word.length)),
      text: word,
    });
  });
};

interface ResponsibilitiesControllProps {
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  errors: Partial<FieldErrorsImpl>;
}

interface ResponsibilitiesControllHandle {
  focus: () => void;
  blur: () => void;
  scrollIntoView: () => void;
}

const ResponsibilitiesControll = forwardRef<ResponsibilitiesControllHandle, ResponsibilitiesControllProps>(
  ({ setValue, name, errors, ...register }, ref) => {
    const editorRef = useRef<Editor | null>(null);
    const editorScrollElementRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(
      ref,
      () => ({
        focus() {
          editorRef.current?.focus();
        },
        blur() {
          editorRef.current?.blur();
        },
        scrollIntoView() {
          editorScrollElementRef.current?.scrollIntoView();
        },
      }),
      [],
    );

    const { t } = useTranslation();

    const responsibilitiesValue = useWatch({ name });
    const responsibilitiesPropsData = [
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.FIRST') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.SECOND') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.THIRD') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.FOURTH') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.FIFTH') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.SIXTH') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.SEVENTH') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.EIGHTH') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.NINTH') },
      { description: t('EXPERIENCE.HINTS.RESPONSIBILITIES.BODY.LIST_ITEMS.TENTH') },
    ];

    const [responsibilitiesModalOpened, toggleResponsibilitiesModal] = useState(false);

    const [responsibilitiesFocus, setResponsibilitiesFocus] = useState(false);

    const [editorState, setEditorState] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          createContentBlockArray(responsibilitiesValue ? responsibilitiesValue.split(/\n/) : ['']),
        ),
      );
    });

    const responsibilitiesLimit = 2000;
    const [responsibilitiesCount, setResponsibilitiesCount] = useState(
      responsibilitiesValue ? responsibilitiesValue.length : 0,
    );

    const hasUnstyledBlocks = (state: EditorState): boolean => {
      const blocks = state.getCurrentContent().getBlocksAsArray();
      const blockTypes = blocks.map((block) => block.getType());

      // check draft-js CoreDraftBlockType
      return blockTypes.includes('unstyled' as DraftBlockType);
    };

    const formatResponsibilities = (responsibilities: string): string =>
      responsibilities
        .split('\n')
        .map((responsibility) => {
          const trimmed = responsibility.trim();
          return trimmed.charAt(0).toUpperCase() + trimmed.slice(1);
        })
        .join('\n');

    const handleResponsibilitiesChange = (state: EditorState) => {
      const currentContentState = editorState.getCurrentContent();
      const newContentState = state.getCurrentContent();

      const shouldValidate = newContentState === currentContentState ? false : true;

      setResponsibilitiesCount(
        state
          .getCurrentContent()
          .getPlainText()
          .replaceAll(/\u000A/g, '').length,
      );
      if (!hasUnstyledBlocks(state)) {
        setEditorState(state);

        const valueForField = formatResponsibilities(newContentState.getPlainText());
        setValue(name, valueForField, { shouldValidate });
      } else {
        setEditorState(editorState);

        const valueForField = formatResponsibilities(currentContentState.getPlainText());
        setValue(name, valueForField, { shouldValidate });
      }
    };

    const handlePastedText = (
      text: string,
      html: string | undefined,
      state: EditorState,
      name: string,
    ): DraftHandleValue => {
      const fragmentFromText = text
        .split(/\r|\n|\r\n/)
        .filter((str) => str.length > 1)
        .map((item) => item.trim());

      if (fragmentFromText.length === 0) return 'handled';

      const newState = Modifier.replaceWithFragment(
        state.getCurrentContent(),
        state.getSelection(),
        BlockMapBuilder.createFromArray(createContentBlockArray(fragmentFromText)),
      );
      handleResponsibilitiesChange(EditorState.push(state, newState, 'insert-fragment'));

      return 'handled';
    };

    return (
      <>
        <TooltipModal
          headerTitle={t('EXPERIENCE.HINTS.RESPONSIBILITIES.HEADER')}
          closeButtonCallback={() => toggleResponsibilitiesModal(!responsibilitiesModalOpened)}
          modalState={responsibilitiesModalOpened}
        >
          {responsibilitiesPropsData.map((responsibility, index) => {
            return (
              <div key={index}>
                <Row>
                  <Col>{responsibility.description}</Col>
                </Row>
                <hr />
              </div>
            );
          })}
        </TooltipModal>
        <div className="experience-container experience-container_responsibilities">
          <label htmlFor="responsibilities">
            {isMobile ? (
              <div onClick={() => toggleResponsibilitiesModal(!responsibilitiesModalOpened)}>
                <span>{t('EXPERIENCE.ENTRY.RESPONSIBILITIES')}</span>
                <span className="hoverHintButton">i</span>
              </div>
            ) : (
              <HoverHint
                header={t('EXPERIENCE.HINTS.RESPONSIBILITIES.HEADER')}
                titleText={
                  <span>
                    {t('EXPERIENCE.ENTRY.RESPONSIBILITIES')}
                    <RequiredFieldIndicator />
                  </span>
                }
              >
                <ul className="hover-list">
                  {responsibilitiesPropsData.map((responsibility, index) => {
                    return <li key={index}>{responsibility.description}</li>;
                  })}
                </ul>
              </HoverHint>
            )}
          </label>
          <textarea
            {...register}
            name={name}
            id="responsibilities"
            defaultValue={responsibilitiesValue ? responsibilitiesValue : ''}
            className="hidden"
          />
          <div
            id={'responsibilities-border'}
            className={`responsibilityBorder${responsibilitiesFocus ? '-focus' : ''}`}
            ref={editorScrollElementRef}
          >
            <Editor
              ref={editorRef}
              onFocus={() => setResponsibilitiesFocus(true)}
              onBlur={() => setResponsibilitiesFocus(false)}
              editorState={editorState}
              onChange={(state: EditorState) => handleResponsibilitiesChange(state)}
              stripPastedStyles={true}
              handlePastedText={(text, html, state) => handlePastedText(text, html, state, name)}
            />
          </div>
          <div className="text-area-errors">
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <span className="experience-container experience-container_error">{message}</span>
              )}
            />
            <CharacterCount limit={responsibilitiesLimit} count={responsibilitiesCount} />
          </div>
        </div>
      </>
    );
  },
);

ResponsibilitiesControll.displayName = 'CustomMenu';

export default ResponsibilitiesControll;
