import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getDegrees } from '../../api/services/degree';

export enum Actions {
  getDegrees = '[DICT-DEGREE] get list',
}

export const getDegreesThunk = createAsyncThunk(Actions.getDegrees, async () => {
  const { data } = await getDegrees();

  return data.items;
});

export const isPendingDegreeNameAction = isPending(getDegreesThunk);
export const isFulfilledDegreeNameAction = isFulfilled(getDegreesThunk);
export const isRejectedDegreeNameAction = isRejected(getDegreesThunk);
