import { createSelector } from '@reduxjs/toolkit';
import { CandidateSkillDto } from '../../api';
import { RootState } from '../rootReducer';
import { SkillsForm, SkillGroupOrder } from '../../types';
import { adapter, CandidateSkillsState } from './reducer';
import { SkillsFormGroup } from '../../modules/Skills/components';
import { RequestStatus } from '../Store.types';

const selectReports = ({ candidateSkills }: RootState) => candidateSkills;
const selectReportsItems = ({ skills }: CandidateSkillsState) => skills;

export const skillsStatusSelector = createSelector(selectReports, (candidateSkills) => mapStatus(candidateSkills));

const mapStatus = (candidateSkills: CandidateSkillsState): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = candidateSkills;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

const skillsEntitiesSelector = createSelector(selectReports, selectReportsItems);

export const skillsSelector = adapter.getSelectors(skillsEntitiesSelector);

export const formSkillsSelector = createSelector(skillsSelector.selectAll, (skills) => mapToFormSkills(skills));

export const skillGroupOrderSelector = createSelector(skillsSelector.selectAll, (skills) =>
  mapGroupNameToOrder(skills),
);

const mapToFormSkills = (skills: CandidateSkillDto[]): SkillsForm =>
  Object.values(skills).reduce(
    (formSkills: SkillsForm, skill: CandidateSkillDto) => {
      const { groupName } = skill;

      if (!(groupName in formSkills)) {
        formSkills[groupName] = [];
      }
      formSkills[groupName].push(skill);

      return formSkills;
    },
    { [SkillsFormGroup.other]: [] },
  );

const mapGroupNameToOrder = (skills: CandidateSkillDto[]): SkillGroupOrder =>
  Object.values(skills).reduce((groupOrder: SkillGroupOrder, skill: CandidateSkillDto) => {
    const { groupName, skillGroupNameIndex } = skill;

    if (!(groupName in groupOrder)) {
      groupOrder[groupName] = skillGroupNameIndex;
    }

    return groupOrder;
  }, {});
