import React from 'react';

interface CloseProps {
  variant?: 'with-border';
}

const Close = ({ variant }: CloseProps) => {
  if (variant && variant === 'with-border') {
    return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="39" height="39" rx="9.5" fill="white" stroke="#C5DCEB" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9577 14.359L19.9671 18.6983V20.3477H18.4446L14.3083 15.907C13.8769 15.4502 13.9023 14.7143 14.359 14.3083C14.8158 13.8769 15.5263 13.9023 15.9577 14.359ZM20.2462 18.6983L24.2556 14.359C24.687 13.9023 25.3975 13.8769 25.8543 14.3083C26.3111 14.7143 26.3364 15.4502 25.9051 15.907L21.7688 20.3477H20.2462V18.6983ZM21.7688 20.6269L25.9051 25.093C26.3364 25.5498 26.3111 26.2603 25.8543 26.6917C25.3975 27.1231 24.687 27.0977 24.2556 26.641L20.2462 22.3017V20.6269H21.7688ZM19.9671 22.3017L15.9577 26.641C15.5263 27.0977 14.8158 27.1231 14.359 26.6917C13.9023 26.2603 13.8769 25.5498 14.3083 25.093L18.4446 20.6269H19.9671V22.3017Z"
          fill="#4E5A62"
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path d="M8.5 1L1 8.5" stroke="#98A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 1L8.5 8.5" stroke="#98A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Close;
