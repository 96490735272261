import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageNotFoundIcon } from '../../icons';
import './PageNotFound.scss';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <main className="page-not-found">
      <section className="page-not-found_container">
        <PageNotFoundIcon />
        <span className="page-not-found_container--text">{t('ERROR.PAGE_NOT_FOUND')}</span>
      </section>
    </main>
  );
};

export default PageNotFound;
