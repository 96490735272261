import { createEntityAdapter, createReducer, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  Actions,
  isCertificatesFromCandidateReportAction,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';
import { CertificateDto, CertificateListDto } from '../../api';

export interface CertificateState extends RequestStatus {
  certificates: EntityState<CertificateDto>;
}

export const adapter = createEntityAdapter<CertificateDto>();

const initialState: CertificateState = {
  ...statusNotAsked,
  certificates: adapter.getInitialState(),
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isPendingAction, (state: CertificateState) => ({
      ...state,
      ...statusIsLoading,
    }))
    .addMatcher(
      isCertificatesFromCandidateReportAction,
      (state: CertificateState, action: PayloadAction<{ certificates: CertificateListDto }>) => ({
        ...state,
        ...statusIsSuccess,
        certificates: adapter.setAll({ ...state.certificates }, action.payload.certificates),
      }),
    )
    .addMatcher(isFulfilledAction, (state: CertificateState, action: PayloadAction<CertificateListDto>) => ({
      ...state,
      ...statusIsSuccess,
      certificates: adapter.setAll({ ...state.certificates }, action.payload),
    }))
    .addMatcher(isRejectedAction, (state: CertificateState, { error }) => ({
      ...state,
      ...statusIsFailure,
      error,
    }));
});

export default reducer;

export type CertificatesState = ReturnType<typeof reducer>;
