import React, { FC, MouseEventHandler } from 'react';
import HitBox from '../HitBox/HitBox';
import { Close } from '../../icons';

import './CloseButton.scss';

export interface CloseButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const CloseButton: FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <HitBox className="close-button" onClick={onClick}>
      <Close />
    </HitBox>
  );
};

export default CloseButton;
