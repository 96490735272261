import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { finishReport, updateReportStatus } from '../../api';
import { StatusEnum } from '../../types/reportStatus';

export enum Actions {
  finishCandidateReport = '[FINISH REPORT CANDIDATE] POST CANDIDATE REPORT',
  updateStatusReport = 'UPDATE REPORT STATUS',
}

export const finishCandidateReportThunk = createAsyncThunk(Actions.finishCandidateReport, async () => {
  const { data } = await finishReport();

  return data;
});

export const updateReportStatusThunk = createAsyncThunk(
  Actions.updateStatusReport,
  async (payload: { id: number; status: StatusEnum }) => {
    updateReportStatus(payload.id, payload.status);
  },
);

export const isPendingAction = isPending(finishCandidateReportThunk);
export const isFulfilledAction = isFulfilled(finishCandidateReportThunk);
export const isRejectedAction = isRejected(finishCandidateReportThunk);
