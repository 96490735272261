import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Paper, RemoveButton, ConfirmationModal, RequiredFieldIndicator } from '../../../../components';
import SelectKeyboard from '../../../../components/SelectKeyboard/SelectKeyboard';
import { TrashIcon } from '../../../../icons';
import { ErrorMessage } from '@hookform/error-message';

interface CertificateFormProps {
  index: number;
  removeCertificate: (index: number) => void;
}

const CertificateForm: FC<CertificateFormProps> = ({ index, removeCertificate }) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const [showModal, setShowModal] = useState(false);

  const toggleShowModal = () => setShowModal(!showModal);

  const baseName = `certificates[${index}]`;

  const id = `${baseName}.id`;
  const idValue = watch(id);
  const type = `${baseName}.type`;
  const typeValue = watch(type);
  const issuer = `${baseName}.issuer`;
  const issuerValue = watch(issuer);

  const formErrors = (errors?.certificates as any)?.[index];

  const yearNotInFuture = (inputYear: string) => {
    const error = t('CERTIFICATES.VALIDATION.NO_FUTURE_YEAR');
    const currentYear = new Date().getFullYear();

    return currentYear < +inputYear ? error : undefined;
  };

  return (
    <Paper>
      <section className="certificate-container certificate">
        <div className="certificate-container education-container_input mt-4">
          <input
            {...register(id, { valueAsNumber: true })}
            type="hidden"
            defaultValue={isNaN(idValue) ? null : idValue}
          />
          <label htmlFor={type}>
            {t('CERTIFICATES.TYPE')}
            <RequiredFieldIndicator />
          </label>
          <input
            {...register(type, {
              required: { value: true, message: t('CERTIFICATES.VALIDATION.NAME_REQUIRED') },
              minLength: { value: 3, message: t('CERTIFICATES.VALIDATION.NAME_MIN_3') },
            })}
            type="text"
            defaultValue={typeValue}
            maxLength={200}
          />
          <ErrorMessage
            errors={errors}
            name={type}
            render={({ message }) => (
              <span className="certificate-container certificate-container_error">{message}</span>
            )}
          />
        </div>
        <div className="certificate-container certificate-container_issuer">
          <div className="certificate-container certificate-container_issuer-duration">
            <div className="certificate-container_issuer-duration-start">
              <p>{t('CERTIFICATES.ISSUER')}</p>
              <input
                {...register(issuer)}
                type="text"
                placeholder="Issuer"
                defaultValue={issuerValue}
                maxLength={200}
              />
              <ErrorMessage
                errors={errors}
                name={issuer}
                render={({ message }) => (
                  <span className="education-container education-container_error">{message}</span>
                )}
              />
            </div>
            <div className="certificate-container_issuer-duration-end">
              <p>
                {t('CERTIFICATES.DATE')} <RequiredFieldIndicator />
              </p>
              <div className="date-container">
                <SelectKeyboard
                  name={`${baseName}.year`}
                  className="year_start"
                  registerOptions={{
                    required: { value: true, message: t('CERTIFICATES.VALIDATION.YEAR_REQUIRED') },
                    min: { value: 1945, message: t('CERTIFICATES.VALIDATION.YEAR_MIN') },
                    validate: (x) => yearNotInFuture(x),
                  }}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name={`${baseName}.year`}
                render={({ message }) => (
                  <span className="education-container education-container_error">{message}</span>
                )}
              />
            </div>
          </div>
          <RemoveButton onClick={toggleShowModal} value="Remove" className="button__no-border">
            <TrashIcon />
          </RemoveButton>

          <ConfirmationModal
            title="Are you sure you want to remove certificate details?"
            confirmButtonText="Confirm"
            declineButtonText="Cancel"
            confirmButtonAction={() => removeCertificate(index)}
            declineButtonAction={toggleShowModal}
            show={showModal}
            onHide={toggleShowModal}
          />
        </div>
      </section>
    </Paper>
  );
};

export default CertificateForm;
