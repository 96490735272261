import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const axiosInstance: AxiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  baseURL: (() => {
    if (['localhost', '127.0.0.1'].indexOf(window.location.hostname) !== -1) {
      return 'http://localhost:3000/api';
    }

    const currentUrl = window.location.origin;

    // default azure domains
    if (currentUrl.includes('azurewebsites.net')) {
      return `${currentUrl.replace('hp-fo', 'hp-api')}/api`;
    }

    const defaultAppServiceApiUrl = currentUrl.replace('-fo-', '-api-');
    const devAppServiceApiUrl = defaultAppServiceApiUrl.replace('-hpfo', '-hpapi');
    const prodAppServiceApiUrl = devAppServiceApiUrl.replace('hp.', 'hpapi.');

    return `${prodAppServiceApiUrl}/api`;
  })(),
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  const response: AxiosResponse = error.response;
  const statusCode = response?.status ? response?.status : '520';

  let statusText;
  if (response?.statusText) {
    statusText = response?.statusText;
  } else {
    statusText = response?.data?.error ? response?.data?.error : 'Unknown Error';
  }

  const errorText = `${statusCode} ${statusText}`;

  toast.error(errorText);
  return Promise.reject(error);
});

axiosInstance.interceptors.request.use((data) => {
  const url = new URL(window.location.href);
  const path = url.hash;

  const token = path.replace('#/', '');
  if (data.url) {
    if (data.url.indexOf('dictionaries') !== -1 || data.url.indexOf('update-status') !== -1) return data; // ignore dictionary questions
    data.url = data.url.endsWith('/') ? `${data.url}${token}` : `${data.url}/${token}`;
  }

  return data;
});

export default axiosInstance;
