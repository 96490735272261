import React, { FC } from 'react';
import { Paper } from '../../components';
import { Step, Steps, UseStepProps } from './PageRoute.types';
import useStep from '../../utils/useStep';
import ConfirmationPage from '../../modules/ConfirmationPage/components/ConfirmationPage/ConfirmationPage';
import { AboutMe } from '../../modules/AboutMe';
import { Certificate } from '../../modules/Certificate';
import { Education } from '../../modules/Education';
import { Experience } from '../../modules/Experience';
import { LandingPage } from '../../modules/LandingPage';
import { Language } from '../../modules/Language';
import { Skills } from '../../modules/Skills';
import { Summary } from '../../modules/Summary';

const steps: Step[] = [
  { id: Steps.LANDING_PAGE },
  { id: Steps.SKILLS },
  { id: Steps.EDUCATION },
  { id: Steps.LANGUAGES },
  { id: Steps.CERTIFICATES },
  { id: Steps.ABOUT_ME },
  { id: Steps.EXPERIENCE },
  { id: Steps.SUMMARY },
  { id: Steps.CONFIRMATION_PAGE_SUBMITTED },
  { id: Steps.CONFIRMATION_PAGE_FINISH_LATER },
];

const PageRoute: FC = (): React.ReactElement => {
  const { step, navigation }: UseStepProps = useStep({
    steps,
    initialStep: 0,
  });

  switch (step.id) {
    case Steps.LANDING_PAGE:
      return (
        <Paper variant="landing-page">
          <LandingPage navigation={navigation} />
        </Paper>
      );
    case Steps.SKILLS:
      return (
        <div className="content-container">
          <Skills navigation={navigation} editMode={step.editMode} />
        </div>
      );
    case Steps.EDUCATION:
      return (
        <div className="content-container">
          <Education navigation={navigation} editMode={step.editMode} />
        </div>
      );
    case Steps.LANGUAGES:
      return (
        <div className="content-container">
          <Language navigation={navigation} editMode={step.editMode} />
        </div>
      );
    case Steps.CERTIFICATES:
      return (
        <div className="content-container">
          <Certificate navigation={navigation} editMode={step.editMode} />
        </div>
      );
    case Steps.ABOUT_ME:
      return (
        <div className="content-container">
          <AboutMe navigation={navigation} editMode={step.editMode} />
        </div>
      );
    case Steps.EXPERIENCE:
      return (
        <div className="content-container">
          <Experience navigation={navigation} editMode={step.editMode} />
        </div>
      );
    case Steps.SUMMARY:
      return (
        <div className="content-container">
          <Paper>
            <Summary navigation={navigation} />
          </Paper>
        </div>
      );
    case Steps.CONFIRMATION_PAGE_SUBMITTED:
      return (
        <div className="content-container">
          <Paper>
            <ConfirmationPage isFinishLater={false} />
          </Paper>
        </div>
      );
    case Steps.CONFIRMATION_PAGE_FINISH_LATER:
      return (
        <div className="content-container">
          <Paper>
            <ConfirmationPage isFinishLater={true} />
          </Paper>
        </div>
      );
  }

  return (
    <div>
      <h3>Hire profile front office</h3>
    </div>
  );
};

export default PageRoute;
