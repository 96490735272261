import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { PageRoute } from '../../../../components/';
import { Router } from '../../components';
import { ToastContainer } from 'react-toastify';
import './Root.scss';

interface Props {
  store: Store;
}

const ToastPortal = () => {
  return ReactDOM.createPortal(<ToastContainer className="Toastify" autoClose={3000} />, document.body);
};

const Root: FC<Props> = ({ store }) => {
  return (
    <Provider store={store}>
      <ToastPortal />
      <Router>
        <PageRoute />
      </Router>
    </Provider>
  );
};

export default Root;
