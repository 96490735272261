import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { CertificateListDto, putNewCertificates } from '../../api';
import { getCandidateReportWithSectionsThunk } from '../candidateReport';

export enum Actions {
  putNewCertificates = '[CERTIFICATE] PUT CERTIFICATES',
}

export const putNewCertificatesThunk = createAsyncThunk(
  Actions.putNewCertificates,
  async (certificates: CertificateListDto) => {
    const { data } = await putNewCertificates(certificates);

    return data;
  },
);

export const isPendingAction = isPending(putNewCertificatesThunk);
export const isFulfilledAction = isFulfilled(putNewCertificatesThunk);
export const isCertificatesFromCandidateReportAction = isFulfilled(getCandidateReportWithSectionsThunk);
export const isRejectedAction = isRejected(putNewCertificatesThunk);
