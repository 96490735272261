import React from 'react';

const Minus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="2" viewBox="0 0 11 2" fill="none">
      <path d="M10 1L1 1" stroke="#4E5A62" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Minus;
