import React, { FC } from 'react';
import './ConfirmationPage.scss';
import ConfirmPageIcon from '../../../../icons/ConfirmPageIcon';
import { useTranslation } from 'react-i18next';
interface ConfirmationPageProps {
  isFinishLater: boolean;
}

const ConfirmationPage: FC<ConfirmationPageProps> = ({ isFinishLater }) => {
  const { t } = useTranslation();

  const text = isFinishLater
    ? {
        paragraph: 'THANKS.DESCRIPTION_SAVE_AND_SEND',
      }
    : {
        paragraph: 'THANKS.DESCRIPTION_SAVE_AND_SEND_LATER',
      };

  return (
    <section className="confirmation-page">
      <header className="confirmation-page_header">
        <h1>
          {' '}
          <span className="confirmation-page--blue">{t('THANKS.THANK')}</span> {t('THANKS.YOU')}
        </h1>
        <span className="confirmation-page--blue">{t('THANKS.SUBTITLE')}</span>
        <hr className="confirmation-page_line" />
        <p className="confirmation-page_text">{t(text.paragraph)}</p>
        <p className="confirmation-page_text_end">{t('THANKS.END')}</p>
      </header>
      <ConfirmPageIcon className="confirmation-page_icon" />
    </section>
  );
};

export default ConfirmationPage;
