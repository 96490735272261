import { AxiosPromise } from 'axios';

import { CandidateSkillListDto } from '../models/CandidateSkillListDto';
import { AssignCandidateSkillListDto } from '../models/AssignCandidateSkillListDto';
import axiosInstance from '../axiosInstance';

const url = '/section';

export const assignSkills = (data: AssignCandidateSkillListDto): AxiosPromise<CandidateSkillListDto> => {
  return axiosInstance({
    url: `${url}/skills`,
    method: 'PUT',
    data,
  });
};
