import React, { FC } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import dayjs from 'dayjs';
import { ExtendedNavigation, Paper, PaperHeader, SAVE_AND_FINISH_LATER, Spinner, Steps } from '../../../../components';
import { CertificatesIcon } from '../../../../icons';
import { CertificateDto } from '../../../../api';
import { months } from '../../../../constants/dates';
import { navigateTo } from '../../../../utils/navigateTo';
import {
  certificateFormSelector,
  certificateStatusSelector,
  putNewCertificatesThunk,
} from '../../../../store/certificate';
import CertificateForm from '../../components/CertificateForm/CertificateForm';
import { CertificateFields, CertificateFormFieldsWithAction, CertificateProps } from './Certificate.type';
import './Certificate.scss';

export const DEFAULT_CERTIFICATE: CertificateFields = {
  id: null,
  type: '',
  issuer: '',
  month: '',
  year: '',
};

const mapCertificateToDto = ({ id, type, issuer, month, year }: CertificateFields): CertificateDto => ({
  id,
  type,
  issuer,
  issuedAt:
    Number(month) > 0
      ? dayjs().year(Number(year)).month(months.indexOf(month)).toDate()
      : Number(year) > 0
      ? dayjs().year(Number(year)).toDate()
      : undefined,
});

const Certificate: FC<CertificateProps> = ({ navigation, editMode }) => {
  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();
  const defaultValues = useSelector(certificateFormSelector);
  const { isPending } = useSelector(certificateStatusSelector);
  const methods = useForm<CertificateFormFieldsWithAction>({ defaultValues, mode: 'onBlur' });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'certificates',
    keyName: 'itemKey',
  });

  const handleAddCertificate = () => append(DEFAULT_CERTIFICATE);
  const handleRemoveCertificate = (index: number) => remove(index);
  const onSubmit = async (values: CertificateFormFieldsWithAction) => {
    const { certificates, action } = values;
    let resultAction;
    if (certificates) {
      const certificateListDto = certificates.map(mapCertificateToDto);
      resultAction = await dispatch(putNewCertificatesThunk(certificateListDto));
    } else {
      resultAction = await dispatch(putNewCertificatesThunk([]));
    }

    if (action.toString() === SAVE_AND_FINISH_LATER) {
      navigation.go(Steps.CONFIRMATION_PAGE_FINISH_LATER);
    } else if (putNewCertificatesThunk.fulfilled.match(resultAction)) {
      navigateTo(action, navigation);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Paper>
          <PaperHeader
            stepName="Certificates"
            icon={<CertificatesIcon className="education_text" />}
            currentStep={Steps.CERTIFICATES}
            text="CERTIFICATES.DESCRIPTION"
          />
        </Paper>
        {fields.map((field, index: number) => (
          <CertificateForm key={field.itemKey} index={index} removeCertificate={handleRemoveCertificate} />
        ))}
        <Paper>
          <ExtendedNavigation
            onSubmit={onSubmit}
            onAddNew={handleAddCertificate}
            addNewText="Add new certificate"
            addNewIsDisabled={!methods.formState.isValid}
            editMode={editMode}
            saveAndFinishLaterMode={true}
          />
        </Paper>
        {isPending && <Spinner />}
      </form>
    </FormProvider>
  );
};

export default Certificate;
