import React, { FC } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { isArray } from 'lodash';
import isEmpty from 'lodash.isempty';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExperienceDto } from '../../../../api';
import {
  ACTION_BACK,
  ExtendedNavigation,
  Paper,
  PaperHeader,
  SAVE_AND_FINISH_LATER,
  Spinner,
  Steps,
} from '../../../../components';
import { months } from '../../../../constants/dates';
import { ExperienceIcon } from '../../../../icons';
import { experienceFormSelector, experienceStatusSelector, putNewExperienceThunk } from '../../../../store/experience';
import { navigateTo } from '../../../../utils/navigateTo';
import { ExperienceForm } from '../../components/ExperienceForm/ExperienceForm';
import './Experience.scss';
import { DEFAULT_EXPERIENCE, ExperienceFields, ExperienceFormWithAction, ExperienceProps } from './Experience.types';

const Experience: FC<ExperienceProps> = ({ navigation, editMode }) => {
  const dispatch = useDispatch<ThunkDispatch<unknown, any, Action>>();
  const defaultValues = useSelector(experienceFormSelector);
  const { isPending } = useSelector(experienceStatusSelector);
  const methods = useForm<ExperienceFormWithAction>({ defaultValues, mode: 'onChange' });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: Steps.EXPERIENCE,
    keyName: 'itemKey',
  });
  const { t } = useTranslation();

  const onSubmit = async (values: ExperienceFormWithAction) => {
    const { experience, action } = values;
    if (fields.length === 0 && action.toString() !== ACTION_BACK) {
      methods.setError(Steps.EXPERIENCE, { message: t('EXPERIENCE.ERRORS.AT_LEAST_ONE') });
      return;
    }

    let experienceList: ExperienceDto[] = [];

    if (experience) {
      experienceList = getExperienceDtos(experience);
    }

    await methods.trigger();
    if (!isEmpty(methods.formState.errors)) return;

    experienceList.forEach((exp, expIdx) => {
      if (!exp.currentlyWork) {
        exp.businessProjects.forEach((bp, bpIdx) => {
          experienceList[expIdx].businessProjects[bpIdx].currentlyWorking = false;
        });
      }
    });
    const putResultAction = await dispatch(putNewExperienceThunk(experienceList));

    if (action?.toString() === SAVE_AND_FINISH_LATER) {
      navigation.go(Steps.CONFIRMATION_PAGE_FINISH_LATER);
    } else if (putNewExperienceThunk.fulfilled.match(putResultAction)) {
      navigateTo(action, navigation);
    }
  };

  const getExperienceDtos = (experience: ExperienceFields[]): ExperienceDto[] => {
    return experience.map((item: ExperienceFields) => ({
      id: item.id,
      jobTitle: item.jobtitle,
      company: item.company,
      currentlyWork: item.currently_working,
      startDate: dayjs(`${item.year_start}-${months.indexOf(item.month_start) + 1}`).toDate(),
      endDate:
        !item.currently_working && item.month_end
          ? dayjs(`${item.year_end}-${months.indexOf(item.month_end) + 1}`).toDate()
          : undefined,
      businessProjects: item.business_projects.map((businessProject) => ({
        id: businessProject.id,
        role: businessProject.role,
        currentlyWorking: businessProject.currentlyWorking ?? false,
        startDate:
          item.business_projects.length > 1 && businessProject.duration_start_year
            ? dayjs(
                `${businessProject.duration_start_year}-${months.indexOf(businessProject.duration_start_month) + 1}`,
              ).toDate()
            : undefined,
        endDate:
          item.business_projects.length > 1 && businessProject.duration_end_year
            ? dayjs(
                `${businessProject.duration_end_year}-${months.indexOf(businessProject.duration_end_month) + 1}`,
              ).toDate()
            : undefined,
        responsibilities: businessProject.responsibilities,
        industry: businessProject.industry,
        businessProjectDescription: businessProject.business_project_description,
      })),
    }));
  };

  const handleAddExperience = () => {
    append(DEFAULT_EXPERIENCE);
    methods.clearErrors('experience');
  };
  const handleRemoveExperience = (index: number) => remove(index);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Paper>
          <PaperHeader
            stepName="Experience"
            icon={<ExperienceIcon className="experience_text" />}
            currentStep={Steps.EXPERIENCE}
            text="EXPERIENCE.DESCRIPTION"
          />
        </Paper>
        {/* @ts-ignore TODO: upgrade TS version  */}
        {fields.map((field: Partial<ExperienceFields & { itemKey: string }>, index: number) => (
          <ExperienceForm key={field.itemKey} index={index} data={field} removeExperience={handleRemoveExperience} />
        ))}
        <Paper>
          <ErrorMessage
            errors={methods.formState.errors}
            name={'experience'}
            render={({ message }) =>
              message ? <p className="error-container extended-nav-error">{message}</p> : <></>
            }
          />
          <ExtendedNavigation
            onSubmit={onSubmit}
            onAddNew={handleAddExperience}
            addNewText="Add new experience"
            addNewIsDisabled={(() => {
              if (isArray(methods.formState.errors.experience)) {
                return !isEmpty(methods.formState.errors);
              }
              return false;
            })()}
            editMode={editMode}
            saveAndFinishLaterMode={true}
          />
        </Paper>
        {isPending && <Spinner />}
      </form>
    </FormProvider>
  );
};

export default Experience;
