import React, { FC } from 'react';
import { useFormState } from 'react-hook-form';
import Button from 'react-bootstrap/cjs/Button';
import Card from 'react-bootstrap/cjs/Card';
import Accordion from 'react-bootstrap/cjs/Accordion';
import Arrow from '../../../../icons/Arrow';
import { useTranslation } from 'react-i18next';
import './SkillAccordion.scss';
import Paper from '../../../../components/Paper/Paper';

export interface AccordionModel<T> {
  [key: string]: T[];
}

interface AccordionProps {
  groupName: string;
  index: string;
  active: boolean;
}

const SkillAccordion: FC<AccordionProps> = ({ groupName, index, active, children }) => {
  const { t } = useTranslation();
  const { errors } = useFormState({ name: groupName });
  const formErrors = errors?.[groupName];

  return (
    <Paper className={`accordion-container ${formErrors ? 'error' : ''}`}>
      <Card>
        <Card.Header className="accordion-container_header">
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={index}
            className="accordion-container_header--toggle"
            tabIndex={-1}
          >
            <h5 className="accordion-container_header--title">
              {t(`SKILLS.${groupName.toUpperCase().replace(/\./g, '')}`)}
            </h5>
            <div className={`arrow-container ${active ? 'active' : ''}`} tabIndex={0}>
              <Arrow />
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={index}>
          <Card.Body className="accordion-container_body">{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Paper>
  );
};

export default SkillAccordion;
