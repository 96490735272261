import { AxiosPromise } from 'axios';
import { ExperienceDto } from '../models/ExperienceDto';

import { ExperienceListDto } from '../models/ExperienceListDto';
import axiosInstance from '../axiosInstance';

const url = '/section';

export const putNewExperience = (data: ExperienceListDto): AxiosPromise<ExperienceDto> => {
  return axiosInstance({
    url: `${url}/experience`,
    method: 'PUT',
    data,
  });
};
