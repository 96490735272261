import { AxiosPromise } from 'axios';
import { SectionsDto } from '../models/SectionsDto';
import axiosInstance from '../axiosInstance';

const url = '/section';

export const getSections = (): AxiosPromise<SectionsDto> => {
  return axiosInstance({
    url: `${url}/`,
    method: 'GET',
  });
};
