import React, { FC, ReactNode } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import './LevelControl.scss';

interface LevelControlProps {
  name: string;
  defaultValue: number;
  level: ReactNode;
  registerOptions?: RegisterOptions;
}

const LevelControl: FC<LevelControlProps> = ({ name, defaultValue, level, registerOptions }) => {
  const { register } = useFormContext();

  return (
    <label className="skill-lvl">
      <input
        {...register(name, registerOptions)}
        className="level-control_input"
        type="number"
        defaultValue={defaultValue}
      />
      {level}
    </label>
  );
};

export default LevelControl;
