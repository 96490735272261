import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getCandidateReport, getSections, patchCandidateDescription } from '../../api';
import { DescriptionDto } from '../../api/models/DescriptionDto';

export enum Actions {
  getCandidateReport = '[CANDIDATE REPORT] GET CANDIDATE REPORT WITH SECTIONS',
  patchCandidatesDescription = '[CANDIDATE REPORT] PATCH CANDIDATE REPORT DESCRIPTION',
}

export const getCandidateReportWithSectionsThunk = createAsyncThunk(Actions.getCandidateReport, async () => {
  const [report, sections] = await Promise.all([getCandidateReport(), getSections()]);

  return { report: report.data, ...sections.data };
});

export const patchCandidateReportDescriptionThunk = createAsyncThunk(
  Actions.patchCandidatesDescription,
  async (description: DescriptionDto) => {
    const { data } = await patchCandidateDescription(description);
    const {
      data: { reportId },
    } = await getCandidateReport();

    return { ...data, reportId };
  },
);

export const isPendingAction = isPending(getCandidateReportWithSectionsThunk);
export const isFulfilledAction = isFulfilled(getCandidateReportWithSectionsThunk);
export const isFulfilledPatchAction = isFulfilled(patchCandidateReportDescriptionThunk);
export const isRejectedAction = isRejected(getCandidateReportWithSectionsThunk);
