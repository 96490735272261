import React, { FC } from 'react';
import './Pencil.scss';

interface PencilProps {
  className?: string;
}

const Pencil: FC<PencilProps> = ({ className = 'default-size' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.43435 10.7108L1.28906 7.56547L8.36597 0.488559C9.01738 -0.162853 10.0735 -0.162853 10.7249 0.488559L11.5113 1.27488C12.1627 1.92629 12.1627 2.98244 11.5113 3.63385L4.43435 10.7108Z"
      />
      <path d="M2.45435 12.0562C2.85155 12.0562 3.23414 11.9145 3.53458 11.6592L0.397006 8.5216C0.141651 8.82203 -1.69672e-05 9.20463 -1.69672e-05 9.60183V11.7782C-1.69672e-05 11.9317 0.124451 12.0562 0.27799 12.0562H2.45435Z" />
    </svg>
  );
};

export default Pencil;
