import React, { FC } from 'react';
import { CloseButton, Select } from '../../../../components';
import { KeyValueEntryDto } from '../../../../api/models/KeyValueEntryDto';
import { useTranslation } from 'react-i18next';

interface LanguageItemProps {
  levels: KeyValueEntryDto[];
  languageNames: KeyValueEntryDto[];
  index: number;
  removeLanguage: (index: number) => void;
}

const LanguageItem: FC<LanguageItemProps> = ({ levels, languageNames, index, removeLanguage }) => {
  const baseName = `languages[${index}]`;
  const { t } = useTranslation();

  return (
    <ul className="language">
      <Select
        name={`${baseName}.name`}
        options={languageNames}
        emptyOption={t('LANGUAGES.EMPTY_SELECTION_FROM_SELECT_-_LANGUAGE')}
      />
      <Select
        name={`${baseName}.level`}
        options={levels}
        emptyOption={t('LANGUAGES.EMPTY_SELECTION_FROM_SELECT_-_LEVEL')}
      />
      <CloseButton onClick={() => removeLanguage(index)} />
    </ul>
  );
};

export default LanguageItem;
